<div class="mb-3 row" *ngIf="formControl$ | async as control" [formGroup]="controlParent$ | async" [ngStyle]="columnLayout">
    <label [for]="field.id" class="col">{{field.label}}</label>
    <div class="col-md" [ngbTooltip]="field.toolTip" placement="top-left">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
        <input
                class="form-control"
                [id]="field.id"
                type="number"
                [formControl]="control"
                [sfAriaDescription]="field.toolTip"
                [attr.aria-required]="(isRequired$ | async)"
        />
        <sf-form-error *ngIf="control.invalid && !control.pristine" [control]="control" [label]="field.label"></sf-form-error>
    </div>
</div>
