import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class EsignMiscService {
    constructor(private _rpcClient: RpcClientService) {}

    logUIStats(metric: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "logUIStats", { metric });
    }

    getStandardDocumentTypeGroups(): Observable<String[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getStandardDocumentTypeGroups",
            {}
        );
    }

    getStandardTemplateGroups(): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getStandardTemplateGroups",
            {}
        );
    }

    getRecordableDocumentTypes(): Observable<any[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getRecordableDocumentTypes",
            {}
        );
    }

    getReferenceTypes(): Observable<string[]> {
        return this._rpcClient.makeRequest("esign", "getReferenceTypes", {});
    }

    getBillingReport(
        organizationIDs: string[],
        startDateString: string,
        endDateString: string,
        includeSampleOrgs: boolean
    ): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getBillingReport", {
            organizationIds: organizationIDs,
            startDateString: startDateString,
            endDateString: endDateString,
            includeSampleOrgs: includeSampleOrgs
        });
    }

    downloadFile(fileContent: any, fileType: string, fileName: string) {
        let blob = new Blob([fileContent], { type: fileType });
        let url = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    getStateNotaryURLs(): Observable<any[]> {
        return this._rpcClient.makeRequest("esign", "getStateNotaryURLs", {});
    }

    getDocBuilderConfig(): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getDocBuilderConfig", {});
    }

    arrayBufferToBase64(ab: ArrayBuffer) {
        let binary = "";
        let bytes = new Uint8Array(ab);
        let len = bytes.byteLength;

        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    base64ToArrayBuffer(base64: string) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }
}
