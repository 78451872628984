import { Injectable, OnInit } from "@angular/core";
import { RpcClientService, SelectableItemWithID } from "@sf/common";
import { Observable } from "rxjs";
import {
    NotaryCredentials,
    NotaryMethod,
    NotaryStatus,
    NotarySealResponse
} from "../interfaces/notary-credentials";
import { NotaryRequirements } from "../interfaces/notary-requirements";
import { SignatureInfo } from "../interfaces/signature-info";
import { UIOrganizationEEligibility } from "../interfaces/e-eligibility";
import { RonJournalRequirements } from "@sf/esign/common";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class UserService {
    constructor(private _rpcClient: RpcClientService) {
        this._rpcClient.makeRequest("userorg", "getUserIdentityTypes")
            .subscribe((result: any) => {
                Object.keys(result).forEach(key => {
                    this.externalIDTypes.push({id: key, label: result[key]});
                });
            });
    }

    private externalIDTypes: SelectableItemWithID[] = [];

    search(
        query: string,
        limit: number = 25,
        enhancedResults: boolean = false,
        includeArchived: boolean = false,
        services: string[] = null
    ) {
        return this._rpcClient.makeRequest("search", "searchBasicUsers", {
            query,
            limit,
            enhancedResults,
            includeArchived,
            services
        });
    }

    searchUsersWithEmail(
        queryString: string,
        limit: number,
        emailAddress: string
    ) {
        return this._rpcClient.makeRequest("search", "searchBasicUsers", {
            query: queryString,
            limit: limit,
            enhancedResults: false,
            includeArchived: false,
            emailAddress: emailAddress
        });
    }

    getUser(userId: string) {
        return this._rpcClient.makeRequest("userorg", "getBasicUser", {
            username: userId
        });
    }

    // for the currently logged-in user
    getChangePasswordSettings() {
        return this._rpcClient.makeRequest(
            "userorg",
            "getChangePasswordSettings",
            {}
        );
    }

    changePassword(oldPassword: string, newPassword: string) {
        return this._rpcClient.makeRequest("userorg", "changePassword", {
            password: oldPassword,
            newPassword: newPassword,
            confirm: newPassword
        });
    }

    suspendUser(username: string) {
        return this._rpcClient.makeRequest("userorg", "suspendUser", {
            username: username
        });
    }

    unsuspendUser(username: string) {
        return this._rpcClient.makeRequest("userorg", "unsuspendUser", {
            username: username
        });
    }

    // Todo: fixme: The following methods all seem like they should be moved to an 'esign' service

    getNotaryJournal(): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getNotaryJournal", {});
    }

    getNotaryJournalEntries(startDate: Date, endDate: Date): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign",
            "getNotaryJournalEntries",
            { startDate, endDate}
        );
    }

    getRonJournalRequirements(notaryId: string): Observable<RonJournalRequirements> {
        return this._rpcClient.makeRequest(
            "esign",
            "getRonJournalRequirements",
            { notaryId }
        );
    }

    getNotaryCredentials(): Observable<NotaryCredentials> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getNotaryCredentials",
            {}
        );
    }

    getNotaryCredentialsByUserId(userId: string): Observable<NotaryCredentials> {
        if (userId) {
            return this._rpcClient.makeRequest(
                    "userorg",
                    "getNotaryCredentialsByUserId",
                    {userId}
            );
        }
        else {
            return this.getNotaryCredentials();
        }
    }

    getSignatureInfo(): Observable<SignatureInfo> {
        return this._rpcClient.makeRequest("userorg", "getSignatureInfo", {});
    }

    getSignatureInfoByUserId(userId: string): Observable<SignatureInfo> {
        if (userId) {
            return this._rpcClient.makeRequest("userorg", "getSignatureInfoByUserId", {userId});
        }
        else {
            return this.getSignatureInfo();
        }
    }

    getStateNotaryRequirements(): Observable<NotaryRequirements> {
        return this._rpcClient.makeRequest(
            "esign",
            "getStateNotaryRequirements",
            {}
        );
    }

    acceptNotaryDisclaimer(userId: string): Observable<void> {
        if (userId) {
            return this._rpcClient.makeRequest("userorg", "acceptNotaryDisclaimerByUserId", {userId});
        }
        else {
            return this._rpcClient.makeRequest("userorg", "acceptNotaryDisclaimer");
        }
    }

    setCommissionState(state: string): Observable<void> {
        return this._rpcClient.makeRequest("userorg", "setCommissionState", {
            state
        });
    }

    setCommissionStateByUserId(state: string, userId: string): Observable<void> {
        if (userId) {
            return this._rpcClient.makeRequest("userorg", "setCommissionStateByUserId", {
                state,
                userId
            });
        }
        else {
            return this.setCommissionState(state);
        }
    }

    setNotaryMethods(ron: boolean, ipen: boolean, userId: string): Observable<Record<NotaryMethod, NotaryStatus>> {
        if (userId) {
            return this._rpcClient.makeRequest("userorg", "setNotaryMethodsByUserId", {
                ron, ipen, userId
            });
        }
        else {
            return this._rpcClient.makeRequest("userorg", "setNotaryMethods", {
                ron, ipen
            });
        }
    }

    generateNotarySeal(): Observable<NotarySealResponse> {
        return this._rpcClient.makeRequest("userorg", "generateNotarySeal", {});
    }

    generateNotarySealByUserId(userId: string): Observable<NotarySealResponse> {
        if (userId) {
            return this._rpcClient.makeRequest("userorg", "generateNotarySealByUserId", {userId});
        }
        else {
            return this.generateNotarySeal();
        }
    }

    updateNotaryActingCounty(fipsCode: string): Observable<void> {
        return this._rpcClient.makeRequest(
            "userorg",
            "updateNotaryActingCounty",
            { fipsCode }
        );
    }

    getNotaryDetailsFromSOS(state: string, commissionNumber: string): Observable<any> {
        return this._rpcClient.makeRequest(
                "userorg",
                "getNotaryDetailsFromSOS",
                {
                    state,
                    commissionNumber
                }
        );
    }

    setNotaryCommissionByUserId(
            commissionNumber: string,
            officialNumber: string,
            expirationDateString: string,
            name: { firstName: string; middleName: string; lastName: string },
            street1: string,
            street2: string,
            city: string,
            state: string,
            zipCode: string,
            countyName: string,
            actingCounty: string,
            nonResident: boolean,
            attorney: boolean,
            userId: string
    ): Observable<Record<NotaryMethod, NotaryStatus>> {
        if (userId) {
            return this._rpcClient.makeRequest("userorg", "setNotaryCommissionByUserId", {
                commissionNumber,
                officialNumber,
                expirationDateString,
                name,
                street1,
                street2,
                city,
                state,
                zipCode,
                countyName,
                actingCounty,
                nonResident,
                attorney,
                userId
            });
        }
        else {
            return this.setNotaryCommission(
                    commissionNumber,
                    officialNumber,
                    expirationDateString,
                    name,
                    street1,
                    street2,
                    city,
                    state,
                    zipCode,
                    countyName,
                    actingCounty,
                    nonResident,
                    attorney);
        }
    }

    setNotaryCommission(
        commissionNumber: string,
        officialNumber: string,
        expirationDateString: string,
        name: { firstName: string; middleName: string; lastName: string },
        street1: string,
        street2: string,
        city: string,
        state: string,
        zipCode: string,
        countyName: string,
        actingCounty: string,
        nonResident: boolean,
        attorney: boolean
    ): Observable<Record<NotaryMethod, NotaryStatus>> {
        return this._rpcClient.makeRequest("userorg", "setNotaryCommission", {
            commissionNumber,
            officialNumber,
            expirationDateString,
            name,
            street1,
            street2,
            city,
            state,
            zipCode,
            countyName,
            actingCounty,
            nonResident,
            attorney
        });
    }

    removeNotaryCredentials(userId: string): Observable<any> {
        if (userId) {
            return this._rpcClient.makeRequest(
                    "userorg",
                    "removeNotaryCredentialsByUserId",
                    { userId }
            );
        }
        else {
            return this._rpcClient.makeRequest(
                    "userorg",
                    "removeNotaryCredentials",
                    {}
            );
        }
    }

    removeNotaryCertificate(certificateID: string, userId: string): Observable<Record<NotaryMethod, NotaryStatus>> {
        if (userId) {
            return this._rpcClient.makeRequest(
                    "userorg",
                    "removeNotaryCertificateByUserId",
                    { certificateID, userId }
            );
        }
        else {
            return this._rpcClient.makeRequest(
                    "userorg",
                    "removeNotaryCertificate",
                    { certificateID: certificateID }
            );
        }
    }

    setNotaryCertificateID(certificateID: string, userId: string): Observable<any> {
        let data: any = {};
        data.certificateID = certificateID;
        if (userId) {
            return this._rpcClient.makeRequest(
                    "userorg",
                    "setNotaryCertificateIDByUserId",
                    { certificateID: certificateID, userId: userId }
            );
        }
        else {
            return this._rpcClient.makeRequest(
                    "userorg",
                    "setNotaryCertificateID",
                    { certificateID: certificateID }
            );
        }
    }

    setFailedNotaryCertificateID(certificateID: string, message: string): Observable<any> {
        return this._rpcClient.makeRequest(
            "userorg",
            "setFailedNotaryCertificateID",
            { certificateID, message }
        );
    }

    linkAccountToOnboard(userId: string, scNotaryId: string): Observable<any> {
        let data: any = {};
        return this._rpcClient.makeRequest(
                "esign",
                "linkAccountToOnboard",
                { userId, scNotaryId }
        );
    }

    public getEEligibilityByOrgID(
        organizationID: string
    ): Observable<UIOrganizationEEligibility> {
        return this._rpcClient.makeRequest("admin", "getEEligibilityByOrgID", {
            organizationID
        });
    }

    public getEEligibilityByState(
        stateStr: string
    ): Observable<UIOrganizationEEligibility> {
        return this._rpcClient.makeRequest("admin", "getEEligibilityByState", {
            stateStr
        });
    }

    verifyNotaryCertificate(
        pdfData: string,
        state: string
    ): Observable<string> {
            return this._rpcClient.makeRequest("esign", "verifyNotaryCertificate", {
                pdfData, state
            });
    }

    public isNotaryCommissionSetupNeeded(userId: string): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "esign",
            "notaryCommissionSetupNeeded",
            {
                userId: userId
            }
        );
    }

    // end of doc builder-specific methods

    public disableNotary(userId: string, comment: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "disableNotary", {
            userId, comment
        });
    }

    public enableNotary(userId: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "enableNotary", {
            userId: userId
        });
    }

    public removeSignatureForm(): Observable<void> {
        return this._rpcClient.makeRequest("userorg", "removeSignatureForm", {});
    }

    public removeSignatureAndInitialsByUserId(userId: string): Observable<void> {
        if (userId) {
            return this._rpcClient.makeRequest("userorg", "removeSignatureAndInitialsByUserId", {userId});
        }
        else {
            return this.removeSignatureAndInitials();
        }
    }

    public removeSignatureAndInitials(): Observable<void> {
        return this._rpcClient.makeRequest("userorg", "removeSignatureAndInitials", {});
    }

    updateSignature(userId: string, data: any, baseLine: number): Observable<any> {
        return this._rpcClient.makeRequest("document", "updateSignature", {
            documentId: userId + "/Signature_Form",
            fileHash: data.fileHash,
            fileID: data.fileID,
            baseLine: baseLine,
            type: data.type
        });
    }

    updateInitials(userId: string, data: any, baseLine: number): Observable<any> {
        return this._rpcClient.makeRequest("document", "updateInitials", {
            documentId: userId + "/Signature_Form",
            fileHash: data.fileHash,
            fileID: data.fileID,
            baseLine: baseLine,
            type: data.type
        });
    }

    public getUserTourSettings(userID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getUserTourSettings", {
            userID: userID
        });
    }

    public electronicNotarySealRequired(): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "electronicNotarySealRequired", {});
    }

    public electronicNotarySealRequiredByUserId(userId: string): Observable<boolean> {
        if (userId) {
            return this._rpcClient.makeRequest("userorg", "electronicNotarySealRequiredByUserId", { userId });
        }
        else {
            return this.electronicNotarySealRequired();
        }
    }

    public setNotaryApprovedByState(): Observable<Record<NotaryMethod,NotaryStatus>> {
        return this._rpcClient.makeRequest("userorg", "setNotaryApprovedByState", {});
    }

    public getUserPasswordPolicy(): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getUserPasswordPolicy", {});
    }

    public isTwoFactorAuthRequired(): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "isTwoFactorAuthRequired", {});
    }

    public generateAndSendValidationCode(phoneNumber: string, destination: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "generateAndSendValidationCode", {
            phoneNumber: phoneNumber,
            destination: destination
        });
    }

    public validateValidationCode(code: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "validateValidationCode", { code: code });
    }

    public getQRCodeImage(password: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getQRCodeImage", { password: password });
    }

    public unregisterAuthenticatorApp(password: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "unregisterAuthenticatorApp", { password: password });
    }

    public isRegisteredForTOTP(): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "isRegisteredForTOTP", { });
    }

    public getUserIdentities(username: string): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "getUserIdentities", { username: username });
    }

    public getExternalIDTypes(): SelectableItemWithID[] {
        return this.externalIDTypes;
    }

    public getExternalIDTypeLabel(type: string): string {
        if (!type) {
            return "";
        }
        let foundItem = this.externalIDTypes.find((thisType) => {
            if (thisType.id == type) {
                return thisType;
            }
            return null;
        });
        if (!foundItem)
            return null;
        return foundItem.label;
    }

    public saveUserIdentity(username: string, organizationID: string, idType: string, idValue: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "saveUserIdentity", {
            username: username,
            organizationID: organizationID,
            identityType: idType,
            externalID: idValue
        });
    }

    public deleteUserIdentity(username: string, organizationID: string, idType: string, externalID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "deleteUserIdentity", {
            username: username,
            organizationID: organizationID,
            identityType: idType,
            externalID: externalID
        });
    }

    public getEPCIdentityHash(username: string, organizationInstanceID: string): Observable<string> {
        return this._rpcClient.makeRequest("userorg", "getEPCIdentityHash", {
            username: username,
            organizationInstanceID: organizationInstanceID
        });
    }

    public isRonTrainingRequired(): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "isRonTrainingRequired", {});
    }

    public getRecipients(state: string, excludeCounties: boolean): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "getRecipients", {
            state: state,
            excludeCounties: excludeCounties
        });
    }

    public setNotaryStatePrerequisitesComplete(complete: boolean, userId: string): Observable<Record<NotaryMethod, NotaryStatus>> {
        if (userId) {
            return this._rpcClient.makeRequest("userorg", "setNotaryStatePrerequisitesCompleteByUserId", {complete, userId});
        }
        else {
            return this._rpcClient.makeRequest("userorg", "setNotaryStatePrerequisitesComplete", {complete});
        }
    }

    public setNextENotaryTrainingDate(date: string): Observable<Record<NotaryMethod, NotaryStatus>> {
        return this._rpcClient.makeRequest("userorg", "setNextENotaryTrainingDate", {date});
    }

    public isNotaryUser(userId: string): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "isNotaryUser", {
            userId: userId
        });
    }
}
