<sf-modal-header [title]="title"></sf-modal-header>
<div class="modal-body"
     id="sf-fee-details-container"
     *ngIf="viewModel$ | async as vm">
    <header class="row row-cols-auto g-0 justify-content-between align-items-center">
        <h3 class="fee-package-details col">
            {{ vm.recordingFeeInfo.organizationName }}
        </h3>
        <div *ngIf="isSuperUser && !docViewer" class="helper-docs-header col">
            <div class="helper-docs-header__left">
                Document {{ currentDocIndex + 1 }} /
                {{ navigableDocumentIDList.length }}
            </div>
            <div class="btn-group helper-docs-header__right"
                 role="group"
                 aria-label="Navigate to next or previous document in package">
                <button class="btn btn-secondary"
                        (click)="goToDocInPackage('previous')"
                        [disabled]="currentDocIndex === 0">
                    <fa-icon icon="chevron-left"></fa-icon>
                </button>
                <button class="btn btn-secondary"
                        (click)="goToDocInPackage('next')"
                        [disabled]="currentDocIndex === navigableDocumentIDList.length - 1">
                    <fa-icon icon="chevron-right"></fa-icon>
                </button>
            </div>
        </div>
    </header>
    <hr />

    <!--  Package Details  -->
    <div class="mb-4">
        <div class="fee-package-details">
            {{ vm.recordingFeeInfo.recipient.orgInfo.name }},
            {{ vm.recordingFeeInfo.recipient.orgInfo.address.state }}
        </div>
        <div class="fee-package-details">
            {{ vm.recordingFeeInfo.document.kindOfInstrument }}
        </div>
        <div class="fee-package-details">
            {{ vm.recordingFeeInfo.document.name }}
        </div>
        <div class="fee-package-details last-line">
            {{ vm.recordingFeeInfo.document.id.key }}
        </div>
    </div>

    <!--  Recipient fee tables  -->
    <div *ngFor="let recipient of getRecipientFeeDataArray(vm.form).controls; let i = index">
        <ng-container *ngIf="vm.recordingFeeInfo.recipientFeeData[i] as recipientData">
            <h2 class="recipient-fee-title">
                {{ recipientData.feeScheduleText == "Recording Fees" ? recipientData.recipientName + " Recording Fees" : recipientData.feeScheduleText }}
            </h2>
            <table class="table sf-table fees-table">
                <thead>
                    <tr>
                        <th>Description</th>
                        <th *ngIf="vm.recordingFeeInfo.showActualFees">
                            Fee
                        </th>
                        <th *ngIf="vm.recordingFeeInfo.showEstimatedFees">
                            Estimated Fee
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let feeGroup of getFeeArrayControls(recipient); let i = index" [formGroup]="feeGroup">
                        <ng-container *ngIf="feeGroup.value as fee">
                            <td>
                                <ng-container *ngIf="fee.type !== 'Other'">
                                    {{ fee.description }}
                                </ng-container>
                                <ng-container *ngIf="fee.type === 'Other'">
                                    <span>Other</span>
                                    <ng-container *ngIf="vm.recordingFeeInfo.showActualFees">
                                        <span *ngIf="!fee.actualOtherOverridable && !actualEditableBySuperUser">
                                            {{ fee.actualDescription ? "(" + fee.actualDescription + ")" : "" }}
                                        </span>
                                        <span *ngIf="fee.actualOtherOverridable || actualEditableBySuperUser">: <input type="text" formControlName="actualDescription"/>
                                        </span>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="vm.recordingFeeInfo.showEstimatedFees && !vm.recordingFeeInfo.showActualFees">
                                        <span
                                            *ngIf="!fee.estimateOtherOverridable && !estimateEditableBySuperUser">
                                            {{ fee.estimateDescription ? "(" + fee.estimateDescription + ")" : "" }}
                                        </span>
                                        <span *ngIf="fee.estimateOtherOverridable || estimateEditableBySuperUser">: <input type="text"  formControlName="estimateDescription"/>
                                        </span>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td *ngIf="vm.recordingFeeInfo.showActualFees">
                                <ng-container *ngIf="fee.type !== 'Other'">
                                    <sf-currency-input *ngIf="(fee.actualOverridable || actualEditableBySuperUser) && fee.amount === 1" formControlName="actualQuantity">
                                    </sf-currency-input>
                                    <span *ngIf="(!fee.actualOverridable && !actualEditableBySuperUser) || fee.amount !== 1">
                                        {{ fee.actualQuantity * fee.amount | currency }}
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="fee.type === 'Other'">
                                    <sf-currency-input
                                        *ngIf="fee.actualOtherOverridable || actualEditableBySuperUser" formControlName="actualOtherAmount">
                                    </sf-currency-input>
                                    <span *ngIf="!fee.actualOtherOverridable && !actualEditableBySuperUser">
                                        {{ fee.actualOtherAmount | currency }}
                                    </span>
                                </ng-container>
                            </td>
                            <td *ngIf="vm.recordingFeeInfo.showEstimatedFees">
                                <ng-container *ngIf="fee.type !== 'Other'">
                                    <sf-currency-input *ngIf="fee.estimateOverridable || estimateEditableBySuperUser" formControlName="estimateQuantity">
                                    </sf-currency-input>
                                    <span *ngIf="!fee.estimateOverridable && !estimateEditableBySuperUser">
                                        {{ fee.estimateQuantity | currency }}
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="fee.type === 'Other'">
                                    <sf-currency-input *ngIf="fee.estimateOtherOverridable || estimateEditableBySuperUser" formControlName="estimateOtherAmount">
                                    </sf-currency-input>
                                    <span *ngIf="!fee.estimateOtherOverridable && !estimateEditableBySuperUser">
                                        {{ fee.estimateOtherAmount | currency }}
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
                    <tfoot *ngIf="vm.recordingFeeInfo.showActualFees || vm.recordingFeeInfo.showEstimatedFees">
                        <tr>
                            <td>
                                <strong>Total:</strong>
                            </td>
                            <td *ngIf="vm.recordingFeeInfo.showActualFees">
                                <strong>{{ (vm.feeTotals[recipientData.recipientID] ? vm.feeTotals[recipientData.recipientID].actualTotal : 0) | currency }}
                                </strong>
                            </td>
                            <td *ngIf="vm.recordingFeeInfo.showEstimatedFees">
                                <strong>{{ (vm.feeTotals[recipientData.recipientID] ? vm.feeTotals[recipientData.recipientID].estimateTotal : 0) | currency }}
                                </strong>
                            </td>
                        </tr>
                    </tfoot>
            </table>
        </ng-container>
    </div>

    <!--  Super user controls  -->
    <div class="col" *ngIf="isSuperUser && !docViewer" [formGroup]="vm.form">
        <div class="d-flex flex-row justify-content-between align-items-center submission-fee-row">
            <strong class="me-1">{{submissionFeeLabel}}: </strong>
            <sf-currency-input
                class="number-field"
                formControlName="submissionFees"
                [currencyMaxDecimal]="2"
            ></sf-currency-input>
        </div>

        <div *ngIf="vm.recordingFeeInfo.showSalesTax"
             class="d-flex flex-row justify-content-between align-items-center sales-tax-row py-1">
            <strong class="me-1">Sales Tax: </strong>
            <sf-currency-input
                class="number-field"
                formControlName="salesTax"
                [currencyMaxDecimal]="2"
            ></sf-currency-input>
        </div>

        <div *ngIf="showMailFee"
            class="d-flex flex-row justify-content-between align-items-center mail-fee-row py-1">
            <strong class="me-1">Mail Fee: </strong>
            <sf-currency-input
                class="number-field"
                formControlName="mailFees"
                [currencyMaxDecimal]="2"
            ></sf-currency-input>
        </div>

        <div *ngIf="totalSurcharge > 0"
             class="d-flex flex-row justify-content-between align-items-center credit-card-fee-row py-1">
            <strong class="me-1">Credit Card Surcharge Fee: </strong>
            <strong>{{totalSurcharge | currency}} ({{surcharge}}%)</strong>
        </div>

        <div class="align-items-center mb-0">
            <label *ngIf="actualEditableBySuperUser" class="me-2 mt-2">
                <input type="checkbox" name="" id="schedulePayments" [(ngModel)]="paymentsScheduled" [ngModelOptions]="{standalone: true}"/>
                Schedule Payments
            </label>
            <button class="btn btn-secondary fee-action-button me-2" (click)="zeroAllFees()">
                Zero All Fees
            </button>
            <button class="btn btn-secondary fee-action-button me-2" (click)="manageTransactions()">
                Manage Transactions
            </button>
        </div>

        <div *ngIf="maxAllowedIncrease > 0" style="margin-top: 10px">
            Note: The submitter has requested this document to be rejected if the total fee exceeds <strong>${{maxAllowedIncrease}}</strong>
        </div>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
