import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { SfValidators } from "@sf/common";

@Component({
    selector: "sf-edit-echeck-memo-dialog",
    templateUrl: "./edit-echeck-memo-dialog.component.html",
    styleUrls: ["./edit-echeck-memo-dialog.component.scss"]
})
export class EditEcheckMemoDialogComponent implements OnInit, OnDestroy {
    @Input() memo: string;
    @Input() editMode: boolean;

    /** Private Variables **/
    private _onDestroy: Subject<void> = new Subject<void>();

    /** Public Variables **/
    primary: ModalButton;
    secondary: ModalButton;
    memoForm: UntypedFormGroup;
    dialogText: string =
        "The memo is displayed in the memo line of the eCheck payment. By default, the package name is used.";
    addMemoMissingClass: boolean = false;
    memoMissingStyle: any = {};

    constructor(
        private _fb: UntypedFormBuilder,
        private _activeModal: NgbActiveModal
    ) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "OK",
            disabled: false,
            callback: this.saveChanges.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.abort.bind(this)
        };

        this.memoForm = this._fb.group({
            memo: [this.memo, SfValidators.echeckMemoNoUrl]
        });

        if (!this.memoForm.get("memo").value) {
            this.addMemoMissingClass = true;
            this.memoMissingStyle = {
                background: "#fef8f2",
                "border-color": "#f8cba6"
            };
        }

        this.catchFormChanges();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
    }

    catchFormChanges() {
        this.memoForm
            .get("memo")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                if (val) {
                    this.addMemoMissingClass = false;
                    this.memoMissingStyle = {};
                } else {
                    this.addMemoMissingClass = true;
                    this.memoMissingStyle = {
                        background: "#fef8f2",
                        "border-color": "#f8cba6"
                    };
                }
                this.primary.disabled = !!this.memoForm.get("memo").errors;
            });
    }

    saveChanges() {
        this._activeModal.close(this.memoForm.get("memo").value);
    }

    abort() {
        this._activeModal.dismiss(null);
    }
}
