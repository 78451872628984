import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    Input,
    OnInit
} from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    ModalButton,
    SelectableItemWithID,
    SortUtilitiesService
} from "@sf/common";
import { SessionOrganization, SessionService } from "@sf/common";
import {
    Phone,
    RoleService,
    SelectableOrganization,
    TokenService,
    User,
    UserOrgService,
    UserService
} from "@sf/userorg/common";
import { GrowlService } from "@sf/common";
import { SelectableItem, SfValidators, SpinnerService } from "@sf/common";
import { OrganizationMultiSelectDialogComponent } from "../organization-multi-select-dialog/organization-multi-select-dialog.component";
import { first } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { EncryptionService } from "@sf/common";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { DocumentBuilderGuideTask } from "@sf/esign/common";

interface Name {
    prefix: string;
    first: string;
    middle: string;
    last: string;
    title: string;
}

interface UserData {
    username: string;
    tokenID: string;
    tokenType: string;
    name: Name;
    title: string;
    phone: Phone;
    email: string;
    tokenEmail: string;
    emails: string;
    selectedOrgIDs: string[];
    selectedRoleIDs: string[];
    externalID?: string;
    externalIDType?: string;
}

// prettier-ignore
@Component({
    selector: "sf-add-user-dialog",
    templateUrl: "./add-user-dialog.component.html",
    styleUrls: ["./add-user-dialog.component.scss"]
})
export class AddUserDialogComponent implements OnInit, AfterViewInit {
    @Input()
    isCreateUserMode: boolean;
    @Input()
    orgID: string; // org to add to
    @Input()
    orgName: string; // org to add to
    @Input()
    orgUrl: string; // organization URL
    @Input()
    bulkAdd: boolean; // if multiple email addresses
    @Input()
    editUserData: any; // data to set into form, if editing a pending user

    primary: ModalButton;
    secondary: ModalButton;
    nextButton: ModalButton;
    backButton: ModalButton;
    warning: SafeHtml = "";
    userInstructions: string;
    allowUsernameDefinition = false;
    currentIndex = 0;
    fixedOrg = false;
    isSuperUser = false;
    isEditMode = false;
    addData: UserData;
    selectedOrgName: string;
    title: string;
    processing = false;
    loadingPage2 = false;
    existingUserCount = 0;
    existingUserID: string = null;
    existingRoles: any[] = null;
    orgOptions: SelectableOrganization[];
    stepCount = 2;
    availableUsernames: SelectableItem[];
    modalTitle: string;
    selectedOrgsTitle = "Click to Select";
    domainRestrictedOrgID: string = null;
    sameOrg = false;
    selectedUser: any = null;

    addUserForm: UntypedFormGroup;
    basicInfoForm: UntypedFormGroup;

    prefixOptions = [
        { option: "", label: "(none)" },
        { option: "Mr", label: "Mr" },
        { option: "Mrs", label: "Mrs" },
        { option: "Miss", label: "Miss" },
        { option: "Ms", label: "Ms" },
        { option: "Mx", label: "Mx" },
        { option: "Dr", label: "Dr" }
    ];
    externalIDTypeOptions: SelectableItemWithID[] = [];

    private cachedEmail: string;
    private defaultAddInstructions =
        "The fields on this page are optional. The data can be provided by you or by the person being invited.";
    private defaultCreateInstructions = "";
    private orgWarning = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private sanitizer: DomSanitizer,
        private activeModal: NgbActiveModal,
        private userOrgService: UserOrgService,
        private sessionService: SessionService,
        private encryptionService: EncryptionService,
        private tokenService: TokenService,
        private growlService: GrowlService,
        private elementRef: ElementRef,
        private router: Router,
        private route: ActivatedRoute,
        private spinnerService: SpinnerService,
        private userorgService: UserOrgService,
        private roleService: RoleService,
        private modalService: NgbModal,
        private componentFactoryResolver: ComponentFactoryResolver,
        private userService: UserService
    ) {
        this.addUserForm = formBuilder.group({
            bulkAdd: [false],
            emailSingle: ["", [SfValidators.emailValidator]],
            emailMulti: [""]
        });
        this.basicInfoForm = formBuilder.group({
            username: [""],
            firstName: [""],
            middleName: [""],
            lastName: [""],
            title: [""],
            phone: ["", SfValidators.phoneValidator],
            extension: [
                "",
                [Validators.maxLength(9), SfValidators.numericValidator]
            ],
            externalID: [""]
        });
    }

    ngOnInit() {
        this.clearData();

        if (this.editUserData) {
            this.isEditMode = true;

            this.addData.tokenID = this.editUserData.tokenID;
            this.addData.tokenType = this.editUserData.tokenType;
            this.addData.tokenEmail = this.editUserData.tokenEmail;

            this.addUserForm.patchValue({
                emailSingle: this.editUserData.email,
                bulkAdd: this.bulkAdd
            });
            if (this.editUserData.assignments) {
                this.editUserData.assignments.forEach((assignment: any) => {
                    this.addData.selectedRoleIDs.push(assignment.id);
                });
            }
            this.basicInfoForm.patchValue({
                firstName: this.editUserData.firstName,
                middleName: this.editUserData.middleName,
                lastName: this.editUserData.lastName,
                title: this.editUserData.title,
                phone: this.editUserData.phone,
                extension: this.editUserData.extension,
                externalID: this.editUserData.externalID,
                externalIDType: this.editUserData.externalIDType
            });
        }

        // Modal buttons
        this.modalTitle = "Add User";
        if (this.isEditMode) {
            this.modalTitle = "Approve User";
        } else if (this.isCreateUserMode) {
            this.modalTitle = "Create User";
        }

        this.primary = {
            text: this.modalTitle,
            disabled: false,
            callback: this.addUser.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.closeDialog.bind(this)
        };
        this.nextButton = {
            text: "Next",
            hidden: false,
            callback: this.nextTab.bind(this)
        };
        this.backButton = {
            text: "Back",
            hidden: false,
            callback: this.backTab.bind(this)
        };
        this.enableDisable();

        this.selectedOrgName = this.orgName;

        if (this.isCreateUserMode) {
            this.userInstructions = this.defaultCreateInstructions;
        } else {
            this.userInstructions = this.defaultAddInstructions;
        }

        if (this.orgID) {
            // organization was pre-selected
            this.fixedOrg = true;
            this.userOrgService.getOrganizationSecurity(this.orgID)
                .pipe(first())
                .subscribe((passwordPolicyData: any) => {
                    if (
                        passwordPolicyData &&
                        passwordPolicyData.hasOwnProperty(
                            "allowUsernameDefinition"
                        )
                    ) {
                        this.allowUsernameDefinition =
                            passwordPolicyData.allowUsernameDefinition;
                    }
                });
            this.addData.selectedOrgIDs = [this.orgID];
        } else {
            this._orgsChanged();
        }

        this.title = this.isCreateUserMode ? "Create API User" : "Add User";
        if (this.orgName != null && this.orgName.length > 0) {
            this.title += " for " + this.orgName;
        }

        this.isSuperUser = this.sessionService.isSuperUser();
        if (!this.isSuperUser) {
            this._buildOrganizationList();
        }

        this.externalIDTypeOptions = this.userService.getExternalIDTypes();
    }

    ngAfterViewInit() {}

    clearData() {
        this.addData = {
            username: "",
            tokenID: "",
            tokenType: "",
            name: {
                prefix: "",
                first: "",
                middle: "",
                last: "",
                title: ""
            },
            title: "",
            phone: {
                areaCode: "",
                prefix: "",
                suffix: "",
                extension: ""
            },
            email: "",
            emails: "",
            tokenEmail: "",
            selectedOrgIDs: null,
            selectedRoleIDs: [],
            externalIDType: null,
            externalID: null
        };

        this.cachedEmail = null;
    }

    enableDisable() {
        this.primary.disabled =
            this.processing ||
            (this.isCreateUserMode && this.currentIndex == 0);
        this.nextButton.hidden = this.currentIndex > 0 || this.bulkAdd;
        this.backButton.hidden = this.currentIndex == 0;
    }

    /*
    getErrorMessage(): string {
        return null; // no errors detected
    }
    */

    closeDialog(response: boolean) {
        this.activeModal.close(!!response);
    }

    rolesChanged(event: any) {
        this.addData.selectedRoleIDs = event.$selection;
    }

    // see if there is already a user with the email address
    checkUniqueEmail() {
        this.loadingPage2 = true;
        this.userOrgService
            .findUsersByEmail(this.addData.email)
            .pipe(first())
            .subscribe((users: User[]) => {
                this.loadingPage2 = false;
                if (users && users.length) {
                    this.warning = "Email address is already in use: " + this.addData.email;
                    if (users.length == 1) {
                        if (this.isSuperUser) {
                            // notice the ton of work to make this seemingly-simple link work
                            this.existingUserID = users[0].username;
                            let appendage = ". <button id='uclicker' class='btn btn-link sf-btn-link' (click)='clickEmail()'>Click here</button> to view existing user.";
                            this.warning = this.sanitizer.bypassSecurityTrustHtml(this.warning + appendage);
                            setTimeout(() => {
                                this.elementRef.nativeElement.querySelector("#uclicker")
                                    .addEventListener("click", this.clickEmail.bind(this));
                                this.addUserForm.controls['emailSingle'].setErrors({'incorrect': true});
                            }, 400);
                        }
                    } else {
                        if (this.isSuperUser) {
                            this.warning = "Email address is already in use by multiple users: " + this.addData.email;
                            setTimeout(() => {
                                this.addUserForm.controls['emailSingle'].setErrors({'incorrect': true});
                            }, 400);
                        }
                    }
                    this.currentIndex = 0;
                    this.enableDisable();
                } else {
                    this.addUserForm.controls['emailSingle'].setErrors(null);
                }
            }, () => {
                this.loadingPage2 = false;
            });
    }

    clickEmail() {
        this.closeDialog(false);
        let newUrl = "/admin/user/" + this.encryptionService.scrambleEncode(this.existingUserID) + "/contact/";
        this.router.navigateByUrl(newUrl);
    }

    clickSecurity() {
        this.closeDialog(false);
        let newUrl = "";
        let orgID = this.addData.selectedOrgIDs[0];
        if (this.domainRestrictedOrgID) {
            orgID = this.domainRestrictedOrgID;
        }
        // this.router.navigate(["../security"], { relativeTo: this.route});
        if (this.isSuperUser) {
            newUrl = this.orgUrl + orgID + "/config/security";
        } else {
            newUrl = this.orgUrl + orgID + "/security";
        }
        this.router.navigateByUrl(newUrl);
    }

    getExistingData() {
        if (this.cachedEmail && this.cachedEmail == this.addData.email) {
            // no change
            return;
        }
        this.cachedEmail = null;
        this.loadingPage2 = true;
        this.userOrgService.findUsersByEmail(this.addData.email)
            .pipe(first())
            .subscribe((users: any[]) => {
                this.loadingPage2 = false;
                this.existingUserCount = 0;
                this.addData.name = {
                    prefix: null,
                    first: null,
                    middle: null,
                    last: null,
                    title: null
                };
                this.addData.title = "";
                this.addData.phone = {
                    areaCode: null,
                    prefix: null,
                    suffix: null,
                    extension: null
                };
                let phoneText = "";
                if (users && users.length) {
                    this.existingUserCount = users.length;
                    if (users.length == 1) {
                        this.cachedEmail = this.addData.email;
                        let user = users[0];
                        this.selectedUser = user;
                        this.userInstructions = "This is the user that will be invited:";
                        this.addData.name = this.userorgService.cloneObj(user.name);
                        this.addData.title = user.title;
                        this.addData.phone = this.userorgService.cloneObj(user.phone);
                        if (user.phone && user.phone.areaCode) {
                            phoneText = user.phone.areaCode + "." + user.phone.prefix + "." + user.phone.suffix
                        }
                        // hack to fix silliness on back end where parts of name are sometimes different
                        if (this.addData.name) {
                            let anyName: any = this.addData.name;
                            if (!anyName.first && !!anyName.firstName) {
                                this.addData.name.first = anyName.firstName;
                            }
                            if (!anyName.last && !!anyName.lastName) {
                                this.addData.name.last = anyName.lastName;
                            }
                            if (!anyName.middle && !!anyName.middleName) {
                                this.addData.name.middle = anyName.middleName;
                            }
                        }
                        // get that user's current roles
                        // we couldn't get here unless the logged-in user has access to 'user'
                        this.roleService.getAssignmentsForUser(user.username)
                            .subscribe((roleResponse: any) => {
                                let rolesMap: any = roleResponse["roles"];
                                this.existingRoles = rolesMap;
                                this.checkSameOrganization();
                            });
                    } else {
                        this.userInstructions =
                                "There are " +
                                users.length +
                                " existing users with the email address '" +
                                this.addData.email + "'. Any one of them might accept your invitation.";
                    }
                } else {
                    this.userInstructions = this.defaultAddInstructions;
                }
                this.basicInfoForm.patchValue({
                    firstName: this.addData.name.first,
                    middleName: this.addData.name.middle,
                    lastName: this.addData.name.last,
                    title: this.addData.title,
                    phone: phoneText,
                    extension: this.addData.phone.extension
                });
            }, () => {
                this.loadingPage2 = false;
            });
    }

    nextTab() {
        let message = this.validateFirstPage();

        if (message) {
            this.warning = message;
            return;
        }

        this.warning = null;
        this.currentIndex++;
        this.enableDisable();
    }

    backTab() {
        this.currentIndex--;
        this.enableDisable();
        this.warning = null;
    }

    orgIsSuspended(org: SessionOrganization): boolean {
        let foundService = org.activeServices.find((service) => {
            return service.status === "SUSPENDED";
        });
        return !!foundService;
    }

    validateFirstPage() {
        let message = null;

        this.addData.email = this.addUserForm.controls.emailSingle.value;
        this.addData.emails = this.addUserForm.controls.emailMulti.value;

        if (this.addData.email) {
            this.addData.email = this.addData.email.trim();
        }

        if (!this.addData.selectedOrgIDs || !this.addData.selectedOrgIDs.length) {
            return "You must select an organization";
        }

        let suspendedName: string = null;
        let foundSuspended = !this.isSuperUser && this.addData.selectedOrgIDs.find((orgID) => {
            let thisOrg = this.sessionService.getPartialOrganization(orgID);
            if (this.orgIsSuspended(thisOrg)) {
                suspendedName = thisOrg.name;
            }
            return !!suspendedName;
        });
        if (foundSuspended) {
            return ("Unable to add users to organization '" + suspendedName +
                    "'. Please resolve service suspension issues.");
        }

        if (this.bulkAdd) {
            if (!this.addData.emails) {
                message = "Please enter a list of email addresses separated by commas";
            } else if (this.addData.selectedOrgIDs.length === 1 && this.addData.selectedOrgIDs[0] === "SIMPFL" &&
                    !this.userOrgService.validateSimplifileEmailDomain(this.addData.emails)) {
                message = "Adding users to the Simplifile organization requires all to have a simplifile.com" +
                        ", theice.com, ice.com, or elliemae.com email address.";
            }
        } else {
            if (!this.addData.email) {
                message = "Please enter an email address";
            } else if (!this.userOrgService.validateEmail(this.addData.email)) {
                message = "Please enter a valid email address";
            } else if (this.addData.selectedOrgIDs.length === 1 && this.addData.selectedOrgIDs[0] === "SIMPFL" &&
                    !this.userOrgService.validateSimplifileEmailDomain(this.addData.email)) {
                message = "Adding a user to the Simplifile organization requires a simplifile.com" +
                        ", theice.com, ice.com, or elliemae.com email address.";
            }
        }

        if (!this.addData.selectedRoleIDs || !this.addData.selectedRoleIDs.length) {
            message = "You must select at least one role";
        }

        if (this.findOrgIDInList(this.addData.selectedOrgIDs, "SIMPFL") &&
                !this.sessionService.hasPermission("admin_add_superuser", "SIMPFL")) {
            message = "You do not have permission to invite users to the Simplifile organization";
        }

        if (this.isCreateUserMode) {
            this.checkUniqueEmail();
        } else if (!this.bulkAdd) {
            this.getExistingData();
        }

        return message;
    }

    findOrgIDInList(list: string[], orgID: string) {
        if (!list) {
            return false;
        }
        let found = list.find((orgIDToFind) => {
            return orgIDToFind == orgID;
        });
        return found;
    }

    validateSecondPage() {
        let prePhone: string = this.basicInfoForm.controls.phone.value;
        let extension: string = this.basicInfoForm.controls.extension.value;

        let phone = SfValidators.getNumericDigits(prePhone);

        if (prePhone && phone.length != 10) {
            return "Phone Number (optional) must be 10 digits long";
        }
        if (extension && !SfValidators.NUMERIC_REGEXP.test(extension)) {
            return "Extension (optional) must be all numbers";
        }
        if (extension && extension.length > 9) {
            return "Extension cannot be longer than 9 digits";
        }

        if (phone) {
            this.addData.phone.areaCode = phone.substring(0, 3);
            this.addData.phone.prefix = phone.substring(3, 6);
            this.addData.phone.suffix = phone.substring(6, 10);
        }

        if (extension) {
            this.addData.phone.extension = extension;
        }

        this.addData.name.first =
            this.basicInfoForm.controls.firstName.value;
        this.addData.name.middle =
            this.basicInfoForm.controls.middleName.value;
        this.addData.name.last = this.basicInfoForm.controls.lastName.value;
        this.addData.name.title = this.basicInfoForm.controls.title.value;
        this.addData.title = this.basicInfoForm.controls.title.value;
        this.addData.username = this.basicInfoForm.controls.username.value;
        this.addData.externalID = this.basicInfoForm.controls.externalID.value;

        if (this.addData.username) {
            if (this.addData.username.length < 7) {
                return "Username must be at least 7 characters long";
            }
            if (this.addData.username.includes(" ")) {
                return "Username must not include spaces";
            }
        }

        if (this.isCreateUserMode) {
            if (!this.addData.username) {
                return "Username is required";
            }
            if (!this.addData.phone || !this.addData.phone.prefix) {
                return "Phone is missing or invalid";
            }
            if (!this.addData.name.first) {
                return "First Name is required";
            }
            if (!this.addData.name.last) {
                return "Last Name is required";
            }
            if (!this.addData.title) {
                return "Title is required";
            }
        }

        if (this.addData.externalIDType) {
            if (!this.addData.externalID) {
                return "Enter an external ID";
            }
        } else if (this.addData.externalID) {
            return "Select an external ID Type";
        }

        return null;
    }

    checkSameOrganization() {
        if (this.existingUserCount != 1) {
            return;
        }
        if (!this.existingRoles) {
            return;
        }

        if (this.addData.selectedOrgIDs.length > 1) {
            // only works if only one org selected
            return;
        }

        let selectedOrgID: string = this.addData.selectedOrgIDs[0];
        this.sameOrg = false;

        let existingRoleIDs: string[] = Object.keys(this.existingRoles);
        let existingRoleMaps: any[] = Object.values(this.existingRoles);
        for (let i = 0; i < existingRoleIDs.length; i++) {
            let existingRoleID: string = existingRoleIDs[i];
            let existingAssignmentsForRole: any[] = existingRoleMaps[i].assignments;
            existingAssignmentsForRole.forEach((existingAssignment: any) => {
                if (existingAssignment.id == selectedOrgID) {
                    this.sameOrg = true;
                }
            });
        }

        if (this.sameOrg) {
            this.userInstructions = "This is the user that will get the new role(s):";
        }
    }

    checkSameRoles(): string {
        if (this.existingUserCount != 1) {
            return null;
        }
        if (!this.existingRoles) {
            return null;
        }

        let selectedOrgIDs: string[] = this.addData.selectedOrgIDs;
        let foundNewRole = false;
        this.addData.selectedRoleIDs.forEach((selectedRoleID: string) => {
            let foundRole = false;
            let existingRoleIDs: string[] = Object.keys(this.existingRoles);
            let existingRoleMaps: any[] = Object.values(this.existingRoles);
            for (let i = 0; i < existingRoleIDs.length; i++) {
                let existingRoleID: string = existingRoleIDs[i];
                if (existingRoleID == selectedRoleID) {
                    let existingAssignmentsForRole: any[] = existingRoleMaps[i].assignments;
                    let foundOrg = false;
                    selectedOrgIDs.forEach((orgID: string) => {
                        existingAssignmentsForRole.forEach((existingAssignment: any) => {
                            if (existingAssignment.id == orgID) {
                                foundOrg = true;
                            }
                        });
                    });
                    if (foundOrg) {
                        foundRole = true;
                    }
                }
            }
            if (!foundRole) {
                foundNewRole = true;
            }
        });

        if (foundNewRole) {
            return null;
        }
        return "The user you are adding already exists, and already has the role(s) you selected.";
    }

    _orgsChanged() {
        // check if we need to clear warning
        if (this.warning && this.orgWarning) {
            this.orgWarning = false;
            this.warning = null;
        }

        // could be one or more orgs
        if (
            this.addData.selectedOrgIDs &&
            this.addData.selectedOrgIDs.length >= 1
        ) {
            // check security policies if username allowed; if one is false, it is false for all
            let allowUsernameDefinition = true;
            this.userOrgService
                .getOrganizationSecurityPolicies(this.addData.selectedOrgIDs)
                .pipe(first())
                .subscribe((policies: any) => {
                    policies.forEach((policy: any) => {
                        if (policy && !policy.allowUsernameDefinition) {
                            allowUsernameDefinition = false;
                        }
                    });
                    this.allowUsernameDefinition = allowUsernameDefinition;
                });

            if (
                this.addData.selectedOrgIDs.length > 1 &&
                this.addData.selectedOrgIDs.includes("SIMPFL")
            ) {
                this.warning =
                    "The Simplifile organization must be selected by itself.";
                this.orgWarning = true;
            }
        } else {
            this.addData.selectedOrgIDs = null;
        }
    }

    orgSelectDialog() {
        const modalRef = this.modalService.open(
            OrganizationMultiSelectDialogComponent,
            {
                backdrop: "static",
                //size: "lg"
                windowClass: "select-orgs-dialog"
            }
        );
        const modalInstance = modalRef.componentInstance;

        modalInstance.initialSelection = this.addData.selectedOrgIDs;
        modalInstance.onlyAdminOrgs = true;
        modalInstance.ignoreDisabled = true;
        modalInstance.organizationOptions = this.orgOptions;

        modalRef.result.then((results: SelectableOrganization[]) => {
            if (results) {
                if (results.length == 0) {
                    this.selectedOrgsTitle = "Click to Select";
                } else if (results.length == 1) {
                    this.selectedOrgsTitle = results[0].label;
                } else if (results.length == 2) {
                    this.selectedOrgsTitle = results[0].label + ", " + results[1].label;
                } else {
                    this.selectedOrgsTitle = "" + results.length + " Organizations Selected";
                }
                this.addData.selectedOrgIDs = results.map((org) => {
                    return org.id;
                });
                this._orgsChanged();
            }
        }, () => {
            // nothing
        });
    }

    _sendAddUser() {
        let params = {
            organizationIDs: this.addData.selectedOrgIDs,
            roleIDs: this.addData.selectedRoleIDs,
            email: this.addData.email,
            name: this.addData.name,
            title: this.addData.title,
            phone: this.addData.phone,
            username: <string>null,
            externalIDType: this.addData.externalIDType,
            externalID: this.addData.externalID
        };
        if (this.addData.username) {
            params.username = this.addData.username;
        }

        let promise = this.tokenService.inviteUser(params);
        return promise;
    }

    _sendAddUsers() {
        let params = {
            organizationIDs: this.addData.selectedOrgIDs,
            roleIDs: this.addData.selectedRoleIDs,
            emails: this.addData.emails
        };
        let observable = this.tokenService.inviteUsers(params);
        return observable;
    }

    _sendApprovePendingUser() {
        let observable = this.tokenService.approvePendingUser(this.addData.tokenID, this.addData.email, this.addData.tokenEmail, this.addData.username);
        return observable;
    }

    _sendCreateUser() {
        let params = {
            orgID: this.addData.selectedOrgIDs[0],
            roleIDs: this.addData.selectedRoleIDs,
            email: this.addData.email,
            username: this.addData.username,
            name: this.addData.name,
            title: this.addData.title,
            phone: this.addData.phone,
            externalIDType: this.addData.externalIDType,
            externalID: this.addData.externalID
        };
        return this.userOrgService.createApiUser(params);
    }

    addUser(event: any) {
        this.warning = null;
        this.availableUsernames = null;

        let errorMessage = this.validateFirstPage();

        if (!errorMessage) {
            errorMessage = this.validateSecondPage();
        }

        if (!errorMessage) {
            errorMessage = this.checkSameRoles();
        }

        if (errorMessage) {
            this.warning = errorMessage;
            this.processing = false;
            this.primary.disabled = false;
            return;
        }

        if (this.isEditMode) {
            this.spinnerService.startSpinner();
            this.processing = true;
            this.primary.disabled = true;
            // send the invitation
            this._sendAddUser().subscribe((result: any) => {
                if (result) {
                    if (result.message) {
                        this.warning = result.message;
                        return;
                    }
                    this.growlService.success(result, "Invitation URL: ", {
                        disableTimeOut: true
                    });
                }

                let message = "An invitation has been sent by email to: " + this.addData.email + " for " +
                        this.addData.selectedOrgIDs.length + " organization(s)";
                this.growlService.success(message);

                // now 'verify' the 'approval' token
                this._sendApprovePendingUser().subscribe((approveResult: any) => {
                    this.processing = false;
                    this.primary.disabled = false;
                    this.spinnerService.stopSpinner();

                    this.closeDialog(true); // close the dialog
                }, () => {
                    // error
                    this.processing = false;
                    this.primary.disabled = false;
                    this.spinnerService.stopSpinner();
                });
            }, (except: any) => {
                this.spinnerService.stopSpinner();
                this.processing = false;
                this.primary.disabled = false;
                this.growlToDialogError(except);
            });
        } else if (this.isCreateUserMode) {
            this.addData.selectedOrgIDs.forEach((orgId, index) => {
                this.addData.selectedOrgIDs[0] = orgId;
                this.processing = true;
                this.primary.disabled = true;
                this.spinnerService.startSpinner();
                this._sendCreateUser()
                    .pipe(first())
                    .subscribe((results: any) => {
                        this.processing = false;
                        this.primary.disabled = false;
                        if (results.message) {
                            results.availableUsernames.forEach((username: string) => {
                                let usernameObject: SelectableItem = {
                                    option: username,
                                    label: username
                                };
                                if (!this.availableUsernames) {
                                    this.availableUsernames = [];
                                }
                                this.availableUsernames.push(usernameObject);
                            });
                            this.warning = results.message;
                            return;
                        }
                        this.closeDialog(true);
                        let message = "User " + this.addData.username + " created";
                        this.growlService.success(message);
                    }, (/*error*/) => {
                        this.processing = false;
                        this.primary.disabled = false;
                    }, () => {
                        this.spinnerService.stopSpinner();
                        this.primary.disabled = false;
                    });
            });
        } else if (this.bulkAdd) {
            this.spinnerService.startSpinner();
            this.processing = true;
            this.primary.disabled = true;
            this._sendAddUsers().subscribe((count: number) => {
                this.processing = false;
                this.primary.disabled = false;
                this.spinnerService.stopSpinner();
                this.closeDialog(true);
                let message = "" + count + " invitation" + (count > 1 ? "s" : "") + " sent by email for " +
                        this.addData.selectedOrgIDs.length + " organizations.";
                this.growlService.success(message);
            }, (except: any) => {
                this.spinnerService.stopSpinner();
                this.processing = false;
                this.primary.disabled = false;
                this.growlToDialogError(except);
            });
        } else if (this.sameOrg) {
            // just assign the role(s)
            this.roleService.addAssignmentsToOrganization([this.selectedUser.userID], this.addData.selectedRoleIDs,
                    this.addData.selectedOrgIDs[0])
                .pipe(first())
                .subscribe((response: any) => {
                    this.growlService.success("Role(s) assigned successfully");
                    this.closeDialog(true);
                }, () => {
                    // error message will display
                });
        } else {
            this.spinnerService.startSpinner();
            this.processing = true;
            this.primary.disabled = true;
            this._sendAddUser().subscribe((result: any) => {
                this.processing = false;
                this.primary.disabled = false;
                this.spinnerService.stopSpinner();
                if (result) {
                    if (result.message) {
                        this.warning = result.message;
                        return;
                    }
                    this.growlService.success(result, "Invitation URL: ", {
                        disableTimeOut: true
                    });
                }

                let message = "An invitation has been sent by email to: " + this.addData.email + " for " +
                        this.addData.selectedOrgIDs.length + " organization(s)";
                this.growlService.success(message);
                this.closeDialog(true);
            }, (except: any) => {
                this.spinnerService.stopSpinner();
                this.processing = false;
                this.primary.disabled = false;
                this.growlToDialogError(except);
            });
        }
    }

    growlToDialogError(except: any) {
        let msg: string = except.error.errorMessage;
        if (msg) {
            // notice the ton of work to make this seemingly-simple link work
            if (msg.includes("email address") && msg.includes("not allowed")) {
                this.warning = this.sanitizer.bypassSecurityTrustHtml(
                        "Email domain is not allowed. Please review the " +
                        "<button id='uclicker' class='btn btn-link sf-btn-link'>Organization Security</button>" +
                        " settings.");
                let strt = msg.indexOf("for organization");
                if (strt) {
                    this.domainRestrictedOrgID = msg.substring(strt + 17);
                }
            }
            setTimeout(() => {
                this.elementRef.nativeElement.querySelector("#uclicker")
                    .addEventListener("click", this.clickSecurity.bind(this));
                this.addUserForm.controls['emailSingle'].setErrors({'incorrect': true});
            }, 400);
        }
    }

    // build the list of orgs an administrator user can choose from
    _buildOrganizationList() {
        let organizations = this.sessionService.getAllOrganizationsWithPermission("organization_users");
        this.orgOptions = organizations.map((organization) => {
            let selOrg: SelectableOrganization = {
                id: organization.id,
                isState: false,
                label: organization.name + " (" + organization.id + ")",
                selected: false
            };
            return selOrg;
        });
        this.orgOptions = this.orgOptions.sort((a, b) => {
            return SortUtilitiesService.stringSortCompareInsensitive(a.label, b.label);
        });

        if (this.orgOptions.length == 1) {
            this.addData.selectedOrgIDs = [this.orgOptions[0].id];
            this.selectedOrgsTitle = this.orgOptions[0].label;
            this._orgsChanged();
        }
    }

    selectedAvailableUsername(event: any) {
        if (event.hasOwnProperty("$selection")) {
            let userItem: SelectableItem = event.$selection;
            this.addData.username = userItem.label;
            this.basicInfoForm.patchValue({
                username: this.addData.username
            });
        }
    }

    bulkChanged() {
        this.bulkAdd = !this.bulkAdd;
        if (this.bulkAdd) {
            this.addData.emails = this.addData.email;
            this.addData.email = null;
            this.stepCount = 1;
        } else {
            this.addData.email = this.addData.emails;
            this.addData.emails = null;
            this.stepCount = 2;
        }
        this.enableDisable();
    }

    externalIDTypeChanged(event: any) {
        if (event.hasOwnProperty("$selection")) {
            if (event.$selection) {
                this.addData.externalIDType = event.$selection.id;
            } else {
                this.addData.externalIDType = null;
            }
        }
    }

    /*
    handlePrefixSelection(selection: SelectableItem) {
        if (!selection) {
            return;
        }
        if (!selection.option) {
            selection.option = null;
        } // we don't want 'undefined', or empty string
        this.addData.name.prefix = selection.option;
    }
    */
}
