<div class="filter-box" *ngIf="!!showFilter">
    <sf-search *ngIf="!lock" class="label-item" [model]="searchText"
        placeholder="Filter roles"
        (cancelEvent)="cancelSearch()"
        [icon]="['far', 'filter']" [enable]="true" [showResults]="true"
        (onChange)="onSearchChange()"></sf-search>
    <span *ngIf="lock" class="label-item">Role</span>
</div>

<div class="selector-top">
    <div *ngIf="!lock && (!orgIDs || !orgIDs.length)" class="label-item">Role</div>
    <div *ngIf="!lock && orgIDs && orgIDs.length" class="row">
        <div class="col-xs-9 col-md-9 clip-text">{{selectionText()}}</div>
        <div class="col-xs-3 col-md-3" *ngIf="!lock && selectedRoles.length">
            <button class="btn btn-secondary btn-tiny float-end" (click)="clearSelection()">Clear</button>
        </div>
    </div>
    <div *ngIf="lock" class="lock-text clip-text">
        {{selectionText()}}
    </div>
</div>

<ul *ngIf="!lock" id="sf-userorg-role-selector" class="selector-bottom" [ngClass]="{missing: !selectedRoles.length}">
    <li class="loading" *ngIf="loading">
        <fa-icon icon="spinner" [spin]="true"></fa-icon>
        <span> Loading...</span>
    </li>
    <li *ngIf="!loading && (!orgIDs || !orgIDs.length)"><i>No organization selected</i></li>
    <li *ngIf="!loading && orgIDs && orgIDs.length > 1 && !rolesList.length"><i>No matching roles exist</i></li>
    <li *ngIf="!loading && multipleOrgsSelected() && !rolesList.length"><i>{{orgIDs.length}} organizations are
        selected</i></li>
    <li *ngIf="!loading && orgIDs && orgIDs.length">
        <div *ngFor="let role of filteredRolesList">
            <div *ngIf="role.type == 'label'">
                <span class="group-label" [ngClass]="{locked: role.locked}">{{role.label}}</span>
                <span *ngIf="role.locked" class="fa fa-question-circle tooltip-interaction"
                    ngbTooltip="{{role.lockReason}}" placement="bottom"></span>
            </div>
            <div *ngIf="role.type != 'label'">
                <label>
                    <input type="checkbox" value="{{role.id}}" [disabled]="role.disabled" [(ngModel)]="role.checked" #checkbox
                        (click)="checkChange($event)" (keyup)="checkChange($event)"/>
                    <span class="tooltip-interaction" [ngClass]="{locked: role.locked}"
                        placement="bottom">&nbsp;{{role.name}}</span>
                    <span *ngIf="apiRolesOnly && role.allowsLogin">
                        <i class="minor-warn">  (Allows Login)</i>
                    </span>
                </label>
            </div>
        </div>
    </li>
</ul>
