<sf-modal-header title="{{allowReorder ? 'Column Display and Order' : 'Columns to Display'}}"></sf-modal-header>
<div class="modal-body">
        <ul *ngIf="allowReorder" class="column-list-box" cdkDropList (cdkDropListDropped)="drop($event)">
            <ng-container *ngFor="let column of possibleColumns; trackBy: identify; let i = index">
                <li *ngIf="!column.locked && column.showMe"
                    [class.reordering]="i == reorderIndex"
                    class="column-row"
                    cdkDrag
                    ckdDragBoundary=".column-list-box">
                    <fa-icon
                        class="reorder-indicator"
                        [icon]="['far','arrows-alt-v']"
                        [attr.aria-label]="'Reorder ' + column.displayName + ' column toggle button'"
                        [sfAriaDescription]="'Activate the reorder button and use the arrow keys to reorder the list or use your mouse to drag/reorder. Press escape to cancel the reordering.'"
                        tabindex="0"
                        (keydown.enter)="toggleReorder(column, i)"
                        (keydown.arrowUp)="moveUp(column, i, $event)"
                        (keydown.arrowLeft)="moveUp(column, i, $event)"
                        (keydown.arrowDown)="moveDown(column, i, $event)"
                        (keydown.arrowRight)="moveDown(column, i, $event)"
                        (keydown.escape)="stopReorder(column)"
                        (blur)="stopReorder(column)"
                    ></fa-icon>
                    <input type="checkbox"
                           [(ngModel)]="column.showMe"
                           (change)="moveUncheckedColumnsToEnd()"
                           title="Select to display this column"
                           [disabled]="column.alwaysVisible"
                           [name]="'column-draggable-' + column.uniqueId"
                           id="column-draggable-{{column.uniqueId}}"/>
                    <label class="reorder-indicator" for="column-draggable-{{column.uniqueId}}">
                        {{column.displayName}}
                        <span *ngIf="column.groupBy">(grouped)</span>
                    </label>
                </li>
                <li *ngIf="column.locked || !column.showMe"
                    class="column-row not-sortable">
                    <fa-icon
                        class="blank-indicator"
                        [icon]="['far','arrows-alt-v']"></fa-icon>
                    <input type="checkbox"
                           [(ngModel)]="column.showMe"
                           (change)="moveUncheckedColumnsToEnd()"
                           title="Select to display this column"
                           [disabled]="column.alwaysVisible"
                           [name]="'column-' + column.uniqueId"
                           id="column-{{column.uniqueId}}"/>
                    <label for="column-{{column.uniqueId}}">{{column.displayName}}</label>
                </li>
            </ng-container>
        </ul>
        <ul *ngIf="!allowReorder" class="column-list-box">
            <li *ngFor="let column of possibleColumns" class="column-row">
                <label>
                    <input type="checkbox" [(ngModel)]="column.showMe" title="Select to display this column"
                        [disabled]="column.alwaysVisible"/>
                    <span>{{column.displayName}}</span>
                </label>
            </li>
        </ul>
        <div class="visually-hidden" aria-live="assertive" #ariaAnnounce></div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
