import { Component, OnDestroy, OnInit } from "@angular/core";
import { GrowlService, ModalButton } from "@sf/common";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { LogoutService } from "@sf/common";
import { UserService } from "@sf/userorg/common";
import { LoginService } from "@sf/login";

// prettier-ignore
@Component({
    selector: "sf-change-password-settings-personal-dialog",
    templateUrl: "./change-password-settings-personal-dialog.component.html",
    styleUrls: ["./change-password-settings-personal-dialog.component.scss"]
})
export class ChangePasswordSettingsPersonalDialogComponent
    implements OnInit, OnDestroy
{
    private _onDestroy: Subject<void> = new Subject<void>();

    changePasswordForm: UntypedFormGroup;
    primary: ModalButton;
    secondary: ModalButton;
    logoutButton: ModalButton;
    newPassword: string = "";
    //confirmNewPassword: string = "";
    passwordFocused: boolean;
    passwordWasChanged: boolean;
    passwordHas3of4: boolean;
    passwordLengthMet: boolean;
    pwdLength: number = 9; //default
    blockExtendedChars = false;
    passwordHasNumber: boolean;
    passwordHasLowerCase: boolean;
    passwordHasUpperCase: boolean;
    passwordHasSpecialChar: boolean;
    passwordsMatch: boolean;
    passwordValid: boolean = false;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private userService: UserService,
        private _logoutService: LogoutService,
        private growlService: GrowlService,
        private loginService: LoginService,
        private _activeModal: NgbActiveModal
    ) {}

    ngOnInit(): void {
        this.changePasswordForm = this._formBuilder.group({
            currentPassword: ["", Validators.required],
            newPassword: ["", Validators.required],
            confirmPassword: ["", Validators.required]
        });

        // Modal buttons
        this.primary = {
            text: "Save",
            disabled: true,
            callback: this.changePassword.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.abort.bind(this)
        };
        this.logoutButton = {
            text: "OK",
            disabled: false,
            callback: this.logout.bind(this)
        };

        this.userService.getChangePasswordSettings()
            .subscribe((results: any) => {
                if (results.passwordLength)
                    this.pwdLength = parseInt(results.passwordLength);
                this.blockExtendedChars = results.blockExtendedChars;
            });

        this.catchPasswordChanges();

        setTimeout(() => {
            this.focusElement("currentPassword");
        }, 5)
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
    }

    catchPasswordChanges() {
        this.changePasswordForm.get("newPassword")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                let password =
                    this.changePasswordForm.controls.newPassword.value;
                let confirm =
                    this.changePasswordForm.controls.confirmPassword.value;

                this.passwordLengthMet =
                    password && password.length >= this.pwdLength;
                this.passwordHasNumber = /[0-9]/.test(password);
                this.passwordHasLowerCase = /[a-z]/.test(password);
                this.passwordHasUpperCase = /[A-Z]/.test(password);
                this.passwordHasSpecialChar = /[^a-zA-Z0-9]/.test(password);

                let countOfFour = 0;
                if (this.passwordHasNumber) {
                    countOfFour++;
                }
                if (this.passwordHasLowerCase) {
                    countOfFour++;
                }
                if (this.passwordHasUpperCase) {
                    countOfFour++;
                }
                if (this.passwordHasSpecialChar) {
                    countOfFour++;
                }
                this.passwordHas3of4 = countOfFour >= 3;

                this.passwordsMatch = confirm === password;

                this.passwordValid =
                    this.passwordLengthMet &&
                    this.passwordHas3of4 &&
                    this.passwordsMatch &&
                    !!this.changePasswordForm.controls.currentPassword.value;

                this.changePasswordForm.updateValueAndValidity({
                    emitEvent: false
                });

                this.primary.disabled = !(
                    this.passwordValid && this.changePasswordForm.valid
                );
            });
        this.changePasswordForm.get("confirmPassword")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                let password =
                    this.changePasswordForm.controls.newPassword.value;
                let confirm =
                    this.changePasswordForm.controls.confirmPassword.value;
                this.passwordsMatch = confirm === password;
                this.passwordValid =
                    this.passwordLengthMet &&
                    this.passwordHas3of4 &&
                    this.passwordsMatch &&
                    !!this.changePasswordForm.controls.currentPassword.value;
                this.changePasswordForm.updateValueAndValidity({
                    emitEvent: false
                });
                this.primary.disabled = !(
                    this.passwordValid && this.changePasswordForm.valid
                );
            });
    }

    changePassword() {
        let password: string = this.changePasswordForm.get("currentPassword").value;
        let newPassword: string = this.changePasswordForm.get("newPassword").value;
        if (this.includesInvalidExtendedChars(newPassword)) {
            this.growlService.error("The password you have entered contains invalid characters. " +
                    "Please use only uppercase and lowercase letters or the digits 0-9.");
            return;
        }
        // note that validation against the password policy happens on the back end
        this.primary.disabled = true;
        if (this.passwordValid) {
            this.userService.changePassword(password, newPassword)
                .subscribe(() => {
                    this.passwordWasChanged = true;
                    this.primary.disabled = false;
                }, () => {
                    this.primary.disabled = false;
                });
        }
    }

    includesInvalidExtendedChars(pwd: string): boolean {
        // nothing is illegal normally
        if (!this.blockExtendedChars) {
            return false;
        }
        return this.loginService.passwordIncludesExtendedChar(pwd);
    }

    handleEnter() {
        if (this.passwordWasChanged) {
            this.logout();
        } else if (!this.primary.disabled) {
            this.changePassword();
        }
    }

    logout() {
        this._logoutService.runLogoutActions();
        window.location.replace("/sf/ui/login");
    }

    abort() {
        this._activeModal.close(false);
    }

    focusElement(elementID: string) {
        window.setTimeout(() => {
            let theField = document.getElementById(elementID);
            if (theField) {
                theField.focus();
            }
        }, 200);
    }
}
