<!--  Unique Labels for Custom Button Excise Number  -->
<div *ngIf="formControl$ | async as control" [formGroup]="controlParent$ | async" class="custom-button">
    <div class="mb-3 row" *ngIf="customFunctionName === VALIDATE_EXCISE">
        <label class="col">
            Excise Number
        </label>
        <div class="col-md">
            <input [id]="inputID + '_excise'"
                   tabindex="0"
                   type="text"
                   class="form-control"
                   [formControl]="control"
                   [autocomplete]="inputID" />
        </div>
    </div>
    <div class="mb-3 row" *ngIf="customFunctionName === VALIDATE_EXCISE">
        <label *ngIf="!(customProperties['reviewQueue.hideExciseAgainField'] === 'true')" class="col">
            Excise Number Again
        </label>
        <div class="col-md">
            <input *ngIf="!(customProperties['reviewQueue.hideExciseAgainField'] === 'true')"
                   [id]="inputID + '_exciseAgain'"
                   tabindex="0"
                   class="form-control excise-again-input"
                   type="text"
                   [required]="exciseAgainRequired"
                   [minlength]="exciseLength ? exciseLength : 6"
                   [maxlength]="exciseLength ? exciseLength : 6"
                   [(ngModel)]="exciseAgain"
                   (ngModelChange)="exciseAgainChanged($event)"
                   [ngModelOptions]="{standalone: true}"
                   [autocomplete]="inputID" />
        </div>
    </div>

    <!--  Default  -->
    <div class="mb-3 row" >
        <label [for]="inputID" class="col" *ngIf="customFunctionName !== NYC_LOT && customFunctionName !== NYC_UNIT">&nbsp;</label>
        <label [for]="inputID" class="col" *ngIf="customFunctionName === NYC_LOT">Lot</label>
        <label [for]="inputID" class="col" *ngIf="customFunctionName === NYC_UNIT">Unit</label>
        <div class="col-md">

            <!--  Default Custom Button  -->
            <input *ngIf="customFunctionName !== REVIEW_VALIDATE
                        && customFunctionName !== NYC_LOT
                        && customFunctionName !== NYC_UNIT
                        && customProperties['reviewQueue.hideGetExciseButton'] !== 'true'"
                   type="button"
                   class="btn btn-primary"
                   value="{{field.label}}"
                   [id]="inputID"
                   (click)="callCustomFunction()" />

            <!--  Validation Custom Button  -->
            <div *ngIf="customFunctionName === REVIEW_VALIDATE ">
                <div *ngIf="!validateToggleWaiting">
                    <button class="btn btn-primary"
                            *ngIf="!validated && docLeftData?.recipientConfig?.review_validate_button"
                            (click)="toggleValidate()" >
                        {{customText['reviewQueue.validate']}}
                    </button>
                    <button class="btn btn-primary"
                            *ngIf="validated && docLeftData?.recipientConfig?.review_unvalidate_button"
                            (click)="toggleValidate()">
                        {{customText['reviewQueue.unvalidate']}}
                    </button>
                </div>
                <div *ngIf="validateToggleWaiting">
                    <button class="btn btn-primary">
                        <fa-icon [icon]="['fad', 'spinner']" [spin]="true"></fa-icon>
                    </button>
                </div>
            </div>
            <!-- NYC LOT -->
            <div *ngIf="customFunctionName === NYC_LOT" class="row">
                <input type="text"
                       [formControl]="control"
                       class="form-control col"
                       [autocomplete]="inputID" />
                &nbsp;
                &nbsp;
                <button class="btn btn-primary col-md-8"
                        (click)="findAddress()"
                        tabindex="0"
                        ngbTooltip="Use this button to find an address based on Borough, Block, and Lot information that you have provided."
                        tooltip-placement="top">Find Address</button>
            </div>
            <!-- NYC UNIT -->
            <div *ngIf="customFunctionName === NYC_UNIT" class="row">
                <input type="text"
                       [formControl]="control"
                       class="form-control col"
                       [autocomplete]="inputID" />
                &nbsp;
                &nbsp;
                <button class="btn btn-primary col-md-8"
                        (click)="findBlockAndLot()"
                        tabindex="0"
                        ngbTooltip="Use this button to find Block and Lot information based on Borough, Street Number, and Street Name information that you have provided."
                        tooltip-placement="top">Find Block and Lot</button>
            </div>
        </div>
    </div>
</div>
