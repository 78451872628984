<div class="logo-part">
    <img src="/sf/ui/images/sf-logo-white.png" alt=""/>
</div>
<div *ngIf="!loaded">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
    <span> Loading...</span>
</div>
<div *ngIf="loaded && loadFailed">
    <div class="alert alert-warning">
        {{loadFailed}}
    </div>
</div>
<div *ngIf="loaded && !loadFailed" class="like-table row">
    <!-- LEFT SIDE -->
    <div class="left-side">
        <div class="head-text">
            {{wizardSteps[currentStep].title}}
        </div>
        <!-- ERROR MESSAGES -->
        <div class="error-row" [hidden]="!formErrorMessages || !formErrorMessages.length">
            <div>Please correct the following errors:</div>
            <ul>
                <li *ngFor="let message of formErrorMessages">
                    <span>{{message}}</span>
                </li>
            </ul>
        </div>
        <!-- STEP 0 - intro -->
        <div *ngIf="currentStep==0">
            <div class="intro">
                <b>{{fullName}}</b>
                <span *ngIf="fullName"> ({{emailAddress}})</span>
                <b *ngIf="!fullName"> {{emailAddress}}</b>
                <b> has been invited to the following roles:</b>
            </div>
            <div class="role-list">
                <div class="role-in-list" *ngFor="let org of invitedOrganizations">
                    <div><b>{{org.name}}</b></div>
                    <ul>
                        <li *ngFor="let role of org.roles">
                            <span>{{role}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="button-row">
                <button class="btn btn-primary" *ngIf="!existingUserID && !multipleUsers" (click)="submitCurrentForm()">
                    Sign Up
                </button>
                <button class="btn btn-primary" *ngIf="!!existingUserID && !multipleUsers && invitedRoles.length == 1"
                    (click)="submitCurrentForm()">Accept New Role
                </button>
                <button class="btn btn-primary" *ngIf="!!existingUserID && !multipleUsers && invitedRoles.length > 1"
                    (click)="submitCurrentForm()">Accept New Roles
                </button>
                <button class="btn btn-primary" *ngIf="multipleUsers" (click)="submitCurrentForm()">
                    Log In to Accept
                </button>
                <button class="btn btn-link" (click)="doCancel()">
                    Cancel Invitation
                </button>
                <button class="btn btn-link" *ngIf="!userLoggedIn && (!!existingUserID || multipleUsers)" (click)="signupAgain()">
                    Create New Account
                </button>
                <button class="btn btn-link" (click)="skipForNow()">Skip for Now</button>
                <button *ngIf="showExistingUserLink" class="btn btn-link" (click)="logInToAccept()">I already have a Simplifile account</button>
            </div>
        </div>
        <!-- STEP 1 - new user information -->
        <form [formGroup]="personalForm" *ngIf="currentStep==1">
            <div class="row">
                <div class="mb-3 form-group col-md-4">
                    <label for="firstName">First Name</label>
                    <input type="text" id="firstName" name="firstName" placeholder="Required"
                        class="form-control" maxlength="255" (blur)="blurSomeFields()"
                        formControlName="firstName" required/>
                </div>
                <div class="mb-3 form-group col-md-4">
                    <label for="middleName">Middle Name</label>
                    <input type="text" id="middleName" name="middleName" placeholder=""
                        class="form-control" maxlength="255"
                        formControlName="middleName"/>
                </div>
                <div class="mb-3 form-group col-md-4">
                    <label for="lastName">Last Name</label>
                    <input type="text" id="lastName" name="lastName" placeholder="Required"
                        class="form-control" maxlength="255" (blur)="blurSomeFields()"
                        formControlName="lastName" required/>
                </div>
            </div>
            <div class="row">
                <div class="mb-3 form-group col-md-6">
                    <label for="email">Email Address</label>
                    <input type="text" id="email" name="email" placeholder="Required"
                        class="form-control" maxlength="500" (blur)="blurSomeFields()"
                        readonly="true"
                        formControlName="email" required/>
                </div>
                <div class="mb-3 form-group col-md-6">
                    <label for="title">Job Title</label>
                    <input type="text" id="title" name="title" placeholder="Required"
                        class="form-control"
                        formControlName="title" required/>
                </div>
            </div>
            <div class="row">
                <div class="mb-3 form-group col-md-6">
                    <label for="phone">Phone Number</label>
                    <input type="tel" id="phone" name="phone" placeholder="Required"
                        class="form-control" sfPhoneInput
                        formControlName="phone" required/>
                </div>
                <div class="mb-3 form-group col-md-3">
                    <label for="extension">Extension</label>
                    <input type="text" id="extension" name="extension" placeholder=""
                        class="form-control"
                        formControlName="extension"/>
                </div>
            </div>
            <div class="row">
                <div class="mb-3 form-group col-md-6">
                    <label for="userID">Choose your Username</label>
                    <input type="text" id="userID" name="userID" placeholder="Required"
                        class="form-control" (focus)="focusUsername()" (blur)="blurUsername()"
                        [readonly]="usernamePredefined" maxlength="55"
                        formControlName="userID" required/>
                </div>
            </div>
            <div class="row">
                <div class="mb-3 form-group col-md-6">
                    <label for="password">Create a Password</label>
                    <input type="password" id="password" name="password" placeholder="Required"
                        class="form-control" (focus)="focusPassword()" (blur)="blurPassword()"
                        formControlName="password" required/>
                </div>
                <div class="mb-3 form-group col-md-6">
                    <label for="confirm">Confirm your Password</label>
                    <input type="password" id="confirm" name="confirm" placeholder="Required"
                        class="form-control" (focus)="focusConfirm()" (blur)="blurConfirm()"
                        formControlName="confirm" required/>
                </div>
            </div>
            <div class="button-row">
                <button class="btn btn-primary" (click)="submitCurrentForm()">Finish</button>
                <button class="btn btn-secondary" (click)="goToStep(0)">Back</button>
                <button *ngIf="showExistingUserLink" class="btn btn-link" (click)="logInToAccept()">I already have an account</button>
            </div>
        </form>
        <!-- STEP 2 - done -->
        <div *ngIf="currentStep==2">
            <div class="button-row">
                <button class="btn btn-primary" (click)="goToLogin()">Log In</button>
            </div>
        </div>
    </div>
    <!-- RIGHT SIDE -->
    <div class="right-side">
        <div *ngIf="!isInUsernameField && !isInPasswordField && !isInConfirmField">
            <div *ngIf="!existingUserID && !multipleUsers">
                <div class="wizard-step" [ngClass]="{'current-step':(currentStep==0), 'done-step':(currentStep>0)}">
                    <span (click)="stepClick(0)">
                        <span class="badge">1</span>
                        <span>New Roles</span>
                    </span>
                </div>
                <div class="step-connector"></div>
                <div class="wizard-step" [ngClass]="{'current-step':(currentStep==1), 'done-step':(currentStep>1)}">
                    <span (click)="stepClick(1)">
                        <span class="badge">2</span>
                        <span>Personal Account</span>
                    </span>
                </div>
            </div>
            <div *ngIf="!!existingUserID || multipleUsers">
                <div class="wizard-step" [ngClass]="{'current-step':(currentStep==0), 'done-step':(currentStep>0)}">
                    <span (click)="stepClick(0)">
                        <span class="badge">1</span>
                        <span>Accept Roles</span>
                    </span>
                </div>
                <div *ngIf="currentStep==1">    <!-- if they chose to sign up a new user with same email -->
                    <div class="step-connector"></div>
                    <div class="wizard-step" [ngClass]="{'current-step':(currentStep==1), 'done-step':(currentStep>1)}">
                    <span (click)="stepClick(1)">
                        <span class="badge">2</span>
                        <span>Personal Account</span>
                    </span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isInUsernameField && availableUsernames.length" class="preload-holder">
            <div class="preload-header">Here are some usernames that are available:</div>
            <div class="preload-picker">
                <div *ngFor="let option of availableUsernames">
                    <button class="btn btn-link sf-btn-link"
                        (mousedown)="clickUsernameOption(option)">{{option}}</button>
                    <!-- must use (mousedown) here -->
                </div>
            </div>
        </div>
        <div *ngIf="isInPasswordField || isInConfirmField" class="preload-holder">
            <div class="preload-header">Password requirements</div>
            <div class="preload-picker pwd-requirements">
                <div class="pwd-requirement">
                    <fa-icon *ngIf="!passwordLengthMet" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"></fa-icon>
                    <fa-icon *ngIf="passwordLengthMet" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                    <span> Must have {{pwdLength}} or more characters</span>
                </div>
                <div class="pwd-requirement">
                    <fa-icon *ngIf="!passwordHas3of4" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"></fa-icon>
                    <fa-icon *ngIf="passwordHas3of4" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                    <span> Must have at least 3 of the following:</span>
                </div>
                <div class="pwd-requirement pwd-requirement-indented">
                    <div class="password-indent"> </div>
                    <div class="password-indented">
                        <fa-icon *ngIf="!passwordHasNumber" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"
                            [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                        <fa-icon *ngIf="passwordHasNumber" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                        <span> A number</span>
                    </div>
                </div>
                <div class="pwd-requirement pwd-requirement-indented">
                    <div class="password-indent"> </div>
                    <div class="password-indented">
                        <fa-icon *ngIf="!passwordHasLowerCase" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"
                            [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                        <fa-icon *ngIf="passwordHasLowerCase" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                        <span> A lower-case character</span>
                    </div>
                </div>
                <div class="pwd-requirement pwd-requirement-indented">
                    <div class="password-indent"> </div>
                    <div class="password-indented">
                        <fa-icon *ngIf="!passwordHasUpperCase" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"
                            [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                        <fa-icon *ngIf="passwordHasUpperCase" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                        <span> An upper-case character</span>
                    </div>
                </div>
                <div class="pwd-requirement pwd-requirement-indented">
                    <div class="password-indent"> </div>
                    <div class="password-indented">
                        <fa-icon *ngIf="!passwordHasSpecialChar" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"
                            [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                        <fa-icon *ngIf="passwordHasSpecialChar" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                        <span> A special character (!@#$?+...)</span>
                    </div>
                </div>
                <div class="pwd-requirement">
                    <fa-icon *ngIf="!passwordsMatch" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"></fa-icon>
                    <fa-icon *ngIf="passwordsMatch" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                    <span> Passwords must match</span>
                </div>
            </div>
        </div>
        <div class="chat">
            <div class="titler">Questions?</div>
            <div>Call us at <a href="tel:800.460.5657">800.460.5657</a> and press option 3.</div>
            <div>We are available from 6 a.m. to 6 p.m. MT.</div>
        </div>
    </div>
</div>
