<div class="sf-unmask">
    <sf-modal-header title="Security Questions"></sf-modal-header>
    <form [formGroup]="securityQuestionsForm">
        <div *ngIf="!loaded">
            <fa-icon icon="spinner" [spin]="true"></fa-icon>
            <span> Loading...</span>
        </div>
        <div class="modal-body" *ngIf="questionList$ | async as questionList">
            <div class="explain" *ngIf="loaded">
                <span>Please select some security questions and your answers to them. </span>
                <span>These are required in case you ever forget your password.</span>
            </div>
            <div class="row main-area">
                <div class="mt-3 col-md-12">
                    <div class="form-row">
                        <div class="mb-3 col-md-8">
                            <label for="question0"><strong>Security Questions</strong></label>
                            <sf-select formControlName="question0"
                                       placeholder="-- Select a question --"
                                       id="question0"
                                       [isSingleSelect]="true"
                                       [options]="questionList"
                                       [sfAriaDescription]="'security question 1'"
                                       trackBy="id" labelBy="question" class="sf-mask"
                            ></sf-select>
                        </div>
                        <div class="mb-3 col-md-4">
                            <label for="answer0"><strong>Answer</strong></label>
                            <input #answer type="password" class="form-control sf-exclude" formControlName="answer0"
                                   autocomplete="new-password" id="answer0" placeholder="" (focus)="onFocus($event)"
                                   (blur)="onBlur($event)" aria-label="answer for security question 1">
                        </div>
                    </div>
                    <div class="form-row" *ngFor="let questionNumber of questionCount">
                        <div class="mb-3 col-md-8">
                            <sf-select formControlName="question{{questionNumber}}"
                                       placeholder="-- Select a question --"
                                       id="question{{questionNumber}}"
                                       [isSingleSelect]="true"
                                       [options]="questionList"
                                       [sfAriaDescription]="'security question' + (questionNumber + 1)"
                                       trackBy="id" labelBy="question" class="sf-mask"
                            ></sf-select>
                        </div>
                        <div class="mb-3 col-md-4">
                            <input #answer type="password" class="form-control sf-exclude" formControlName="answer{{questionNumber}}"
                                   autocomplete="new-password" id="answer{{questionNumber}}" placeholder=""
                                   (focus)="onFocus($event)" (blur)="onBlur($event)" [sfAriaDescription]="'answer for security question ' + (questionNumber + 1)">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="mb-3 col-md-8"></div>
                        <div class="mb-3 form-check col-md-4">
                            <input class="formCheckInput" type="checkbox" id="showCheckbox" formControlName="showAnswersCheckbox">
                            <label class="formCheckLabel" for="showCheckbox">
                                Show Answers
                            </label>
                        </div>
                    </div>
                    <div class="mb-3 row mt-1 col-md-9">
                        <label for="currentPassword" class="col-form-label" style="padding-left: 0;">
                            For security, enter your current password:</label>
                        <input type="password" maxlength="32"
                               formControlName="currentPassword"
                               id="currentPassword" class="form-control mx-sm-3 col-md-4 float-end sf-exclude"
                               autocomplete="new-password">
                    </div>
                </div>
            </div>
        </div>
    </form>
    <sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
</div>
