import {
    Conditionals,
    DynamicFormState,
    FieldFormState,
    FieldState,
    FieldStateWrapper,
    FieldViewState,
    FormState,
    RepeatableFieldDefinition,
    ViewState
} from "../interfaces/dynamic-form-state";
import {
    ConditionUpdateType,
    NewConditionalRule,
    StateChange
} from "../interfaces/conditional.interface";
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormGroup
} from "@angular/forms";
import { deepMergeOverrideArrays } from "@sf/common";
import { generateRandomString } from "@sf/common";
import { buildFormControlFromField } from "./build-form.helpers";
import * as deepmerge from "deepmerge";

export function setExternalValueForFormGroup(
    formGroup: UntypedFormGroup,
    value: any,
    currentState: DynamicFormState,
    options?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
    }
): {
    change: StateChange;
    fieldsToRemove: string[];
} | null {
    try {
        if (value !== null) formGroup.patchValue(value, options);
        return null;
    } catch (error) {
        const stateChange: StateChange = {};
        if (
            error.message.includes(
                "There are no form controls registered with this array yet"
            ) ||
            error.message.includes("Cannot find form control at index") ||
            error.message.includes(
                "Must supply a value for form control at index"
            ) ||
            error.message.includes("value.forEach is not a function") ||
            error.message.includes(
                "Must supply a value for form control with name"
            )
        ) {
            let update = parseFormGroupForMissingArrayControls(
                formGroup,
                value,
                currentState
            );
            const newUpdate = setExternalValueForFormGroup(
                formGroup,
                value,
                currentState,
                options
            );
            if (newUpdate) {
                update = deepmerge(update, newUpdate);
            }
            return deepmerge(stateChange, update);
        }

        throw error;
    }
}

export function parseFormGroupForMissingArrayControls(
    formGroup: UntypedFormGroup,
    value: any,
    currentState: DynamicFormState,
    currentViewPath: string = null,
    stateChange: {
        change: StateChange;
        fieldsToRemove: string[];
    } = {
        change: {},
        fieldsToRemove: []
    }
): {
    change: StateChange;
    fieldsToRemove: string[];
} {
    let keys = Object.keys(formGroup.controls);

    for (let key of keys) {
        if (formGroup.controls.hasOwnProperty(key)) {
            let control = formGroup.controls[key];
            let controlValue = value[key];
            let childViewPath = "";
            if (!currentViewPath) {
                childViewPath = key;
            } else {
                childViewPath = currentViewPath + "." + key;
            }

            if (control instanceof UntypedFormGroup) {
                const update = parseFormGroupForMissingArrayControls(
                    control,
                    controlValue,
                    currentState,
                    childViewPath
                );

                stateChange = deepMergeOverrideArrays(stateChange, update);
            } else if (control instanceof UntypedFormArray) {
                controlValue = controlValue || [];
                if (!Array.isArray(value[key])) {
                    value[key] = controlValue;
                }
                let hasUpdate = false;
                let update: StateChange = {};
                if (control.controls.length < controlValue.length) {
                    hasUpdate = true;
                    update[ConditionUpdateType.View] = {
                        [childViewPath]: {
                            arrayFields: [
                                ...currentState.viewState[childViewPath]
                                    .arrayFields
                            ]
                        }
                    };
                    for (
                        let i = control.controls.length;
                        i < controlValue.length;
                        i++
                    ) {
                        const { update: stateUpdate, newPath } =
                            getStateUpdateForNewArrayField(
                                childViewPath,
                                currentState
                            );
                        currentState.viewState = {
                            ...currentState.viewState,
                            ...(stateUpdate[
                                ConditionUpdateType.View
                            ] as ViewState)
                        };
                        currentState.formState = {
                            ...currentState.formState,
                            ...(stateUpdate[
                                ConditionUpdateType.Form
                            ] as FormState)
                        };
                        currentState.fieldState = {
                            ...currentState.fieldState,
                            ...(stateUpdate[
                                ConditionUpdateType.View
                            ] as FieldStateWrapper)
                        };
                        let newControl = buildNewControlForArrayField(
                            newPath,
                            stateUpdate
                        );

                        update[ConditionUpdateType.View][
                            childViewPath
                        ].arrayFields.push(newPath);

                        control.push(newControl);

                        update = deepMergeOverrideArrays(update, stateUpdate);
                    }
                } else if (control.controls.length > controlValue.length) {
                    hasUpdate = true;
                    update[ConditionUpdateType.View] = {
                        [childViewPath]: {
                            arrayFields: [
                                ...currentState.viewState[childViewPath]
                                    .arrayFields
                            ]
                        }
                    };
                    for (
                        let i = control.controls.length - 1;
                        i >= controlValue.length;
                        i--
                    ) {
                        control.removeAt(i);
                        stateChange.fieldsToRemove.push(
                            update[ConditionUpdateType.View][childViewPath]
                                .arrayFields[i]
                        );
                        update[ConditionUpdateType.View][
                            childViewPath
                        ].arrayFields.splice(i, 1);
                    }
                }
                stateChange.change = deepMergeOverrideArrays(
                    stateChange.change,
                    update
                );
                const currentFields = hasUpdate
                    ? update[ConditionUpdateType.View][childViewPath]
                          .arrayFields
                    : currentState.viewState[childViewPath].arrayFields;
                for (let i = 0; i < control.controls.length; i++) {
                    let childControl = control.controls[i];
                    let childControlValue = controlValue[i];
                    let childPath = currentFields[i];
                    if (
                        childControlValue &&
                        childControl instanceof UntypedFormGroup
                    ) {
                        const update = parseFormGroupForMissingArrayControls(
                            childControl,
                            childControlValue,
                            currentState,
                            childPath,
                            stateChange
                        );

                        stateChange = deepMergeOverrideArrays(
                            stateChange,
                            update
                        );
                    }
                }
            }
        }
    }

    return stateChange;
}

export function removeFieldAndChildrenFromState(
    fieldPath: string,
    state: DynamicFormState
) {
    for (const statePart of [
        ConditionUpdateType.View,
        ConditionUpdateType.Form,
        ConditionUpdateType.Field,
        ConditionUpdateType.Conditionals
    ]) {
        if (state.hasOwnProperty(statePart)) {
            for (const fieldKey of Object.keys(state[statePart])) {
                if (fieldKey.startsWith(fieldPath)) {
                    delete state[statePart][fieldKey];
                }
            }
        }
    }

    // remove other conditionals with "fieldsToApply" values that have the same path as the removed fieldPath
    for (const fieldKey of Object.keys(state.conditionals)) {
        for (const condition of state.conditionals[fieldKey]) {
            for (let i = condition.fieldsToApply.length - 1; i >= 0; --i) {
                let toApplyKey: string = condition.fieldsToApply[i];
                if (toApplyKey.startsWith(fieldPath)) {
                    condition.fieldsToApply.splice(i, 1);
                }
            }
        }
    }
}

export function removeGivenFieldsAndChildrenFromState(
    fieldPaths: string[],
    state: DynamicFormState
) {
    for (const statePart of [
        ConditionUpdateType.View,
        ConditionUpdateType.Form,
        ConditionUpdateType.Field,
        ConditionUpdateType.Conditionals
    ]) {
        if (state.hasOwnProperty(statePart)) {
            for (let i = 0; i < fieldPaths.length; ++i) {
                for (const fieldKey of Object.keys(state[statePart])) {
                    if (fieldKey.startsWith(fieldPaths[i])) {
                        delete state[statePart][fieldKey];
                    }
                }
            }
        }
    }
}

export function getStateUpdateForNewArrayField(
    fieldPath: string,
    state: DynamicFormState
): {
    update: StateChange;
    newPath: string;
} {
    const fieldState: FieldState = state.fieldState[fieldPath] as FieldState;

    const newPath = fieldPath + "." + generateRandomString(5);
    const update = transformRepeatableFieldsToNewIndex(
        newPath,
        fieldState.repeatableFieldState,
        state
    );

    return {
        update,
        newPath
    };
}

export function buildNewControlForArrayField(
    newPath: string,
    update: StateChange
): AbstractControl {
    const newBaseField = update[ConditionUpdateType.Field][
        newPath
    ] as FieldState;

    return buildFormControlFromField(
        newBaseField,
        update[ConditionUpdateType.Field],
        update[ConditionUpdateType.View] as ViewState,
        update[ConditionUpdateType.Form] as FormState
    );
}

export function transformRepeatableFieldsToNewIndex(
    newPath: string,
    repeatableFieldState: RepeatableFieldDefinition,
    oldState: DynamicFormState,
    toReplace: string = "*"
): StateChange {
    return {
        [ConditionUpdateType.View]: replaceViewStatePath(
            newPath,
            repeatableFieldState.viewState,
            toReplace
        ),
        [ConditionUpdateType.Form]: replaceFormStatePath(
            newPath,
            repeatableFieldState.formState,
            toReplace
        ),
        [ConditionUpdateType.Field]: replaceFieldStatePath(
            newPath,
            repeatableFieldState.fieldState,
            toReplace
        ),
        [ConditionUpdateType.Conditionals]: replaceConditionals(
            newPath,
            repeatableFieldState.conditionals,
            oldState.conditionals,
            toReplace
        )
    };
}

export function replaceConditionals(
    newPath: string,
    conditionals: Conditionals,
    oldConditionals: Conditionals,
    toReplace: string = "*"
): Conditionals {
    const newState: Conditionals = {};
    for (let key in conditionals) {
        if (conditionals.hasOwnProperty(key)) {
            const fieldKey = replaceValueIfString(key, newPath, toReplace);
            if (key === fieldKey) {
                newState[fieldKey] = replaceConditionalsStatePathMergeArrays(
                    newPath,
                    conditionals[key],
                    oldConditionals[key],
                    toReplace
                );
            } else {
                newState[fieldKey] = replaceConditionalsStatePath(
                    newPath,
                    conditionals[key],
                    toReplace
                );
            }
        }
    }
    return newState;
}

export function replaceConditionalsStatePath(
    newPath: string,
    conditionals: NewConditionalRule[],
    toReplace: string = "*"
): NewConditionalRule[] {
    return conditionals.map((rule) =>
        replaceWithNewIndex(newPath, rule, toReplace)
    );
}

export function replaceConditionalsStatePathMergeArrays(
    newPath: string,
    conditionals: NewConditionalRule[],
    oldConditionals: NewConditionalRule[],
    toReplace: string = "*"
): NewConditionalRule[] {
    let newConditionals = [];
    for (let i = 0; i < conditionals.length; ++i) {
        newConditionals[i] = replaceWithNewIndex(
            newPath,
            conditionals[i],
            toReplace,
            oldConditionals[i]
        );
    }
    return newConditionals;
}

export function replaceViewStatePath(
    newPath: string,
    viewState: ViewState,
    toReplace: string = "*"
): ViewState {
    const newState: ViewState = {};
    for (let key in viewState) {
        if (viewState.hasOwnProperty(key)) {
            const fieldKey = replaceValueIfString(key, newPath, toReplace);
            newState[fieldKey] = replaceFieldViewStatePath(
                newPath,
                viewState[key],
                toReplace
            );
        }
    }
    return newState;
}

export function replaceFieldViewStatePath(
    newPath: string,
    viewState: FieldViewState,
    toReplace: string = "*"
): FieldViewState {
    const newObject: FieldViewState = replaceWithNewIndex(
        newPath,
        viewState,
        toReplace
    );
    if (newObject.visibleFieldsMap) {
        newObject.visibleFieldsMap = replaceWithNewIndex(
            newPath,
            newObject.visibleFieldsMap,
            toReplace
        );
    }
    if (newObject.parent === "rootField") {
        newObject.parent = getParentPath(newPath);
    }
    return newObject;
}

export function getParentPath(fieldFullPath: string) {
    const parent = fieldFullPath.split(".");
    return parent.slice(0, parent.length - 1).join(".");
}

export function replaceFormStatePath(
    newPath: string,
    formState: FormState,
    toReplace: string = "*"
): FormState {
    const newState: FormState = {};
    for (let key in formState) {
        if (formState.hasOwnProperty(key)) {
            const fieldKey = replaceValueIfString(key, newPath, toReplace);
            newState[fieldKey] = replaceFieldFormStatePath(
                newPath,
                formState[key],
                toReplace
            );
        }
    }
    return newState;
}

export function replaceFieldFormStatePath(
    newPath: string,
    viewState: FieldFormState,
    toReplace: string = "*"
): FieldFormState {
    const newObject: FieldFormState = replaceWithNewIndex(
        newPath,
        viewState,
        toReplace
    );
    return newObject;
}

export function replaceFieldStatePath(
    newPath: string,
    fieldState: FieldStateWrapper,
    toReplace: string = "*"
): FieldStateWrapper {
    const newState: FieldStateWrapper = {};
    for (let key in fieldState) {
        if (fieldState.hasOwnProperty(key)) {
            const fieldKey = replaceValueIfString(key, newPath, toReplace);
            newState[fieldKey] = replaceFieldPath(
                newPath,
                fieldState[key] as FieldState,
                toReplace
            );
        }
    }
    return newState;
}

export function replaceFieldPath(
    newPath: string,
    viewState: FieldState,
    toReplace: string = "*"
): FieldState {
    const newObject: FieldState = replaceWithNewIndex(
        newPath,
        viewState,
        toReplace
    );
    // if (newObject.visibleFieldsMap) {
    //     newObject.visibleFieldsMap = replaceWithNewIndex(
    //         newPath,
    //         newObject.visibleFieldsMap
    //     );
    // }
    return newObject;
}

export function replaceWithNewIndex(
    newPath: string,
    state: any,
    toReplace: string = "*",
    oldState: any = null
) {
    const newObject: any = {};
    for (let fieldProp in state) {
        if (state.hasOwnProperty(fieldProp)) {
            const fieldKey = replaceValueIfString(
                fieldProp,
                newPath,
                toReplace
            );
            const value = state[fieldProp];
            if (Array.isArray(value)) {
                newObject[fieldKey] = value.map((item) =>
                    replaceValueIfString(item, newPath, toReplace)
                );
                if (oldState && oldState[fieldKey]) {
                    for (let i = oldState[fieldKey].length - 1; i >= 0; --i) {
                        newObject[fieldKey].unshift(oldState[fieldKey][i]);
                    }
                }
            } else {
                newObject[fieldKey] = replaceValueIfString(
                    value,
                    newPath,
                    toReplace
                );
            }
        }
    }

    return newObject;
}

export function replaceValueIfString(
    value: string,
    replace: string,
    toReplace = "*"
) {
    return typeof value === "string"
        ? value.replace(toReplace, replace)
        : value;
}
