import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { first, sampleTime, takeUntil } from "rxjs/operators";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    ConfirmationModalComponent,
    SfValidators,
    SpinnerService
} from "@sf/common";
import { CancelInvitationDialogComponent } from "../../dialogs/cancel-invitation-dialog/cancel-invitation-dialog.component";
import { InvitationSharedService } from "../../services/invitation-shared.service";
import {
    AddressVerificationService,
    Contract,
    ContractService,
    ContractType,
    TokenService,
    UserOrgService
} from "@sf/userorg/common";

interface WizardStep {
    title: string;
}

declare const window: any;

// prettier-ignore
@Component({
    selector: "sf-new-organization",
    templateUrl: "./new-organization.component.html",
    styleUrls: ["./new-organization.component.scss"]
})
export class NewOrganizationComponent implements OnInit, OnDestroy {
    /* Private Variables */
    private _ngOnDestroy: Subject<void>;
    private _userDetails: any = {
        first: "",
        last: "",
        email: ""
    };

    /* Public Variables */
    loaded = false;
    loadFailed: string = null;
    tokenID: string = null;
    serviceName: string = "";
    companyForm: UntypedFormGroup;
    personalForm: UntypedFormGroup;
    currentStep = 0;
    mustSignContract = false;
    contractType: ContractType = null;
    contractID: string = null;
    formErrorMessages: string[] = [];
    isInUsernameField = false;
    isInPasswordField = false;
    isInConfirmField = false;
    availableUsernames: string[] = [];
    emailMatchesUser = false;
    organizationID: string;
    organizationName: string;

    // credit card stuff
    licenseFeeAmount = 0;

    verifiedAddress: any = null;
    showAddressCheckbox = true;
    invalidAddressMessage: string = null;
    addressModel = {
        isChecked: false,
        radio: ""
    };

    passwordHas9Characters = false;
    passwordHas3of4 = false;
    passwordHasNumber = false;
    passwordHasLowerCase = false;
    passwordHasUpperCase = false;
    passwordHasSpecialChar = false;
    passwordsMatch = false;

    wizardSteps: WizardStep[] = [
        {
            title: "New Organization Registration"
        },
        {
            title: "Create Your Personal Simplifile Account"
        },
        {
            title: "Accept Simplifile License Agreement"
        },
        {
            title: "License Fee"
        },
        {
            title: "Enrollment Complete"
        }
    ];

    //businessTypes: SelectableItemWithID[] = null;

    constructor(
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private modalService: NgbModal,
        private tokenService: TokenService,
        private addressVerificationService: AddressVerificationService,
        private userorgService: UserOrgService,
        private invitationSharedService: InvitationSharedService,
        private spinnerService: SpinnerService,
        private contractService: ContractService
    ) {
        this.companyForm = formBuilder.group({
            companyLegalName: [
                "",
                [Validators.required, SfValidators.nameFieldValidator]
            ],
            companyPreferredName: [
                "",
                [Validators.required, SfValidators.nameFieldValidator]
            ],
            businessType: [""],
            phone: ["", [Validators.required, SfValidators.phoneValidator]],
            extension: [
                "",
                [Validators.maxLength(9), SfValidators.numericValidator]
            ],
            website: ["", SfValidators.urlValidator],
            street: [
                "",
                [Validators.required, SfValidators.nameFieldValidator]
            ],
            street2: ["", SfValidators.nameFieldValidator],
            city: ["", [Validators.required, SfValidators.nameFieldValidator]],
            state: ["", Validators.required],
            zipCode: ["", [Validators.required, SfValidators.zipCodeValidator]],
            whichAddress: [""],
            checkAddress: [false]
        });
        this.personalForm = formBuilder.group({
            firstName: ["", Validators.required],
            middleName: [""],
            lastName: ["", Validators.required],
            title: ["", Validators.required],
            userID: [
                "",
                [
                    Validators.required,
                    Validators.minLength(7),
                    SfValidators.usernameValidator
                ]
            ],
            password: ["", [Validators.required]],
            confirm: ["", [Validators.required]],
            email: ["", [Validators.required, SfValidators.emailValidator]]
        });
    }

    ngOnInit() {
        this._ngOnDestroy = new Subject();

        this.route.paramMap
            .pipe(takeUntil(this._ngOnDestroy))
            .subscribe((params) => {
                this.tokenID = params.get("tokenID");
                this.preloadForms(this.tokenID);
            });

        //this.businessTypes = this.userorgService.getSelectableBusinessTypes();

        this.catchFormChanges();
    }

    ngOnDestroy() {
        this._ngOnDestroy.next();
    }

    catchFormChanges() {
        this.companyForm.get("street").valueChanges.subscribe((val) => {
            this.resetInvalidAddress();
        });
        this.companyForm.get("street2").valueChanges.subscribe((val) => {
            this.resetInvalidAddress();
        });
        this.companyForm.get("city").valueChanges.subscribe((val) => {
            this.resetInvalidAddress();
        });
        this.companyForm.get("zipCode").valueChanges.subscribe((val) => {
            this.resetInvalidAddress();
        });
        this.companyForm.get("website")
            .valueChanges.pipe(takeUntil(this._ngOnDestroy), sampleTime(250))
            .subscribe((newVal) => {
                if (newVal && newVal.length) {
                    this.companyForm.get("website").patchValue(newVal.trim());
                }
            });
        this.personalForm.get("password").valueChanges.subscribe((val) => {
            let pwd = this.personalForm.controls.password.value;
            let confirm = this.personalForm.controls.confirm.value;

            this.passwordHas9Characters = pwd && pwd.length >= 9;
            this.passwordHasNumber = /[0-9]/.test(pwd);
            this.passwordHasLowerCase = /[a-z]/.test(pwd);
            this.passwordHasUpperCase = /[A-Z]/.test(pwd);
            this.passwordHasSpecialChar = /[^a-zA-Z0-9]/.test(pwd);

            let countOfFour = 0;
            if (this.passwordHasNumber) {
                countOfFour++;
            }
            if (this.passwordHasLowerCase) {
                countOfFour++;
            }
            if (this.passwordHasUpperCase) {
                countOfFour++;
            }
            if (this.passwordHasSpecialChar) {
                countOfFour++;
            }
            this.passwordHas3of4 = countOfFour >= 3;

            this.passwordsMatch = confirm === pwd;
        });
        this.personalForm.get("confirm").valueChanges.subscribe((val) => {
            let pwd = this.personalForm.controls.password.value;
            let confirm = this.personalForm.controls.confirm.value;
            this.passwordsMatch = confirm === pwd;
        });
    }

    cleanse(before: string): string {
        if (!before) {
            return before;
        }
        let after = "";
        for (let i = 0; i < before.length; i++) {
            let c = before.charAt(i);
            if (c == "\u00A0") {
                c = " ";
            } else if (c == "\u2013" || c == "\u2014" || c == "\u2011") {
                c = "-";
            } else {
                // leave it
            }
            after += c;
        }
        return after;
    }

    // set the preferred name to match the legal name
    updatePreferredName() {
        let newName = this.cleanse(
            this.companyForm.controls.companyLegalName.value
        );
        this.companyForm.patchValue({
            companyPreferredName: newName
        });
    }

    pasteCore(event: ClipboardEvent, elementID: string) {
        let clipboardData = event.clipboardData || window.clipboardData;
        let uncleaned = clipboardData.getData("text");
        let cleaned = this.cleanse(uncleaned);
        if (cleaned != uncleaned) {
            let ele = <HTMLInputElement>document.getElementById(elementID);
            setTimeout(() => {
                ele.value = cleaned;
                this.companyForm.patchValue({ [elementID]: cleaned });
            });
        }
    }

    pasteLegalName(event: ClipboardEvent) {
        this.pasteCore(event, "companyLegalName");
    }

    pastePreferredName(event: ClipboardEvent) {
        this.pasteCore(event, "companyPreferredName");
    }

    pasteStreet1(event: ClipboardEvent) {
        this.pasteCore(event, "street");
    }

    pasteStreet2(event: ClipboardEvent) {
        this.pasteCore(event, "street2");
    }

    pasteCity(event: ClipboardEvent) {
        this.pasteCore(event, "city");
    }

    private resetInvalidAddress() {
        this.invalidAddressMessage = null;
        this.companyForm.patchValue({
            whichAddress: "",
            checkAddress: false
        });
    }

    private preloadForms(tokenID: string) {
        this.tokenService.getOrganizationInvitation(tokenID)
            .subscribe((invitation: any) => {
                if (invitation) {
                    this.loaded = true;

                    this._userDetails.first = invitation.firstName;
                    this._userDetails.last = invitation.lastName;
                    this._userDetails.email = invitation.email;

                    if (invitation.licenseFee) {
                        this.licenseFeeAmount = parseFloat(invitation.licenseFee);
                    } else {
                        this.licenseFeeAmount = 0;
                    }

                    let businessTypeArray = this.userorgService.getBusinessTypeIDsArray(invitation.businessTypes);

                    if (!invitation.preferredName) {
                        invitation.preferredName = invitation.orgName;
                    }

                    //default the legal name to be the org name if no legal name entered.
                    if (!invitation.legalName) {
                        invitation.legalName = invitation.orgName;
                    }

                    this.companyForm.patchValue({
                        companyLegalName: invitation.legalName,
                        companyPreferredName: invitation.preferredName,
                        businessType: businessTypeArray,
                        phone: SfValidators.formatPhone(invitation.phone),
                        extension: invitation.extension,
                        website: invitation.website,
                        street: invitation.street,
                        street2: invitation.street2,
                        city: invitation.city,
                        state: invitation.state,
                        zipCode: invitation.zipCode
                    });
                    this.personalForm.patchValue({
                        firstName: invitation.firstName,
                        middleName: invitation.middleName,
                        lastName: invitation.lastName,
                        title: invitation.title,
                        email: invitation.email
                    });

                    if (invitation.serviceID) {
                        this.serviceName = invitation.serviceID;
                        let lowerServiceID = invitation.serviceID.toLowerCase();
                        if (lowerServiceID.startsWith("submitter") || lowerServiceID.startsWith("esign ") ||
                                lowerServiceID.startsWith("trustee")) {
                            this.mustSignContract = true;
                        }
                    }

                    this.userorgService.getAvailableUsernames(invitation.firstName, invitation.lastName,
                            invitation.email, "")
                        .pipe(first())
                        .subscribe((availableUsernames: any[]) => {
                            if (availableUsernames) {
                                this.availableUsernames = availableUsernames;
                            } else {
                                this.availableUsernames = [];
                            }
                        });
                    this.userorgService.countUsersWithThisEmailAddress(invitation.email)
                        .pipe(first())
                        .subscribe((count: number) => {
                            this.emailMatchesUser = count > 0;
                        });

                    this.storeInvitationResults(invitation);

                    // deal with returning to this page with partial results
                    if ((this.contractID) || (this.organizationID && invitation.resultingUsername)) {
                        this.readContract();
                    }
                }
            }, () => {
                // error
                this.loadFailed =
                        "Your invitation was not found. Please try clicking again on the link in the email message you received.";
                this.loaded = true;
            });
    }

    storeInvitationResults(invitation: any) {
        let product = invitation.product;
        this.contractType = this.contractService.contractTypeForService(product);
        this.contractID = invitation.resultingContractID;
        this.organizationID = invitation.resultingOrganizationID;
        this.organizationName = invitation.orgName;
    }

    readContract() {
        this.spinnerService.startSpinner();
        this.contractService.getActiveOrFutureContract(this.organizationID, this.contractType)
            .subscribe((contract: Contract) => {
                this.spinnerService.stopSpinner();
                let nextStep = 0;
                if (this.licenseFeeAmount) {
                    nextStep = 3;
                }
                if (contract) {
                    this.contractID = contract.contractID;
                    if (!contract.executedDate) {
                        // not signed yet
                        nextStep = 2;
                    }
                } else {
                    this.loadFailed = "Your new contract is missing. Please contact Support.";
                }

                this.goToStep(nextStep);
            }, () => {
                this.spinnerService.stopSpinner();
                this.loadFailed = "Your new contract is missing. Please contact Support.";
            });
    }

    goToStep(step: number) {
        // the timeout seems to be required for Edge browser
        window.setTimeout(() => {
            this.currentStep = step;
            this.isInConfirmField = false;
            this.isInUsernameField = false;
            this.isInPasswordField = false;
        }, 10);
    }

    stepClick(step: number) {
        if (this.currentStep > 1) {
            // can't go back
            return;
        }

        if (step == this.currentStep) {
            // staying on same step
            return;
        }

        if (step > this.currentStep) {
            // going forward - need to submit form
            this.submitCurrentForm();
            return;
        }

        // going backward
        this.formErrorMessages = [];
        this.goToStep(step);
    }

    stateChange(selection: any) {
        this.companyForm.controls.state.setValue(
            selection ? selection.abbrev : null
        );
        this.resetInvalidAddress();
    }

    doCancel() {
        const modalRef = this.modalService.open(
            CancelInvitationDialogComponent
        );
        const modalInstance = modalRef.componentInstance;
        modalInstance.tokenID = this.tokenID;
        modalRef.result.then(
            (result: any) => {
                if (result) {
                    sf.metricLog.recordMetric(
                        "onboarding",
                        "cancelInvitation",
                        1,
                        false
                    );
                    this.invitationCanceled(result);
                }
            },
            () => {
                // nothing
            }
        );
    }

    invitationCanceled(action: string) {
        const modal = this.modalService.open(ConfirmationModalComponent);
        const modalInstance = modal.componentInstance;

        modalInstance.title = "Invitation Canceled";
        modalInstance.primary = {
            text: "OK",
            responseValue: 1,
            callback: this.goToLogin.bind(this)
        };
        modalInstance.hideSecondary = true;
        modalInstance.message =
            "Thank you. Your invitation has been successfully canceled";
        if (action == "allInvites") {
            modalInstance.message +=
                ", and you will not receive future invitations from Simplifile. You may receive other types of emails from Simplifile.";
        } else {
            modalInstance.message += ".";
        }
    }

    buildLoginUrl(): string {
        return "/sf/ui/login?token=" + this.tokenID;
    }

    goToLogin() {
        window.location = "/sf/ui/login";
    }

    clickUsernameOption(selection: string) {
        if (selection) {
            this.personalForm.patchValue({
                userID: selection
            });
            window.setTimeout(() => {
                let passwordField = document.getElementById("password");
                passwordField.focus();
            }, 200);
        }
    }

    focusUsername() {
        this.isInUsernameField = true;
    }

    blurUsername() {
        // need timeout in case clicked
        window.setTimeout(() => {
            this.isInUsernameField = false;
        }, 100);
    }

    focusPassword() {
        this.isInPasswordField = true;
    }

    blurPassword() {
        this.isInPasswordField = false;
    }

    focusConfirm() {
        this.isInConfirmField = true;
    }

    blurConfirm() {
        this.isInConfirmField = false;
    }

    submitCurrentForm() {
        switch (this.currentStep) {
            case 0:
                this.submitCompanyForm();
                break;
            case 1:
                this.submitPersonalForm();
                break;
            default:
                break;
        }
    }

    submitCompanyForm() {
        let orgLegalName: string =
            this.companyForm.controls.companyLegalName.value;
        let orgPreferredName: string =
            this.companyForm.controls.companyPreferredName.value;
        let street = this.companyForm.controls.street.value;
        let street2 = this.companyForm.controls.street2.value;
        let city = this.companyForm.controls.city.value;
        let phone = this.companyForm.controls.phone.value;
        let extension: string = this.companyForm.controls.extension.value;
        let website: string = this.companyForm.controls.website.value;
        let state = this.companyForm.controls.state.value;
        let zipCode = this.companyForm.controls.zipCode.value;

        /*
        let businessType = this.companyForm.controls.businessType.value;
        if (!Array.isArray(businessType)) {
            this.companyForm.patchValue({
                businessType: [businessType]
            });
        }
        */

        phone = SfValidators.getNumericDigits(phone);

        if (extension) {
            extension = extension.trim();
            this.companyForm.patchValue({
                extension: extension
            });
        }

        this.formErrorMessages = [];

        if (!orgLegalName || !orgLegalName.trim()) {
            this.formErrorMessages.push("Enter a Company Legal Name");
        } else if (orgLegalName.length == 4) {
            // because of Chrome bug, look for a birth year here
            let year = parseInt(orgLegalName, 10);
            if (!isNaN(year) && year > 1900 && year < 2100) {
                this.formErrorMessages.push("Invalid Company Name");
            }
        }
        if (!phone) {
            this.formErrorMessages.push("Enter a Phone Number");
        } else if (phone.length != 10) {
            this.formErrorMessages.push("Phone Number must contain 10 digits");
        }
        if (extension && !SfValidators.NUMERIC_REGEXP.test(extension)) {
            this.formErrorMessages.push(
                "Extension (optional) must be all numbers"
            );
        }
        if (extension && extension.length > 9) {
            this.formErrorMessages.push(
                "Extension cannot be longer than 9 digits"
            );
        }
        if (website && !SfValidators.URL_REGEXP.test(website)) {
            this.formErrorMessages.push(
                "Organization Website must start with http:// or https:// and have a valid domain ending. Examples would include http://www.samplewebsite.com or https://samplewebsite.org."
            );
        }
        if (!street || !street.trim().length) {
            this.formErrorMessages.push("Enter a Street Address");
        }
        if (!city || !city.trim().length) {
            this.formErrorMessages.push("Enter a City");
        }
        if (!state) {
            this.formErrorMessages.push("Select a State");
        }
        if (!zipCode) {
            this.formErrorMessages.push("Enter a ZIP Code");
        } else if (zipCode.length != 5 && zipCode.length != 10) {
            this.formErrorMessages.push("ZIP Code must be 5 or 10 digits");
        } else if (!SfValidators.ZIPCODE_REGEX.test(zipCode)) {
            this.formErrorMessages.push("Enter a valid ZIP Code");
        } else if (
            SfValidators.ORGNAME_REGEXP.test(orgLegalName) ||
            SfValidators.ORGNAME_REGEXP.test(orgPreferredName)
        ) {
            this.formErrorMessages.push(
                "Organization names must not start with 'www.'"
            );
        }
        /*
        if (businessType.length === 0) {
            this.formErrorMessages.push("Select a business type");
        }
        */

        if (!this.formErrorMessages.length && this.companyForm.invalid) {
            this.formErrorMessages.push("Please correct input errors");
        }

        if (this.formErrorMessages.length) {
            return;
        }

        let whichAddress = this.companyForm.controls.whichAddress.value;
        let checkAddress = this.companyForm.controls.checkAddress.value;

        if (checkAddress || whichAddress) {
            // user has specified how to deal with bad address
            this.invalidAddressMessage = null;
            if (whichAddress == "recommended" && this.verifiedAddress) {
                this.companyForm.patchValue({
                    street:
                        this.verifiedAddress.street1 +
                        (this.verifiedAddress.secondary
                            ? " " + this.verifiedAddress.secondary
                            : ""),
                    street2: this.verifiedAddress.street2,
                    city: this.verifiedAddress.city,
                    state: this.verifiedAddress.state,
                    zipCode: this.verifiedAddress.zip
                });
            } else {
                this.verifiedAddress = null; // don't use any recommendation
            }
            this.saveOrganizationData();
            this.goToStep(1);
        } else {
            // verify the address
            let address = {
                street: street,
                street2: street2,
                city: city,
                state: state,
                zipCode: zipCode
            };
            if (this.addressVerificationService.isPOBox(address)) {
                this.invalidAddressMessage =
                    "Please enter your entity's physical address, not a post office box.";
                this.showAddressCheckbox = false;
                this.verifiedAddress = false;
                return;
            }
            this.spinnerService.startSpinner();
            this.verifiedAddress = null;
            this.showAddressCheckbox = true;
            this.invalidAddressMessage = null;
            this.addressVerificationService.verifyAddress(address)
                .pipe(first())
                .subscribe((verifiedAddress: any) => {
                    this.spinnerService.stopSpinner();
                    if (verifiedAddress) {
                        this.verifiedAddress = verifiedAddress;
                        // default to use the recommended address
                        this.companyForm.patchValue({
                            whichAddress: "recommended"
                        });
                        if (verifiedAddress.street1 == street && //verifiedAddress.street2 == street2 &&
                                verifiedAddress.city == city && verifiedAddress.state == state && verifiedAddress.zip ==
                                zipCode) {
                            // matches as expected
                            this.saveOrganizationData();
                            this.goToStep(1);
                        } else {
                            if (this.addressVerificationService.isPOBox(verifiedAddress)) {
                                this.invalidAddressMessage =
                                        "Please enter your entity's physical address, not a post office box.";
                                this.showAddressCheckbox = false;
                                this.verifiedAddress = false;
                            } else {
                                this.invalidAddressMessage = "Address validation recommends this address:<br/>" +
                                        this.parseVerifiedAddress(verifiedAddress);
                            }
                        }
                    } else {
                        this.invalidAddressMessage =
                                "The address information entered is not a recognized address.<br/>Are you sure it is correct?";
                    }
                }, () => {
                    this.spinnerService.stopSpinner();
                });
        }
    }

    /**
     * update the token with organization data entered by user
     */
    saveOrganizationData() {
        let companyData = this.companyForm.value;
        if (this.verifiedAddress) {
            companyData.timeZone = this.verifiedAddress.timeZone;
        }

        this.tokenService.updateOrganizationInvitation(this.tokenID, companyData)
            .subscribe(() => {
                // go on
            }, () => {
                // go on
            });
    }

    parseVerifiedAddress(verifiedAddress: any) {
        let result = verifiedAddress.street1;
        if (verifiedAddress.secondary) {
            result = result + " " + verifiedAddress.secondary;
        }
        if (verifiedAddress.street2) {
            result = result + "<br/>" + verifiedAddress.street2;
        }
        result =
            result +
            "<br/>" +
            verifiedAddress.city +
            ", " +
            verifiedAddress.state +
            "&nbsp;&nbsp;" +
            verifiedAddress.zip;

        return result;
    }

    submitPersonalForm() {
        let firstName: string = this.personalForm.controls.firstName.value;
        let middleName: string = this.personalForm.controls.middleName.value;
        let lastName: string = this.personalForm.controls.lastName.value;
        let email: string = this.personalForm.controls.email.value;
        let userID: string = this.personalForm.controls.userID.value;
        if (userID) {
            userID = userID.toLowerCase();
        }

        this.formErrorMessages = this.invitationSharedService.validateNewUserForm(this.personalForm, true, // title
                false, // phone
                this.passwordHas9Characters, this.passwordHas3of4, this.passwordsMatch);

        if (this.formErrorMessages.length) {
            return;
        }

        this.spinnerService.startSpinner();
        this.userorgService.countUsersWithThisEmailAddress(email)
            .pipe(first())
            .subscribe((count: number) => {
                this.emailMatchesUser = count > 0;
                if (this.emailMatchesUser) {
                    let loginUrl = this.buildLoginUrl();
                    this.formErrorMessages.push(
                            "The email address " + email + " is already in use. Please <a href='" + loginUrl +
                            "'>click here</a> to log in.");
                    this.spinnerService.stopSpinner();
                } else {
                    this.userorgService.getAvailableUsernames(firstName, lastName, email, userID)
                        .pipe(first())
                        .subscribe((availableUsernames: any[]) => {
                            if (availableUsernames) {
                                this.availableUsernames = availableUsernames;
                                if (availableUsernames.includes(userID)) {
                                    // username is available, so do the big submit
                                    this.submitAllData();
                                } else {
                                    this.formErrorMessages.push("Username '" + userID +
                                            "' is not available. Please choose a different username.");
                                }
                            } else {
                                this.availableUsernames = [];
                            }
                        }, () => {
                        }, () => {
                            this.spinnerService.stopSpinner();
                        });
                }
            }, () => {
                this.spinnerService.stopSpinner();
            });
    }

    signContract() {
        this.spinnerService.startSpinner();
        this.tokenService.getOrganizationInvitation(this.tokenID)
            .subscribe((invitation: any) => {
                this.spinnerService.stopSpinner();
                if (invitation) {
                    this.storeInvitationResults(invitation);
                    this.goToStep(2);
                } else {
                    alert("Error preparing to view contract");
                    this.goToLogin();
                }
            }, () => {
                alert("Your new organization is missing. Please contract Support.");
                this.spinnerService.stopSpinner();
            });
    }

    licenseAction(event: string) {
        if (event == "accept") {
            if (!this.licenseFeeAmount) {
                this.goToLogin();
            } else {
                this.prepareForPayment();
            }
        } else if (event == "skip") {
            this.goToLogin();
        }
    }

    paymentAction(event: string) {
        this.goToLogin();
    }

    prepareForPayment() {
        this.spinnerService.startSpinner();
        this.tokenService.getOrganizationInvitation(this.tokenID)
            .subscribe((invitation: any) => {
                this.spinnerService.stopSpinner();
                if (invitation) {
                    this.storeInvitationResults(invitation);
                    this.readContract();
                }
                this.goToStep(3);
            }, () => {
                alert("Your new organization is missing. Please contract Support.");
                this.spinnerService.stopSpinner();
            });
    }

    // call this after adding organization, if user is logged in
    /*
    refreshSession() {
        // kind-of hack: need to update the session to get updated permissions
        this.spinnerService.startSpinner();
        this.userorgActivationService
        .updateUserSession()
        .then((session: any) => {
            if (session && session.session) {
                this.sessionService.setSession(session.session);
                this.userorgService.clearCachedOrgsAndUsers();
            }
            this.actionTaken.emit("skip");
        })
        .finally(() => {
            this.spinnerService.stopSpinner();
        });
    }
    */

    submitAllData() {
        this.isInConfirmField = false;
        this.isInPasswordField = false;
        this.isInUsernameField = false;

        let companyData = this.companyForm.value;
        let personalData = this.personalForm.value;
        let mixedData = { ...companyData, ...personalData };
        if (this.verifiedAddress) {
            mixedData.timeZone = this.verifiedAddress.timeZone;
        }

        this.spinnerService.startSpinner();
        this.tokenService.acceptOrganizationInvitation(this.tokenID, mixedData)
            .subscribe((contractID: string) => {
                this.contractID = contractID;
                if (this.mustSignContract) {
                    this.signContract();
                } else {
                    this.goToStep(4);
                    this.doneNoLicense();
                }
                this.spinnerService.stopSpinner();
            }, () => {
                this.spinnerService.stopSpinner();
            });
    }

    doneNoLicense() {
        const modal = this.modalService.open(ConfirmationModalComponent);
        const modalInstance = modal.componentInstance;

        modalInstance.title = "Enrollment Successful";
        modalInstance.primary = {
            text: "Log In",
            responseValue: 1,
            callback: this.goToLogin.bind(this)
        };
        modalInstance.hideSecondary = true;
        modalInstance.message =
            "Welcome to Simplifile! Your organization is now registered, and your user is now created.";
    }
}
