<div class="mb-3 row" *ngIf="formControl$ | async as control" [formGroup]="controlParent$ | async">
    <label class="col">{{ placeFieldLabelAsOptionLabel ? '' : field.label }}</label>
    <div class="col-md" [ngbTooltip]="field.toolTip" [ngClass]="{'value-wrong':control.invalid}" placement="top-left">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
        <div *ngFor="let option of (options$ | async); let $index = index;" class="row">
            <div class="col-md-1">
                <input [id]="option.label + '_' + field.id"
                       type="checkbox"
                       [name]="field.label"
                       (change)="onCheckboxChange($event)"
                       [value]="option.option"
                       [(ngModel)]="optionValuesMap[option.option]"
                       [ngModelOptions]="{standalone: true}"
                       [disabled]="isDisabled"
                       attr.aria-label="{{field.label}} {{option.label}}"
                />
            </div>
            <label class="radio-label col-md" [for]="option.label + '_' + field.id" [ngClass]="{'invalid':errors !== null}">
                {{ placeFieldLabelAsOptionLabel ? field.label : option.label}}
            </label>
        </div>
    </div>
</div>
