import {
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild
} from "@angular/core";
import {
    GridOptions,
    GridApi,
    GridReadyEvent,
    ColGroupDef,
    RowNode,
    RowDataUpdatedEvent,
    GetRowIdParams
} from "@ag-grid-community/core";
import { TemplateRendererComponent } from "@sf/common";
import { debounceTime, pairwise, startWith, takeUntil } from "rxjs/operators";
import { UIOrganizationEEligibility } from "@sf/userorg/common";
import { SessionService } from "@sf/common";
import { StatesService } from "@sf/common";
import { UntypedFormControl } from "@angular/forms";
import { EEligibilityDataService } from "../e-eligibility-data.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EEligibilityChangeHistoryDialogComponent } from "../e-eligibility-change-history-dialog/e-eligibility-change-history-dialog.component";
import { CurrentOrganizationService } from "@sf/userorg/common";
import { Organization } from "@sf/userorg/common";
import { Subject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { GrowlService } from "@sf/common";
import { dayjs } from "@sf/common";
import { EEligibilityEditNoteDialogComponent } from "../e-eligibility-edit-note-dialog/e-eligibility-edit-note-dialog.component";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons";

interface State {
    abbrev: string;
    name: string;
}

@Component({
    selector: "sf-e-eligibility-status-by-jurisdiction",
    templateUrl: "./e-eligibility-status-by-jurisdiction.component.html",
    styleUrls: ["./e-eligibility-status-by-jurisdiction.component.scss"]
})
export class EEligibilityStatusByJurisdictionComponent
    implements OnInit, OnDestroy
{
    private _onDestroy$ = new Subject<void>();

    orgID: string = "";
    orgState: string = "";
    activeOrgID: string = null;
    private _states: State[];
    private _gridApi: GridApi;
    private _needsReset = false;
    private _stateNames: string[];
    private _stateAbbrevs: string[];
    private _stateIDsToShow: string[] = [];
    private _rowDataChangedCallback: Function = () => {};

    public columnDefs: ColGroupDef[];
    public filterText: UntypedFormControl = new UntypedFormControl("");
    public gridOptions: GridOptions;
    public hasEditPermission: boolean;

    @ViewChild("checkmarkTemplate", { static: true })
    public checkmarkTemplate: TemplateRef<any>;
    @ViewChild("readOnlyCheckmarkTemplate", { static: true })
    public readOnlyCheckmarkTemplate: TemplateRef<any>;
    @ViewChild("tripleOptionCheckmarkTemplate", { static: true })
    public tripleOptionCheckmarkTemplate: TemplateRef<any>;
    @ViewChild("dateTemplate", { static: true })
    public dateTemplate: TemplateRef<any>;
    @ViewChild("orgNameTemplate", { static: true })
    public orgNameTemplate: TemplateRef<any>;

    constructor(
        private _statesService: StatesService,
        private _sessionService: SessionService,
        private _eEligibilityDataService: EEligibilityDataService,
        private _modalService: NgbModal,
        private _currentOrgService: CurrentOrganizationService,
        private _activatedRoute: ActivatedRoute,
        private _growlService: GrowlService
    ) {}

    ngOnInit(): void {
        this.activeOrgID = this._activatedRoute.snapshot.paramMap.get("orgID");
        this.gridOptions = this._setGridOptions();
        this.columnDefs = this._getColumnDefs();

        this.hasEditPermission = this._sessionService.hasPermission(
            "admin_manage_e_eligibility_settings",
            "SIMPFL"
        );

        this._states = this._statesService.getAllStatesPlusDC();
        this._stateAbbrevs = this._states.map((s: State) => s.abbrev);
        this._stateNames = this._states.map((s: State) => s.name.toLowerCase());

        this.filterText.valueChanges
            .pipe(startWith(""), debounceTime(500), pairwise())
            .subscribe(([prevValue, filterText]: [string, string]) => {
                if (this._filterTextRefersToState(filterText)) {
                    this._displayThatStateOnly(filterText);
                } else if (filterText.trim().length >= 3) {
                    this._displaySearchResults(filterText);
                } else if (filterText.length < 3 && prevValue.length >= 3) {
                    this._displayAllStates();
                }
            });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    public openHistoryDialog(row: UIOrganizationEEligibility) {
        const modal = this._modalService.open(
            EEligibilityChangeHistoryDialogComponent,
            { size: "lg" }
        );
        const modalInstance: EEligibilityChangeHistoryDialogComponent =
            modal.componentInstance;
        modalInstance.orgID = row.orgID;
        if (this.isState(row)) {
            modalInstance.stateAbbrev = row.stateAbbrev;
            modalInstance.name = row.stateName;
        } else {
            modalInstance.name = `${row.orgName}, ${row.stateAbbrev}`;
        }
    }

    public openEditNoteDialog(row: UIOrganizationEEligibility) {
        const modal = this._modalService.open(
            EEligibilityEditNoteDialogComponent,
            { size: "md" }
        );
        const modalInstance: EEligibilityEditNoteDialogComponent =
            modal.componentInstance;
        modalInstance.orgID = row.orgID;
        if (this.isState(row)) {
            modalInstance.stateAbbrev = row.stateAbbrev;
            modalInstance.name = row.stateName;
        } else {
            modalInstance.name = `${row.orgName}, ${row.stateAbbrev}`;
        }
    }

    private _updateRows(
        update: Partial<UIOrganizationEEligibility>,
        stateApprChange?: boolean
    ): void {
        if (!update) {
            return;
        }
        const rowToUpdate = this._gridApi.getRowNode(update.orgID);
        if (!rowToUpdate) {
            // row isn't shown yet, don't bother updating it
            return;
        }
        const rowData: UIOrganizationEEligibility = { ...rowToUpdate.data };
        const rowsToUpdate: UIOrganizationEEligibility[] = [
            { ...rowData, ...update }
        ];
        if (stateApprChange) {
            const vendorUpdate: Partial<UIOrganizationEEligibility> = {
                simpfl_approved_ipen_vendor: update.simpfl_approved_ipen_vendor,
                simpfl_approved_ron_vendor: update.simpfl_approved_ron_vendor
            };
            this._gridApi.forEachNodeAfterFilter((rowNode) => {
                const data: UIOrganizationEEligibility | null = rowNode.data;
                if (data?.orgHierarchy.includes(rowData.stateName)) {
                    const orgID = data.orgID;
                    rowsToUpdate.push({ ...data, ...vendorUpdate, orgID });
                }
            });
        }
        this._gridApi.applyTransaction({ update: rowsToUpdate });
        // update the cache
        this._eEligibilityDataService.getEEligibilityForAllStates().subscribe();
    }

    public isDisabledCheckmark(
        row: UIOrganizationEEligibility,
        fieldName: string
    ): boolean {
        return (
            !this.isState(row) &&
            [
                "isRegistered",
                "simpfl_approved_ipen_vendor",
                "simpfl_approved_ron_vendor"
            ].includes(fieldName)
        );
    }

    public changeTripleCheckmark(row: any, field: any) {
        let rowData = this._gridApi.getRowNode(row.orgID).data as any;
        if (!this.isDisabledCheckmark(row, field)) {
            let former = rowData[field];
            // cycle to the next option
            if (rowData[field] === true) {
                rowData[field] = false;
            } else if (rowData[field] === false) {
                rowData[field] = null;
            } else if (
                rowData[field] === null ||
                rowData[field] === undefined
            ) {
                rowData[field] = true;
            }
            let current = rowData[field];
            const historyStr = `${field} was changed from ${
                former === null ? "not required" : former
            } to ${current === null ? "not required" : current}`;
            const eligibilityUpdateData = {
                accepts_ipen_in_state: rowData.accepts_ipen_in_state,
                accepts_ipen_out_of_state: rowData.accepts_ipen_out_of_state,
                simpfl_approved_ipen_vendor:
                    rowData.simpfl_approved_ipen_vendor,
                accepts_ron_in_state: rowData.accepts_ron_in_state,
                accepts_ron_out_of_state: rowData.accepts_ron_out_of_state,
                simpfl_approved_ron_vendor: rowData.simpfl_approved_ron_vendor,
                accepts_e_notarized_security_instrument:
                    rowData.accepts_e_notarized_security_instrument,
                e_eligibility_notes: rowData.e_eligibility_notes
            };
            const orgID = row.orgID;
            this._eEligibilityDataService
                .saveEEligibilityState(
                    eligibilityUpdateData,
                    row.stateAbbrev,
                    false,
                    historyStr
                )
                .subscribe(() => {
                    this._updateRows(
                        {
                            ...eligibilityUpdateData,
                            orgID,
                            last_updated: dayjs().toDate(),
                            last_updated_by: this._sessionService.getUserID()
                        },
                        true
                    );
                    this._growlService.success("Successfully updated state");
                });
        }
    }

    public changeCheckmark(row: any, field: any) {
        let rowData = this._gridApi.getRowNode(row.orgID).data as any;
        if (!this.isDisabledCheckmark(row, field)) {
            let former = rowData[field];
            // cycle to the next option
            rowData[field] = !row[field];
            let current = rowData[field];
            const historyStr = `${field} was changed from ${former} to ${current}`;

            const eligibilityUpdateData = {
                accepts_ipen_in_state: rowData.accepts_ipen_in_state,
                accepts_ipen_out_of_state: rowData.accepts_ipen_out_of_state,
                simpfl_approved_ipen_vendor:
                    rowData.simpfl_approved_ipen_vendor,
                accepts_ron_in_state: rowData.accepts_ron_in_state,
                accepts_ron_out_of_state: rowData.accepts_ron_out_of_state,
                simpfl_approved_ron_vendor: rowData.simpfl_approved_ron_vendor,
                accepts_e_notarized_security_instrument:
                    rowData.accepts_e_notarized_security_instrument,
                e_eligibility_notes: rowData.e_eligibility_notes
            };
            const orgID = row.orgID;
            if (this.isState(row)) {
                this._eEligibilityDataService
                    .saveEEligibilityState(
                        eligibilityUpdateData,
                        row.stateAbbrev,
                        false,
                        historyStr
                    )
                    .subscribe(() => {
                        this._updateRows({
                            ...eligibilityUpdateData,
                            orgID,
                            last_updated: dayjs().toDate(),
                            last_updated_by: this._sessionService.getUserID()
                        });
                        this._growlService.success(
                            "Successfully updated state"
                        );
                    });
            } else {
                this._eEligibilityDataService
                    .saveEEligibility(
                        orgID,
                        eligibilityUpdateData,
                        false,
                        historyStr
                    )
                    .subscribe(() => {
                        this._updateRows({
                            ...eligibilityUpdateData,
                            orgID,
                            last_updated: dayjs().toDate(),
                            last_updated_by: this._sessionService.getUserID()
                        });
                        this._growlService.success(
                            "Successfully updated county"
                        );
                    });
            }
        }
    }

    public countyDataNotYetRetrieved(orgID: string): boolean {
        return this._gridApi?.getRowNode(orgID)?.allChildrenCount < 1;
    }

    public getEEligibilityForAllRecipientsInState(
        stateRow: UIOrganizationEEligibility,
        hideOtherStates: boolean = false
    ) {
        this._eEligibilityDataService
            .getEEligibilityForAllRecipientsInState(stateRow.stateAbbrev)
            .subscribe((counties: UIOrganizationEEligibility[]) => {
                this._gridApi.applyTransaction({
                    add: counties
                });
                this._gridApi.setRowNodeExpanded(
                    this._gridApi.getRowNode(stateRow.orgID),
                    true
                );
                if (hideOtherStates) {
                    this._stateIDsToShow = [stateRow.orgID];
                    this._gridApi.onFilterChanged();
                }
            });
    }

    public isState(row: UIOrganizationEEligibility): boolean {
        return row.stateName === row.orgName;
    }

    private _resetGridWithStateData() {
        this._gridApi.setRowData(
            this._eEligibilityDataService.getCachedStateData()
        );
        this._needsReset = false;
    }

    private _displayThatStateOnly(filterText: string) {
        const stateOrgID = this._getStateOrgID(filterText);
        if (this._needsReset) {
            this._resetGridWithStateData();
            this._rowDataChangedCallback = this._insertStateData.bind(
                this,
                stateOrgID
            );
        } else {
            this._insertStateData(stateOrgID);
        }
    }

    private _insertStateData(stateOrgID: string) {
        if (this._gridApi.getRowNode(stateOrgID)?.allChildrenCount) {
            this._gridApi.setRowNodeExpanded(
                this._gridApi.getRowNode(stateOrgID),
                true
            );
            this._stateIDsToShow = [stateOrgID];
            this._gridApi.onFilterChanged();
        } else {
            this.getEEligibilityForAllRecipientsInState(
                this._eEligibilityDataService
                    .getCachedStateData()
                    .find(
                        (data: UIOrganizationEEligibility) =>
                            data.orgID === stateOrgID
                    ),
                true
            );
        }
    }

    private _displaySearchResults(filterText: string) {
        this._eEligibilityDataService
            .getEEligibilityBySearchOrgName(filterText.trim())
            .subscribe(
                (
                    matchingCountiesAndTheirStates: UIOrganizationEEligibility[]
                ) => {
                    this._gridApi.setRowData(matchingCountiesAndTheirStates);
                    this._needsReset = true;
                    this._rowDataChangedCallback = () => {
                        this._gridApi.expandAll();
                        this._stateIDsToShow = [];
                        this._gridApi.onFilterChanged();
                    };
                }
            );
    }

    private _displayAllStates() {
        if (this._needsReset) {
            this._resetGridWithStateData();
            this._rowDataChangedCallback = () => {};
        }
        this._gridApi.collapseAll();
        this._stateIDsToShow = [];
        this._gridApi.onFilterChanged();
    }

    private _getStateOrgID(filterText: string): string {
        if (filterText.length === 3) {
            return filterText.slice(0, 2).toUpperCase() + "S000";
        } else {
            return (
                this._states.find(
                    (s: State) =>
                        s.name.toLowerCase() === filterText.trim().toLowerCase()
                ).abbrev + "S000"
            );
        }
    }

    private _filterTextRefersToState(filterText: string): boolean {
        return (
            (filterText.length === 3 &&
                filterText[2] === " " &&
                this._stateAbbrevs.includes(
                    filterText.slice(0, 2).toUpperCase()
                )) ||
            this._stateNames.includes(filterText.trim().toLowerCase())
        );
    }

    private _setGridOptions(): GridOptions {
        return {
            suppressRowClickSelection: true,
            suppressCellFocus: true,
            headerHeight: 40,
            defaultColDef: {
                suppressMovable: true,
                sortable: false,
                resizable: false,
                width: 80
            },
            treeData: true,
            rowBuffer: 100,
            groupDisplayType: "custom",
            icons: {
                groupContracted: `<button class="btn btn-plus-minus">+</button>`,
                groupExpanded: `<button class="btn btn-plus-minus">﹣</button>`
            },
            overlayLoadingTemplate: `<span class="loading-overlay">${icon(
                faSpinner,
                {
                    classes: ["fa-spin"],
                    attributes: { "aria-hidden": "true" }
                }
            ).html.join("")}</i> Loading</span>`,
            getRowId: (params: GetRowIdParams): string => {
                return params.data.orgID;
            },
            onGridReady: (event: GridReadyEvent) => {
                this._gridApi = event.api;

                this._eEligibilityDataService
                    .getEEligibilityForAllStates()
                    .subscribe((data: any[]) => {
                        data.forEach((state: any) => {
                            state.stateName = state.orgName = state.name;
                            state.orgHierarchy = [state.stateName];
                            state.orgID = state.stateAbbrev + "S000";
                        });
                        let stateData: UIOrganizationEEligibility[] = data;
                        this._gridApi.setRowData(stateData);
                        this._rowDataChangedCallback = () => {};
                        this._subscribeToCurrentOrg();
                    });
            },
            getDataPath: (row: UIOrganizationEEligibility): string[] => {
                return row.orgHierarchy;
            },
            isExternalFilterPresent: (): boolean => {
                return !!this._stateIDsToShow.length;
            },
            doesExternalFilterPass: (node: RowNode): boolean => {
                return this._stateIDsToShow.includes(node.data.orgID);
            },
            onRowDataUpdated: (_: RowDataUpdatedEvent) => {
                this._rowDataChangedCallback();
            }
        };
    }

    private _getColumnDefs(): ColGroupDef[] {
        return [
            {
                headerName: "",
                children: [
                    {
                        headerName: "Name",
                        field: "orgName",
                        headerTooltip:
                            "State, county, or other recording jurisdiction",
                        flex: 1,
                        minWidth: 250,
                        showRowGroup: true,
                        cellRenderer: "agGroupCellRenderer",
                        cellRendererParams: {
                            suppressCount: true,
                            innerRenderer: TemplateRendererComponent,
                            innerRendererParams: {
                                ngTemplate: this.orgNameTemplate
                            }
                        },
                        resizable: true,
                        sort: "asc"
                    },
                    {
                        headerName: "e-Rec",
                        field: "isRegistered",
                        headerTooltip: "eRecording status",
                        cellRenderer: TemplateRendererComponent,
                        cellRendererParams: {
                            ngTemplate: this.readOnlyCheckmarkTemplate,
                            fieldName: "isRegistered"
                        }
                    }
                ]
            },
            {
                headerName: "IPEN",
                headerTooltip: "In Person Electronic Notarization",
                children: [
                    {
                        headerName: "In-St",
                        field: "accepts_ipen_in_state",
                        headerTooltip: "IPEN accepted in-state",
                        cellRenderer: TemplateRendererComponent,
                        cellRendererParams: {
                            ngTemplate: this.checkmarkTemplate,
                            fieldName: "accepts_ipen_in_state"
                        }
                    },
                    {
                        headerName: "Out-St",
                        field: "accepts_ipen_out_of_state",
                        headerTooltip: "IPEN accepted out-of-state",
                        cellRenderer: TemplateRendererComponent,
                        cellRendererParams: {
                            ngTemplate: this.checkmarkTemplate,
                            fieldName: "accepts_ipen_out_of_state"
                        }
                    },
                    {
                        headerName: "Appr",
                        field: "simpfl_approved_ipen_vendor",
                        headerTooltip:
                            "Simplifile is an approved IPEN vendor (state only)",
                        cellRenderer: TemplateRendererComponent,
                        cellRendererParams: {
                            ngTemplate: this.tripleOptionCheckmarkTemplate,
                            fieldName: "simpfl_approved_ipen_vendor"
                        }
                    }
                ]
            },
            {
                headerName: "RON",
                headerTooltip: "Remote Online Notarization",
                children: [
                    {
                        headerName: "In-St",
                        field: "accepts_ron_in_state",
                        headerTooltip: "RON accepted in-state",
                        cellRenderer: TemplateRendererComponent,
                        cellRendererParams: {
                            ngTemplate: this.checkmarkTemplate,
                            fieldName: "accepts_ron_in_state"
                        }
                    },
                    {
                        headerName: "Out-St",
                        field: "accepts_ron_out_of_state",
                        headerTooltip: "RON accepted out-of-state",
                        cellRenderer: TemplateRendererComponent,
                        cellRendererParams: {
                            ngTemplate: this.checkmarkTemplate,
                            fieldName: "accepts_ron_out_of_state"
                        }
                    },
                    {
                        headerName: "Appr",
                        field: "simpfl_approved_ron_vendor",
                        headerTooltip:
                            "Simplifile is an approved RON vendor (state only)",
                        cellRenderer: TemplateRendererComponent,
                        cellRendererParams: {
                            ngTemplate: this.tripleOptionCheckmarkTemplate,
                            fieldName: "simpfl_approved_ron_vendor"
                        }
                    }
                ]
            },
            {
                headerName: "Other",
                children: [
                    {
                        headerName: "Sec Inst",
                        field: "accepts_e_notarized_security_instrument",
                        headerTooltip:
                            "eRecorded Security Instruments accepted",
                        width: 100,
                        cellRenderer: TemplateRendererComponent,
                        cellRendererParams: {
                            ngTemplate: this.checkmarkTemplate,
                            fieldName: "accepts_e_notarized_security_instrument"
                        }
                    }
                ]
            },
            {
                headerName: "Last Updated",
                children: [
                    {
                        headerName: "Date",
                        field: "last_updated",
                        headerTooltip: "Last updated date",
                        width: 130,
                        cellRenderer: TemplateRendererComponent,
                        cellRendererParams: {
                            ngTemplate: this.dateTemplate
                        }
                    },
                    {
                        headerName: "By",
                        field: "last_updated_by",
                        headerTooltip: "User who performed the last update",
                        width: 150
                    }
                ]
            }
        ];
    }

    private _subscribeToCurrentOrg() {
        if (this.activeOrgID) {
            this._currentOrgService.currentOrganization$
                .pipe(takeUntil(this._onDestroy$))
                .subscribe((updatedRecipient: Organization) => {
                    if (updatedRecipient) {
                        this.orgState = String(
                            updatedRecipient.address.state + " "
                        );
                        this._displaySearchResults(updatedRecipient.name);
                        this.filterText.patchValue(updatedRecipient.name);
                    }
                });
        }
    }
}
