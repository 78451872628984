import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { TokenService } from "@sf/userorg/common";
import { SpinnerService } from "@sf/common";
import { SfValidators } from "@sf/common";
import { first, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { CancelInvitationDialogComponent } from "../../dialogs/cancel-invitation-dialog/cancel-invitation-dialog.component";
import { ConfirmationModalComponent } from "@sf/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { InvitationSharedService } from "../../services/invitation-shared.service";
import { UserOrgService } from "@sf/userorg/common";
import { SessionService } from "@sf/common";

interface WizardStep {
    title: string;
}

interface PendingRole {
    organizationID: string;
    organizationName: string;
    roleID: string;
    roleName: string;
}

interface PendingOrganization {
    name: string;
    roles: string[];
}

declare const window: any;

// prettier-ignore
@Component({
    selector: "sf-new-user",
    templateUrl: "./new-user.component.html",
    styleUrls: ["./new-user.component.scss"]
})
export class NewUserComponent implements OnInit, OnDestroy {
    /* Private Variables */
    private _ngOnDestroy: Subject<void>;
    private _userDetails: any = {
        first: "",
        last: "",
        email: ""
    };

    /* Public Variables */
    loaded = false;
    loadFailed: string = null;
    tokenID: string = null;
    userLoggedIn = false;
    showExistingUserLink = true;    // for users who may have an existing account under different email address
    fullName = "";
    emailAddress = "";
    invitedRoles: PendingRole[] = null;
    invitedOrganizations: PendingOrganization[] = null;
    personalForm: UntypedFormGroup;
    currentStep = 0;
    formErrorMessages: string[] = [];
    isInUsernameField = false;
    isInPasswordField = false;
    isInConfirmField = false;
    availableUsernames: string[] = [];
    multipleUsers = false;
    existingUserID: string = null;
    usernamePredefined = false;

    passwordLengthMet = false;
    pwdLength: number = 9; //default
    passwordHas3of4 = false;
    passwordHasNumber = false;
    passwordHasLowerCase = false;
    passwordHasUpperCase = false;
    passwordHasSpecialChar = false;
    passwordsMatch = false;

    wizardSteps: WizardStep[] = [
        {
            title: "Accept Invitation and Simplifile Sign Up"
        },
        {
            title: "Create Your Personal Simplifile Account"
        },
        {
            title: "Sign Up Complete"
        }
    ];

    constructor(
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private modalService: NgbModal,
        private tokenService: TokenService,
        private sessionService: SessionService,
        private userorgService: UserOrgService,
        private invitationSharedService: InvitationSharedService,
        private spinnerService: SpinnerService
    ) {
        this.personalForm = formBuilder.group({
            firstName: ["", Validators.required],
            middleName: [""],
            lastName: ["", Validators.required],
            title: ["", Validators.required],
            phone: ["", [Validators.required, SfValidators.phoneValidator]],
            extension: [
                "",
                [Validators.maxLength(9), SfValidators.numericValidator]
            ],
            userID: [
                "",
                [
                    Validators.required,
                    Validators.minLength(7),
                    SfValidators.usernameValidator
                ]
            ],
            password: ["", [Validators.required]],
            confirm: ["", [Validators.required]],
            email: ["", [Validators.required, SfValidators.emailValidator]]
        });
    }

    ngOnInit() {
        this._ngOnDestroy = new Subject();

        this.route.paramMap
            .pipe(takeUntil(this._ngOnDestroy))
            .subscribe((params) => {
                this.tokenID = params.get("tokenID");
                this.preloadForms(this.tokenID);
            });

        this.catchFormChanges();
    }

    ngOnDestroy() {
        this._ngOnDestroy.next();
    }

    catchFormChanges() {
        this.personalForm.get("password").valueChanges.subscribe((val) => {
            let pwd = this.personalForm.controls.password.value;
            let confirm = this.personalForm.controls.confirm.value;

            this.passwordLengthMet = pwd && pwd.length >= this.pwdLength;
            this.passwordHasNumber = /[0-9]/.test(pwd);
            this.passwordHasLowerCase = /[a-z]/.test(pwd);
            this.passwordHasUpperCase = /[A-Z]/.test(pwd);
            this.passwordHasSpecialChar = /[^a-zA-Z0-9]/.test(pwd);

            let countOfFour = 0;
            if (this.passwordHasNumber) {
                countOfFour++;
            }
            if (this.passwordHasLowerCase) {
                countOfFour++;
            }
            if (this.passwordHasUpperCase) {
                countOfFour++;
            }
            if (this.passwordHasSpecialChar) {
                countOfFour++;
            }
            this.passwordHas3of4 = countOfFour >= 3;

            this.passwordsMatch = confirm === pwd;
        });
        this.personalForm.get("confirm").valueChanges.subscribe((val) => {
            let pwd = this.personalForm.controls.password.value;
            let confirm = this.personalForm.controls.confirm.value;
            this.passwordsMatch = confirm === pwd;
        });
    }

    private preloadForms(tokenID: string) {
        this.tokenService.getUserInvitation(tokenID)
            .subscribe((invitation: any) => {
                if (invitation) {
                    this.loaded = true;

                    this.pwdLength = parseInt(invitation.passwordLength);
                    this._userDetails.first = invitation.firstName;
                    this._userDetails.last = invitation.lastName;
                    this._userDetails.email = invitation.email;

                    if (invitation.firstName || invitation.lastName) {
                        this.fullName = (invitation.firstName ? invitation.firstName + " " : "") +
                                (invitation.lastName ? invitation.lastName : "");
                    }
                    this.emailAddress = invitation.email;
                    this.invitedRoles = invitation.roles;
                    this.invitedOrganizations = this.groupOrganizations(invitation.roles);
                    this.userLoggedIn = invitation.userLoggedIn;

                    // see if there is at least one existing user with the email address
                    this.multipleUsers = invitation.multipleUsers;
                    this.existingUserID = invitation.existingUsername;

                    if (this.existingUserID) {
                        // even if there is more than one user with this email address,
                        // they have specified which user it is
                        this.multipleUsers = false;
                    }

                    if (this.existingUserID || this.multipleUsers) {
                        this.wizardSteps[0].title = "Accept Invitation";
                        this.wizardSteps[2].title = "Acceptance Complete";
                    }

                    this.personalForm.patchValue({
                        firstName: invitation.firstName,
                        middleName: invitation.middleName,
                        lastName: invitation.lastName,
                        title: invitation.title,
                        phone: SfValidators.formatPhone(invitation.phone),
                        extension: invitation.extension,
                        email: invitation.email,
                        userID: invitation.assignedUsername
                    });

                    if (invitation.assignedUsername) {
                        this.usernamePredefined = true;
                    }

                    if (invitation.resultingUsername) {
                        this.loadFailed = "This invitation has already been accepted.";
                        /*
                        if (invitation.resultingUsername) {
                            this.loadFailed +=
                                " Username=" + invitation.resultingUsername;
                        }
                        */
                        this.loaded = true;
                    }

                    if (!invitation.firstName) {
                        invitation.firstName = "";
                    }
                    if (!invitation.lastName) {
                        invitation.lastName = "";
                    }

                    this.generateUsernameOptions(invitation.firstName, invitation.lastName, invitation.email, "");
                }
            }, () => {
                // error
                this.loadFailed =
                        "Your invitation was not found. Please try clicking again on the link in the email message you received.";
                this.loaded = true;
            });
    }

    blurSomeFields() {
        let firstName = this.personalForm.controls.firstName.value;
        let lastName = this.personalForm.controls.lastName.value;
        let email = this.personalForm.controls.email.value;
        this.generateUsernameOptions(firstName, lastName, email, "");
    }

    generateUsernameOptions(firstName: string, lastName: string, email: string, userID: string) {
        if (!firstName) {
            firstName = "";
        }
        if (!lastName) {
            lastName = "";
        }
        if (!email) {
            email = "";
        }
        if (!userID) {
            userID = "";
        }
        this.userorgService.getAvailableUsernames(firstName, lastName, email, userID)
            .pipe(first())
            .subscribe((availableUsernames: any[]) => {
                if (availableUsernames) {
                    this.availableUsernames = availableUsernames;
                } else {
                    this.availableUsernames = [];
                }
            });
    }

    /**
     * We get data from back end where one entry for each role
     */
    private groupOrganizations(
        pendingRoles: PendingRole[]
    ): PendingOrganization[] {
        // First step: group roles by organizations
        let orgsObject: any = {};

        for (let role of pendingRoles) {
            let existingOrg: any = orgsObject[role.organizationName];
            let existingRoles: string[];
            if (existingOrg) {
                existingRoles = existingOrg.roles;
            } else {
                orgsObject[role.organizationName] = {};
            }
            if (!existingRoles) {
                existingRoles = [];
            }
            existingRoles.push(role.roleName);
            orgsObject[role.organizationName].name = role.organizationName;
            orgsObject[role.organizationName].roles = existingRoles;
        }

        // Second step: convert to array
        let orgList: PendingOrganization[] = [];

        for (let orgName in orgsObject) {
            if (orgName) {
                // orgName will be a string
                let existingOrg = orgsObject[orgName];
                let pendingOrg: PendingOrganization = {
                    name: existingOrg.name,
                    roles: existingOrg.roles
                };
                orgList.push(pendingOrg);
            }
        }

        return orgList;
    }

    goToStep(step: number) {
        if (step < this.currentStep) {
            this.formErrorMessages = [];
        }

        this.currentStep = step;
    }

    stepClick(step: number) {
        if (step == this.currentStep) {
            // staying on same step
            return;
        }

        if (step > this.currentStep) {
            // going forward - need to submit form
            this.submitCurrentForm();
            return;
        }

        // going backward
        this.goToStep(step);
    }

    skipForNow() {
        if (!this.userLoggedIn) {
            this.goToLogin();
        } else {
            this.goToHomePage();
        }
    }

    doCancel() {
        const modalRef = this.modalService.open(
            CancelInvitationDialogComponent
        );
        const modalInstance = modalRef.componentInstance;
        modalInstance.tokenID = this.tokenID;
        modalRef.result.then(
            (result: any) => {
                if (result) {
                    sf.metricLog.recordMetric(
                        "onboarding",
                        "cancelInvitation",
                        1,
                        false
                    );
                    this.invitationCanceled(result);
                }
            },
            () => {
                // nothing
            }
        );
    }

    invitationCanceled(action: string) {
        const modal = this.modalService.open(ConfirmationModalComponent, {
            backdrop: "static",
            keyboard: false
        });
        const modalInstance = modal.componentInstance;
        modal.result.then(
            (result: any) => {
                this.goToLogin();
            },
            () => {
                // handle click on X in upper-right
                this.goToLogin();
            }
        );

        modalInstance.title = "Invitation Canceled";
        modalInstance.primary = {
            text: "OK",
            responseValue: 1
            //callback: this.goToLogin.bind(this)
        };
        modalInstance.hideSecondary = true;
        modalInstance.message =
            "Thank you. Your invitation has been successfully canceled";
        if (action == "allInvites") {
            modalInstance.message +=
                ", and you will not receive future invitations from Simplifile. You may receive other types of emails from Simplifile.";
        } else {
            modalInstance.message += ".";
        }
    }

    goToLogin() {
        let loginUrl = "/sf/ui/login";
        window.location = loginUrl;
    }

    goToHomePage() {
        let homePage = this.sessionService.getHomePageUrl();
        if (homePage && !this.sessionService.isSystemUser()) {
            window.location = homePage;
        } else {
            this.goToLogin();
        }
    }

    clickUsernameOption(selection: string) {
        if (this.usernamePredefined) {
            return;
        }
        if (selection) {
            this.personalForm.patchValue({
                userID: selection
            });
            window.setTimeout(() => {
                let passwordField = document.getElementById("password");
                passwordField.focus();
            }, 200);
        }
    }

    focusUsername() {
        if (!this.usernamePredefined) {
            this.isInUsernameField = true;
        }
    }

    blurUsername() {
        // need timeout in case clicked
        window.setTimeout(() => {
            this.isInUsernameField = false;
        }, 100);
    }

    focusPassword() {
        this.isInPasswordField = true;
    }

    blurPassword() {
        this.isInPasswordField = false;
    }

    focusConfirm() {
        this.isInConfirmField = true;
    }

    blurConfirm() {
        this.isInConfirmField = false;
    }

    logInToAccept() {
        // Note that the token will be assigned to the user in login.jsp
        // See index.jsp for how the redirect happens
        // Make it so user will go straight to 'ROLES' page after login (or if already logged in)
        let rolesPage = "ui/settings/roles";
        let redirectUrl = rolesPage + "&token=" + this.tokenID;
        let queryString = "?r=" + redirectUrl + "&err=roles";
        let loginUrl = "/sf/ui/login/" + queryString;

        window.location = loginUrl;
    }

    submitCurrentForm() {
        switch (this.currentStep) {
            case 0:
                if (this.multipleUsers) {
                    // There is more than one existing user with the email address
                    // they will need to log in so we know which one
                    this.logInToAccept();
                } else if (this.existingUserID) {
                    // accept new roles
                    this.submitNewRoles();
                } else {
                    // show sign-up form
                    this.goToStep(1);
                }
                break;
            case 1:
                this.submitPersonalForm();
                break;
            default:
                break;
        }
    }

    signupAgain() {
        this.goToStep(1);
    }

    submitPersonalForm() {
        let firstName: string = this.personalForm.controls.firstName.value;
        let middleName: string = this.personalForm.controls.middleName.value;
        let lastName: string = this.personalForm.controls.lastName.value;
        let email: string = this.personalForm.controls.email.value;
        let userID: string = this.personalForm.controls.userID.value;
        if (userID) {
            userID = userID.toLowerCase();
        }

        this.formErrorMessages =
            this.invitationSharedService.validateNewUserForm(
                this.personalForm,
                true, // title
                true, // phone
                this.passwordLengthMet,
                this.passwordHas3of4,
                this.passwordsMatch
            );

        if (this.formErrorMessages.length) {
            return;
        }

        let isSimplifile = false;
        this.invitedRoles.forEach((role) => {
            if (role.organizationID == "SIMPFL") {
                isSimplifile = true;
            }
        });
        if (isSimplifile && !this.userorgService.validateSimplifileEmailDomain(email)) {
            this.formErrorMessages.push("Simplifile users must have an email address at simplifile.com, ice.com, theice.com, or elliemae.com.");
            return;
        }

        this.spinnerService.startSpinner();
        //check username
        this.userorgService.getAvailableUsernames(firstName, lastName, email, userID)
            .pipe(first())
            .subscribe((availableUsernames: any[]) => {
                if (availableUsernames) {
                    this.availableUsernames = availableUsernames;
                    if (availableUsernames.includes(userID)) {
                        // username is available
                    } else {
                        this.formErrorMessages.push(
                                "Username '" + userID + "' is not available. Please choose a different username.");
                    }
                } else {
                    this.availableUsernames = [];
                }
                //now check email
                this.userorgService.countUsersWithThisEmailAddress(email)
                    .pipe(first())
                    .subscribe((count: number) => {
                        if (count > 0) {
                            this.formErrorMessages.push("Email address '" + email +
                                    "' belongs to an existing user. Do you already have an account?");
                            this.showExistingUserLink = true;
                        }

                        //done checks, submit
                        this.spinnerService.stopSpinner();
                        if (this.formErrorMessages.length == 0) {
                            // we submit the form data
                            window.setTimeout(() => {
                                // timeout needed here because of timing issue
                                this.submitAllData();
                            });
                        }
                    }, () => {
                        this.spinnerService.stopSpinner();
                    });
            }, () => {
                this.spinnerService.stopSpinner();
            });
    }

    submitNewRoles() {
        this.spinnerService.startSpinner();
        this.tokenService.acceptUserRolesInvitation(this.tokenID, this.existingUserID)
            .subscribe(() => {
                this.goToStep(2);
                this.done();
                this.spinnerService.stopSpinner();
            }, () => {
                this.spinnerService.stopSpinner();
            });
    }

    submitAllData() {
        this.isInConfirmField = false;
        this.isInPasswordField = false;
        this.isInUsernameField = false;

        let roleData = {};
        let personalData = this.personalForm.value;
        let mixedData = { ...roleData, ...personalData };

        this.spinnerService.startSpinner();
        this.tokenService.acceptUserInvitation(this.tokenID, mixedData)
            .subscribe(() => {
                this.goToStep(2);
                this.done();
                this.spinnerService.stopSpinner();
            }, () => {
                this.spinnerService.stopSpinner();
            });
    }

    done() {
        const modal = this.modalService.open(ConfirmationModalComponent);
        const modalInstance = modal.componentInstance;

        if (this.existingUserID) {
            modalInstance.title = "Roles Added";
            if (this.invitedRoles.length == 1) {
                modalInstance.message = "Your new role has been added.";
            } else {
                modalInstance.message = "Your new roles have been added.";
            }
        } else {
            modalInstance.title = "Enrollment Successful";
            modalInstance.message = "Welcome to Simplifile! Your user is now created.";
        }
        modalInstance.primary = {
            text: "Log In",
            responseValue: 1,
            callback: this.goToLogin.bind(this)
        };
        modalInstance.hideSecondary = true;
    }
}
