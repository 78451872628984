<div *ngIf="rowData && (!hideActionFlag || !rowData[hideActionFlag])">
    <div *ngIf="isMenu" class="actions-menu">
        <div role="group" ngbDropdown #dropDown="ngbDropdown" container="body">
            <button class="btn btn-link sf-btn-link ms-1" ngbDropdownToggle [attr.id]="'actions-dropdown-' + uniqueID"
                    (keydown)="handleKeydown($event);" [attr.aria-label]="!nameProperty ? 'actions' : 'actions for ' + rowData[nameProperty]">
                <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
            </button>
            <div ngbDropdownMenu class="dropdown-menu" [attr.aria-labelledby]="'actions-dropdown-' + uniqueID">
                <div class="tooltip-wrapper" *ngFor="let action of actions; let i = index;" [ngbTooltip]="action.tooltip" [openDelay]="750">
                    <button #action class="dropdown-item" (click)="doAction(action)" [disabled]="actionDisabled[action.name]" [attr.aria-label]="action.label">
                        <fa-icon [attr.id]="'menu-icon-' + uniqueID + '-' + i" [fixedWidth]="true" [icon]="[action.style, action.name]"></fa-icon>
                        <span class="action-description">{{action.description}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isMenu" class="inline-list">
        <div *ngFor="let action of actions; let i = index;" class="tooltip-wrapper" [ngbTooltip]="action.tooltip" container="body" [openDelay]="750">
            <button [attr.id]="'inline-action' + uniqueID + '-' + i" [disabled]="actionDisabled[action.name]" (click)="doAction(action)" [attr.aria-label]="action.label">
                <fa-icon [attr.id]="'action-icon-' + uniqueID + '-' + i" [icon]="[action.style, action.name]"></fa-icon>
                <span *ngIf="action.description" class="action-description">{{action.description}}</span>
            </button>
        </div>
    </div>
</div>
