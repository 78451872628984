import { Component, OnInit } from "@angular/core";
import { ModalButton } from "../../interfaces/modal-button";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

@Component({
    selector: "sf-change-notes-modal",
    templateUrl: "./change-notes-modal.component.html",
    styleUrls: ["./change-notes-modal.component.scss"]
})
export class ChangeNotesModalComponent implements OnInit {
    constructor(private _fb: UntypedFormBuilder) {}

    notesForm = this._fb.group({
        notes: [""]
    });

    // Required Modal Properties
    primary: ModalButton;
    secondary: ModalButton;
    title: string;

    ngOnInit() {
        this.title = "Briefly describe your changes";
        this.primary = {
            text: "Save",
            responseValue: "save"
        };

        this.secondary = {
            text: "Cancel"
        };
    }
}
