import { UntypedFormArray } from "@angular/forms";
import { AbstractDynamicFormControl } from "./abstract-dynamic-form-control";
import {
    ArrayField,
    ArrayFieldVisibility,
    DynamicField,
    DynamicFormArrayControl,
    FieldVisibility,
    GroupField
} from "../interfaces";
import { FieldType } from "../enums/field-type.enum";
import { applyMixins } from "@sf/common";
import { Type } from "@angular/core";
import { InputComponent } from "../components";
import { generateRandomString } from "@sf/common";
import { Subject } from "rxjs";

export class DynamicFormArray
    extends UntypedFormArray
    implements AbstractDynamicFormControl, DynamicFormArrayControl
{
    isVisible = true;
    type = FieldType.ARRAY;
    repeatableFields: DynamicField | DynamicField[] = [];
    dynamicField: ArrayField;
    expanded: boolean;
    expandable: boolean;
    maxLength: number;
    collapsedMessage: string | Function;

    setIsVisible: (isVisible: boolean) => void;
    setComponent: (component: Type<InputComponent>) => void;
    setDynamicField: (field: DynamicField) => void;
    checkVisibility: (viewState: FieldVisibility) => void;
    checkForChanges$ = new Subject<void>();
    checkForChanges: () => void;

    onVisibilityChange(viewState: ArrayFieldVisibility) {
        this.checkVisibility(viewState);

        for (let controlIndex of this.controls.keys()) {
            (this.controls[controlIndex] as any).onVisibilityChange(
                viewState.fields[controlIndex]
            );
        }
    }

    setRepeatableFields(fields: DynamicField | DynamicField[]) {
        this.repeatableFields = fields;
    }

    setMaxLength(max: number) {
        this.maxLength = max && max > 0 ? max : null;
    }

    getNewDynamicField(newIndex: number) {
        let newFields = JSON.parse(
            JSON.stringify(this.dynamicField.repeatableFields)
        );

        newFields.component = this.dynamicField.repeatableFields.component;

        // if the collapsed Message is a function, then JSON.stringify ignores it. Therefore we need to copy it here
        newFields.collapsedMessage = (
            this.dynamicField.repeatableFields as GroupField
        ).collapsedMessage;

        if (newFields.fields && newFields.fields.length) {
            for (let key of newFields.fields.keys()) {
                newFields.fields[key].component = (
                    this.dynamicField.repeatableFields as GroupField
                ).fields[key].component;
            }
        }

        return this._updateFullPath(newFields, newIndex);
    }

    setExpandability(field: GroupField) {
        this.expanded = field.expanded;
        this.expandable = field.expandable;
        this.collapsedMessage =
            typeof field.collapsedMessage === "function"
                ? field.collapsedMessage.bind(this)
                : field.collapsedMessage;
    }

    private _updateFullPath(field: DynamicField, index: number) {
        let newFullPath = [];

        for (let i = 0; i < field.fullPath.length; i++) {
            let currentPath = field.fullPath[i];

            if (currentPath === "*") {
                if (i < field.fullPath.length - 2) {
                    newFullPath.push(this.dynamicField.fullPath[i]);
                } else {
                    newFullPath.push(index);
                }
            } else {
                newFullPath.push(currentPath);
            }
        }

        field.path = field.path === "*" ? "" + index : field.path;
        field.fullPath = newFullPath;
        field.id = newFullPath + "-" + generateRandomString(10);

        return field;
    }
}
applyMixins(DynamicFormArray, [AbstractDynamicFormControl]);
