<sf-modal-header [title]="'Common Parties'"></sf-modal-header>
<div class="modal-body" >
    <div class="common-party-editor-directive">
        <div *ngIf="errorMessage">
            <div class="errorMessage">There was an error loading the common party names.</div>
        </div>
        <div *ngIf="!errorMessage" class="commonPartiesList">
            <div *ngFor="let party of commonParties;index as $index" class="row" >
                <a class="col-1 link" (click)="removeParty($index)">
                    <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                </a>
                <a class="col-5 link link-editor" (click)="setEditingParty($index)" *ngIf="!party.editing">
                    <div *ngIf="enableAdvancedCommonParties">
                        {{party.value.party.nameUnparsed && party.value.party.nameUnparsed.length > 0? party.value.party.nameUnparsed : "-- Blank --"}}
                    </div>
                    <div *ngIf="!enableAdvancedCommonParties">
                        {{party.value && party.value.length > 0 ? party.value : "-- Blank --"}}
                    </div>
                </a>
                <div class="col-5" *ngIf="party.editing">
                    <input
                        *ngIf="party.editing && !enableAdvancedCommonParties"
                        [id]="'CPInput_' + $index"
                        type="text"
                        [(ngModel)]="party.value"
                        (blur)="setEditingParty($index)" />
                    <input
                        *ngIf="party.editing && enableAdvancedCommonParties"
                        [id]="'CPInput_' + $index"
                        type="text"
                        [(ngModel)]="party.value.party.nameUnparsed"
                        (blur)="setEditingParty($index)" />
                </div>
            </div>
            <br/>
            <div class="row link">
                <a class="col-1 primary" (click)="newParty()">
                    <fa-icon [icon]="['far', 'plus-square']"></fa-icon>
                </a>
                <a class="col-5 primary" (click)="newParty()">Add New Party </a>
            </div>
            <div class="row" *ngIf="isSubmitter && orgID && enableAdvancedCommonParties && hasAdminPermission" id="advanced-common-party">
                <hr/>
                <a class="col-1"
                   [href]="'/sf/ui/submitter/organization/' + orgID + '/e-record/commonParties'"
                   target="_blank">
                    <fa-icon [icon]="['far', 'cog']"></fa-icon>
                </a>
                <a class="col-11"
                   [href]="'/sf/ui/submitter/organization/' + orgID + '/e-record/commonParties'"
                   target="_blank">
                    Advanced Party Configuration
                </a>

            </div>
            <hr *ngIf="possibleParties.length !== 0 " />
            <br/>
        </div>
        <h4 *ngIf="possibleParties.length !== 0 ">Party Names Not Yet Added</h4>
        <div class="existingPartyList">
            <div *ngFor="let party of possibleParties; let $index = index" class="row ">
                <a class="col-1 link" (click)="addParty($index)">
                    <fa-icon [icon]="['far', 'plus-circle']"></fa-icon>
                </a>
                <div class="col-5">{{party.value}}</div>
            </div>
        </div>
    </div>
</div>
<sf-modal-footer *ngIf="!enableAdvancedCommonParties" [primary]="primary" [secondary]="secondary" ></sf-modal-footer>
<sf-modal-footer *ngIf="enableAdvancedCommonParties" [primary]="done" ></sf-modal-footer>
