<div class="mb-3 row" *ngIf="formControl$ | async as control" [formGroup]="controlParent$ | async">
    <label [for]="field.id+'_custom_zip'" class="col">{{field.label}}</label>
    <div class="col-md" [ngbTooltip]="field.toolTip" [ngClass]="{'value-wrong':control.invalid}" placement="top-left">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
        <input
            class="form-control"
            [id]="field.id+'_custom_zip'"
            type="text"
            placeholder="ZIP"
            [autocomplete]="field.id+'_custom_zip'"
            [ngbTypeahead]="typeAhead"
            [focusFirst]="false"
            (click)="focus$.next($any($event).target.value)"
            (focus)="showHighlight();focus$.next($any($event).target.value)"
            (blur)="hideHighlight()"
            [formControl]="control">
        <sf-form-error *ngIf="control.invalid && (!control.pristine || (control.pristine && !control.errors['required']))"
                       [control]="control"
                       [label]="field.label">
        </sf-form-error>
    </div>
</div>
