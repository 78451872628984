<div class="sf-unmask">
    <sf-modal-header [title]=imageUpdateItem.dialogTitle></sf-modal-header>
    <div class="sf-settings-image-upload-dialog modal-body">
        <div class="row no-gutters" [ngClass]="{'explain': profilePicFilePreview}">
            <i>{{imageUpdateItem.dialogText}}</i>
        </div>
        <div *ngIf="errorMessage" class="input-error row mt-2" [ngClass]="{'on': errorMessage}">
            {{errorMessage}}
        </div>
        <div *ngIf="profilePicFilePreview" class="top-space row col-sm-12 bottom-space">
            <div class="col-sm-6">
                <div *ngIf="(!showCropper && !imageUpdateItem.orgLogo) || (loading && imageUpdateItem.orgLogo)"
                     class="bottom-space d-flex justify-content-center">
                    <fa-icon icon="spinner" size="2x" [spin]="true"></fa-icon>
                </div>
                <div class="bottom-space bold text-overflow">Image to Upload: {{profilePicFile.name}}</div>
                <!--  Add in logic to display a message if using a non IE/Safari browser when uploading a tiff.
                Display image cropper and tool if IE/Safari always
                or if not tiff and any chrome/edge/firefox browser-->
                <!-- not helpful
                <div *ngIf="!loading && isTiff && !imageUpdateItem.isIE && !imageUpdateItem.isSafari">
                    <div style="height: 50px; white-space: normal">No image preview available for tiff files on this browser</div>
                </div>
                -->
                <div *ngIf="imageUpdateItem.orgLogo">
                    <div *ngIf="(!loading && !isTiff && !imageUpdateItem.isIE && !imageUpdateItem.isSafari) || (!loading && (imageUpdateItem.isIE || imageUpdateItem.isSafari))">
                        <div><img [src]="profilePicFilePreview" class="profile-image sf-exclude" alt="Preview"/></div>
                    </div>
                </div>
                <div *ngIf="!imageUpdateItem.orgLogo">
                    <div [hidden]="!showCropper" *ngIf="(!loading && !isTiff && !imageUpdateItem.isIE && !imageUpdateItem.isSafari) || (!loading && (imageUpdateItem.isIE || imageUpdateItem.isSafari))">
                        <div class="sf-exclude">
                            <image-cropper
                                    [imageFile]="profilePicFile"
                                    [maintainAspectRatio]="true"
                                    [containWithinAspectRatio]="containWithinAspectRatio"
                                    [aspectRatio]="1"
                                    [cropperMinWidth]="80"
                                    [roundCropper]="true"
                                    [transform]="transform"
                                    [alignImage]="'left'"
                                    format="png"
                                    (imageCropped)="imageCropped($event)"
                                    (imageLoaded)="imageLoaded()"
                                    (cropperReady)="cropperReady($event)"
                                    (loadImageFailed)="loadImageFailed()"
                            ></image-cropper>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-sm-8">
                                <input type="range" step='0.0000001' min='0' max='9' #ranger
                                       (input)="zoomSlider(+ranger.value)" (change)="zoomSlider(+ranger.value)" value='0'
                                       aria-label="Zoom level">
                            </div>
                            <div  [ngClass]="{ 'zoom-label-ie': imageUpdateItem.isIE }" class="col-md-4 bold">Zoom</div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="croppedImage" class="col-sm-6">
                <div class="bottom-space bold">Image Preview:</div>
                <div>
                    <img [src]="croppedImageUrl" class="profile-image-preview sf-exclude" alt="Preview"
                         style="border: 1px solid gray; border-radius: 50%;"/>
                </div>
            </div>
        </div>
        <!--    Hidden File Input-->
        <div>
            <input hidden (change)="uploadProfilePic($event)" #imgFileUpload type="file" id="singleImageFile" name="singleImageFile" accept="image/*" class="upload-input" />
        </div>
    </div>
    <sf-modal-footer [primary]="primary" [secondary]="secondary" [tertiary]="tertiary"></sf-modal-footer>
</div>
