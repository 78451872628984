import { Component, Inject, OnInit } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "../../services/login.service";
import { filter, switchMap, take } from "rxjs/operators";
import { Auth } from "@sf/common";
import { APP_BOOTSTRAP_SERVICE, AppBootstrapService } from "@sf/common";
import { SocketService } from "@sf/common";
import { UserLockedDialogComponent } from "../../modals/user-locked-dialog/user-locked-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { QueryStringService } from "@sf/common";

interface CodeResponse {
    email: string;
    mobilePhone: string;
    mobileAllowed: boolean;
    voiceAllowed: boolean;
    totpSecret: boolean;
    totpAllowed: boolean;
    has: boolean; // if a code has been sent during this session
}

enum MFAChannel {
    email = "email",
    sms = "sms",
    voice = "voice",
    totp = "totp"
}

// prettier-ignore
@Component({
    selector: "sf-second-factor",
    templateUrl: "./second-factor.component.html",
    styleUrls: ["./second-factor.component.scss"]
})
export class SecondFactorComponent implements OnInit {
    sendForm: UntypedFormGroup;
    loginForm: UntypedFormGroup;
    formErrorMessages: string[] = [];
    codeSent = false;
    howSent: string = "";
    sendResultText = "";
    codeOnFile = false;
    invalidCode = false;
    simplifilePhone: string = "800.460.5657";
    sending = false;
    validating = false;
    waiting = false;
    destination: MFAChannel = MFAChannel.email;
    sentDestination: MFAChannel = null;
    hasMobilePhone = false;
    totpSecret = false;
    totpAllowed = false;
    mobileNumber = "";
    mobileAllowed = false;
    voiceAllowed = false;
    emailAddress = "";

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private loginService: LoginService,
        @Inject(APP_BOOTSTRAP_SERVICE)
        private appBootstrap: AppBootstrapService,
        private queryStringService: QueryStringService,
        private socketService: SocketService
    ) {
        this.sendForm = formBuilder.group({
            destination: []
        });
        this.loginForm = formBuilder.group({
            code: ["", Validators.required]
        });
    }

    ngOnInit() {
        this.sending = true;
        this.loginService.isValidSecondFactorCodeOnFile().subscribe((result: CodeResponse) => {
            this.sending = false;
            if (result.has) {
                // code has been sent, and user probably refreshed the page
                this.codeSent = true;
                this.focusElement("code");
            }
            this.emailAddress = result.email;
            this.mobileNumber = result.mobilePhone;
            this.mobileAllowed = result.mobileAllowed;
            this.voiceAllowed = result.voiceAllowed;
            this.totpSecret = result.totpSecret;
            this.totpAllowed = result.totpAllowed;
            if (result.mobilePhone) {
                this.hasMobilePhone = true;
                this.destination = MFAChannel.sms;
            }
        }, (errorResult: any) => {
            this.sending = false;
        });
    }

    focusElement(elementID: string) {
        window.setTimeout(() => {
            let theField = document.getElementById(elementID);
            if (theField) {
                theField.focus();
            }
        }, 200);
    }

    destinationChanged(destination: string) {
        if (destination == MFAChannel.sms) {
            this.destination = MFAChannel.sms;
        } else if (destination == MFAChannel.voice) {
            this.destination = MFAChannel.voice;
        } else if (destination == MFAChannel.email) {
            this.destination = MFAChannel.email;
        } else if (destination == MFAChannel.totp) {
            this.destination = MFAChannel.totp;
            this.codeSent = true;
            this.sentDestination = this.destination;
        } else {
            alert("invalid channel");
        }
    }

    sendCode() {
        this.formErrorMessages = [];
        this.howSent = "";
        this.sendResultText = "";
        this.sentDestination = null;

        this.sending = true;
        if (this.destination == MFAChannel.sms) {
            this.sendCodeSMS();
        } else if (this.destination == MFAChannel.voice) {
            this.sendCodeVoice();
        } else {
            this.sendCodeEmail();
        }

        this.startWaiting();
    }

    startWaiting() {
        // disable the button for 10 seconds
        this.waiting = true;
        window.setTimeout(() => {
            this.waiting = false;
        }, 10000);
    }

    sendCodeEmail() {
        this.loginService.sendSecondFactorEmail().subscribe((result: any) => {
            this.howSent = "We sent an authorization code to your email " + this.emailAddress + ".";
            this.codeSent = true;
            this.sending = false;
            this.focusElement("code");
            this.sendResultText = "Check your email inbox for a message from simplifile.com";
            this.sentDestination = MFAChannel.email;
            if (result.code) {
                this.howSent += " The code is: " + result.code;
            }
        }, (errorResult: any) => {
            this.formErrorMessages.push("Email send failed. Please try logging in again.");
            this.sending = false;
        });
    }

    sendCodeSMS() {
        this.loginService.sendSecondFactorSMS().subscribe((result: any) => {
            this.howSent = "We sent an authorization code to your phone number " + this.mobileNumber + ".";
            this.codeSent = true;
            this.sending = false;
            this.focusElement("code");
            this.sendResultText = "Check your mobile phone for the authorization code";
            this.sentDestination = MFAChannel.sms;
            if (result.code) {
                this.howSent += " The code is: " + result.code;
            }
        }, (errorResult: any) => {
            this.formErrorMessages.push("Text send failed. Please try another method or log in again.");
            this.sending = false;
        });
    }

    sendCodeVoice() {
        this.loginService.sendSecondFactorVoice().subscribe((result: any) => {
            this.howSent = "We are trying to call your phone number " + this.mobileNumber + ".";
            this.codeSent = true;
            this.sending = false;
            this.focusElement("code");
            this.sendResultText = "We will call your phone with the authorization code";
            this.sentDestination = MFAChannel.voice;
            if (result.code) {
                this.howSent += " The code is: " + result.code;
            }
        }, (errorResult: any) => {
            this.formErrorMessages.push("Phone call failed. Please try another method or log in again.");
            this.sending = false;
        });
    }

    submitCode() {
        let code: string = this.loginForm.controls.code.value;
        if (code) {
            code = code.trim();
        }
        if (!code) {
            this.invalidCode = true;
            return;
        }

        this.invalidCode = false;
        this.validating = true;
        this.loginService.secondFactorLogin(code, this.sentDestination).subscribe(
            (result: any) => {
                if (result.status == "success") {
                    let nextPage = result.nextPage;
                    let redirect = this.getRedirectParam();
                    if (redirect) {
                        nextPage = redirect;
                    }
                    this.goToNextPage(nextPage);
                } else if (result.status == "locked") {
                    this.validating = false;
                    this.invalidCode = true;
                    this.userLockedOut();
                } else {
                    this.validating = false;
                    this.invalidCode = true;
                }
            },
            (errorResult: any) => {
                this.invalidCode = true;
                this.validating = false;
            }
        );
    }

    goToNextPage(nextPage: string) {
        if (nextPage.startsWith("/sf/ui")) {
            // user is logged in, so launch the socket
            let nextPageInternal = nextPage.replace("/sf/ui", "");

            this.socketService.authMessages
                .pipe(
                    filter((auth: Auth) => auth.data.authenticated),
                    switchMap((auth: Auth) => this.appBootstrap.bootstrapAfterLogin(auth)),
                    take(1)
                )
                .subscribe(() => {
                    this.router.navigateByUrl(nextPageInternal);
                });
            this.socketService.reconnect();
        } else {
            // not a normal case
            window.location.assign(nextPage);
        }
    }

    userLockedOut() {
        const modalRef = this.modalService.open(UserLockedDialogComponent);
        const modalInstance = modalRef.componentInstance;
        modalRef.result.then(
            (result: any) => {
                this.goToLogin();
            },
            () => {
                this.goToLogin();
            }
        );
    }

    clickContact() {
        let newUrl = "/login/contact";
        this.router.navigate([newUrl], { relativeTo: this.route });
    }

    clickBack() {
        let newUrl = "/login";
        this.router.navigate([newUrl], { relativeTo: this.route });
    }

    goToLogin() {
        let newUrl = "/sf/ui/login";
        window.location.assign(newUrl);
    }

    getRedirectParam(): string {
        let redirect = this.queryStringService.getQueryParam("fr");
        if (redirect) {
            let path = redirect;
            path = decodeURIComponent(path);
            if (!path.startsWith("/")) {
                path = "/" + path;
            }
            if (!path.startsWith("/sf/")) {
                path = "/sf" + path;
            }
            redirect = path;
        }
        return redirect;
    }
}
