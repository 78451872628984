// MAKE SURE TO USE DEEP IMPORTS!
// https://github.com/FortAwesome/angular-fontawesome/issues/34
// Keep imports sorted alphabetically (case-insensitive).
import {
    faAddressBook,
    faAdjust,
    faAlarmExclamation,
    faAngleDoubleDown,
    faAngleDoubleRight,
    faAngleDoubleUp,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArchive as faArchiveOutline,
    faArchive,
    faArrowAltCircleRight,
    faArrowCircleDown,
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowCircleUp,
    faArrowDown,
    faArrowFromTop,
    faArrowLeft,
    faArrowRight,
    faArrowsAlt,
    faArrowsAltV,
    faArrowsH,
    faArrowsV,
    faArrowToLeft,
    faArrowToRight,
    faArrowUp,
    faAsterisk,
    faAt,
    faBackspace,
    faBan,
    faBars,
    faBell,
    faBellSlash,
    faBicycle,
    faBolt,
    faBomb,
    faBook,
    faBox,
    faBoxOpen,
    faBuilding,
    faCalculator,
    faCalendar,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarMinus,
    faCalendarTimes,
    faCameraRetro,
    faCaretSquareDown as faCaretSquareDownRegular,
    faCaretSquareRight as faCaretSquareRightRegular,
    faCartArrowDown,
    faCertificate,
    faChalkboardTeacher,
    faChartBar,
    faCheck,
    faCheckCircle,
    faCheckDouble,
    faCheckSquare,
    faChessBoard,
    faChevronCircleRight,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCity,
    faClipboard,
    faClipboardCheck,
    faClipboardList,
    faClipboardListCheck,
    faClock,
    faCloudUploadAlt,
    faCode,
    faCodeBranch,
    faCoffee,
    faCog,
    faCogs,
    faColumns,
    faComment,
    faCommentAltEdit,
    faCommentDollar,
    faComments,
    faCompressAlt,
    faCopy,
    faCreditCard,
    faCube,
    faCubes,
    faCut,
    faDatabase,
    faDesktop,
    faDollarSign,
    faDoorClosed,
    faDotCircle,
    faDownload,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faEnvelope,
    faEraser,
    faExchange,
    faExchangeAlt,
    faExclamation,
    faExclamationCircle,
    faExclamationSquare,
    faExclamationTriangle,
    faExpandAlt,
    faExpandArrowsAlt,
    faExternalLink,
    faExternalLinkSquare,
    faEye,
    faEyeSlash,
    faFile,
    faFileAlt,
    faFileCheck,
    faFileCode,
    faFileContract,
    faFileCsv,
    faFileExcel,
    faFileExclamation,
    faFileExport,
    faFileImage,
    faFileInvoiceDollar,
    faFilePdf,
    faFileSignature,
    faFileTimes,
    faFileUpload,
    faFileWord,
    faFilter,
    faFlag,
    faFlagCheckered,
    faFlask,
    faFolderOpen,
    faFont,
    faGavel,
    faGift,
    faGlobeAmericas,
    faGraduationCap,
    faGreaterThan,
    faGripHorizontal,
    faGripVertical,
    faHandHoldingUsd,
    faHandPaper,
    faHandPointRight,
    faHandPointUp,
    faHandRock,
    faHandshake,
    faHeart,
    faHistory,
    faHome,
    faHourglassEnd,
    faHourglassHalf,
    faIdCard,
    faImages,
    faInfo,
    faInfoCircle,
    faKey,
    faKeyboard,
    faLessThan,
    faLevelDown,
    faLevelUp,
    faLifeRing,
    faLink,
    faListAlt,
    faListUl,
    faLock,
    faLongArrowLeft,
    faLongArrowRight,
    faMagic,
    faMale,
    faMap,
    faMapPin,
    faMicrophone,
    faMicrophoneSlash,
    faMinus,
    faMinusCircle,
    faMinusSquare,
    faMobile,
    faMoneyCheck,
    faNewspaper,
    faObjectGroup,
    faObjectUngroup,
    faPaperPlane,
    faPauseCircle,
    faPenAlt,
    faPencilAlt,
    faPhone,
    faPhoneAlt,
    faPhoneSquare,
    faPhotoVideo,
    faPlay,
    faPlayCircle,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faPrint,
    faProjectDiagram,
    faQuestion,
    faQuestionCircle,
    faRandom,
    faReceipt,
    faRedo,
    faRepeat,
    faRetweet,
    faRotateLeft,
    faRotateRight,
    faSave,
    faScannerImage,
    faSearch,
    faSearchMinus,
    faSearchPlus,
    faServer,
    faShieldAlt,
    faSignal,
    faSignature,
    faSignInAlt,
    faSignOutAlt,
    faSlidersH,
    faSortAlphaDown,
    faSortAlphaDownAlt,
    faSortAmountDown,
    faSortAmountDownAlt,
    faSortAmountUp,
    faSortAmountUpAlt,
    faSortDown,
    faSortNumericDown,
    faSortNumericDownAlt,
    faSortUp,
    faSpinner as faSpinnerRegular,
    faSquare,
    faStamp,
    faStar,
    faStickyNote,
    faStopwatch,
    faSun,
    faSync,
    faSyncAlt,
    faTable,
    faTags,
    faTasks,
    faTasksAlt,
    faText,
    faTh,
    faThList,
    faThumbsDown,
    faThumbsUp,
    faTicketAlt,
    faTimes,
    faTimesCircle,
    faTimesOctagon,
    faToggleOff,
    faToggleOn,
    faTrademark,
    faTrash,
    faTrashAlt,
    faTruck,
    faUndo,
    faUndoAlt,
    faUniversity,
    faUnlink,
    faUnlock,
    faUpload,
    faUser,
    faUserChart,
    faUserCircle,
    faUserClock,
    faUserCog,
    faUserEdit,
    faUserMinus,
    faUserPlus,
    faUsers,
    faUsersClass,
    faUsersCog,
    faUserShield,
    faUserSlash,
    faUserTag,
    faUserTimes,
    faVectorSquare,
    faVideo,
    faVideoSlash,
    faVolume,
    faVolumeOff,
    faVolumeUp,
    faWalking,
    faWasher,
    faWindowClose,
    faWindowMaximize,
    faWindowRestore,
    faWrench
} from "@fortawesome/pro-regular-svg-icons";
import {
    faAlignJustify,
    faCheckCircle as faCheckCircleLight,
    faClipboard as faClipboardLight,
    faEquals,
    faListAlt as faListAltLight,
    faNotesMedical,
    faSitemap,
    faStickyNote as faStickyNoteLight
} from "@fortawesome/pro-light-svg-icons";
import {
    faArchive as faArchiveSolid,
    faAsterisk as faAsteriskSolid,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretSquareDown,
    faCaretSquareRight,
    faCaretUp,
    faCertificate as faCertificateSolid,
    faCheckCircle as faCheckCircleSolid,
    faCheckSquare as faCheckSquareSolid,
    faCircle as faCircleSolid,
    faComment as faCommentSolid,
    faDollarSign as faDollarSignSolid,
    faDoNotEnter,
    faExclamationTriangle as faExclamationTriangleSolid,
    faFileAlt as faFileAltSolid,
    faInfo as faInfoSolid,
    faLandmark,
    faMinusSquare as faMinusSquareSolid,
    faMobileAlt,
    faPaperPlane as faPaperPlaneSolid,
    faPlusCircle as faPlusCircleSolid,
    faPlusSquare as faPlusSquareSolid,
    faQuestion as faQuestionSolid,
    faQuestionCircle as faQuestionCircleSolid,
    faSpaceStationMoon,
    faSquare as faSquareSolid,
    faStar as faStarSolid,
    faTh as faTHSolid,
    faTimes as faTimesSolid,
    faTimesCircle as faTimesCircleSolid,
    faWindowClose as faWindowCloseSolid
} from "@fortawesome/pro-solid-svg-icons";
import {
    faCcAmex,
    faCcDinersClub,
    faCcDiscover,
    faCcJcb,
    faCcMastercard,
    faCcVisa,
    faEmpire,
    faHtml5,
    faRebel,
    faSalesforce
} from "@fortawesome/free-brands-svg-icons";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons";

import { Icons } from "../interfaces";

// MAKE SURE TO USE DEEP IMPORTS!
// https://github.com/FortAwesome/angular-fontawesome/issues/34
// Keep imports sorted alphabetically (case-insensitive).

// Keep the fields of the ICONS object sorted alphabetically (case-insensitive).
export const ICONS: Icons = {
    addressBook: faAddressBook,
    adjust: faAdjust,
    alarmExclamation: faAlarmExclamation,
    alignJustify: faAlignJustify,
    angleDoubleDown: faAngleDoubleDown,
    angleDoubleRight: faAngleDoubleRight,
    angleDoubleUp: faAngleDoubleUp,
    angleDown: faAngleDown,
    angleLeft: faAngleLeft,
    angleRight: faAngleRight,
    angleUp: faAngleUp,
    archive: faArchive,
    archiveOutline: faArchiveOutline,
    archiveSolid: faArchiveSolid,
    arrowAltCircleRight: faArrowAltCircleRight,
    arrowCircleLeft: faArrowCircleLeft,
    arrowCircleRight: faArrowCircleRight,
    arrowCircleUp: faArrowCircleUp,
    arrowCircleDown: faArrowCircleDown,
    arrowDown: faArrowDown,
    arrowFromTop: faArrowFromTop,
    arrowLeft: faArrowLeft,
    arrowRight: faArrowRight,
    arrowsAlt: faArrowsAlt,
    arrowsAltV: faArrowsAltV,
    arrowsV: faArrowsV,
    arrowsH: faArrowsH,
    arrowToLeft: faArrowToLeft,
    arrowToRight: faArrowToRight,
    arrowUp: faArrowUp,
    asterisk: faAsterisk,
    asteriskSolid: faAsteriskSolid,
    at: faAt,
    backspace: faBackspace,
    ban: faBan,
    bars: faBars,
    bell: faBell,
    bellSlash: faBellSlash,
    bicycle: faBicycle,
    bolt: faBolt,
    bomb: faBomb,
    book: faBook,
    box: faBox,
    boxOpen: faBoxOpen,
    building: faBuilding,
    calculator: faCalculator,
    calendar: faCalendar,
    calendarAlt: faCalendarAlt,
    calendarCheck: faCalendarCheck,
    calendarMinus: faCalendarMinus,
    calendarTimes: faCalendarTimes,
    cameraRetro: faCameraRetro,
    caretDown: faCaretDown,
    caretLeft: faCaretLeft,
    caretRight: faCaretRight,
    caretSquareDown: faCaretSquareDown,
    caretSquareDownRegular: faCaretSquareDownRegular,
    caretSquareRight: faCaretSquareRight,
    caretSquareRightRegular: faCaretSquareRightRegular,
    caretUp: faCaretUp,
    cartArrowDown: faCartArrowDown,
    ccAmex: faCcAmex,
    ccDinersClub: faCcDinersClub,
    ccDiscover: faCcDiscover,
    ccJcb: faCcJcb,
    ccMastercard: faCcMastercard,
    ccVisa: faCcVisa,
    certificate: faCertificate,
    certificateSolid: faCertificateSolid,
    chalkboardTeacher: faChalkboardTeacher,
    chartBar: faChartBar,
    check: faCheck,
    checkCircle: faCheckCircle,
    checkCircleLight: faCheckCircleLight,
    checkCircleSolid: faCheckCircleSolid,
    checkDouble: faCheckDouble,
    checkSquare: faCheckSquare,
    checkSquareSolid: faCheckSquareSolid,
    chessBoard: faChessBoard,
    chevronCircleRight: faChevronCircleRight,
    chevronDown: faChevronDown,
    chevronLeft: faChevronLeft,
    chevronRight: faChevronRight,
    chevronUp: faChevronUp,
    chevronDoubleLeft: faChevronDoubleLeft,
    chevronDoubleRight: faChevronDoubleRight,
    circle: faCircle,
    circleSolid: faCircleSolid,
    city: faCity,
    clipboard: faClipboard,
    clipboardLight: faClipboardLight,
    clipboardCheck: faClipboardCheck,
    clipboardList: faClipboardList,
    clipboardListCheck: faClipboardListCheck,
    clock: faClock,
    cloudUploadAlt: faCloudUploadAlt,
    coffee: faCoffee,
    code: faCode,
    codeBranch: faCodeBranch,
    cog: faCog,
    cogs: faCogs,
    columns: faColumns,
    comment: faComment,
    commentSolid: faCommentSolid,
    commentDollar: faCommentDollar,
    commentAltEdit: faCommentAltEdit,
    comments: faComments,
    compressAlt: faCompressAlt,
    copy: faCopy,
    creditCard: faCreditCard,
    cube: faCube,
    cubes: faCubes,
    cut: faCut,
    database: faDatabase,
    desktop: faDesktop,
    dollarSign: faDollarSign,
    dollarSignSolid: faDollarSignSolid,
    doNotEnter: faDoNotEnter,
    doorClosed: faDoorClosed,
    dotCircle: faDotCircle,
    download: faDownload,
    edit: faEdit,
    ellipsisH: faEllipsisH,
    ellipsisV: faEllipsisV,
    empire: faEmpire,
    envelope: faEnvelope,
    equals: faEquals,
    eraser: faEraser,
    exchange: faExchange,
    exchangeAlt: faExchangeAlt,
    exclamation: faExclamation,
    exclamationCircle: faExclamationCircle,
    exclamationSquare: faExclamationSquare,
    exclamationTriangle: faExclamationTriangle,
    exclamationTriangleSolid: faExclamationTriangleSolid,
    expandAlt: faExpandAlt,
    expandArrowsAlt: faExpandArrowsAlt,
    externalLink: faExternalLink,
    externalLinkSquare: faExternalLinkSquare,
    eye: faEye,
    eyeSlash: faEyeSlash,
    file: faFile,
    fileAlt: faFileAlt,
    fileAltSolid: faFileAltSolid,
    fileCheck: faFileCheck,
    fileCode: faFileCode,
    fileCsv: faFileCsv,
    fileContract: faFileContract,
    fileExcel: faFileExcel,
    fileExclamation: faFileExclamation,
    fileExport: faFileExport,
    fileImage: faFileImage,
    fileInvoiceDollar: faFileInvoiceDollar,
    filePdf: faFilePdf,
    fileTimes: faFileTimes,
    fileUpload: faFileUpload,
    fileWord: faFileWord,
    fileSignature: faFileSignature,
    filter: faFilter,
    flag: faFlag,
    flagCheckered: faFlagCheckered,
    flask: faFlask,
    folderOpen: faFolderOpen,
    font: faFont,
    gavel: faGavel,
    gift: faGift,
    globeAmericas: faGlobeAmericas,
    graduationCap: faGraduationCap,
    greaterThan: faGreaterThan,
    gripHorizontal: faGripHorizontal,
    gripVertical: faGripVertical,
    handHoldingUsd: faHandHoldingUsd,
    handPointRight: faHandPointRight,
    handPointUp: faHandPointUp,
    handPaper: faHandPaper,
    handRock: faHandRock,
    handshake: faHandshake,
    heart: faHeart,
    history: faHistory,
    home: faHome,
    hourglassEnd: faHourglassEnd,
    hourglassHalf: faHourglassHalf,
    html: faHtml5,
    idCard: faIdCard,
    images: faImages,
    info: faInfo,
    infoSolid: faInfoSolid,
    infoCircle: faInfoCircle,
    key: faKey,
    keyboard: faKeyboard,
    lessThan: faLessThan,
    landmark: faLandmark,
    levelDown: faLevelDown,
    levelUp: faLevelUp,
    link: faLink,
    lifeRing: faLifeRing,
    listAlt: faListAlt,
    listAltLight: faListAltLight,
    listUl: faListUl,
    lock: faLock,
    longArrowRight: faLongArrowRight,
    longArrowLeft: faLongArrowLeft,
    magic: faMagic,
    male: faMale,
    map: faMap,
    mapPin: faMapPin,
    microphone: faMicrophone,
    microphoneSlash: faMicrophoneSlash,
    minus: faMinus,
    minusCircle: faMinusCircle,
    minusSquare: faMinusSquare,
    minusSquareSolid: faMinusSquareSolid,
    mobile: faMobile,
    mobileAlt: faMobileAlt,
    moneyCheck: faMoneyCheck,
    newspaper: faNewspaper,
    notesMedical: faNotesMedical,
    objectGroup: faObjectGroup,
    objectUngroup: faObjectUngroup,
    paperPlane: faPaperPlane,
    paperPlaneSolid: faPaperPlaneSolid,
    pauseCircle: faPauseCircle,
    penAlt: faPenAlt,
    pencilAlt: faPencilAlt,
    phoneSquare: faPhoneSquare,
    photoVideo: faPhotoVideo,
    phone: faPhone,
    phoneAlt: faPhoneAlt,
    play: faPlay,
    playCircle: faPlayCircle,
    plus: faPlus,
    plusCircle: faPlusCircle,
    plusCircleSolid: faPlusCircleSolid,
    plusSquare: faPlusSquare,
    plusSquareSolid: faPlusSquareSolid,
    print: faPrint,
    projectDiagram: faProjectDiagram,
    question: faQuestion,
    questionSolid: faQuestionSolid,
    questionCircle: faQuestionCircle,
    questionCircleSolid: faQuestionCircleSolid,
    random: faRandom,
    rebel: faRebel,
    receipt: faReceipt,
    redo: faRedo,
    repeat: faRepeat,
    retweet: faRetweet,
    rotateLeft: faRotateLeft,
    rotateRight: faRotateRight,
    salesforce: faSalesforce,
    save: faSave,
    scannerImage: faScannerImage,
    search: faSearch,
    searchMinus: faSearchMinus,
    searchPlus: faSearchPlus,
    server: faServer,
    shieldAlt: faShieldAlt,
    signal: faSignal,
    signature: faSignature,
    signInAlt: faSignInAlt,
    signOutAlt: faSignOutAlt,
    sitemap: faSitemap,
    slidersH: faSlidersH,
    sortAlphaDown: faSortAlphaDown,
    sortAlphaDownAlt: faSortAlphaDownAlt,
    sortAmountDown: faSortAmountDown,
    sortAmountDownAlt: faSortAmountDownAlt,
    sortAmountUp: faSortAmountUp,
    sortAmountUpAlt: faSortAmountUpAlt,
    sortDown: faSortDown,
    sortNumericDown: faSortNumericDown,
    sortNumericDownAlt: faSortNumericDownAlt,
    sortUp: faSortUp,
    spaceStationMoon: faSpaceStationMoon,
    spinner: faSpinner,
    spinnerRegular: faSpinnerRegular,
    square: faSquare,
    squareSolid: faSquareSolid,
    stamp: faStamp,
    star: faStar,
    starSolid: faStarSolid,
    stickyNote: faStickyNote,
    stickyNoteLight: faStickyNoteLight,
    stopwatch: faStopwatch,
    sun: faSun,
    sync: faSync,
    syncAlt: faSyncAlt,
    table: faTable,
    tags: faTags,
    tasks: faTasks,
    tasksAlt: faTasksAlt,
    text: faText,
    th: faTh,
    thSolid: faTHSolid,
    thList: faThList,
    thumbsDown: faThumbsDown,
    thumbsUp: faThumbsUp,
    ticketAlt: faTicketAlt,
    times: faTimes,
    timesCircle: faTimesCircle,
    timesCircleSolid: faTimesCircleSolid,
    timesOctagon: faTimesOctagon,
    timesSolid: faTimesSolid,
    toggleOff: faToggleOff,
    toggleOn: faToggleOn,
    trademark: faTrademark,
    trash: faTrash,
    trashAlt: faTrashAlt,
    truck: faTruck,
    undo: faUndo,
    undoAlt: faUndoAlt,
    university: faUniversity,
    unlink: faUnlink,
    unlock: faUnlock,
    upload: faUpload,
    user: faUser,
    userChart: faUserChart,
    userCircle: faUserCircle,
    userClock: faUserClock,
    userCog: faUserCog,
    userEdit: faUserEdit,
    userMinus: faUserMinus,
    userPlus: faUserPlus,
    users: faUsers,
    usersClass: faUsersClass,
    usersCog: faUsersCog,
    userShield: faUserShield,
    userSlash: faUserSlash,
    userTag: faUserTag,
    userTimes: faUserTimes,
    vectorSquare: faVectorSquare,
    video: faVideo,
    videoSlash: faVideoSlash,
    volume: faVolume,
    volumeOff: faVolumeOff,
    volumeUp: faVolumeUp,
    walking: faWalking,
    washer: faWasher,
    windowClose: faWindowClose,
    windowCloseSolid: faWindowCloseSolid,
    windowMaximize: faWindowMaximize,
    windowRestore: faWindowRestore,
    wrench: faWrench
};
// Keep the fields of the ICONS object sorted alphabetically (case-insensitive).
