import {
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    AfterViewInit,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import {
    COMPONENT_FROM_FIELD,
    DynamicFormStore,
    FieldState,
    FieldType,
    InputComponent,
    FieldViewState,
    GroupFieldViewState
} from "@sf/dynamic-form-viewer";
import { scan, take, takeUntil } from "rxjs/operators";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { CustomPartyTypeFieldComponent } from "../custom-party-type-field/custom-party-type-field.component";
import { Observable, of, Subject } from "rxjs";
import { CustomPartyNameFieldComponent } from "../custom-party-name-field/custom-party-name-field.component";
import { ErecordDocumentDetails } from "@sf/document/erecord-viewer/common";
import { CustomButtonFieldComponent } from "../custom-button-field/custom-button-field.component";

export interface messageDisplay {
    display: string;
    hasNewLine: boolean;
}

@Component({
    selector: "sf-custom-group-field",
    templateUrl: "./custom-group-field.component.html",
    styleUrls: ["./custom-group-field.component.scss"]
})
export class CustomGroupFieldComponent
    extends InputComponent
    implements OnInit, OnDestroy
{
    private _destroy$: Subject<void> = new Subject();

    @Output()
    onPartySelection?: EventEmitter<any> = new EventEmitter();
    @Output()
    onPartyTypeChanged: EventEmitter<string | number> = new EventEmitter();
    @Input()
    field: FieldState;
    @Input()
    autoCompleteData?: Observable<string[]>;
    @Input()
    docLeftData?: ErecordDocumentDetails;
    @Input()
    onSave: Observable<any>;
    @Input()
    onExpandableSet?: Observable<any>;
    @ViewChild("groupHost", { read: ViewContainerRef, static: true })
    groupHost: ViewContainerRef;
    isInnerGroup = false;

    fromTypeChangeClick: boolean = false;

    private _groupIsValid: boolean = true;
    private _latestFieldStates: FieldState[] = [];
    private _fromClick: boolean = false;
    collapsedMessageDisplay: messageDisplay[] = [];
    expanded = true;
    partyTypeSelected: string = null;

    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        @Inject(COMPONENT_FROM_FIELD)
        private _getComponentFromField: (
            field: FieldState
        ) => Type<InputComponent>,
        protected _facade: DynamicFormStore,
        private cd: ChangeDetectorRef
    ) {
        super(_facade);
    }

    ngOnInit() {
        super.ngOnInit();

        // only trigger on load, on expand/collapse, and on partytype switch?
        this._facade
            .getVisibleFieldsForField(this.field.path)
            .pipe(
                scan(
                    (previous, curr) => [
                        previous[1].map((field) => field.path),
                        curr
                    ],
                    [[], []]
                ),
                takeUntil(this._destroy$)
            )
            .subscribe(([previous, current]) => {
                this._setDisplayMessage(current);
                if (this.expanded || this._fromClick) {
                    this.loadComponents(
                        !this.expanded && this._fromClick ? [] : current,
                        this.expanded && this._fromClick ? [] : previous
                    );
                    this._fromClick = false;
                }
            });

        this.onPartyTypeChanged
            .pipe(takeUntil(this._destroy$))
            .subscribe((typeChange) => {
                if (typeChange === "Individual") {
                    this.fromTypeChangeClick = true;
                    setTimeout(() => {
                        this._setDisplayMessage();
                    });
                }
            });

        this.onExpandableSet
            .pipe(takeUntil(this._destroy$))
            .subscribe((set) => {
                setTimeout(() => {
                    this._checkValidity();
                });
            });

        this._checkValidity();
    }

    private _checkValidity() {
        this._facade
            .getViewStateForField(this.field.path)
            .pipe(take(1))
            .subscribe((viewState: FieldViewState) => {
                if (viewState) {
                    this._setDisplayMessage();
                    this.cd.detach();
                    this.expanded = viewState.expanded;
                    this._areAllFieldsValid(viewState as GroupFieldViewState);
                }
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
    }

    loadComponents(controls: FieldState[], previous: string[]) {
        let nextIndex = 0;
        let currentFields = [];
        let fieldsToAdd: { field: FieldState; index: number }[] = [];
        if (
            controls.length > 0 ||
            !this._latestFieldStates ||
            this._latestFieldStates.length === 0
        ) {
            this._latestFieldStates = controls;
        }

        for (let field of controls) {
            currentFields.push(field.path);
            if (!previous.includes(field.path)) {
                fieldsToAdd.push({ field: field, index: nextIndex });
            }
            nextIndex++;
        }

        for (let visibleControl of [...previous]) {
            if (!currentFields.includes(visibleControl)) {
                let index = previous.indexOf(visibleControl);
                try {
                    this.groupHost.remove(index);
                } catch (e) {} // sometimes during a copy, a field at a given index willhave already been removed
                previous.splice(index, 1);
            }
        }

        for (let i = 0; i < fieldsToAdd.length; ++i) {
            this._createNewComponent(
                fieldsToAdd[i].field,
                fieldsToAdd[i].index
            );
        }
    }

    toggleExpanded() {
        this._fromClick = true;
        this.expanded = !this.expanded;
        this._facade.setExpanded(this.field.fullPath, this.expanded);
    }

    forceCollapse() {
        this._facade.setExpanded(this.field.fullPath, false);
        this.expanded = false;
    }

    forceExpand() {
        this._facade.setExpanded(this.field.fullPath, true);
        this.expanded = true;
    }

    private _setDisplayMessage(currentFieldStates?: FieldState[]) {
        let fieldStatesToUse = currentFieldStates
            ? currentFieldStates
            : this._latestFieldStates;
        this._formFacade
            .getFormControl(this.field.fullPath)
            .pipe(take(1))
            .subscribe((formGroup: any) => {
                if (typeof this.field.collapsedMessage === "function") {
                    if (fieldStatesToUse.length > 0) {
                        this.partyTypeSelected = "";
                        for (let fieldState of fieldStatesToUse) {
                            if (fieldState.type === FieldType.PARTYTYPE) {
                                this.partyTypeSelected =
                                    formGroup.controls[fieldState.path].value;
                            }
                        }
                        let collapsedMessage: string | string[] =
                            this.field.collapsedMessage(
                                formGroup,
                                fieldStatesToUse
                            );

                        this.collapsedMessageDisplay =
                            this._convertMessages(collapsedMessage);
                    }
                } else {
                    this.collapsedMessageDisplay = this._convertMessages(
                        this.field.collapsedMessage
                    );
                }
            });
    }

    private _areAllFieldsValid(groupViewState: GroupFieldViewState) {
        this._formFacade
            .getFormControl(this.field.fullPath)
            .pipe(take(1))
            .subscribe((formGroup: UntypedFormGroup) => {
                let valid: boolean = true;

                for (let subControlKey in formGroup.controls) {
                    valid =
                        valid &&
                        (formGroup.controls[subControlKey].status !==
                            "INVALID" ||
                            !groupViewState.visibleFieldsMap[
                                this.field.fullPath + "." + subControlKey
                            ]);
                }
                this._groupIsValid = valid;

                if (
                    this.field.expandable &&
                    this._groupIsValid &&
                    !this.fromTypeChangeClick &&
                    this.expanded
                ) {
                    // the first time this component is loaded, it needs to be expanded so the
                    // collapsed message text can be generated. HERE the component is then re-collapsed
                    this.forceCollapse();
                } else if (
                    this.field.expandable &&
                    !this._groupIsValid &&
                    !this.expanded
                ) {
                    this.forceExpand();
                }

                this.cd.reattach();
                this.cd.markForCheck();
            });
    }

    private _convertMessages(
        collapsedMessage: string | string[]
    ): messageDisplay[] {
        let collapsedMessageDisplay: messageDisplay[];
        if (Array.isArray(collapsedMessage)) {
            collapsedMessageDisplay = collapsedMessage.map(
                (message: string) => {
                    let hasNewLine: boolean = message.indexOf("\\n") > -1;
                    if (hasNewLine) {
                        message = message.split("\\n")[1];
                    }
                    return {
                        display: message,
                        hasNewLine: hasNewLine
                    };
                }
            );
        } else {
            collapsedMessageDisplay = [
                {
                    display: collapsedMessage,
                    hasNewLine: false
                }
            ];
        }

        return collapsedMessageDisplay;
    }

    private _createNewComponent(field: FieldState, index: number) {
        const componentType = this._getComponentFromField(field);
        const componentFactory =
            this._componentFactoryResolver.resolveComponentFactory(
                componentType
            );

        const component = this.groupHost.createComponent<InputComponent>(
            componentFactory,
            index
        );
        const instance = component.instance;

        instance.field = field;
        instance.controlPath = this.controlPath + "." + field.path;

        if (instance instanceof CustomGroupFieldComponent) {
            instance.isInnerGroup = true;
        }
        if (instance instanceof CustomButtonFieldComponent) {
            instance.docLeftData = this.docLeftData;
            instance.onSave = this.onSave;
        }
        if (instance instanceof CustomPartyTypeFieldComponent) {
            instance.onSelection = this.onPartyTypeChanged;
        }
        if (instance instanceof CustomPartyNameFieldComponent) {
            instance.autoCompleteData = this.autoCompleteData;
            instance.onPartySelection = this.onPartySelection;
        }
    }
}
