<div class="array-field-body" *ngIf="field.label || field.expandable">
    <div class="d-flex flex-row justify-content-between">
        <div class="group-header" class="expand-toggle">
            <h4 (click)="toggleExpanded()" class="d-flex flex-row">
                <span class="fieldName">{{field.label}}</span>
                <span *ngIf="!expanded" class="ms-1">({{previousLength}}) </span>
                <fa-icon *ngIf="field.expandable" class="ms-1"
                         [icon]="!expanded ? ['fas', 'caret-right'] : ['fas', 'caret-down']">
                </fa-icon>
            </h4>
        </div>
        <div ngbDropdown container="body" class="d-flex flex-row-reverse" *ngIf="showOptions">
        <span>
            <button
                    class="btn btn-primary py-0 px-1 ms-auto d-inline-block "
                    ngbDropdownToggle
                    container="body"
                    placement="left"
                    type="button"
                    ngbTooltip="Auto-Complete Options"
                    aria-label="Auto-Complete Options"
            >
                <fa-icon
                        [icon]="['far', 'ellipsis-h']"
                ></fa-icon>
            </button>
            <div>
            <ul ngbDropdownMenu>
                <li ngbDropdownItem *ngFor="let document of otherDocuments">
                    <a href="javascript:void(0)"
                       (click)="copyFieldsFromOtherDoc(document.id)">
                        Copy {{field.label}} from {{document.name}}
                    </a>
                </li>
                <hr *ngIf="otherDocuments && otherDocuments.length && hasPartyName" />
                <li ngbDropdownItem *ngIf="hasPartyName">
                    <a href="javascript:void(0)"
                       (click)="commonPartyDialog()">
                        Edit Common Party Names
                    </a>
                </li>
            </ul>
            </div>
        </span>
        </div>
    </div>
    <div class="form-array-fields" [ngClass]="{'last-group-is-pristine':lastGroupIsPristine}">
        <ng-container #arrayHost></ng-container>
        <div class="actions" [ngClass]="{'hidden':maxReached || !expanded}">
            <br *ngIf="(disabled$ | async) || !this.allowAdd" />
            <br *ngIf="(disabled$ | async) || !this.allowAdd" />
            <button
                    class="btn btn-primary"
                    (click)="addItemClick()"
                    aria-label="Add item"
                    [ngClass]="{'disabled':lastIsBlank}"
                    *ngIf="!(disabled$ | async) && this.allowAdd">
                <fa-icon [icon]="['far', 'plus-circle']"></fa-icon>
            </button>
        </div>
        <div [ngClass]="{'hidden':!maxReached}">
            <br/>
            <br/>
            You may only have {{field.maxLength}} entries for "{{field.label}}".
        </div>
    </div>

</div>
