<div class="security-questions">
    <div class="content">
        <div class="center-text logo-container">
            <img class="sf-logo-large" src="/sf/ui/images/sf-logo.png" alt="">
        </div>
        <form name="executeForm">
            <fieldset *ngIf="showSecuritySection && allQuestionList && allQuestionList.length" >
                <div id="security-section">
                    <div>
                        <div class="explain">
                            <span>
                                Please select some security questions and your answers to them.
                                These are required in case you ever forget your password.
                                Each question may only be selected once.
                            </span>
                        </div>
                    </div>
                </div>
                <div class="input-error row" [ngClass]="{'on': warning}">
                    {{warning}}
                </div>
                <table>
                    <thead>
                    <tr>
                        <td class="question">
                            <span>Security Question</span>
                        </td>
                        <td class="answer">
                            <span>Answer</span>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let number of numQuestions; let i = index">
                        <td class="question">
                            <sf-select #securityQuestion class="" [name]="'question' + i"
                                    placeholder="-- Select a question --"
                                    [isSingleSelect]="true" [options]="questionListEach[i]" trackBy="id" labelBy="label"
                                    (select)="securityQuestionSelect($event, i)"></sf-select>
                        </td>
                        <td class="answer">
                            <input #securityAnswer type="password" [name]="'answer' + i" class="answer form-control"
                                    size="20" maxlength="20" autocomplete="off"
                                    aria-label="Security Answer"
                                    (focus)="onSecurityFocus($event)" (blur)="onSecurityBlur($event)"
                                    (keyup)="checkSecurityKeyInput($event)"/>
                        </td>
                    </tr>
                    <tr>
                        <td class="question">&nbsp;</td>
                        <td class="answer">
                            <label>
                                <input type="checkbox" [(ngModel)]="showSecurityAnswers" name="showSecurityAnswers"
                                        (change)="toggleSecurityAnswers()"/>
                                <span> Show Answers</span>
                            </label>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="separator">
                    <hr/>
                </div>
            </fieldset>

            <fieldset *ngIf="showSupportSection && supportList && supportList.length">
                <div>
                    <div class="explain">
                        <span>Please select one question and answer that we will ask you whenever you call our support center.
                            This is to help ensure that someone doesn't try to impersonate you.</span>
                    </div>
                </div>
                <table>
                    <thead>
                    <tr>
                        <td class="question">
                            <span>Phone Support Question</span>
                        </td>
                        <td class="answer">
                            <span>Answer</span>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="question">
                            <sf-select #supportQuestion name="supportQuestion" id="supportQuestion" placeholder="-- Select a question --"
                                       [isSingleSelect]="true" [options]="supportList" trackBy="id" labelBy="label"
                                       (select)="selectSupportQuestion($event)">
                            </sf-select>
                        </td>
                        <td class="answer">
                            <input #supportAnswer type="password" name="supportAnswer" id="supportAnswer"
                                   class="answer form-control" size="20" maxlength="20" autocomplete="off"
                                    aria-label="Answer to support question"
                                   (focus)="onSupportFocus($event)" (blur)="onSupportBlur($event)"
                                   (keyup)="checkSupportKeyInput($event)"/>
                        </td>
                    </tr>
                    <tr>
                        <td class="question">&nbsp;</td>
                        <td class="answer">
                            <label>
                                <input type="checkbox" [(ngModel)]="showSupportAnswer" name="showSupportAnswer"
                                        (change)="toggleSupportAnswer()"/>
                                <span> Show Answer</span>
                            </label>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="separator">
                    <hr/>
                </div>
            </fieldset>
            <fieldset id="key-section">
                <table>
                    <tbody>
                    <tr>
                        <td class="password-cell">
                            <span>For security, enter your current password:</span>
                        </td>
                        <td class="answer">
                            <input type="password" [(ngModel)]="userPassword" name="key" id="key"
                                    aria-label="Enter your current password"
                                    class="answer form-control" size="20" maxlength="32" autocomplete="off"
                                    (keyup)="checkKeyInput($event)"/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </fieldset>
            <div class="mt-5">
                <button class="btn btn-primary col-md-4 large me-2" (click)="save()">Submit</button>
                <button *ngIf="canSkip" class="btn btn-secondary col-md-4 large" (click)="doLater()">Do this later</button>
            </div>
        </form>
    </div>
</div>
