<div class="search-wrapper" cdkOverlayOrigin #wrapper="cdkOverlayOrigin">
    <form role="search" [attr.aria-label]="ariaLabel">
        <input type="text"
               role="combobox"
               name="search"
               class="form-control"
               #searchInput
               aria-autocomplete="list"
               (click)="onSearchClick($event)"
               (blur)="handleBlur($event)"
               aria-controls="results-listbox"
               [attr.aria-expanded]="isOpen"
               [attr.aria-activedescendant]="active"
               [attr.aria-busy]="busy"
               [(ngModel)]="query"
               (ngModelChange)="updateQuery($event)"
               [ngModelOptions]="{updateOn: 'change'}"
               [placeholder]="placeholder"
               (keydown.escape)="handleEscape()"
               (keydown.enter)="handleEnter($event)"
               (keydown.arrowDown)="handleArrowDown()"
               (keydown.alt.arrowDown)="handleAltArrowDown()"
               (keydown.arrowUp)="handleArrowUp()">
    </form>
    <fa-icon *ngIf="!query" [icon]="['far', 'search']" [fixedWidth]="true"  class="magnifying-glass"></fa-icon>
    <button (click)="reset()" *ngIf="query" class="btn" aria-label="clear">
        <fa-icon [icon]="['far', 'times']" [fixedWidth]="true" ></fa-icon>
    </button>
</div>
<div class="listbox-wrapper" *ngIf="isOpen" id="results-listbox" [ngClass]="{'busy': busy}" sfClickOff (onClickOff)="handleClickOff()">
    <div role="complementary" (keydown.escape)="exit($event)" (keydown.arrowLeft)="exit($event)" aria-label="Results">
        <ng-container #panelContent></ng-container>
    </div>
    <div class="spinner-overlay">
        <fa-icon [icon]="['fad', 'spinner']" [spin]="true"></fa-icon>
    </div>
</div>

<ng-template #groupSection
             let-groupId="id"
             let-title="title"
             let-icon="icon"
             let-totalResults="totalResults"
             let-selectable="selectable"
             let-results="results"
             let-expandOverride="expandOverride"
             let-optionTemplate="optionTemplate">
    <section>
        <header id="group-{{groupId}}"
                [tabindex]="selectable ? 0 : -1"
                (keydown.enter)="handleEnterOnHeader($event, groupId)"
                (click)="selectGroup($event, groupId)"
                [attr.role]="selectable && 'button'">
            <div>
                <fa-icon *ngIf="icon" [icon]="icon" class="me-2"></fa-icon>
                <span>{{ title }}</span>
            </div>
            <span class="badge badge-default">{{ totalResults }}</span>
        </header>
        <ul data-testid="listbox" [attr.aria-label]="title" (keydown.escape)="exit($event)">
            <li *ngFor="let option of results"
                data-testid="option"
                [tabindex]="allowTabSelection ? 0 : -1"
                [id]="option.id"
                [attr.aria-selected]="active === option.id ? true : null"
                [ngClass]="{active: active === option.id}"
                (keydown.enter)="handleEnterOnOption($event, option.id)"
                (click)="selectOption($event, option.id)">
                <ng-container *ngTemplateOutlet="optionTemplate; context: {$implicit: option}"></ng-container>
            </li>
            <li *ngIf="(results.length < totalResults && showExpandMore) || expandOverride"
                data-testid="option"
                [tabindex]="allowTabSelection ? 0 : -1"
                id="group-expand-{{groupId}}"
                class="group-expand"
                [attr.aria-selected]="active === 'group-expand-' + groupId ? true : null"
                [ngClass]="{active: active === 'group-expand-' + groupId}"
                (keydown.enter)="handleEnterOnExpand($event, groupId)"
                (click)="getMoreResults($event, groupId)">
                Expand results...
            </li>
        </ul>
    </section>
</ng-template>

<ng-template>
    <ng-content select="[sfGlobalSearchResult]"></ng-content>
</ng-template>

<ng-template #noResults>
    <li>No results</li>
</ng-template>
