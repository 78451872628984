import { Directive, Input } from "@angular/core";
import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors
} from "@angular/forms";
import { Observable } from "rxjs";

import { map, mapTo, shareReplay, startWith, switchMap } from "rxjs/operators";
import { DynamicFormStore } from "../../services/dynamic-form-store";
import { FieldState } from "../../interfaces/dynamic-form-state";

@Directive()
export abstract class InputComponent {
    @Input() field: FieldState;
    @Input() controlPath: string;
    @Input() useColumnLayout: boolean;

    constructor(protected _formFacade: DynamicFormStore) {}

    formControl$: Observable<UntypedFormControl>;
    controlParent$: Observable<UntypedFormGroup>;
    formErrors$: Observable<ValidationErrors>;
    isRequired$: Observable<boolean>;
    columnLayout: any = {};

    ngOnInit() {
        this.columnLayout = this.useColumnLayout
            ? { "flex-direction": "column", display: "block" }
            : {};
        this.formControl$ = this._formFacade
            .getFormControl(this.controlPath)
            .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
        this.controlParent$ = this.formControl$.pipe(
            map((control) => control.parent as UntypedFormGroup)
        );

        this.formErrors$ = this.formControl$.pipe(
            switchMap((control) => {
                return control.statusChanges.pipe(
                    startWith(control),
                    mapTo(control)
                );
            }),
            map((control) => {
                return control.errors;
            })
        );

        this.isRequired$ = this.formControl$.pipe(
            map((control) => {
                const validator = control.validator?.({} as AbstractControl);
                return validator?.required;
            })
        );
    }
}
