import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { LoginService } from "../../services/login.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserLockedDialogComponent } from "../../modals/user-locked-dialog/user-locked-dialog.component";
import {
    APP_BOOTSTRAP_SERVICE,
    AppBootstrapService,
    Auth,
    QueryStringService,
    SessionService,
    SocketService
} from "@sf/common";
import { of, Subject } from "rxjs";
import { catchError, mapTo, switchMap, takeUntil } from "rxjs/operators";

declare const window: any;

// prettier-ignore
@Component({
    selector: "sf-login",
    templateUrl: "./sc-notary-onboard.component.html",
    styleUrls: ["./sc-notary-onboard.component.scss"]
})
export class ScNotaryOnboardComponent implements OnInit, OnDestroy {
    formErrorMessages: string[] = [];
    notaryForm: UntypedFormGroup;
    showLogin: boolean = false;
    showNoAccount: boolean = false;
    vToken: string;

    _$onDestroy$ = new Subject<void>();

    constructor(
            private formBuilder: UntypedFormBuilder,
            private router: Router,
            private _route: ActivatedRoute,
            private modalService: NgbModal,
            private loginService: LoginService,
            private queryStringService: QueryStringService,
            @Inject(APP_BOOTSTRAP_SERVICE)
            private _appBootstrap: AppBootstrapService,
            private _socketService: SocketService,
            private sessionService: SessionService
    ) {
        this.notaryForm = formBuilder.group({
            username: ["", [Validators.required]],
            password: ["", [Validators.required]]
        });
    }

    ngOnInit() {
        this._route.queryParams
                .subscribe((params) => {
                    this.vToken = params.vToken;
                });
    }

    ngOnDestroy() {
        this._$onDestroy$.next();
        this._$onDestroy$.complete();
    }


    existingAccount() {
        this.showLogin = true;
    }

    newAccount() {
        this.showLogin = false;
        this.showNoAccount = true;
    }

    authenticate() {
        this.formErrorMessages = [];
        let username: string = this.notaryForm.controls.username.value;
        let password: string = this.notaryForm.controls.password.value;

        this.loginService
                .login(username, password, null, null, null)
                .subscribe(
                        (results: any) => {
                            if (results && results.status == 'success') {
                                this.goToNextPage("/sf/ui/settings/sc-notary-onboard-info?vToken=" + this.vToken);
                            } else {
                                this.formErrorMessages.push("Authentication failed.");
                            }
                        },
                        (errorResult: any) => {
                            this.notaryForm.patchValue({
                                password: ""
                            });
                            let message = "Authentication Failed";
                            if (errorResult && errorResult.error && errorResult.error.errorMessage) {
                                message = errorResult.error.errorMessage;
                            }
                            if (message && (message.startsWith("Too many failed") || message.startsWith("Too many login"))) {
//                                this.userLockedOut();
                            } else {
                                this.formErrorMessages.push(message);
                            }
                        }
                );

    }

    goToNextPage(nextPage: string) {
        if (
                nextPage.startsWith("/sf/ui/login/") &&
                !nextPage.endsWith("security-questions")
        ) {
            // user is not logged in, so just go
            let nextPageInternal = nextPage.replace("/sf/ui", "");
            this.router.navigateByUrl(nextPageInternal);
        } else if (nextPage.startsWith("/sf/ui")) {
            // user is logged in, so launch the socket
            this._socketService.authMessages
                    .pipe(
                        switchMap((auth: Auth)=> {
                            if (auth.data.authenticated) {
                                return this._appBootstrap.bootstrapAfterLogin(auth).pipe(mapTo(auth));
                            }
                            else {
                                of(auth);
                            }
                        }),
                        takeUntil(this._$onDestroy$)
                    )
                    .subscribe(
                            (auth: Auth) => {
                                // don't do screwy observable filter stuff here or you can get in an infinite wait
                                if (auth.data.authenticated) {
                                    nextPage = this.checkPageType(nextPage);
                                    let nextPageInternal = nextPage.replace(
                                            "/sf/ui",
                                            ""
                                    );
                                    this.router.navigateByUrl(nextPageInternal);
                                } else {
                                    // we've had cases where the socket authentication failed
                                    this.formErrorMessages.push(
                                            "Error in network connection. Please try again."
                                    );
                                }
                            },
                            (error: any) => {
                                this.formErrorMessages.push(
                                        "Network connection error. Please try again. " +
                                        error
                                );
                            }
                    );
            this._socketService.reconnect();
        } else {
            // not a normal case
            window.location.assign(nextPage);
        }
    }

    userLockedOut() {
        const modalRef = this.modalService.open(UserLockedDialogComponent);
        modalRef.result.then(
                () => {
                    // nothing
                },
                () => {
                    // nothing
                }
        );
    }

    // make sure super-user pages for super-users
    // This is just a convenience to catch cases when a super-user impersonates a user and then the session times out.
    checkPageType(nextPage: string): string {
        if (this.sessionService.isSuperUser()) {
            if (!nextPage.startsWith("/sf/ui/admin")) {
                // super-user can't start out in non-admin page
                return this.sessionService.getHomePageUrl();
            }
        } else {
            if (nextPage.startsWith("/sf/ui/admin")) {
                // normal user can't start out in admin page
                return this.sessionService.getHomePageUrl();
            }
        }
        return nextPage;
    }
}
