import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import {
    EncryptionService,
    GrowlService,
    HotKeysService,
    SessionService,
    SpinnerService
} from "@sf/common";
import { RecentUsersService, User, UserService } from "@sf/userorg/common";
import {
    AdminLoginAsService,
    AdminOrganizationService,
    AdminUserorgService,
    RecentOrganizationsService
} from "@sf/userorg/admin-common";
import { SubmitterPackagesService } from "@sf/submitter/common";
import { catchError, debounceTime, takeUntil } from "rxjs/operators";
import { of, Subject } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";

// prettier-ignore
interface UserEx extends User {
    encryptedID: string;
}

@Component({
    selector: "sf-support-banner",
    templateUrl: "./support-banner.component.html",
    styleUrls: [
        "./support-banner.component.scss",
        "./support-banner-universal-search.scss"
    ]
})
export class SupportBannerComponent implements OnInit, OnDestroy {
    private _onDestroy = new Subject<void>();

    availableActions: any[] = [];
    isSuperUser = false;
    rootUser: string;
    loggedInUser: string;
    userSearch: string = null;
    showPageActionsMenu = false;
    showUserSearchMenu = false;
    foundUsers: UserEx[] = [];
    selectedUser: UserEx = null;
    searchUsersDebouncedSubject: Subject<string> = new Subject<string>();

    _togglingUserSearchMenu = false;
    _togglingActionMenu = false;

    constructor(
        private injector: Injector,
        private sessionService: SessionService,
        private encryptionService: EncryptionService,
        private userService: UserService,
        private adminUserorgService: AdminUserorgService,
        private adminLoginAsService: AdminLoginAsService,
        private hotKeysService: HotKeysService,
        private router: Router,
        private recentUsersService: RecentUsersService,
        private organizationService: AdminOrganizationService,
        private recentOrganizationsService: RecentOrganizationsService,
        private submitterPackagesService: SubmitterPackagesService,
        private growlService: GrowlService,
        private spinnerService: SpinnerService
    ) {
        this.router.events.subscribe((val: NavigationEnd) => {
            if (val instanceof NavigationEnd) {
                this.updateEllipsisMenu(val.url);
            }
        });
    }

    ngOnInit() {
        this.rootUser = this.sessionService.getRootUser();
        this.loggedInUser = this.sessionService.getUsername();
        this.isSuperUser = this.sessionService.isSuperUser();

        this._initHotkeys();
        this.updateEllipsisMenu(this.router.url);
        this.searchUsersDebouncedSubject
            .pipe(takeUntil(this._onDestroy), debounceTime(250))
            .subscribe(this.searchUsers.bind(this));
    }

    ngOnDestroy() {
        this._onDestroy.next();
    }

    loginAs(user: UserEx) {
        // if you are impersonating, and switching users, then don't save the current page as a super-user page
        this.adminUserorgService.loginAs(user.username, !this.isSuperUser);
    }

    switchBack() {
        if (this.isSuperUser) {
            this.goToAdminHomePage();
        } else {
            this.goToLastAdminPage();
        }
    }

    enterKeyInput() {
        // log in as selected user
        this.loginAs(this.selectedUser);
    }

    arrowDownInput() {
        if (this.selectedUser) {
            this.selectUser(this.selectedUser);
        }
    }

    searchUsers(query: string) {
        let oldUsers = this.foundUsers;
        this.foundUsers = null; // starts spinner
        if (!query) {
            this.recentUsersService
                .getPastRecentUsers()
                .subscribe((users: User[]) => {
                    this.foundUsers = this.removeSuperUsers(users);
                    this.selectedUser = this.foundUsers[0];
                });
            return;
        }
        this.userService
            .search(query, 30)
            .pipe(
                catchError((err) => {
                    // do nothing - might be an error caused by search interrupted
                    // InterruptedException for example
                    this.foundUsers = oldUsers; // to stop spinner
                    return of(null);
                })
            )
            .subscribe(
                (users: User[]) => {
                    this.foundUsers = this.removeSuperUsers(users);
                    this.selectedUser = this.foundUsers[0];
                },
                (err) => {
                    // second do nothing - might be an error caused by search interrupted
                    // InterruptedException for example
                    this.foundUsers = oldUsers; // to stop spinner
                }
            );
    }

    removeSuperUsers(userList: User[]): UserEx[] {
        let response: UserEx[] = [];
        if (userList) {
            userList.forEach((user: UserEx) => {
                let foundSimplifileProduct = false;
                if (user.products) {
                    foundSimplifileProduct = user.products.find(
                        (product: any) => {
                            return product.id == "admin";
                        }
                    );
                }
                if (!foundSimplifileProduct) {
                    user.encryptedID = this.encryptionService.scrambleEncode(
                        user.id
                    );
                    response.push(user);
                }
            });
        }
        return response;
    }

    focusElement(elementID: string, delay?: number) {
        window.setTimeout(() => {
            let theField = document.getElementById(elementID);
            theField.focus();
        }, 200);
    }

    onUserFocus(user: UserEx) {
        this.selectedUser = user;
    }

    arrowDown(user: UserEx) {
        let nextUser = this.getNextUser(user);
        if (nextUser) {
            this.selectUser(nextUser);
        }
    }

    arrowUp(user: UserEx) {
        let prevUser = this.getPreviousUser(user);
        if (prevUser) {
            this.selectUser(prevUser);
        }
    }

    getNextUser(user: UserEx): UserEx {
        if (!this.foundUsers) {
            return null;
        }
        for (let i = 0; i < this.foundUsers.length - 1; i++) {
            if (this.foundUsers[i].id == user.id) return this.foundUsers[i + 1];
        }
        return null;
    }

    getPreviousUser(user: UserEx): UserEx {
        if (!this.foundUsers) {
            return null;
        }
        for (let i = this.foundUsers.length - 1; i > 0; i--) {
            if (this.foundUsers[i].id == user.id) return this.foundUsers[i - 1];
        }
        return null;
    }

    selectUser(user: UserEx) {
        if (user) {
            this.focusElement("luser" + user.encryptedID, 25);
        }
    }

    toggleUserSearchMenu() {
        this._togglingUserSearchMenu = true;
        setTimeout(() => {
            this.showUserSearchMenu = !this.showUserSearchMenu;
            this.clearSearch();
            if (this.showUserSearchMenu) {
                this.searchUsers(this.userSearch);
                setTimeout(() => {
                    this.focusElement("supportSearchInput");
                    this._togglingUserSearchMenu = false;
                }, 100);
            } else {
                this._togglingUserSearchMenu = false;
            }
        }, 50);
    }

    toggleActionMenu() {
        this._togglingActionMenu = true;
        setTimeout(() => {
            this.showPageActionsMenu = !this.showPageActionsMenu;
            this.clearSearch();
            this._togglingActionMenu = false;
        }, 50);
    }

    updateEllipsisMenu(url: string) {
        this.availableActions = [];

        if (
            url.substring(0, 19) == "/submitter/package/" ||
            url.substring(0, 15) == "/submitter/package/"
        ) {
            this.availableActions = [
                {
                    label: "Jump to Package Admin Tools",
                    action: this._goToPackageAdminTools.bind(this)
                },
                {
                    label: "View package as user",
                    action: this._viewPackageAsUser.bind(this)
                }
            ];
        } else if (url.substring(0, 21) == "/admin/package-admin/") {
            this.availableActions = [
                {
                    label: "View package as user",
                    action: this._viewPackageAsUser.bind(this)
                }
            ];
        } else if (
            (url.substring(0, 26) == "/signing/data-entry/esign/" &&
                url.substr(-9) == "/document") ||
            (url.substring(0, 20) == "/signing/view/esign/" &&
                url.substr(-9) == "/document") ||
            (url.substring(0, 20) == "/signing/sign/esign/" &&
                url.substr(-9) == "/document") ||
            (url.substring(0, 22) == "/signing/review/esign/" &&
                url.substr(-9) == "/document") ||
            (url.substring(0, 26) == "/signing/print_only/esign/" &&
                url.substr(-9) == "/document") ||
            (url.substring(0, 25) == "/signing/needs_poa/esign/" &&
                url.substr(-9) == "/document")
        ) {
            this.availableActions = [
                {
                    label: "Jump to Admin Tools",
                    action: this._goToPackageAdminToolsFromESignDocument.bind(
                        this
                    )
                }
            ];
        } else if (url.substring(0, 20) == "/admin/organization/") {
            this.availableActions = [];
            this.adminUserorgService
                .getSalesforceAccessURLByType(url, "account")
                .subscribe((accessURL: string) => {
                    this.availableActions.push({
                        label: "View Account in Salesforce",
                        action: this._goToSalesforceURL.bind(this, accessURL),
                        icon: ["fab", "salesforce"]
                    });
                    //check for opportunity
                    if (
                        url.endsWith("license") ||
                        url.endsWith("license-paper")
                    ) {
                        this.adminUserorgService
                            .getSalesforceAccessURLByType(url, "opportunity")
                            .subscribe((accessURL: string) => {
                                this.availableActions.push({
                                    label: "View Opportunity in Salesforce",
                                    action: this._goToSalesforceURL.bind(
                                        this,
                                        accessURL
                                    ),
                                    icon: ["fab", "salesforce"]
                                });
                            });
                    }
                });
        } else if (url.substring(0, 12) == "/admin/user/") {
            this.availableActions = [];
            this.availableActions.push({
                label: "View Contact in Salesforce",
                action: this._getUserContactFromSalesforce.bind(this, url),
                icon: ["fab", "salesforce"]
            });
        }
    }

    clearSearch() {
        this.userSearch = null;
    }

    _initHotkeys() {
        this.hotKeysService
            .addShortcut({
                combo: "ctrl+b",
                description: "Go back"
            })
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => this.switchBack());
        this.hotKeysService
            .addShortcut({
                combo: "ctrl+.",
                description: "Home"
            })
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => this.goToAdminHomePage());
        this.hotKeysService
            .addShortcut({
                combo: "ctrl+s",
                description: "Search"
            })
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                if (this.isSuperUser) {
                    this.focusElement("supportBarUniversalSearch");
                }
            });
    }

    clickAwayActionsMenu() {
        if (this._togglingActionMenu) {
            return;
        }
        setTimeout(() => {
            this.showPageActionsMenu = false;
        });
    }

    clickAwayUserSearchMenu() {
        if (this._togglingUserSearchMenu) {
            return;
        }
        setTimeout(() => {
            this.showUserSearchMenu = false;
        });
    }

    goToAdminHomePage() {
        // call this so that the super user is logged back in
        this._goToSuperUserPage("ui/admin/mainmenu");
    }

    // go back to superuser view
    // @param returnUrl is the URL where you want to go after logging back in as the superuser
    _goToSuperUserPage(returnUrl: string) {
        if (!this.sessionService.getRootUser()) {
            window.location.href = "/sf/" + returnUrl;
            return;
        }

        // scramble the username
        let scrambled = this.encryptionService.scrambleEncode(
            this.sessionService.getRootUser()
        );

        // build URL and go
        let url = "/sf/login.jsp?ua=" + scrambled + "&a=true";
        if (returnUrl) {
            returnUrl = encodeURIComponent(returnUrl);
            url += "&r=" + returnUrl;
        }

        window.location.href = url;
    }

    goToLastAdminPage() {
        let lastPage = null;
        let ss = window.sessionStorage;
        if (ss) {
            lastPage = ss.getItem("lastAdminPage");
        }

        if (!lastPage) {
            lastPage = "ui/admin/mainmenu";
        }

        this._goToSuperUserPage(lastPage);
    }

    _viewPackageAsUser() {
        let packageID: string = window.location.pathname.substring(27);
        packageID = packageID.split("/")[0];
        if (!this.isSuperUser) {
            this.recentOrganizationsService
                .getLastRecentOrganizationID()
                .subscribe((lastOrgID: string) => {
                    this.adminLoginAsService.loginToOrganization(
                        lastOrgID,
                        null,
                        packageID
                    );
                });
        } else {
            this.submitterPackagesService
                .getPackage(packageID)
                .subscribe((pkg: any) => {
                    this.adminLoginAsService.loginToOrganization(
                        pkg.submitterID,
                        pkg.submitter,
                        packageID
                    );
                });
        }
    }

    _goToPackageAdminTools() {
        const pathArr = window.location.pathname.split("/");
        let packageID = pathArr[pathArr.length - 2];
        //let sanePackageID = this.sanitizer.bypassSecurityTrustHtml(packageID as string);
        //let packageIDString = sanePackageID as string;
        let url = "ui/admin/package-admin/" + packageID;
        this._goToSuperUserPage(url);
    }

    _goToPackageAdminToolsFromESignDocument() {
        let documentID = this.router.url.substr(-34).split("/")[0];
        this.submitterPackagesService
            .getEsignDocument(documentID)
            .subscribe((document: any) => {
                if (document.templateID) {
                    this._goToSuperUserPage(
                        `ui/admin/esign/templates/${document.templateID}/content`
                    );
                }
                if (document.analysisMappingId) {
                    this._goToSuperUserPage(
                        `ui/admin/esign/analysis/${document.analysisMappingId}`
                    );
                }
            });
    }

    private _getUserContactFromSalesforce(url: string) {
        this.spinnerService.startSpinner();
        this.adminUserorgService
            .getSalesforceAccessURLByType(url, "user")
            .subscribe(
                (accessURL: string) => {
                    this.spinnerService.stopSpinner();
                    this._goToSalesforceURL(accessURL);
                },
                () => {
                    this.spinnerService.stopSpinner();
                }
            );
    }

    private _goToSalesforceURL(url: string) {
        this.toggleActionMenu();
        //if valid url open a new window, otherwise show a growl
        if (url.startsWith("http")) {
            window.open(url);
        } else {
            this.growlService.error(url, null, {
                timeOut: 2500,
                extendedTimeOut: 2500
            });
        }
    }
}
