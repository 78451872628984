<div
    class="mb-3 row party_type"
    *ngIf="formControl$ | async as control"
    [formGroup]="controlParent$ | async">
        <label class="col">{{field.label}}</label>
        <div class="col-md" [ngbTooltip]="field.toolTip" [ngClass]="{'value-wrong':control.invalid}" placement="top-left">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
            <div *ngFor="let option of (options$ | async)" class="row">
                <div class="col-md-1">
                    <input [id]="field.fullPath + '_' + option.option"
                           type="radio"
                           [name]="field.fullPath + '_' + control"
                           [formControl]="control"
                           [value]="option.option"
                           (click)="selectionClicked(option.option)"
                    />
                </div>
                <label class="radio-label col-md" [for]="field.fullPath + '_' + option.option">{{option.label}}</label>
            </div>
        </div>
</div>
