import { Component, Input, OnInit } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { isEmpty, isEqualStrings, ModalButton, SfValidators } from "@sf/common";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    Address,
    Name,
    Organization,
    Phone,
    UserOrgService,
    UserService
} from "@sf/userorg/common";
import { first } from "rxjs/operators";

export interface UserEditData {
    username: string;
    name: Name;
    // prefix?: string;
    // firstName: string;
    // middleName?: string;
    // lastName: string;
    // title: string;
    // initials?: string;
    phone: Phone;
    // extension?: string;
    mobilePhone: Phone;
    email: string;
    unverifiedEmail?: string;
    address?: Address;
    timezone?: string;
    mobilePhoneVerifiedDate?: Date;
}

// prettier-ignore
@Component({
    selector: "sf-user-edit-dialog",
    templateUrl: "./user-edit-dialog.component.html",
    styleUrls: ["./user-edit-dialog.component.scss"]
})
export class UserEditDialogComponent implements OnInit {
    @Input()
    title: string;
    @Input()
    contactData: UserEditData;
    @Input()
    isSuperUser: boolean;
    @Input()
    allowSecondaryTitle: boolean;

    checkUniqueEmail: boolean = true;
    isRecipient: boolean = false;
    editForm: UntypedFormGroup;
    primary: ModalButton;
    secondary: ModalButton;
    warning: string = null;
    userOptions: any[] = [];
    prefixOptions = [
        { option: "Mr", label: "Mr" },
        { option: "Mrs", label: "Mrs" },
        { option: "Miss", label: "Miss" },
        { option: "Ms", label: "Ms" },
        { option: "Mx", label: "Mx" },
        { option: "Dr", label: "Dr" }
    ];
    invalidAddressMessage: string = null;
    verifiedAddress: any = null;
    addressCorrect: boolean = false;
    radioSelection: string = "recommended";
    showCheckbox: boolean = false;
    showRadios: boolean = false;
    mobilePhoneString: string;
    userIsSuper = false;

    private originalEmail: string = null;

    constructor(
            private formBuilder: UntypedFormBuilder,
            private activeModal: NgbActiveModal,
            private modalService: NgbModal,
            private userorgService: UserOrgService,
            private userService: UserService
    ) {
        this.editForm = formBuilder.group({
            prefix: [""],
            firstName: ["", [Validators.required, SfValidators.notAllWhitespace]],
            middleName: [""],
            lastName: ["", [Validators.required, SfValidators.notAllWhitespace]],
            title: ["", [Validators.required, SfValidators.notAllWhitespace]],
            secondaryTitle: [
                "",
                this.allowSecondaryTitle ? [Validators.required, SfValidators.notAllWhitespace] : null
            ],
            initials: [""],
            phone: ["", [SfValidators.phoneValidator]],
            extension: [[Validators.maxLength(9), SfValidators.numericValidator]],
            mobilePhone: ["", [SfValidators.phoneValidator]],
            email: ["", [Validators.required, SfValidators.emailValidator]],
            street1: [""],
            street2: [""],
            city: [""],
            state: [""],
            zipCode: ["", [SfValidators.zipCodeValidator]]
        });
    }

    ngOnInit() {
        // Modal buttons
        this.primary = {
            text: "Save",
            disabled: false,
            callback: this.saveChanges.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.abort.bind(this)
        };

        let phoneString = "";
        if (this.contactData.phone) {
            phoneString = SfValidators.formatPhone(
                    this.contactData.phone.areaCode + this.contactData.phone.prefix + this.contactData.phone.suffix);
        }
        this.mobilePhoneString = "";
        if (this.contactData.mobilePhone) {
            this.mobilePhoneString = SfValidators.formatPhone(
                    this.contactData.mobilePhone.areaCode + this.contactData.mobilePhone.prefix +
                    this.contactData.mobilePhone.suffix);
        }

        let email = this.contactData.email;
        if (this.contactData.unverifiedEmail) {
            email = this.contactData.unverifiedEmail;
        }

        // patch form
        this.editForm.patchValue({
            prefix: this.contactData.name.prefix,
            firstName: this.contactData.name.first,
            middleName: this.contactData.name.middle,
            lastName: this.contactData.name.last,
            title: this.contactData.name.title,
            secondaryTitle: this.contactData.name.secondaryTitle,
            initials: this.contactData.name.initials,
            email: email,
            phone: phoneString,
            mobilePhone: this.mobilePhoneString,
            extension: (this.contactData.phone.extension ? this.contactData.phone.extension : "")
        });

        if (this.contactData.address) {
            this.editForm.patchValue({
                street1: this.contactData.address.streets
                    ? this.contactData.address.streets[0]
                    : "",
                street2: this.contactData.address.streets
                    ? this.contactData.address.streets[1]
                    : "",
                city: this.contactData.address.city,
                state: this.contactData.address.state,
                zipCode: this.contactData.address.zipCode
            });
        }

        this.originalEmail = email;

        this.isRecipient =
            this.contactData.name.initials &&
            this.contactData.name.initials.length > 0;

        this.userService.getUser(this.contactData.username)
            .subscribe((user: any) => {
                let orgs: Organization[] = user.organizations;
                this.userIsSuper = !!orgs?.find((org) => {
                    return (org.id == "SIMPFL");
                });
            });

        this.focusElement("firstName");
    }

    focusElement(elementID: string) {
        window.setTimeout(() => {
            let theField = document.getElementById(elementID);
            theField.focus();
        }, 200);
    }

    buildReturnData(): UserEditData {
        let username = this.contactData.username;
        let initials = this.editForm.controls.initials.value;
        if (initials) {
            initials = initials.trim();
        }
        let phone = this.editForm.controls.phone.value;
        let extension: string = this.editForm.controls.extension.value;
        let mobilePhone = this.editForm.controls.mobilePhone.value;
        let prefix = this.editForm.controls.prefix.value;
        let firstName = this.editForm.controls.firstName.value;
        if (firstName) {
            firstName = firstName.trim();
        }
        let middleName = this.editForm.controls.middleName.value;
        if (middleName) {
            middleName = middleName.trim();
        }
        let lastName = this.editForm.controls.lastName.value;
        if (lastName) {
            lastName = lastName.trim();
        }
        let title = this.editForm.controls.title.value;
        if (title) {
            title = title.trim();
        }
        let secondaryTitle = this.editForm.controls.secondaryTitle.value;
        if (secondaryTitle) {
            secondaryTitle = secondaryTitle.trim();
        }
        let email = this.editForm.controls.email.value;
        let street1 = this.editForm.controls.street1.value;
        let street2 = this.editForm.controls.street2.value;
        let city = this.editForm.controls.city.value;
        let state = this.editForm.controls.state.value;
        let zip = this.editForm.controls.zipCode.value;
        let county = this.contactData.address
            ? this.contactData.address.county
            : "";

        phone = SfValidators.getNumericDigits(phone);
        mobilePhone = SfValidators.getNumericDigits(mobilePhone);

        return {
            username: username,
            name: {
                first: firstName,
                middle: middleName,
                last: lastName,
                prefix: prefix,
                title: title,
                secondaryTitle: secondaryTitle,
                initials: initials
            },
            phone: {
                areaCode: phone.substring(0, 3),
                prefix: phone.substring(3, 6),
                suffix: phone.substring(6, 10),
                extension: extension
            },
            mobilePhone: {
                areaCode: mobilePhone.substring(0, 3),
                prefix: mobilePhone.substring(3, 6),
                suffix: mobilePhone.substring(6, 10)
            },
            email: email,
            address: {
                streets: [street1, street2],
                city: city,
                state: state,
                zipCode: zip,
                county: county
            }
        };
    }

    saveChanges() {
        let contactData: UserEditData = this.buildReturnData();

        //if this message exists, other validation has already happened - don't do it anymore
        if (this.invalidAddressMessage) {
            //user acknowledges as correct, or take as it was entered
            if ((this.showCheckbox && this.addressCorrect) || (this.showRadios && this.radioSelection == "entered")) {
                this.activeModal.close(contactData);
            } else if (this.showRadios && this.radioSelection == "recommended") {
                //update with recommended address
                //copying off this data and sending back to save, but won't update the dialog (causes an error in the console
                //likely due to the modal dialog having been destroyed)
                let addressData = {...contactData};
                addressData.address.streets[0] = this.verifiedAddress.street1;
                if (this.verifiedAddress.secondary) {
                    addressData.address.streets[0] =
                            addressData.address.streets[0] + " " + this.verifiedAddress.secondary;
                }
                addressData.address.streets[1] = this.verifiedAddress.street2;
                addressData.address.city = this.verifiedAddress.city;
                addressData.address.state = this.verifiedAddress.state;
                addressData.address.zipCode = this.verifiedAddress.zip;

                if (this.verifiedAddress.fips) {
                    addressData.address.county = this.verifiedAddress.fips;
                }
                if (this.verifiedAddress.timeZone) {
                    addressData.timezone = this.verifiedAddress.timeZone;
                }
                this.activeModal.close(addressData);
            }
        } else {
            this.warning = this.validate(contactData);
            if (!this.warning) {
                if (this.checkUniqueEmail && contactData.email != this.originalEmail) {
                    this.userorgService.isEmailAddressAvailable(contactData.email, contactData.username)
                        .pipe(first())
                        .subscribe((available: boolean) => {
                            if (available) {
                                //this.activeModal.close(contactData);
                                this.verifyAddress(contactData);
                            } else {
                                this.warning = "Email address '" + contactData.email + "' is already used by another user";
                            }
                        });
                } else {
                    //this.activeModal.close(contactData);
                    this.verifyAddress(contactData);
                }
            }
        }
    }

    verifyAddress(contactData: UserEditData) {
        this.showCheckbox = false;
        this.showRadios = false;
        this.invalidAddressMessage = null;
        this.verifiedAddress = null;

        let address: Address = contactData.address;
        if (address && address.streets && address.streets.length >= 1 && address.city && address.state &&
                address.zipCode) {
            let inputAddress = {
                street: contactData.address.streets[0],
                street2: contactData.address.streets[1],
                city: contactData.address.city,
                state: contactData.address.state, //state abbrev.
                zipCode: contactData.address.zipCode
            };
            this.userorgService.verifyAddress(inputAddress)
                .pipe(first())
                .subscribe((verifiedAddress: any) => {
                    if (verifiedAddress) {
                        let verifiedStreet = verifiedAddress.street1;
                        if (verifiedAddress.secondary) {
                            verifiedStreet += " " + verifiedAddress.secondary;
                        }
                        if (isEqualStrings(verifiedStreet, contactData.address.streets[0]) &&
                                (isEmpty(verifiedAddress.street2) ||
                                        isEqualStrings(verifiedAddress.street2, contactData.address.streets[1])) &&
                                isEqualStrings(verifiedAddress.city, contactData.address.city) &&
                                isEqualStrings(verifiedAddress.state, contactData.address.state) &&
                                isEqualStrings(verifiedAddress.zip, contactData.address.zipCode)) {
                            this.activeModal.close(contactData);
                        } else {
                            this.verifiedAddress = verifiedAddress;
                            this.invalidAddressMessage = "Address validation recommends this address:<br/>" +
                                    this.parseVerifiedAddress(verifiedAddress);
                            this.showRadios = true;
                        }
                    } else {
                        this.invalidAddressMessage =
                                "The address information entered is not a recognized address.<br/>Are you sure it is correct?";
                        this.showCheckbox = true;
                    }
                });
        } else {
            //no address
            this.activeModal.close(contactData);
        }
    }

    parseVerifiedAddress(verifiedAddress: any) {
        let result = verifiedAddress.street1;
        if (verifiedAddress.secondary)
            result = result + " " + verifiedAddress.secondary;
        if (verifiedAddress.street2)
            result = result + "<br/>" + verifiedAddress.street2;
        result =
            result +
            "<br/>" +
            verifiedAddress.city +
            ", " +
            verifiedAddress.state +
            "&nbsp;&nbsp;" +
            verifiedAddress.zip;
        if (verifiedAddress.timeZone)
            result = result + "<br/>Timezone: " + verifiedAddress.timeZone;

        return result;
    }

    abort() {
        this.activeModal.close(false);
    }

    validate(contactData: UserEditData) {
        if (this.editForm.invalid) {
            return "Please correct invalid or missing data.";
        }
        if (contactData.phone.extension && !SfValidators.NUMERIC_REGEXP.test(contactData.phone.extension)) {
            return "Extension (optional) must be all numbers";
        }
        if (this.isSuperUser && this.userIsSuper &&
                !this.userorgService.validateSimplifileEmailDomain(contactData.email)) {
            return "Simplifile organization users should have a simplifile.com, theice.com, ice.com, or elliemae.com email address.";
        }
        if (!contactData.phone.areaCode && !contactData.mobilePhone.areaCode) {
            return "A phone number or a mobile phone number must be provided";
        }
        return null;
    }

    prefixChanged(selection: any) {
        let option: string = null;
        if (selection && selection.option) {
            option = selection.option;
        }
        this.editForm.controls.prefix.setValue(option);
    }

    stateChanged(selection: any) {
        this.editForm.controls.state.setValue(
            selection ? selection.abbrev : null
        );
    }
}
