<div class="fa fa-spinner fa-spin" [hidden]="!!(package$ | async)"></div>
<div class="d-flex flex-column h-100" [hidden]="!(package$ | async)">
    <div class="row control-section">
        <div class="col-md-12">
            <div class="row" [formGroup]="displayForm">
                <div
                    class="col-md-4 mb-4 sf-mask"
                    id="package-history-document-selector"
                >
                    <sf-select
                        [options]="documentSelectionList$ | async"
                        trackBy="id"
                        labelBy="text"
                        [isSingleSelect]="true"
                        formControlName="document"
                    >
                    </sf-select>
                </div>
                <div
                    class="col-md-8 toggle-switch-container d-flex mb-4"
                    id="package-history-view-toggle"
                >
                    <div
                        class="btn-group btn-group-toggle"
                        ngbRadioGroup
                        name="listView"
                        formControlName="view"
                    >
                        <label
                            ngbButtonLabel
                            class="btn btn-secondary d-flex align-items-center"
                        >
                            <input
                                ngbButton
                                type="radio"
                                value="card"
                            /><fa-icon
                                [icon]="icons.windowMaximize"
                                class="me-1"
                            ></fa-icon
                            >Cards
                        </label>
                        <label
                            ngbButtonLabel
                            class="btn btn-secondary d-flex align-items-center"
                        >
                            <input
                                ngbButton
                                type="radio"
                                value="table"
                            /><fa-icon
                                [icon]="icons.thList"
                                class="me-1"
                            ></fa-icon
                            >List
                        </label>
                    </div>
                    <div class="download-options">
                        <button (click)="csvExport()" class="btn btn-link">
                            <fa-icon
                                [icon]="['far', 'download']"
                                class="me-1"
                            ></fa-icon>
                            CSV
                        </button>
                        <button (click)="pdfExport()" class="btn btn-link">
                            <fa-icon [icon]="['far', 'download']"></fa-icon>
                            PDF
                        </button>
                    </div>
                </div>
            </div>
            <div
                class="row legend-details mb-4"
                *ngIf="document$ | async as document"
            >
                <div class="col-md-12">
                    <div class="package-header small">
                        <div *ngIf="!document.isHelper">
                            <span class="sf-mask">{{ document.name }}</span> | {{ document.type }} |
                            <sf-pill
                                [value]="document.status"
                                [simple]="true"
                            ></sf-pill>
                        </div>
                        <div *ngIf="document.isHelper">
                            Helper Type: {{ document.type }} |
                            <span class="sf-mask">{{ document.parent.name }}</span> |
                            {{ document.parent.type }} |
                            <sf-pill
                                [value]="document.parent.status"
                                [simple]="true"
                            ></sf-pill>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row flex-grow-1" *ngIf="historyList$ | async as historyList">
        <div
            *ngIf="!!historyList && historyList.length === 0"
            class="package-list-viewport"
        >
            No history for this
            {{ (document$ | async) ? "document" : "package" }}.
        </div>

        <div *ngIf="historyList.length > 0" class="h-100 col-md-12">
            <!-- just hide the grid so that the grid api will always exist, so it can be used for CSV export -->
            <ag-grid-angular
                class="ag-grid ag-theme-sf enable-user-selection h-100"
                suppressRowClickSelection
                suppressCellFocus
                [headerHeight]="30"
                [rowHeight]="90"
                (gridReady)="onGridReady($event)"
                (firstDataRendered)="$event.api.sizeColumnsToFit()"
                (gridSizeChanged)="onGridSizeChanged()"
                [rowData]="historyList"
                [hidden]="!(tableView$ | async)"
            >
            </ag-grid-angular>

            <ng-container *ngIf="cardView$ | async">
                <cdk-virtual-scroll-viewport itemSize="50" class="h-100">
                    <div
                        *cdkVirtualFor="let historyEvent of historyList"
                        class="package-history-card {{ historyEvent.event }}"
                        tabindex="0"
                    >
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6">
                                    <p>{{ historyEvent.formattedDate }}</p>
                                </div>
                            </div>
                            <div class="row justify-content-between">
                                <div class="col-md-6">
                                    <p class="{{ historyEvent.event }}">
                                        {{ historyEvent.event | uppercase }}
                                    </p>
                                </div>
                                <div class="col-md-3">
                                    <p class="package-user sf-mask">
                                        {{ historyEvent.user }}
                                    </p>
                                </div>
                            </div>
                            <div class="row justify-content-between">
                                <div class="col-md-6">
                                    <p class="document-type">
                                        <span
                                            *ngIf="
                                                !historyEvent.dataEntryVersion
                                            "
                                            class="sf-mask"
                                            >{{ historyEvent.details }}</span
                                        >
                                        <button
                                            class="btn btn-link sf-btn-link sf-mask"
                                            *ngIf="
                                                historyEvent.dataEntryVersion
                                            "
                                            (click)="
                                                showDataEntryChanges(
                                                    historyEvent
                                                )
                                            "
                                        >
                                            {{ historyEvent.details }}
                                        </button>
                                    </p>
                                </div>
                                <div class="col-md-3">
                                    <p>{{ historyEvent.organization }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #dateTemplate let-row>
    <div [title]="row.formattedDate">{{ row.formattedDate }}</div>
</ng-template>

<ng-template #detailsTemplate let-row>
    <span *ngIf="!row.dataEntryVersion" tabindex="0" class="sf-mask">{{ row.details }}</span>
    <button
        class="btn btn-link sf-btn-link sf-mask data-entry-modification"
        *ngIf="row.dataEntryVersion"
        (click)="showDataEntryChanges(row)"
    >
        {{ row.details }}
    </button>
</ng-template>

<ng-template #dataEntryChangesTemplate>
    <div>
        <div>
            <b class="sf-mask">{{ dataEntryChange.user }}</b> &nbsp; {{ dataEntryChange.date }}
        </div>
        <br />
        <table class="table table-striped">
            <tr>
                <th>Group</th>
                <th>Field</th>
                <th>Previous Value</th>
                <th>Changed to</th>
            </tr>
            <tr *ngFor="let field of dataEntryChange.history" class="sf-mask">
                <td>{{ field.group }}</td>
                <td>{{ field.name }}</td>
                <td>{{ field.Before }}</td>
                <td>{{ field.After }}</td>
            </tr>
        </table>
    </div>
</ng-template>
