<div class="logo">
</div>

<div class="d-flex">
    <!-- Page Content -->
    <div class="container-fluid content ">
        <div class="row head-text all-caps">
            <div class="col-12">
                Validate Loan Information
            </div>
        </div>
        <div class="row instructions">
            <div class="col-12">
                Enter loan details to view the loan status and next actions you can take.
            </div>
        </div>

        <!-- ERROR MESSAGES -->
        <div class="error-row" [hidden]="!formErrorMessages || !formErrorMessages.length">
            <ul *ngIf="formErrorMessages.length > 1">
                <li *ngFor="let message of formErrorMessages">
                    <span [innerHTML]="message"></span>
                </li>
            </ul>
            <div *ngIf="formErrorMessages.length == 1">
                <span [innerHTML]="formErrorMessages[0]"></span>
            </div>
        </div>

        <div *ngIf="!this.tokenExpired" class="info-box">
            <form [formGroup]="softAuthForm">
                <div class="row">
                    <div class="mb-3 col-12 col-sm-6">
                        <label for="emailAddress">Your Email Address</label>
                        <input type="text"
                               id="emailAddress"
                               placeholder="Required"
                               class="form-control"
                               autofocus
                               formControlName="emailAddress"/>
                    </div>
                    <ng-container formGroupName="securityAnswers">
                        <div *ngFor="let field of questions | keyvalue" class="mb-3 col-12 col-sm-6">
                            <label for="{{field.key}}">{{field.value}}</label>
                            <input *ngIf="field.key !== 'CLOSING_DATE' && field.key !== 'PROPERTY_STATE'"
                                   id="{{field.key}}"
                                   class="form-control"
                                   type="text"
                                   placeholder="Required"
                                   [formControlName]="$any(field.key)"/>
                            <sf-date-picker
                                *ngIf="field.key === 'CLOSING_DATE' && !hideDateKludge"
                                formControlName="CLOSING_DATE"
                                id="CLOSING_DATE"
                                placeholder="Required (MM/DD/YYYY)"
                                stringFormat="MM/DD/YYYY"
                                [tabindex]="0">
                            </sf-date-picker>
                            <sf-select
                                *ngIf="field.key === 'PROPERTY_STATE'"
                                id="PROPERTY_STATE"
                                name="loanState"
                                [filterDebounce]="0"
                                formControlName="PROPERTY_STATE"
                                [options]="USStates"
                                labelBy="name"
                                trackBy="abbrev"
                                [additionalPropertiesToFilter]="['abbrev']"
                                [isSingleSelect]="true"
                                [hasFilter]="true"
                                placeholder="Required">
                            </sf-select>
                        </div>
                    </ng-container>
                </div>
                <div class="row">
                    <div class="mb-3 col-12 col-sm-6 offset-sm-3 text-center button-row">
                        <!--<button [disabled]="softAuthForm.invalid" class="btn btn-primary" (click)="doSoftAuth()">-->
                        <button class="btn btn-primary"
                                [attr.disabled]="softAuthForm.invalid || tokenExpired || processing ? true : null"
                                [ngClass]="{disabled: softAuthForm.invalid || tokenExpired || processing}"
                                (click)="doSoftAuth()">
                            <span *ngIf="!processing">CONTINUE</span>
                            <fa-icon *ngIf="processing" [icon]="['fad', 'spinner']" [spin]="true" class="spinner-button"></fa-icon>
                        </button>
                    </div>
                </div>
                <div class="row sign-in d-md-none">
                    <div class="mb-3 col-12 text-center">
                        <button class="btn btn-link sf-btn-link" (click)="doFullLogin()">Have an Simplifile account?
                            Sign in here.
                        </button>
                    </div>
                </div>
                <div class="row d-md-none">
                    <div class="mb-3 col-12">
                        <h3>Questions?</h3>
                        Call us at 800.460.5657 and press option 3.<br>
                        We are available from 6 a.m. to 6 p.m. MST.
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- Right Sidebar -->
    <div class="right-panel d-none d-md-flex flex-column align-items-center">
        <div class="secondary-text text-center">VIEW ALL OF YOUR LOAN ACTIVITIES</div>
        <button class="btn btn-outline-secondary sign-in" (click)="doFullLogin()"> Sign In To Simplifile</button>
        <div class="panel-questions">
            <h3>Questions?</h3>
            Call us at 800.460.5657 and press option 3.<br>
            We are available from 6 a.m. to 6 p.m. MST.
        </div>
    </div>
</div>

