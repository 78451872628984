<div class="sf-unmask">
    <sf-modal-header title="Mobile Phone Number"></sf-modal-header>
    <div class="modal-body">
        <div class="input-error row" [ngClass]="{'on': warning}">
            {{warning}}
        </div>
        <div class="explain">
            Your mobile phone number can be used for two-factor authentication.
            You must verify your phone number with a verification code before it can be used for two-factor authentication.
        </div>
        <div class="bold">
            <label for="number">Enter Mobile Phone Number</label>
        </div>
        <div class="mb-3">
            <input type="text" id="number" name="number" class="form-control phone-input sf-mask" [(ngModel)]="phoneNumber"
                   (keydown)="numberChanged()" (keyup)="numberChanged()" (keypress)="numberChanged()"
                   maxlength="14"/>
        </div>
        <div *ngIf="!voiceSupported" class="">
            <span>We will send a text message to this phone number.</span>
        </div>
        <div *ngIf="voiceSupported" class="">
            <label class="bold">Select Delivery Method</label>
            <label>
                <input type="radio" name="destination" value="sms" [(ngModel)]="destination"
                       [checked]="true"
                       (change)="destinationChanged('sms')"/>
                <span> Text Message</span>
            </label>
            <label>
                <input type="radio" name="destination" value="voice" [(ngModel)]="destination"
                       (change)="destinationChanged('voice')"/>
                <span> Phone Call</span>
            </label>
        </div>
    </div>
    <sf-modal-footer [primary]="primary" [secondary]="secondary" [tertiary]="tertiary"></sf-modal-footer>
</div>
