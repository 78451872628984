import {
    Component,
    ComponentFactoryResolver,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { FieldState } from "../../interfaces";
import { InputComponent } from "../../components/input/input.component";
import { Subject } from "rxjs";
import { COMPONENT_FROM_FIELD } from "../../helpers/fields.helpers";

import { scan, take, takeUntil } from "rxjs/operators";
import { DynamicFormStore } from "../../services/dynamic-form-store";
import { UntypedFormControl } from "@angular/forms";

@Component({
    selector: "sf-group-field",
    templateUrl: "./group-field.component.html",
    styleUrls: ["./group-field.component.scss"]
})
export class GroupFieldComponent
    extends InputComponent
    implements OnInit, OnChanges, OnDestroy
{
    private _destroy$: Subject<void> = new Subject();

    @Input() field: FieldState;
    @Input() useColumnLayout: boolean;
    isInnerGroup = false;
    @ViewChild("groupHost", { read: ViewContainerRef, static: true })
    groupHost: ViewContainerRef;

    collapsedMessageDisplay: string = "";
    expanded = true;

    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        @Inject(COMPONENT_FROM_FIELD)
        private _getComponentFromField: (
            field: FieldState
        ) => Type<InputComponent>,
        protected _facade: DynamicFormStore
    ) {
        super(_facade);
    }

    ngOnInit() {
        super.ngOnInit();

        this._facade
            .getVisibleFieldsForField(this.field.fullPath)
            .pipe(
                scan(
                    (previous, curr) => [
                        previous[1].map((field) => field.id),
                        curr
                    ],
                    [[], []]
                ),
                takeUntil(this._destroy$)
            )
            .subscribe(([previous, current]) =>
                this.loadComponents(current, previous)
            );

        this._facade
            .getViewStateForField(this.field.fullPath)
            .pipe(takeUntil(this._destroy$))
            .subscribe((viewState) => {
                this.expanded = viewState.expanded;
                this._setDisplayMessage();
            });

        this._setDisplayMessage();
    }

    ngOnChanges(changes: SimpleChanges): void {}

    ngOnDestroy(): void {
        this._destroy$.next();
    }

    loadComponents(controls: FieldState[], previous: string[]) {
        let nextIndex = 0;
        let currentFields = [];
        let fieldsToAdd: { field: FieldState; index: number }[] = [];

        for (let field of controls) {
            currentFields.push(field.id);
            if (!previous.includes(field.id)) {
                fieldsToAdd.push({ field: field, index: nextIndex });
            }
            nextIndex++;
        }

        for (let visibleControl of [...previous]) {
            if (!currentFields.includes(visibleControl)) {
                let index = previous.indexOf(visibleControl);
                this.groupHost.remove(index);
                previous.splice(index, 1);
            }
        }

        for (let i = 0; i < fieldsToAdd.length; ++i) {
            this._createNewComponent(
                fieldsToAdd[i].field,
                fieldsToAdd[i].index
            );
        }
    }

    toggleExpanded() {
        this._facade.setExpanded(this.field.fullPath, !this.expanded);
    }

    private _setDisplayMessage() {
        if (!this.expanded) {
            let subs = this.formControl$
                .pipe(take(1))
                .subscribe((control: UntypedFormControl) => {
                    let collapsedMessage: string | string[];
                    if (typeof this.field.collapsedMessage === "function") {
                        collapsedMessage = this.field.collapsedMessage(control);
                        this.collapsedMessageDisplay = !Array.isArray(
                            collapsedMessage
                        )
                            ? collapsedMessage
                            : collapsedMessage.join("");
                    } else {
                        collapsedMessage = this.field.collapsedMessage;
                        this.collapsedMessageDisplay = !Array.isArray(
                            collapsedMessage
                        )
                            ? collapsedMessage
                            : collapsedMessage.join("");
                    }
                });

            subs.unsubscribe();
        }
    }

    private _createNewComponent(field: FieldState, index: number) {
        const componentType = this._getComponentFromField(field);
        const componentFactory =
            this._componentFactoryResolver.resolveComponentFactory(
                componentType
            );

        const component = this.groupHost.createComponent<InputComponent>(
            componentFactory,
            index
        );
        const instance = component.instance;
        instance.useColumnLayout = this.useColumnLayout;
        instance.field = field;
        instance.controlPath = this.controlPath + "." + field.path;

        if (instance instanceof GroupFieldComponent) {
            instance.isInnerGroup = true;
        }
    }
}
