<sf-modal-header title="Assign User Roles"></sf-modal-header>
<div class="modal-body">
    <div class="">
        <div class="col-xs-12 col-md-12" *ngIf="warning">
            <div class="alert alert-danger" [innerHTML]="warning"></div>
        </div>
    </div>
    <div class="row">
        <!-- organization selector -->
        <div class="col-xs-4 col-md-4 selector-height">
            <fa-icon *ngIf="!initialized" icon="spinner" [spin]="true"></fa-icon>
            <sf-userorg-organization-multi-selector *ngIf="initialized"
                id="orgSelector" name="orgSelector"
                [lock]="!!lockedOrganizationName"
                [initialSelection]="selectedOrganizationIDs"
                [options]="organizationOptions"
                [allOptions]="allOrganizationOptions"
                [selectableOrgServices]="selectableServices"
                (changed)="orgsChanged($event)">
            </sf-userorg-organization-multi-selector>
        </div>
        <!-- user selector -->
        <div class="col-xs-4 col-md-4 selector-height">
            <fa-icon *ngIf="!initialized" icon="spinner" [spin]="true"></fa-icon>
            <sf-userorg-user-multi-selector *ngIf="initialized"
                id="user-assignment" name=userSelector
                class="sf-mask"
                [lock]="!!lockedUserName"
                [initialSelection]="selectedUserIDs"
                [options]="userOptions"
                [allOptions]="allUserOptions"
                (changed)="usersChanged($event)"
                [isSimplifile]="!!lockedOrganizationName && lockedOrganizationName == 'Simplifile'">
            </sf-userorg-user-multi-selector>
        </div>
        <!-- role selector -->
        <div class="col-xs-4 col-md-4 selector-height">
            <fa-icon *ngIf="!initialized" icon="spinner" [spin]="true"></fa-icon>
            <sf-userorg-role-multi-selector *ngIf="initialized"
                id="role-assignment2" name="roleSelector"
                [lock]="!!lockedRoleName"
                [orgIDs]="selectedOrganizationIDs"
                [showFilter]="true"
                [initialSelection]="selectedRoleIDs"
                (changed)="rolesChanged($event)"
                [dimmedRoles]="existingRoleIDs">
            </sf-userorg-role-multi-selector>
        </div>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
