<div *ngIf="!loaded">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
    Loading . . .
</div>
<div class="" *ngIf="loaded">
    <div>
        <div class="api-definition-container" *ngIf="!apiServiceEnabled">
            This organization does not have an applicable API service enabled.
        </div>
        <div class="api-definition-container" *ngIf="canEdit && !allDefinitionKeys">
            This organization does not have any API integrations configured.
        </div>
    </div>

    <div *ngIf="apiServiceEnabled && allDefinitionKeys" class="">
        <div class="api-definition-container">
            <sf-select (select)="selectDefinitionKey($event)"
                [options]="allDefinitionKeys"
                [selectedOption]="apiKey"
                trackBy="option" labelBy="label"
                [isSingleSelect]="true" placeholder="Select a Definition Key"
                [hasClearButton]="false" [hasFilter]="true">
            </sf-select>
        </div>
    </div>
</div>

<div *ngIf="apiServiceEnabled && allDefinitionKeys && selectedOrganization"
    class="virtual-grid sf-status-badge-container" #grid>
    <div class="action-row">
        <div class="row">
            <div class="container-fluid">
                <sf-search [model]="query" placeholder="Filter" (onChange)="doSearchFilter()"
                        (cancelEvent)="clearFilter()" [icon]="['far', 'filter']" [enable]="!!usersWithTokens.length"
                        [showResults]="false"></sf-search>

                <div *ngIf="canEdit" class="btn-group action-row-action-group" role="group" ngbDropdown
                        placement="bottom-right">
                    <button type="button" class="btn btn-secondary actions-button" ngbDropdownToggle>
                        Actions
                    </button>
                    <div ngbDropdownMenu aria-labelledby="actionButton" class="dropdown-menu">
                        <button class="dropdown-item" (click)="addAPIToken(null)">
                            <fa-icon [fixedWidth]="true" icon="plus-circle"></fa-icon>&nbsp;&nbsp;Add New Tokens
                        </button>
                        <button class="dropdown-item" (click)="removeAPIToken()">
                            <fa-icon [fixedWidth]="true" icon="minus-circle"></fa-icon>&nbsp;&nbsp;Remove Tokens
                        </button>
                        <button class="dropdown-item" (click)="cancelTokenRequest()">
                            <fa-icon [fixedWidth]="true" icon="times-circle"></fa-icon>&nbsp;&nbsp;Cancel Pending Requests
                        </button>
                        <button class="dropdown-item" *ngIf="canExport" (click)="downloadTokens()">
                            <fa-icon [fixedWidth]="true" icon="file-excel"></fa-icon>&nbsp;&nbsp;Generate & Export Tokens
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- column headers -->
    <div class="table-header">
        <div *ngIf="canEdit" class="virtual-column checkbox-col">
            <div>
                <input type="checkbox" class='item-select' [(ngModel)]="allSelected" aria-label="Select All"
                        name="selectall_check" (change)="selectAll()"/>
            </div>
        </div>
        <div class="virtual-column sortable" #nameCol>
            <button class="btn btn-link sf-btn-link column-header" (click)="changeSort('name')">
                Name
                <fa-icon *ngIf="sortKey=='name' && sortAscending"  icon="sort-amount-down-alt"></fa-icon>
                <fa-icon *ngIf="sortKey=='name' && !sortAscending" icon="sort-amount-up"></fa-icon>
            </button>
        </div>
        <div class="virtual-column sortable" #otherCol>
            <button class="btn btn-link sf-btn-link column-header" (click)="changeSort('username')">
                Username
                <fa-icon *ngIf="sortKey=='username' && sortAscending"  icon="sort-amount-down-alt"></fa-icon>
                <fa-icon *ngIf="sortKey=='username' && !sortAscending" icon="sort-amount-up"></fa-icon>
            </button>
        </div>
        <div class="virtual-column sortable token-col">
            <button class="btn btn-link sf-btn-link column-header" (click)="changeSort('tokenSort')">
                API Token
                <fa-icon *ngIf="sortKey=='tokenSort' && sortAscending"  icon="sort-amount-down-alt"></fa-icon>
                <fa-icon *ngIf="sortKey=='tokenSort' && !sortAscending" icon="sort-amount-up"></fa-icon>
            </button>
        </div>
        <div *ngIf="canEdit" class="virtual-column icon-header">
            <div>&nbsp;</div>
        </div>
    </div>
    <!-- the data -->
    <cdk-virtual-scroll-viewport itemSize="44" (scrolledIndexChange)="adjustColumnWidths()">
        <div *cdkVirtualFor="let user of sortedList" class="virtual-row">
            <div *ngIf="canEdit" class="virtual-column checkbox-col checkbox-holder">
                <input type="checkbox" class='item-select' [(ngModel)]="user.selected"
                        aria-label="Select this key" name="select_check" (change)="selectUser()"/>
            </div>
            <div class="virtual-column" #nameCol>
                <span>
                    <button *ngIf="canLinkToUsers" (click)="clickUser(user)"
                        class="btn btn-link sf-btn-link">
                        <b [innerHTML]="user.firstLastName | highlight:query.text"></b>
                    </button>
                    <b *ngIf="!canLinkToUsers" class=""
                        [innerHTML]="user.firstLastName | highlight:query.text">
                    </b>
                </span>
                <span *ngIf="user.emailShared" class="space-left">
                    <button class="btn btn-link sf-btn-link" *ngIf="isSuperUser" tooltip-append-to-body="true"
                            ngbTooltip="Email address '{{user.email}}' is shared by more than one user. Click icon to view shared users."
                            placement="bottom-left" (click)="viewSharedEmail(user.email)"
                            aria-label="Duplicate email address">
                        <fa-icon [fixedWidth]="true" icon="exclamation-circle" class="dup-icon"></fa-icon>
                    </button>
                    <span class="" *ngIf="!isSuperUser" tooltip-append-to-body="true"
                            ngbTooltip="Email address '{{user.email}}' is shared by more than one user"
                            placement="bottom-left">
                        <fa-icon [fixedWidth]="true" icon="exclamation-circle" class="dup-icon"></fa-icon>
                    </span>
                </span>
            </div>
            <div class="virtual-column" #otherCol>
                <span *ngIf="user.username" [innerHTML]="user.username | highlight:query.text"></span>
            </div>
            <div class="virtual-column token-col">
                <fa-icon *ngIf="!!user.apiToken" icon="check" class="check-space" container="body"
                    ngbTooltip="A token is assigned" placement="left"></fa-icon>
                <span *ngIf="user.pendingAccept"
                    class="badge disabled-badge" container="body" ngbTooltip="Waiting for user to accept ({{user.email}})"
                    placement="left">Pending</span>
            </div>
            <div *ngIf="canEdit && !user.apiToken && !user.pendingAccept" class="virtual-column icon-col">
                <button class="btn btn-link sf-btn-link" aria-label="Add API Token" (click)="addAPIToken(user)">
                    <fa-icon icon="plus-circle"
                        ngbTooltip="Add API Token"  placement="left"></fa-icon>
                </button>
            </div>
            <div *ngIf="canEdit && (user.apiToken || user.pendingAccept)" class="virtual-column no-wrap icon-col overflow-on">
                <div class="btn-group ellipsis-group" role="group" ngbDropdown placement="bottom-right">
                    <button type="button" class="btn more-dots btn-secondary" aria-label="Token menu" ngbDropdownToggle>
                        <fa-icon icon="ellipsis-h"></fa-icon>
                    </button>
                    <div ngbDropdownMenu role="menu">
                        <button class="dropdown-item" *ngIf="user.apiToken && !user.pendingAccept"
                                role="menuitem" (click)="resetAPIToken(user)">
                            <fa-icon [fixedWidth]="true" icon="plus-circle"></fa-icon>&nbsp;&nbsp;Request New API Token
                        </button>
                        <button class="dropdown-item" *ngIf="user.apiToken"
                                role="menuitem" (click)="removeAPIToken(user)">
                            <fa-icon [fixedWidth]="true" icon="minus-circle"></fa-icon>&nbsp;&nbsp;Remove API Token
                        </button>
                        <button class="dropdown-item" *ngIf="user.pendingAccept"
                                role="menuitem" (click)="cancelTokenRequest(user)">
                            <fa-icon [fixedWidth]="true" icon="times-circle"></fa-icon>&nbsp;&nbsp;Cancel Token Request
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
</div>
