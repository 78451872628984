import { Component, OnInit, OnDestroy } from "@angular/core";
import { DynamicFormStore } from "@sf/dynamic-form-viewer";
import { CustomFormService } from "../../custom-form.service";
import { generateRandomString } from "@sf/common";
import { InputAutoCompleteComponent } from "../../components/input-auto-complete/input-auto-complete.component";
import { ErecordHighlightService } from "@sf/document/erecord-viewer/common";
import { UntypedFormControl } from "@angular/forms";
import { CustomPhoneNumberFieldComponent } from "../custom-phone-number-field/custom-phone-number-field.component";

@Component({
    selector: "sf-custom-text-field",
    templateUrl: "./custom-text-field.component.html",
    styleUrls: ["./custom-text-field.component.scss"]
})
export class CustomTextFieldComponent
    extends InputAutoCompleteComponent
    implements OnInit, OnDestroy
{
    randomName: string = generateRandomString(5);
    isPhoneType: boolean = false;

    constructor(
        protected _formFacade: DynamicFormStore,
        protected _customFormService: CustomFormService,
        protected _highlightService: ErecordHighlightService
    ) {
        super(_formFacade, _customFormService, _highlightService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.isPhoneType = !!this.field.phoneValueFormat;
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    showHighlight() {
        super.showHighlight();
    }

    hideHighlight() {
        super.hideHighlight();
    }

    onPhoneInput(event: Event, control: UntypedFormControl) {
        CustomPhoneNumberFieldComponent.handlePhoneNumberInput(
            event,
            control,
            this.field.phoneValueFormat
        );
    }
}
