<div #selectButton class="sf-select-two-field" id="ms-two-button-{{selectorId}}">
    <div class="sf-select-two-field__right-container">
        <button class="sf-select-two-field__clear-button"
             [ngClass]="{'sf-select-two-field__clear-button--active': view.hasSelections}"
             *ngIf="hasClearButton && view.currentSelection && view.currentSelection[0]"
             (click)="view.hasSelections && clearButtonClicked($event)"
             [attr.aria-label]="'Clear Selection'">
            <fa-icon class="sf-select-two-field__clear-icon" [icon]="icons.clear" *ngIf="view.hasSelections"></fa-icon>
        </button>
        <div class="sf-select-two-field__caret-container">
            <fa-icon class="sf-select-two-field__caret-icon" [icon]="icons.downArrow"></fa-icon>
        </div>
    </div>
    <div class="sf-select-two-field__display">
        {{view.msDisplay || placeholder}}
        <fa-icon
          *ngIf="view.isSingleSelect && view.showIconInDisplay && view.rightMetadataIsIcon && view.currentSelection[0]?.$$msRightMetadata"
          [icon]="view.currentSelection[0].$$msRightMetadata"></fa-icon>
    </div>
</div>
<div *sfAttachToBody class="sf-select-two sf-select-two-popup sf-select-two-popup-down {{dropdownClass}}" id="ms-two-popup-{{selectorId}}"
     sfSelectMaintainPosition sfSelectCloseOnOffClick [selectorId]="selectorId" (toggleOpen)="toggleOpen($event)"
     [isOpen]="view.isOpen" [minPopupWidth]="minPopupWidth" [selectButtonRef]="selectButton"
     [ngClass]="{'sf-select-two-hidden': !view.isOpen}" id="ms-two-popup-container-{{selectorId}}"
     (keydown)="handleKeydown($event)"
     role="alert">
    <div *ngIf="showSelectedToggle" class="sf-select-two__toggle-show-selected">
        <div class="sf-select-two__toggle-group">
            <div
                (click)="setShowOnlySelected(false)" class="sf-select-two__toggle-item sf-select-two__toggle-item__left"
                [ngClass]="{'sf-select-two__toggle-item-selected': !view.showOnlySelected}">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm">
                            All<span class="font-italic" *ngIf="view.isFiltered">&nbsp;(Filtered)</span>
                        </div>
                        <div class="col-sm text-end">
                            {{options.length }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- The funky formatting here is to ensure proper layout of two 50% boxes side by side - do not remove this comment -->
            <div
                (click)="setShowOnlySelected(true)" class="sf-select-two__toggle-item sf-select-two__toggle-item__right"
                [ngClass]="{'sf-select-two__toggle-item-selected': view.showOnlySelected}">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm">
                            Selected
                        </div>
                        <div class="col-sm text-end">
                            {{view.currentSelection ? view.currentSelection.length : 0}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FILTER -->
    <div [hidden]="!view.hasFilter" class="sf-select-two-filter__container">
        <div class="sf-select-two-filter">
            <div class="sf-select-two-filter__right-container" >
                <button
                     #searchBoxClearButton
                     id="ms-two-clear-filter-{{selectorId}}"
                     class="sf-select-two-filter__clear-button"
                     [ngClass]="{ 'sf-select-two-filter__clear-button--active': !!view.itemFilter }"
                     (click)="clearFilter($event)"
                     (keydown)="clearFilter($event)"
                     [attr.aria-label]="'Clear Filter'">
                    <fa-icon class="sf-select-two-field__clear-icon" [icon]="icons.clear"></fa-icon>
                </button>
            </div>
            <div class="sf-select-two-filter__input-container" >
                <input type="text"
                       class="sf-select-two-filter__input"
                       id="ms-two-input-{{selectorId}}"
                       #searchBoxInput
                       [placeholder]="filterPlaceholder || 'Begin typing to search'"
                       (keydown)="filterAction($event)"
                       [attr.aria-label]="filterPlaceholder || 'Filter'"
                       tabindex="-1">
                <div class="sf-select-two-filter__icon-container float-end">
                    <fa-icon *ngIf="view.filterIcon" [icon]="view.filterIcon" size="lg" class="sf-select-two-filter__icon"></fa-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="sf-select-two__list" role="listbox"
         id="sf-select-two__list-{{selectorId}}"
         [attr.aria-activedescendant]="view.hashedActiveItemId ? 'msAnchor' + selectorId + view.hashedActiveItemId : null"
         [attr.aria-multiselectable]="!view.isSingleSelect"
         [attr.aria-label]="'Options'"
         [ngStyle]="{ 'max-height': view.maxPopupHeight + 'px' }"
         tabindex="0"
         #popupList>
        <div *ngIf="view.showNoItemsMessage" class="sf-select-two__no-items" role="option">
            No items to display
        </div>
        <div id="msAnchor{{selectorId}}msTopItem" aria-hidden="true"></div>
        <!-- SELECT ALL -->
        <div *ngIf="view.showSelectAll"
             class="sf-select-two__list-item  sf-select-two__select-all"
             role="option"
             [ngClass]="{'sf-select-two__list-item--single': view.isSingleSelect}"
             (click)="!view.isSelectAllDisabled && selectAllClicked()"
             id="msAnchor{{selectorId}}msSelectAllItem"
             [title]="view.selectAllLabel"
             [attr.checked]="view.isSelectAllChecked"
             [attr.selected]="view.isSelectAllActive"
             [attr.disabled]="view.isSelectAllDisabled"
             [attr.aria-label]="view.selectAllLabel">
            <div class="sf-select-two__item-checkbox-container" *ngIf="!view.showOnlySelected">
                <fa-icon
                    [icon]="(view.isSelectAllChecked ? ['far', 'check-square'] : ['far', 'square'])"
                    [class.disabled]="view.isSelectAllDisabled"
                ></fa-icon>
            </div>
            <div class="sf-select-two__item-label-container">
                <div class="sf-select-two__item-label">
                    {{view.selectAllLabel}}
                </div>
            </div>
        </div>
        <!-- RECENT SELECTIONS -->
        <ng-container *ngIf="view.showRecentSelections && view.recentlySelectedItems.length > 0">
            <ng-container *ngFor="let recentSelection of view.recentlySelectedItems; trackBy: ngForTrackBy">
                <div class="sf-select-two__list-item sf-select-two__list-item--single"
                     id="msAnchor{{selectorId}}{{recentSelection.$$msHashedId}}recent"
                     role="option"
                     [attr.disabled]="recentSelection.$$msDisabled"
                     (click)="!recentSelection.$$msDisabled && toggleItemSelection(recentSelection.$$msId)">
                    <div class="sf-select-two__item-label-container">
                        <div class="sf-select-two__item-label">
                            {{recentSelection.$$msLabel}} <fa-icon
                                [icon]="['far', 'clock']" class="float-end sf-select-two__item-label-recent-selection-indicator"></fa-icon>
                        </div>
                    </div>
                </div>
            </ng-container>
            <hr class="sf-select-two__item-divider" *ngIf="!view.hideMainList" aria-hidden="true" />
        </ng-container>
        <!-- MAIN LIST -->
        <div *ngIf="!view.hideMainList">
            <ng-container *ngFor="let group of view.msGroupedItems; trackBy: ngForTrackByGroup">
                <!-- GROUP LABEL -->
                <div
                    class="sf-select-two__list-item sf-select-two__group-start"
                    [ngClass]="{'sf-select-two__list-item--single': view.isSingleSelect}"
                    *ngIf="view.isItemsGrouped"
                    role="option"
                    id="msAnchor{{selectorId}}{{group.$$msHashedId}}"
                    [title]="group.$$msTitle"
                    (click)="!group.$$msDisabled && toggleGroupSelection(group.id)"
                    [attr.checked]="group.selected"
                    [attr.selected]="group.active"
                    [attr.disabled]="group.$$msDisabled">
                    <div class="sf-select-two__item-checkbox-container" *ngIf="!view.isSingleSelect">
                        <fa-icon
                            [icon]="(group.selected ? ['far', 'check-square'] : ['far', 'square'])"
                            [class.disabled]="group.$$msDisabled"
                        ></fa-icon>
                    </div>
                    <div class="sf-select-two__item-label-container">
                        <div class="sf-select-two__item-label">
                            {{group.label}}
                        </div>
                    </div>
                </div>
                <!-- GROUP ITEMS -->
                <div *ngFor="let item of group.items; trackBy: ngForTrackBy;">
                    <div class="sf-select-two__list-item"
                         [ngClass]="{'sf-select-two__grouped-list-item': view.isItemsGrouped, 'sf-select-two__list-item--single': view.isSingleSelect}"
                         role="option"
                         id="msAnchor{{selectorId}}{{item.$$msHashedId}}"
                         [title]="item.$$msTitle"
                         (click)="!item.$$msDisabled && toggleItemSelection(item.$$msId)"
                         [attr.checked]="item.$$msSelected"
                         [attr.selected]="item.$$msActive"
                         [attr.aria-selected]="item.$$msActive"
                         [attr.disabled]="item.$$msDisabled"
                         [attr.aria-disabled]="item.$$msDisabled"
                         attr.aria-describedby="msAnchor{{selectorId}}{{item.$$msHashedId}}description">
                        <div class="sf-select-two__item-checkbox-container" *ngIf="!view.isSingleSelect">
                            <fa-icon
                                [icon]="(!!item.$$msSelected ? ['far', 'check-square'] : ['far', 'square'])"
                                [class.disabled]="!!item.$$msDisabled"
                            ></fa-icon>
                        </div>
                        <div class="sf-select-two__item-label-container">
                            <div class="sf-select-two__right-container"
                                 *ngIf="view.hasRightMetadata"
                                 aria-hidden="true">
                                <span *ngIf="!view.rightMetadataIsIcon" class="badge bg-dark {{item.rightMetadataClass}}">{{item.$$msRightMetadata}}</span>
                                <fa-icon *ngIf="view.rightMetadataIsIcon && item.$$msRightMetadata" [icon]="item.$$msRightMetadata" class="sf-select-two__right-container--icon"></fa-icon>
                            </div>
                            <div class="sf-select-two__item-label">
                                {{item.$$msLabel}}
                            </div>
                            <div *ngIf="view.hasBottomMetadata && view.bindAsHtml"
                                 class="sf-select-two__item__bottom-metadata sf-select-two__item-label"
                                 aria-hidden="true"
                                 [innerHTML]="item.$$msBottomMetadata">
                            </div>
                            <div *ngIf="view.hasBottomMetadata && !view.bindAsHtml"
                                 class="sf-select-two__item__bottom-metadata sf-select-two__item-label"
                                 aria-hidden="true">
                                {{item.$$msBottomMetadata}}
                            </div>
                        </div>
                        <span id="msAnchor{{selectorId}}{{item.$$msHashedId}}description"
                              *ngIf="item.description"
                              [hidden]="true"
                              aria-hidden="true">{{item.ariaDescription}}</span>
                    </div>
                    <hr class="sf-select-two__item-divider" *ngIf="item.hasLineBelow"/>
                </div>
            </ng-container>
            <div *ngIf="view.hasLimitedDropdown" class="sf-select-two__list-item sf-select-two__list-item-limit">
                <div class="sf-select-two__item-label">
                    Showing top {{view.dropdownLimit}} results.<br />Use filter to see other results.
                </div>
            </div>
        </div>
    </div>
    <!-- FOOTER -->
    <div class="sf-select-two-footer" *ngIf="view.hasFooter">
        <button (click)="callFooterActionLeft()" class="btn btn-link sf-btn-link float-start" *ngIf="!!footerActionLeftIcon || !!footerActionLeftLabel">
            <fa-icon [icon]="footerActionLeftIcon" aria-hidden="true" *ngIf="footerActionLeftIcon"></fa-icon>
            <span class="sf-select-two-footer__text">{{footerActionLeftLabel}}</span>
        </button>

        <button (click)="callFooterActionRight()" class="btn btn-link sf-btn-link float-end" *ngIf="!!footerActionRightIcon || !!footerActionRightLabel">
            <fa-icon [icon]="footerActionRightIcon" aria-hidden="true" *ngIf="footerActionRightIcon"></fa-icon>
            <span class="sf-select-two-footer__text">{{footerActionRightLabel}}</span>
        </button>
    </div>
</div>
