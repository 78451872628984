<sf-tabbed-modal-header [title]="modalTitle" [currentStep]="stepIndex" [stepCount]="numSteps" [hideSteps]="!showSteps"></sf-tabbed-modal-header>
<div class="modal-body add-payment-account-dialog-body sf-unmask">

    <div *ngIf="warning" class="d-flex flex-column input-error">
        <span>{{warning}}</span>
    </div>

    <!-- Step 1: Account Type -->
    <form *ngIf="stepIndex === 1 && !addCCFromPendingPayments" #typeForm="ngForm">
        <div>
            <span class="no-accounts-title">Start by selecting the payment account type you would like to use:</span>
            <br/><br/>
            <div class="ps-4">
                <div class="mb-3" *ngIf="hasSubmitter || hasRecordingPlus || hasDocBuilder || hasEsignEvents || hasNotary">
                    <label class="radio-label">
                        <input type="radio" id="checking" name="radioTypes" [(ngModel)]="radioAccountType"
                               [value]="paymentAccountType.BANK" (change)="handleAccountTypeSelected($event)"/>
                        ACH Debit <span class="recommended">(Recommended)</span>
                    </label><br/>
                    <div class="radio-description">
                        All service fees are collected nightly via secure Automated Clearing House (ACH).
                        We will debit your account one time each business day for any charges incurred the previous day.
                        <b>This account type includes Checking, Savings and General Ledger bank accounts.</b>
                    </div>
                </div>
                <div class="mb-3" *ngIf="hasSubmitter">
                    <label class="radio-label">
                        <input type="radio" id="echeck" name="radioTypes" [(ngModel)]="radioAccountType"
                               [value]="paymentAccountType.E_CHECK" (change)="handleAccountTypeSelected($event)" />
                        eCheck <span class="echeck-reminder">(Payments must be approved the same day as recording)</span>
                    </label><br/>
                    <div class="radio-description">
                        One electronic check (eCheck) will be created for each recorded package and queued for approval by
                        an authorized user in your organization. Approval must happen the same day as recording. Once approved,
                        eChecks are processed through your bank just like paper checks. eChecks work with all checking accounts
                        and includes all the security and regulations of a paper check.
                    </div>
                </div>
<!--                <div class="mb-3" *ngIf="showLegalEase && hasSubmitter">-->
<!--                    <label class="radio-label">-->
<!--                        <input type="radio" id="frost" name="radioTypes" [(ngModel)]="radioAccountType"-->
<!--                               [value]="paymentAccountType.FROST_BANK" (change)="handleAccountTypeSelected($event)"/>-->
<!--                        LegalEase-->
<!--                    </label><br/>-->
<!--                    <div class="radio-description">-->
<!--                        LegalEase, from Frost Bank, is an account specifically used for paying county and state services.-->
<!--                        A 16-digit LegalEase access number is required. Your account is debited one time each business day-->
<!--                        for any charges incurred the previous day.-->
<!--                    </div>-->
<!--                </div>-->

                <div class="mb-4" *ngIf="showCard">
                    <label class="radio-label">
                        <input type="radio" id="credit-card" name="radioTypes" [(ngModel)]="radioAccountType"
                               [value]="paymentAccountType.CREDIT_CARD" (change)="handleAccountTypeSelected($event)" />
                        Credit/Debit Card
                    </label><br/>
                        <div *ngIf="showBasicCardText" class="radio-description">A credit/debit card can only be used to pay the Simplifile license fee.</div>
                        <div *ngIf="!showBasicCardText" class="radio-description">Credit/debit cards can be used to pay the Simplifile
                            license fee as well as other service fees. A surcharge may be added to the total fee amount
                            if a credit card is used to pay other service fees. A surcharge is not added to debit card transactions.</div>
                </div>
            </div>
        </div>
    </form>

    <!-- Step 2: Account Details (non credit card)-->
    <form *ngIf="stepIndex === 2 && step1Group.controls.paymentAccountType.value === paymentAccountType.BANK" [formGroup]="step2Group">
        <div class="d-flex flex-column">
            <label *ngIf="!addCCFromPendingPayments || !editMode || (editMode && (!hasAdminPermission || !hasOrgAccountingPermission))">Payment Type: {{paymentTypeLabel}} </label>
            <label class="section-title">Payment Account Details</label>
            <div class="d-flex flex-column section-details">
                <!-- PS-9955 asks that we not allow anyone to change the type for an existing account
                <div *ngIf="editMode && !accountUsed && (hasAdminPermission || hasOrgAccountingPermission)" class="mb-3">
                    <label class="form-label">Payment Account Type</label>
                    <sf-select
                        name="accountType"
                        placeholder="Select Account Type..."
                        [isSingleSelect]="true"
                        [options]="accountTypeOptions"
                        [selectedOption]="selectedAccountType"
                        trackBy="option"
                        labelBy="label"
                        [required]="true"
                        (select)="handleAccountTypeSelected($event)"></sf-select>
                </div>
                -->
                <div *ngIf="step2Group.controls.bankAccountType.value === paymentAccountType.CHECKING ||
                         step2Group.controls.bankAccountType.value === paymentAccountType.SAVINGS ||
                         step2Group.controls.bankAccountType.value === paymentAccountType.GENERAL_LEDGER">
                    <label class="pe-3">
                        <input type="radio" id="b_checking" formControlName="bankAccountType" [value]="paymentAccountType.CHECKING"/>
                        <b> Checking</b> <span class="recommended"> (Recommended)</span>
                    </label>
                    <label class="pe-3">
                        <input type="radio" id="b_savings" formControlName="bankAccountType" [value]="paymentAccountType.SAVINGS"/>
                        <b> Savings</b>
                    </label>
                    <label *ngIf="showGeneralLedger">
                        <input type="radio" id="b_ledger" formControlName="bankAccountType" [value]="paymentAccountType.GENERAL_LEDGER"/>
                        <b> General Ledger</b>
                    </label>
                </div>
                <div class="mb-3">
                    <label for="bankname" class="form-label">Bank Name</label>
                    <input type="text" id="bankname" formControlName="bankName" (change)="populateNickname()" class="form-control sf-mask"/>
                </div>
                <div class="mb-3" *ngIf="step2Group.controls.bankAccountType.value !== paymentAccountType.FROST_BANK">
                    <div class="d-flex">
                        <label for="routingNumber" class="form-label">ACH Routing Number</label>
                        <fa-icon class="ms-1" [icon]="['far', 'info-circle']" [fixedWidth]="true"
                                 ngbTooltip="Click for more information"
                                 placement="right" (click)="showRoutingInfo()"></fa-icon>
                    </div>
                    <input type="text" id="routingNumber" formControlName="routingNumber" maxlength="9" autocomplete="off" class="form-control sf-mask"/>
                </div>
                <div class="mb-3" *ngIf="step2Group.controls.bankAccountType.value !== paymentAccountType.FROST_BANK">
                    <label for="verifyRoutingNumber" class="form-label">Verify ACH Routing Number</label>
                    <input type="text" id="verifyRoutingNumber" formControlName="verifyRoutingNumber" maxlength="9"
                           autocomplete="off" class="form-control sf-mask"/>
                </div>
                <div class="mb-3">
                    <div class="d-flex">
                        <label for="accountNumber" class="form-label">Account Number</label>
                        <fa-icon class="ms-1" [icon]="['far', 'info-circle']" [fixedWidth]="true"
                                 [ngbTooltip]="routerTipContent" tooltipClass="router-tooltip"
                                 placement="right"></fa-icon>
                    </div>
                    <input type="text" id="accountNumber" formControlName="accountNumber" maxlength="17" autocomplete="off" class="form-control sf-mask"/>
                </div>
                <div class="mb-3">
                    <label for="verifyAccountNumber" class="form-label">Verify Account Number</label>
                    <input type="text" id="verifyAccountNumber" formControlName="verifyAccountNumber" maxlength="17" autocomplete="off"
                        (change)="populateNickname()" class="form-control sf-mask"/>
                </div>
                <div class="mb-3" *ngIf="step2Group.controls.bankAccountType.value === paymentAccountType.FROST_BANK">
                    <label for="expirationMonth" class="form-label">Expiration Month / Year (Optional)</label>
                    <div class="d-flex">
                        <input class="small-width form-control sf-mask" type="text" id="expirationMonth"
                               formControlName="expirationMonth" maxlength="2" placeholder="MM"/>
                        &nbsp;/&nbsp;
                        <input class="small-width form-control sf-mask" type="text" id="expirationYear"
                               formControlName="expirationYear" maxlength="2" placeholder="YY"/>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="nameOnAccount" class="form-label">Name of Individual on Account</label>
                    <input type="text" id="nameOnAccount" formControlName="nameOnAccount" class="form-control sf-mask"/>
                </div>
                <div class="mb-3">
                    <div class="d-flex">
                        <label for="nickName" class="form-label">Account Nickname</label><br/>
                        <fa-icon class="ms-1" [icon]="['far', 'info-circle']" [fixedWidth]="true"
                                 ngbTooltip="The name that will be used by Simplifile to identify the account"
                                 placement="right"></fa-icon>
                    </div>
                    <input type="text" id="nickName" formControlName="nickName" class="form-control sf-mask"/>
                </div>
            </div>
        </div>
    </form>

    <!-- Step 3: Signatory -->
    <form *ngIf="stepIndex === 3 && step1Group.controls.paymentAccountType.value === paymentAccountType.BANK" [formGroup]="step3Group">
        <div class="d-flex flex-column">
            <label>Payment Type: {{paymentTypeLabel}} </label>
            <label class="section-title">Authorized User Details</label>
            <div class="d-flex flex-column section-details">
                <div class="mb-3">
                    <div class="d-flex">
                        <label for="signatory" class="form-label">Authorized Bank Account User </label><br/>
                        <fa-icon class="ms-1" [icon]="['far', 'info-circle']" [fixedWidth]="true"
                                 ngbTooltip="This is your name if you are an authorized user for this bank account"
                                 placement="right"></fa-icon>
                    </div>
                    <input type="text" id="signatory" formControlName="signatory" class="form-control sf-mask"/>
                </div>
                <div class="mb-3">
                    <label for="jobTitle" class="form-label">Job Title</label>
                    <input type="text" id="jobTitle" formControlName="jobTitle" class="form-control"/>
                </div>
                <div class="mb-3">
                    <label for="phone" class="form-label">Phone Number</label>
                    <input type="text" id="phone" formControlName="phone" sfPhoneInput class="form-control sf-mask"/>
                </div>
                <div class="mb-3">
                    <label for="companyName" class="form-label">Company Name</label>
                    <input type="text" id="companyName" formControlName="companyName" placeholder="Your complete company name" class="form-control sf-mask"/>
                </div>
            </div>
        </div>
    </form>

    <!-- Step 4: Confirmation -->
    <form *ngIf="stepIndex === 4 && step1Group.controls.paymentAccountType.value === paymentAccountType.BANK" [formGroup]="step4Group">
        <div class="d-flex flex-column">
            <label>Payment Type: {{paymentTypeLabel}} </label>
            <label class="section-title">Confirm Account Details</label>
            <div class="section-details">
                <div class="d-flex">
                    <label class="final-label">Authorized Signatory</label>
                    <label class="flex-fill sf-mask truncate" sfTooltipOnOverflow="{{step3Group.controls.signatory.value}}" container="body">
                        {{step3Group.controls.signatory.value}}
                    </label>
                </div>
                <div class="d-flex">
                    <label class="final-label">Job Title</label>
                    <label class="flex-fill sf-mask truncate" sfTooltipOnOverflow="{{step3Group.controls.jobTitle.value}}" container="body">
                        {{step3Group.controls.jobTitle.value}}
                    </label>
                </div>
                <div class="d-flex">
                    <label class="final-label">Phone Number</label>
                    <label class="flex-fill sf-mask">{{step3Group.controls.phone.value}}</label>
                </div>
                <div class="d-flex">
                    <label class="final-label">Company Name</label>
                    <label class="flex-fill sf-mask truncate" sfTooltipOnOverflow="{{step3Group.controls.companyName.value}}" container="body">
                        {{step3Group.controls.companyName.value}}
                    </label>
                </div>
                <div class="d-flex">
                    <label class="final-label">Account Nickname</label>
                    <label class="flex-fill sf-mask truncate" sfTooltipOnOverflow="{{step2Group.controls.nickName.value}}" container="body">
                        {{step2Group.controls.nickName.value}}
                    </label>
                </div>
                <div class="d-flex">
                    <label class="final-label">Bank Name</label>
                    <label class="flex-fill sf-mask truncate" sfTooltipOnOverflow="{{step2Group.controls.bankName.value}}" container="body">
                        {{step2Group.controls.bankName.value}}
                    </label>
                </div>
                <div class="d-flex" *ngIf="step2Group.controls.bankAccountType.value !== paymentAccountType.FROST_BANK">
                    <label class="final-label">ACH Routing Number</label>
                    <label class="flex-fill sf-mask">{{step2Group.controls.routingNumber.value}}</label>
                </div>
                <div class="d-flex">
                    <label class="final-label">Account Number</label>
                    <label class="flex-fill sf-mask">{{step2Group.controls.accountNumber.value}}</label>
                </div>
                <div class="d-flex" *ngIf="step2Group.controls.bankAccountType.value === paymentAccountType.FROST_BANK && step2Group.controls.expirationMonth.value && step2Group.controls.expirationYear.value">
                    <label class="final-label">Expiration Date</label>
                    <label class="flex-fill sf-mask">{{step2Group.controls.expirationMonth.value}} / {{step2Group.controls.expirationYear.value}}</label>
                </div>
                <div class="d-flex">
                    <label class="final-label">Name on Account</label>
                    <label class="flex-fill sf-mask truncate" sfTooltipOnOverflow="{{step2Group.controls.nameOnAccount.value}}" container="body">
                        {{step2Group.controls.nameOnAccount.value}}
                    </label>
                </div>
            </div>
            <div>
                <label class="section-title">Terms & Conditions</label>
                <fa-icon class="ms-1" [icon]="['far', 'info-circle']" [fixedWidth]="true"
                         ngbTooltip="You must agree to these terms"
                         placement="right"></fa-icon>
            </div>
            <div class="d-flex flex-column section-details">
                <p style="font-size: smaller">
                    I, as an authorized signatory, by my submission of this form, authorize Simplifile, LC (Vendor) to
                    initiate debit entries to my (our) account and financial institution named above, for payments
                    due to Vendor for electronic document recording as described in your License Agreement(s) between
                    Company and Vendor as those payments become due. Vendor will identify transactions with the Simplifile
                    ID number 1010658627. I will provide this number to my (our) financial institution to prevent any
                    returns due to debit blockers, account filters, etc. I acknowledge that the electronic fund transfer
                    transactions to the account must comply with the provisions of U.S. law.
                </p>
                <p style="font-size: smaller">Use of this payment account is subject to validation and approval by Simplifile, LC.</p>
                <div class="d-flex">
                    <label for="agreeCheckbox">
                        <input type="checkbox" class="mt-1 me-1" id="agreeCheckbox" formControlName="agreeCheckbox" (click)="handleAgreeCheckbox($event)"/>
                        <b>I understand and agree to the above terms</b>
                    </label>
                </div>
            </div>
        </div>
    </form>

    <!-- Step 2: Invoice Details -->
    <form *ngIf="stepIndex === 2 && step1Group.controls.paymentAccountType.value === paymentAccountType.INVOICE" [formGroup]="step2GroupInvoice">
        <div class="d-flex flex-column">
            <label *ngIf="!editMode || (editMode && (!hasAdminPermission || !hasOrgAccountingPermission))">Payment Type: Invoice</label>
            <label class="section-title">Invoice Account Details</label>
        </div>
        <div class="d-flex flex-column" [ngClass]="{'section-details': !editMode}">
            <div *ngIf="editMode" class="row">
                <div class="mb-3 col-md-11">
                    <div class="d-flex">
                        <label for="nickName2" class="form-label">Account Nickname</label><br/>
                        <fa-icon class="ms-1" [icon]="['far', 'info-circle']" [fixedWidth]="true"
                                 ngbTooltip="The name that will be used by Simplifile to identify the account"
                                 placement="right"></fa-icon>
                    </div>
                    <input type="text" id="nickName2" formControlName="nickName" class="form-control sf-mask" placeholder="Required"/>
                </div>
            </div>
        </div>
    </form>

    <!-- Step 2: Credit Card Details -->
    <form *ngIf="(stepIndex === 1 && addCCFromPendingPayments) ||stepIndex === 2 && step1Group.controls.paymentAccountType.value === paymentAccountType.CREDIT_CARD" [formGroup]="step2GroupCC">
        <div class="d-flex flex-column">
            <label *ngIf="!editMode || (editMode && (!hasAdminPermission || !hasOrgAccountingPermission))">Payment Type: {{paymentTypeLabel}} </label>
            <label class="section-title">Credit Card Details</label>
<!--            <div [hidden]="true" class="mb-3">-->
<!--                <label class="form-label">Payment Account Type</label>-->
<!--                <sf-select-->
<!--                    name="accountType"-->
<!--                    placeholder="Select Account Type..."-->
<!--                    [isSingleSelect]="true"-->
<!--                    [options]="accountTypeOptions"-->
<!--                    [selectedOption]="step1Group.controls.paymentAccountType.value"-->
<!--                    trackBy="option"-->
<!--                    labelBy="label"-->
<!--                    required-->
<!--                    (select)="handleAccountTypeSelected($event)"></sf-select>-->
<!--            </div>-->
            <div class="d-flex flex-column" [ngClass]="{'section-details': !editMode}">
                <div *ngIf="editMode" class="row">
                    <div class="mb-3 col-md-11">
                        <div class="d-flex">
                            <label for="nickName3" class="form-label">Account Nickname</label><br/>
                            <fa-icon class="ms-1" [icon]="['far', 'info-circle']" [fixedWidth]="true"
                                     ngbTooltip="The name that will be used by Simplifile to identify the account"
                                     placement="right"></fa-icon>
                        </div>
                        <input type="text" id="nickName3" formControlName="nickName" class="form-control sf-mask" placeholder="Required"/>
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-md-11">
                        <label for="cardName">Name on Card</label>
                        <input type="text" id="cardName" name="name" placeholder="Required" (change)="populateCCNickname()"
                            class="form-control sf-mask" formControlName="creditCardName" required/>
                    </div>
                </div>
                <div *ngIf="!editMode" class="">
                    <div class="mb-3 float-start">
                        <label for="cardNumberElement">Card Number</label>
                        <div id="cardNumberElement" class="credit-card-row card-number sf-mask"></div>
                    </div>
                    <div class="mb-3 float-start">
                        <label for="cardExpiryElement">Expires</label>
                        <div id="cardExpiryElement" class="credit-card-row card-expiry sf-mask"></div>
                    </div>
                    <div class="mb-3 float-start">
                        <label for="cardCvcElement">CVC</label>
                        <div id="cardCvcElement" class="credit-card-row card-cvc sf-mask"></div>
                    </div>
                    <div class="mb-3 float-start">
                        <label> </label>
                        <div class="card-image" [ngClass]="{'card-error': !!creditCardNumberError}">
                            <fa-icon *ngIf="creditCardImage" [icon]="['fab', creditCardImage]"></fa-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="editMode" class="row">
                    <div class="mb-3 col-md-4">
                        <label for="ccLast4">Card Number</label>
                        <div>
                            <input type="text" id="ccLast4" name="ccLast4" class="form-control sf-mask" formControlName="creditCardLast4"/>
                        </div>
                    </div>
                    <div class="mb-3 col-md-3">
                        <label for="ccExpiration">Expires</label>&nbsp;
                        <span *ngIf="getCreditCardStatus(paymentAccount) == 'EXPIRING'">
                            <fa-icon [icon]="['far', 'info-circle']" class="ms-1 info-expiring" ngbTooltip="Card is Expiring"></fa-icon>
                        </span>
                        <span *ngIf="getCreditCardStatus(paymentAccount) == 'EXPIRED'">
                            <fa-icon [icon]="['far', 'info-circle']" class="ms-1 info-expired" ngbTooltip="Card has Expired"></fa-icon>
                        </span>
                        <span>
                            <input type="text" id="ccExpiration" name="ccExpiration" class="form-control sf-mask"
                                formControlName="creditCardExp" placeholder="MM/YYYY" maxlength="7"/>
                        </span>
                    </div>
                    <div class="mb-3 col-md-3">
                        <label for="ccPostalCode">Zip Code</label>
                        <div>
                            <input type="text" id="ccPostalCode" name="ccPostalCode" class="form-control sf-mask" formControlName="creditCardZip"/>
                        </div>
                    </div>
                    <div class="mb-3 col-md-2">
                        <label> </label>
                        <div class="card-image" [ngClass]="{'card-error': !!creditCardNumberError}">
                            <fa-icon *ngIf="creditCardImage" [icon]="['fab', creditCardImage]"></fa-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="!editMode" class="row">
                    <div class="mb-3 col-md-6">
                        <label for="zipCode">Card Billing Zip Code</label>
                        <input type="text" id="zipCode" name="zip" placeholder="Required" maxlength="10"
                            class="form-control sf-mask" formControlName="creditCardZip" required/>
                    </div>
                </div>
                <div *ngIf="!editMode" class="row">
                    <div class="mb-3 col-md-11">
                        <div class="d-flex">
                            <label for="nickName4" class="form-label">Account Nickname</label><br/>
                            <fa-icon class="ms-1" [icon]="['far', 'info-circle']" [fixedWidth]="true"
                                     ngbTooltip="The name that will be used by Simplifile to identify the account"
                                     placement="right"></fa-icon>
                        </div>
                        <input type="text" id="nickName4" formControlName="nickName" class="form-control sf-mask" placeholder="Required"/>
                    </div>
                </div>
            </div>
            <div *ngIf="editMode && hasAdminPermission && currEnvironment != 'PROD'">
                <div class="d-flex">
                    <input type="checkbox" id="editPaymentMethod" (change)="toggleEditPaymentMethod()"/>&nbsp;
                    <label for="editPaymentMethod" style="margin-bottom: unset">Edit Stripe Payment Method</label>
                </div>
                <div *ngIf="editPaymentMethod">
                    <div class="caution">
                        <div class="mt-1">WARNING! Only change this value if you know what you are doing!!</div>
                        <div class="mt-1">You MUST remember the original Payment Method identifier in order to revert the payment account back.</div>
                    </div>
                    <div class="mt-2 d-flex">
                        <label for="stripePaymentMethod" style="padding-top: 8px; margin-bottom: unset">ID:</label>&nbsp;
                        <input type="text" id="stripePaymentMethod" [(ngModel)]="getCCAccount().token" [ngModelOptions]="{standalone: true}" class="form-control sf-mask" style="width: 350px"/>&nbsp;
                        <span ngbDropdown [container]="'body'" style="padding-top: 8px" ngbTooltip="Click to change Payment Method">
                            <fa-icon [icon]="['fal','list-alt']" ngbDropdownToggle [fixedWidth]="true" style="font-size: 17px"></fa-icon>
                            <div class="action-menu" ngbDropdownMenu>
                                <button ngbDropdownItem (click)="getCCAccount().token = 'pm_card_visa_chargeDeclined'; validateExpiration(true);">Charge Declined</button>
                                <button ngbDropdownItem (click)="getCCAccount().token = 'pm_card_visa_chargeDeclinedInsufficientFunds'; validateExpiration(true);">Insufficient Funds</button>
                                <button ngbDropdownItem (click)="getCCAccount().token = 'pm_card_visa_chargeDeclinedLostCard'; validateExpiration(true);">Lost Card</button>
                                <button ngbDropdownItem (click)="getCCAccount().token = 'pm_card_visa_chargeDeclinedStolenCard'; validateExpiration(true);">Stolen Card</button>
                                <button ngbDropdownItem (click)="getCCAccount().token = 'pm_card_chargeDeclinedExpiredCard'; validateExpiration(false);">Expired Card</button>
                                <button ngbDropdownItem (click)="getCCAccount().token = 'pm_card_chargeDeclinedIncorrectCvc'; validateExpiration(true);">Incorrect CVC</button>
                                <button ngbDropdownItem (click)="getCCAccount().token = 'pm_card_chargeDeclinedProcessingError'; validateExpiration(true);">Processing Error</button>
                            </div>
                        </span>
                        <fa-icon [icon]="['fal','clipboard']" [fixedWidth]="true" style="padding-top: 8px" ngbTooltip="Click to copy Payment Method to clipboard" (click)="copyText(getCCAccount().token)"></fa-icon>
                    </div>
                </div>
            </div>
            <div *ngIf="!editMode" class="d-flex flex-column">
                <div>
                    <label class="section-title">Terms & Conditions</label>
                    <fa-icon class="ms-1" [icon]="['far', 'info-circle']" [fixedWidth]="true"
                             ngbTooltip="You must agree to these terms"
                             placement="right"></fa-icon>
                </div>
                <div class="d-flex flex-column section-details">
                    <p style="font-size: smaller">
                        You agree, by your submission of this form, to authorize Simplifile LC ("Simplifile") to charge
                        your credit card for purposes of fulfilling your payment obligations that are due and payable to
                        Simplifile, as those payments become due. You further represent and warrant that (i) the credit card information
                        provided to Simplifile (including, but not limited to, card number, expiration date and card holder's
                        name) is valid in all respects and lawfully authorized for use, (ii) that such credit card belongs
                        to you or has been authorized for use by you by the valid cardholder, and (iii) the credit card
                        information you provided will be saved by Simplifile for future transactions on your account. Upon
                        expiration of the credit card, you agree to notify Simplifile of the new credit card expiration
                        date and all other relevant information pertaining to the new credit card.
                    </p>
                    <div class="d-flex">
                        <label for="cardAgreeCheckbox">
                            <input type="checkbox" class="mt-1 me-1" id="cardAgreeCheckbox" tabindex="0" (click)="handleAgreeCheckbox($event)"/>
                            <b>I understand and agree to the above terms & conditions</b>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </form>

</div>
<sf-tabbed-modal-footer [primary]="primaryButton" [secondary]="secondaryButton"
    [nextButton]="nextButton" [backButton]="backButton"></sf-tabbed-modal-footer>

<ng-template #routerTipContent>
    <p>Where to find this on a check:</p>
    <img src="/sf/ui/images/sampleCheck.png" alt="Sample check"/>
</ng-template>
