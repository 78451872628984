<div class="sf-organization-payment-accounts sf-unmask">
    <div *ngIf="!paymentAccountsLoaded">
        <fa-icon
            [icon]="['fad', 'spinner']"
            [spin]="true"
            [fixedWidth]="true"
        ></fa-icon
        ><i>Loading accounts...</i>
    </div>
    <div *ngIf="!isZeroDayState()" id="payment-accounts">
        <div *ngIf="organizationId && paymentAccountsLoaded">
            <div id="accounts-header">
                <label class="accounts-title">Payment Accounts</label>&nbsp; <label class="badge archived-badge"
                    ngbTooltip="{{filteredAccounts.length + ' Payment Accounts'}}"
                    placement="right">{{filteredAccounts.length}}</label>

                <span ngbDropdown [container]="'body'" class="float-end">
                    <button class="btn btn-primary" (click)="addPaymentAccount()">
                        Add Account
                    </button>
                    <button class="btn btn-outline-primary ms-1" ngbDropdownToggle>
                        Actions
                    </button>
                    <div class="action-menu" ngbDropdownMenu>
                        <button ngbDropdownItem (click)="showImportDialog()">
                            <fa-icon
                                [icon]="['far', 'upload']"
                                [fixedWidth]="true"
                            ></fa-icon>
                            <span class="ms-2"></span>
                            Import Payment Accounts
                        </button>
                        <button *ngIf="isSuperUser" ngbDropdownItem (click)="toggleShowArchived()">
                            <fa-icon
                                *ngIf="!showArchived"
                                [icon]="['far', 'eye']"
                                [fixedWidth]="true"
                            ></fa-icon>
                            <fa-icon
                                *ngIf="showArchived"
                                [icon]="['far', 'eye-slash']"
                                [fixedWidth]="true"
                            ></fa-icon>
                            <span class="ms-2"></span>
                            <span *ngIf="!showArchived"
                                >Show Removed Payment Accounts</span
                            >
                            <span *ngIf="showArchived"
                                >Hide Removed Payment Accounts</span
                            >
                        </button>
                        <button
                            ngbDropdownItem
                            (click)="showAboutDialog('payment')"
                        >
                            <fa-icon
                                [icon]="['far', 'info-circle']"
                                [fixedWidth]="true"
                            ></fa-icon>
                            <span class="ms-2"></span>
                            About Payment Accounts
                        </button>
                    </div>
                </span>
            </div>
            <div [ngStyle]="gridDivStyle">
                <ag-grid-angular
                    *ngIf="hasAccounts"
                    class="ag-theme-sf accounts-grid-overrides"
                    [rowData]="filteredAccounts"
                    [gridOptions]="gridOptions"
                ></ag-grid-angular>
            </div>
        </div>
    </div>
    <div *ngIf="isZeroDayState()" class="row col-md-12 col-sm-12">
        <div class="no-accounts-div col-md-7 col-sm-7">
            <div *ngIf="missingAccountInfo" class="mb-2">
                <span class="no-accounts-title">{{missingAccountInfo.organizationName}} ({{missingAccountInfo.organizationID}}) is missing a payment account for <b>{{missingAccountInfo.productLabel}} {{missingAccountInfo.isLicense ? "(License)" : "(Fees)"}} .</b></span>
            </div>
            <span class="no-accounts-title">Select the payment account type you would like to use: </span>
            <br/><br/>
            <div class="ps-4">
                <div class="mb-3" *ngIf="hasSubmitter || hasRecordingPlus || hasDocBuilder || hasSignEvents || hasNotary">
                    <label class="radio-label">
                        <input type="radio" id="checking" name="account-type" [(ngModel)]="initialAccountType" [value]="paymentAccountType.BANK" />
                        ACH Debit <span class="recommended">(Recommended)</span>
                    </label><br/>
                    <div class="radio-description">
                        All service fees are collected nightly via secure Automated Clearing House (ACH).
                        We will debit your account one time each business day for any charges incurred the previous day.<br/>
                        This account type includes Checking, Savings and General Ledger bank accounts.
                    </div>
                </div>
                <div class="mb-3" *ngIf="missingAccountInfo.product == 'SUBMITTER' || missingAccountInfo.product == 'SUBMITTER_PAPER'">
                    <label class="radio-label">
                        <input type="radio" id="echeck" name="account-type" [(ngModel)]="initialAccountType" [value]="paymentAccountType.E_CHECK"/>
                        eCheck <span class="echeck-reminder">(Payments must be approved the same day as recording)</span>
                    </label><br/>
                    <div class="radio-description">
                        One electronic check (eCheck) will be created for each recorded package and queued for approval by
                        an authorized user in your organization. Approval must happen the same day as recording. Once approved,
                        eChecks are processed through your bank just like paper checks. eChecks work with all checking
                        accounts and includes all the security and regulations of a paper check.
                    </div>
                </div>
<!--                <div class="mb-4" *ngIf="!orgEnabledForCards">-->
<!--                    <label class="radio-label-disabled">-->
<!--                        <input type="radio" id="credit-card" value="credit-card" disabled/>-->
<!--                        Credit Card (Not Currently Available)-->
<!--                    </label>-->
<!--                </div>-->
<!--                <div class="mb-3" *ngIf="orgEnabledForCards">-->
                <div class="mb-3" *ngIf="showCard">
                    <label class="radio-label">
                        <input type="radio" id="credit-card" name="account-type" [(ngModel)]="initialAccountType"
                               [value]="paymentAccountType.CREDIT_CARD"/>
                        Credit/Debit Card
                    </label><br/>
                    <div *ngIf="showBasicCardText" class="radio-description">
                        A credit/debit card can only be used to pay the Simplifile license fee.
                    </div>
                    <div *ngIf="!showBasicCardText" class="radio-description">
                        Credit/debit cards can be used to pay the Simplifile license fee as well as other service fees.
                        A surcharge may be added to the total fee amount if a credit card is used to pay other service fees.
                        A surcharge is not added to debit card transactions.
                    </div>
                </div>
            </div>
            <button class="btn btn-primary" (click)="addPaymentAccount()">Continue</button>
        </div>
        <div *ngIf="organizationId" class="col-md-5 col-sm-5 float-end">
            <div id="accounts-header">
                <span ngbDropdown [container]="'body'" class="float-end">
                    <button class="btn btn-outline-primary ms-1" ngbDropdownToggle>
                        Actions
                    </button>
                    <div class="action-menu" ngbDropdownMenu>
                        <button ngbDropdownItem (click)="showImportDialog()">
                            <fa-icon
                                [icon]="['far', 'upload']"
                                [fixedWidth]="true"
                            ></fa-icon>
                            <span class="ms-2"></span>
                            Import Payment Accounts
                        </button>
                        <button *ngIf="isSuperUser" ngbDropdownItem (click)="toggleShowArchived()">
                            <fa-icon
                                *ngIf="!showArchived"
                                [icon]="['far', 'eye']"
                                [fixedWidth]="true"
                            ></fa-icon>
                            <fa-icon
                                *ngIf="showArchived"
                                [icon]="['far', 'eye-slash']"
                                [fixedWidth]="true"
                            ></fa-icon>
                            <span class="ms-2"></span>
                            <span *ngIf="!showArchived"
                            >Show Removed Payment Accounts</span
                            >
                            <span *ngIf="showArchived"
                            >Hide Removed Payment Accounts</span
                            >
                        </button>
                        <button
                            ngbDropdownItem
                            (click)="showAboutDialog('payment')"
                        >
                            <fa-icon
                                [icon]="['far', 'info-circle']"
                                [fixedWidth]="true"
                            ></fa-icon>
                            <span class="ms-2"></span>
                            About Payment Accounts
                        </button>
                    </div>
                </span>
            </div>
        </div>
    </div>

    <div *ngIf="hasSubmitter && !isZeroDayState()" id="submitter-accounts" class="accounts-section">
    <hr style="margin-top: 2rem"/>
        <div class="account-section-header">
            <label class="accounts-title">Recording Fee Defaults</label>
            <span ngbDropdown [container]="'body'" class="float-end ellipsis-group">
                <button ngbDropdownToggle class="btn btn-link sf-btn-link ellipsis-dropdown" aria-label="eRecording actions">
                    <fa-icon [icon]="['far', 'ellipsis-h']" [fixedWidth]="true"></fa-icon>
                </button>
                <div class="action-menu" ngbDropdownMenu>
                    <!--  not implementing yet                -->
                    <!--                <button ngbDropdownItem>-->
                    <!--                    <fa-icon [icon]="cog" [fixedWidth]="true"></fa-icon>-->
                    <!--                    <span class="ms-2"></span>-->
                    <!--                    eRecord Payment Settings-->
                    <!--                </button>-->
                    <button *ngIf="isSuperUser" ngbDropdownItem (click)="goToPaymentSettings()">
                        <fa-icon [icon]="['far', 'cog']" [fixedWidth]="true"></fa-icon>
                        <span class="ms-2"></span>
                        Recording Payment Settings
                    </button>
                    <button *ngIf="hasSalesTax" ngbDropdownItem (click)="goToSalesTax()">
                        <fa-icon [icon]="['far', 'cog']" [fixedWidth]="true"></fa-icon>
                        <span class="ms-2"></span>
                        Sales Tax Settings
                    </button>
                    <button ngbDropdownItem (click)="showAboutDialog('erecord')">
                        <fa-icon [icon]="['far', 'info-circle']" [fixedWidth]="true"></fa-icon>
                        <span class="ms-2"></span>
                        About Recording Fees
                    </button>
                </div>
            </span>
        </div>
        <div *ngFor="let fee of feeCategories['SUBMITTER']" class="fee-item">
            <div *ngIf="showFee(fee, 'SUBMITTER')" class="d-flex align-items-center">
                <div class="flex-fill fee-item-label">
                    <label ngbTooltip="{{getTooltipForFee('SUBMITTER', fee)}}" placement="right">{{ getLabelForFee(fee) }}&nbsp;</label>
                </div>
                <div class="flex-fill fee-item-accounts">
                    <sf-select
                        #submitterSelects
                        [id]="fee + '_SUBMITTER'"
                        [placeholder]="getPlaceholderText(fee, 'SUBMITTER')"
                        [isSingleSelect]="true"
                        [options]="getDefaultAccounts(fee, 'SUBMITTER')"
                        [selectedOption]="getDefaultAccountID(fee, 'SUBMITTER')"
                        trackBy="paymentAccountID"
                        labelBy="viewLabel"
                        [hasFilter]="false"
                        [isDisabled]="isSearchingPackages"
                        placement="right"
                        (select)="
                            handleDefaultAccountSelected(
                                $event,
                                fee,
                                'SUBMITTER'
                            )
                        "
                        disableBy="invalid"
                        groupBy="group"
                        class="sf-mask"
                    ></sf-select>
                </div>
                <div *ngIf="missingDefault('SUBMITTER', fee)" class="icon-warning" >
                    <fa-icon icon="exclamation-circle" ngbTooltip="Please set a default for the License Fee" placement="right"></fa-icon>
                </div>
                <div *ngIf="isSearchingPackages && fee == feeSearched" class="flex-fill fee-item-checking">
                    <fa-icon
                        [icon]="['fad', 'spinner']"
                        [spin]="true"
                        [fixedWidth]="true"
                    ></fa-icon
                    ><i>Checking packages...</i>
                </div>
            </div>
        </div>
        <div *ngIf="hasRecordingPlus">
            <div *ngFor="let fee of feeCategories['SUBMITTER_PAPER']" class="fee-item">
                <div *ngIf="showFee(fee, 'SUBMITTER_PAPER')" class="d-flex align-items-center">
                    <div class="flex-fill fee-item-label">
                        <label ngbTooltip="{{getTooltipForFee('SUBMITTER_PAPER', fee)}}" placement="right">{{getLabelForFee(fee)}}&nbsp;</label>
                    </div>
                    <div class="flex-fill fee-item-accounts">
                        <sf-select
                                #docBuilderSelects
                                [placeholder]="getPlaceholderText(fee, 'SUBMITTER_PAPER')"
                                [isSingleSelect]="true"
                                [options]="getDefaultAccounts(fee, 'SUBMITTER_PAPER')"
                                [selectedOption]="getDefaultAccountID(fee, 'SUBMITTER_PAPER')"
                                trackBy="paymentAccountID"
                                labelBy="viewLabel"
                                [hasFilter]="false"
                                [isDisabled]="isSearchingPackages"
                                placement="right"
                                disableBy="invalid"
                                groupBy="group"
                                class="sf-mask"
                                (select)="handleDefaultAccountSelected($event, fee, 'SUBMITTER_PAPER')"></sf-select>
                    </div>
                    <div *ngIf="missingDefault('SUBMITTER_PAPER', fee)" class="icon-warning" >
                        <fa-icon icon="exclamation-circle" ngbTooltip="Please set a default for the License Fee" placement="right"></fa-icon>
                    </div>
                    <div *ngIf="isSearchingPackages && fee == feeSearched" class="flex-fill fee-item-checking">
                        <fa-icon
                                [icon]="['fad', 'spinner']"
                                [spin]="true"
                                [fixedWidth]="true"
                        ></fa-icon
                        ><i>Checking packages...</i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="hasDocBuilder && !isZeroDayState()" id="docbuilder-accounts" class="accounts-section">
        <hr style="margin-top: 2rem"/>
        <div class="account-section-header">
            <label class="accounts-title">Document Builder Fee Defaults</label>
            <span ngbDropdown [container]="'body'" class="float-end ellipsis-group">
                <button ngbDropdownToggle class="btn btn-link sf-btn-link ellipsis-dropdown" aria-label="Document builder actions">
                    <fa-icon [icon]="['far', 'ellipsis-h']" [fixedWidth]="true"></fa-icon>
                </button>
                <div class="action-menu" ngbDropdownMenu>
                    <button *ngIf="isSuperUser" ngbDropdownItem (click)="goToDocBuilderSettings()">
                        <fa-icon [icon]="['far', 'cog']" [fixedWidth]="true"></fa-icon>
                        <span class="ms-2"></span>
                        Document Builder Payment Settings
                    </button>
                    <button ngbDropdownItem (click)="showAboutDialog('docbuilder')">
                        <fa-icon [icon]="['far', 'info-circle']" [fixedWidth]="true"></fa-icon>
                        <span class="ms-2"></span>
                        About Document Builder Fees
                    </button>
                </div>
            </span>
        </div>
        <div *ngFor="let fee of feeCategories['SUBMITTER_SIGNING']" class="fee-item">
            <div *ngIf="showFee(fee, 'SUBMITTER_SIGNING')" class="d-flex align-items-center">
                <div class="flex-fill fee-item-label">
                    <label ngbTooltip="{{getTooltipForFee('SUBMITTER_SIGNING', fee)}}" placement="right">{{getLabelForFee(fee)}}&nbsp;</label>
                </div>
                <div class="flex-fill fee-item-accounts">
                    <sf-select
                        #docBuilderSelects
                        [placeholder]="getPlaceholderText(fee, 'SUBMITTER_SIGNING')"
                        [isSingleSelect]="true"
                        [options]="getDefaultAccounts(fee, 'SUBMITTER_SIGNING')"
                        [selectedOption]="getDefaultAccountID(fee, 'SUBMITTER_SIGNING')"
                        trackBy="paymentAccountID"
                        labelBy="viewLabel"
                        [hasFilter]="false"
                        [isDisabled]="isSearchingPackages"
                        placement="right"
                        disableBy="invalid"
                        groupBy="group"
                        class="sf-mask"
                        (select)="handleDefaultAccountSelected($event, fee, 'SUBMITTER_SIGNING')"></sf-select>
                </div>
                <div *ngIf="missingDefault('SUBMITTER_SIGNING', fee)" class="icon-warning" >
                    <fa-icon icon="exclamation-circle" ngbTooltip="Please set a default for the License Fee" placement="right"></fa-icon>
                </div>
                <div *ngIf="isSearchingPackages && fee == feeSearched" class="flex-fill fee-item-checking">
                    <fa-icon
                        [icon]="['fad', 'spinner']"
                        [spin]="true"
                        [fixedWidth]="true"
                    ></fa-icon
                    ><i>Checking packages...</i>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="hasSignEvents && !isZeroDayState()" id="events-accounts" class="accounts-section">
        <hr style="margin-top: 2rem"/>
        <div class="account-section-header">
            <label class="accounts-title">eSign Events Fee Defaults</label>
            <span ngbDropdown [container]="'body'" class="float-end ellipsis-group">
                <button ngbDropdownToggle class="btn btn-link sf-btn-link ellipsis-dropdown" aria-label="Esign events actions">
                    <fa-icon [icon]="['far', 'ellipsis-h']" [fixedWidth]="true"></fa-icon>
                </button>
                <div class="action-menu" ngbDropdownMenu>
                    <button *ngIf="isSuperUser" ngbDropdownItem (click)="goToEESettings()">
                        <fa-icon [icon]="['far', 'cog']" [fixedWidth]="true"></fa-icon>
                        <span class="ms-2"></span>
                        eSign Events Payment Settings
                    </button>
                    <button ngbDropdownItem (click)="showAboutDialog('sign-events')">
                        <fa-icon [icon]="['far', 'info-circle']" [fixedWidth]="true"></fa-icon>
                        <span class="ms-2"></span>
                        About eSign Events Fees
                    </button>
                </div>
            </span>
        </div>
        <div *ngFor="let fee of feeCategories['ESIGN_EVENT']" class="fee-item">
            <div *ngIf="showFee(fee, 'ESIGN_EVENT')" class="d-flex align-items-center">
                <div class="flex-fill fee-item-label">
                    <label ngbTooltip="{{getTooltipForFee('ESIGN_EVENT', fee)}}" placement="right">{{getLabelForFee(fee)}}&nbsp;</label>
                </div>
                <div class="flex-fill fee-item-accounts">
                    <sf-select
                            #signEventsSelects
                            [placeholder]="getPlaceholderText(fee, 'ESIGN_EVENT')"
                            [isSingleSelect]="true"
                            [options]="getDefaultAccounts(fee, 'ESIGN_EVENT')"
                            [selectedOption]="getDefaultAccountID(fee, 'ESIGN_EVENT')"
                            trackBy="paymentAccountID"
                            labelBy="viewLabel"
                            [hasFilter]="false"
                            [isDisabled]="false"
                            placement="right"
                            disableBy="invalid"
                            groupBy="group"
                            class="sf-mask"
                            (select)="handleDefaultAccountSelected($event, fee, 'ESIGN_EVENT')"></sf-select>
                </div>
                <div *ngIf="missingDefault('ESIGN_EVENT', fee)" class="icon-warning" >
                    <fa-icon icon="exclamation-circle" ngbTooltip="Please set a default for the License Fee" placement="right"></fa-icon>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="hasNotary && !isZeroDayState()" id="notary-accounts" class="accounts-section">
        <hr style="margin-top: 2rem"/>
        <div class="account-section-header">
            <label class="accounts-title">Notary Fee Defaults</label>
            <span ngbDropdown [container]="'body'" class="float-end ellipsis-group">
                <button ngbDropdownToggle class="btn btn-link sf-btn-link ellipsis-dropdown" aria-label="Notary actions">
                    <fa-icon [icon]="['far', 'ellipsis-h']" [fixedWidth]="true"></fa-icon>
                </button>
                <div class="action-menu" ngbDropdownMenu>
                    <button ngbDropdownItem (click)="showAboutDialog('notary')">
                        <fa-icon [icon]="['far', 'info-circle']" [fixedWidth]="true"></fa-icon>
                        <span class="ms-2"></span>
                        About Notary Fees
                    </button>
                </div>
            </span>
        </div>
        <div *ngFor="let fee of feeCategories['NOTARY']" class="fee-item">
            <div *ngIf="showFee(fee, 'NOTARY')" class="d-flex align-items-center">
                <div class="flex-fill fee-item-label">
                    <label ngbTooltip="{{getTooltipForFee('NOTARY', fee)}}" placement="right">{{getLabelForFee(fee)}}&nbsp;</label>
                </div>
                <div class="flex-fill fee-item-accounts">
                    <sf-select
                            #signEventsSelects
                            [placeholder]="getPlaceholderText(fee, 'NOTARY')"
                            [isSingleSelect]="true"
                            [options]="getDefaultAccounts(fee, 'NOTARY')"
                            [selectedOption]="getDefaultAccountID(fee, 'NOTARY')"
                            trackBy="paymentAccountID"
                            labelBy="viewLabel"
                            [hasFilter]="false"
                            [isDisabled]="false"
                            placement="right"
                            disableBy="invalid"
                            groupBy="group"
                            class="sf-mask"
                            (select)="handleDefaultAccountSelected($event, fee, 'NOTARY')"></sf-select>
                </div>
                <div *ngIf="missingDefault('NOTARY', fee)" class="icon-warning" >
                    <fa-icon icon="exclamation-circle" ngbTooltip="Please set a default for the License Fee" placement="right"></fa-icon>
                </div>
            </div>
        </div>
    </div>

    <!-- not doing recipient yet-->

    <!--    <div *ngIf="hasRecipient && hasAccounts" id="recipient-accounts" class="accounts-section">-->
    <!--        <label class="accounts-title">Recipient eRecording Fee Defaults</label>-->
    <!--        <span ngbDropdown [container]="'body'" class="float-end">-->
    <!--            <span ngbDropdownToggle class="ellipsis-dropdown">-->
    <!--                <fa-icon [icon]="ellipsis" [fixedWidth]="true"></fa-icon>-->
    <!--            </span>-->
    <!--            <div class="action-menu" ngbDropdownMenu>-->
    <!--                <button ngbDropdownItem>-->
    <!--                    <fa-icon [icon]="info" [fixedWidth]="true"></fa-icon>-->
    <!--                    <span class="ms-2"></span>-->
    <!--                    About Recipient eRecording Fees-->
    <!--                </button>-->
    <!--            </div>-->
    <!--        </span>-->
    <!--    </div>-->
</div>

<ng-template #accountNameTemplate let-data>
    <button class="btn btn-link p-0 sf-mask" (click)="editAccount(data)" ngbTooltip="Click to update" container="body" placement="right">
        {{ data.label }}
    </button>
</ng-template>

<ng-template #nameOnAccountTemplate let-data>
    <button *ngIf="data.paymentAccountType != paymentAccountType.INVOICE" class="btn btn-link p-0 sf-mask" (click)="editAccount(data)" ngbTooltip="Click to update" container="body" placement="right">
        {{ data.paymentAccountType == paymentAccountType.BANK ? data.nameOnBankAccount : data.nameOnCard }}
    </button>
    <button *ngIf="data.paymentAccountType == paymentAccountType.INVOICE" class="btn btn-link p-0 sf-mask" (click)="editAccount(data)" ngbTooltip="Click to update" container="body" placement="right">
        {{ data.label }}
    </button>
</ng-template>

<ng-template #accountStatusTemplate let-data let-params="params">
    <button *ngIf="params.value == 'active'" class="btn badge active-badge">Active</button>
    <button *ngIf="params.value == 'removed'" class="btn badge archived-badge">Removed</button>
    <button *ngIf="params.value == 'expiring'" class="btn badge expiring-badge" (click)="editAccount(data)"
            ngbTooltip="Click to update expiration date" container="body" placement="right">Expiring</button>
    <button *ngIf="params.value == 'expired'" class="btn badge expired-badge" (click)="editAccount(data)"
            ngbTooltip="Click to update expiration date" container="body" placement="right">Expired</button>
</ng-template>

<ng-template #accountDetailsTemplate let-data>
    <div *ngIf="data.paymentAccountType == paymentAccountType.BANK" class="account-details-cell sf-mask">
        {{ data.bankName }}<br />
        <div
            *ngIf="
                data.accountType === paymentAccountType.E_CHECK &&
                data.accountName
            "
        >
            {{ data.accountName }}<br />
        </div>
        <div
            *ngIf="data.accountType !== paymentAccountType.FROST_BANK"
            class="account-details-accounts"
        >
            <span ngbTooltip="Routing Number" placement="right" container="body">{{ data.routingNumber }}&nbsp;</span><br/>
            <span ngbTooltip="Account Number" placement="right" container="body">{{ data.accountNumber }}&nbsp;</span>
        </div>
        <div *ngIf="data.accountType === paymentAccountType.FROST_BANK">
            <div class="account-details-accounts">
                <span ngbTooltip="Account Number" placement="right" container="body">{{ data.accountNumber }}&nbsp;</span>
            </div>
            <div *ngIf="data.expiration.month && data.expiration.year" class="account-details-accounts">
                Expiration: {{ data.expiration.month }} /
                {{ data.expiration.year }}
            </div>
        </div>
    </div>
    <div *ngIf="data.paymentAccountType == paymentAccountType.CREDIT_CARD" class="account-details-cell sf-mask">
        {{ data.brand }}
        <div class="account-details-accounts">
            <div *ngIf="getCreditCardStatus(data) == creditCardStatus.EXPIRED">
                Expired {{ getCreditCardExpirationDayjs(data).format("MM/YY") }}
            </div>
            <div *ngIf="!(getCreditCardStatus(data) == creditCardStatus.EXPIRED)">
                Expiring {{ getCreditCardExpirationDayjs(data).format("MM/YY") }}
            </div>
        </div>
    </div>
    <div *ngIf="data.paymentAccountType == paymentAccountType.INVOICE" class="account-details-cell">
        <span>Invoice</span>
    </div>
</ng-template>

<ng-template #accountActionsHeaderTemplate>
    <label aria-label="Options"></label>
</ng-template>

<ng-template #accountActionsTemplate let-data>
    <div #actionsDropdown="ngbDropdown" [attr.id]="'dropdown-' + data.paymentAccountID" class="ellipsis-group"
            ngbDropdown [container]="'body'">
        <button ngbDropdownToggle aria-label="Actions" class="btn btn-link p-0">
            <fa-icon [icon]="['far', 'ellipsis-h']" [fixedWidth]="true"></fa-icon>
        </button>
        <div class="action-menu" ngbDropdownMenu>
            <button ngbDropdownItem [attr.id]="'firstMenuItem-' + data.paymentAccountID" (click)="editAccount(data)">
                <fa-icon [icon]="['far', 'pencil-alt']" [fixedWidth]="true"></fa-icon>
                <span class="ms-2"></span>
                View/Edit Payment Account
            </button>
            <button
                *ngIf="hasAcctMgmtPermission && (data.isActive || data.active) && canRemoveAccount(data)"
                ngbDropdownItem
                (click)="confirmArchive(data, true)"
            >
                <fa-icon [icon]="['far', 'archive']" [fixedWidth]="true"></fa-icon>
                <span class="ms-2"></span>
                Remove Payment Account
            </button>
            <button
                *ngIf="hasAcctMgmtPermission && !(data.isActive || data.active) && data.accountType != 'frost bank'"
                ngbDropdownItem
                (click)="confirmArchive(data, false)"
            >
                <fa-icon [icon]="['far', 'archive']" [fixedWidth]="true"></fa-icon>
                <span class="ms-2"></span>
                Activate Payment Account
            </button>
            <div *ngIf="isSuperUser" class="dropdown-divider"></div>
            <button
                *ngIf="isSuperUser"
                ngbDropdownItem
                (click)="copyID(data.paymentAccountID)"
            >
                Copy ID: {{ data.paymentAccountID }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #accountDefaultTemplate let-data>
    <fa-icon *ngIf="isDefaultAccount(data)" [icon]="['far', 'check']" [fixedWidth]="true"
             ngbTooltip="Payment account is set as a default" placement="left" container="body"></fa-icon>
</ng-template>
