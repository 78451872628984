import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren
} from "@angular/core";
import { ModalButton, SfValidators } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UserOrgService } from "@sf/userorg/common";
import { Observable, Subject } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { GrowlService } from "@sf/common";
import { AnswersList, QuestionList } from "@sf/userorg/common";

@Component({
    selector: "sf-edit-security-questions-settings-personal-dialog",
    templateUrl:
        "./edit-security-questions-settings-personal-dialog.component.html",
    styleUrls: [
        "./edit-security-questions-settings-personal-dialog.component.scss"
    ]
})
export class EditSecurityQuestionsSettingsPersonalDialogComponent
    implements OnInit, OnDestroy
{
    @Input() userID: string;
    @ViewChildren("answer") securityAnswerInputs: QueryList<ElementRef>;

    private _onDestroy: Subject<void> = new Subject<void>();

    securityQuestionsForm: UntypedFormGroup;
    primary: ModalButton;
    secondary: ModalButton;
    loaded: boolean;
    questionsList: QuestionList[] = [];
    questionCount: number[] = [1, 2, 3, 4];
    answersList: AnswersList[] = [];
    showChecked: boolean;
    questionsDontMatch: boolean = true;

    questionList$: Observable<any> = this._userorgService
        .getSecurityQuestionList()
        .pipe(
            map((questionsList: any) => {
                let questionsArray: QuestionList[] = [];
                Object.keys(questionsList).forEach((key: string) => {
                    questionsArray.push({
                        id: key,
                        question: questionsList[key]
                    });
                });
                return questionsArray;
            }),
            tap(() => {
                for (let i = 0; i < this.questionCount.length + 1; i++) {
                    this.answersList.push({
                        questionID: "",
                        answer: ""
                    });
                }
                this.loaded = true;
            })
        );

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _activeModal: NgbActiveModal,
        private _userorgService: UserOrgService,
        private _growlService: GrowlService
    ) {}

    ngOnInit() {
        this.securityQuestionsForm = this._formBuilder.group({
            currentPassword: ["", Validators.required],
            question0: ["", Validators.required],
            question1: ["", Validators.required],
            question2: ["", Validators.required],
            question3: ["", Validators.required],
            question4: ["", Validators.required],
            answer0: [
                "",
                {
                    validators: [
                        Validators.required,
                        Validators.minLength(4),
                        SfValidators.notAllWhitespace
                    ],
                    updateOn: "blur"
                }
            ],
            answer1: [
                "",
                {
                    validators: [
                        Validators.required,
                        Validators.minLength(4),
                        SfValidators.notAllWhitespace
                    ],
                    updateOn: "blur"
                }
            ],
            answer2: [
                "",
                {
                    validators: [
                        Validators.required,
                        Validators.minLength(4),
                        SfValidators.notAllWhitespace
                    ],
                    updateOn: "blur"
                }
            ],
            answer3: [
                "",
                {
                    validators: [
                        Validators.required,
                        Validators.minLength(4),
                        SfValidators.notAllWhitespace
                    ],
                    updateOn: "blur"
                }
            ],
            answer4: [
                "",
                {
                    validators: [
                        Validators.required,
                        Validators.minLength(4),
                        SfValidators.notAllWhitespace
                    ],
                    updateOn: "blur"
                }
            ],
            showAnswersCheckbox: [false]
        });

        // Modal buttons
        this.primary = {
            text: "OK",
            disabled: true,
            callback: this.save.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.abort.bind(this)
        };

        this.catchFormChanges();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
    }

    save() {
        let questionAnswerMap: any = {};
        this.answersList.forEach((answer) => {
            if (answer.answer) {
                answer.answer = answer.answer.trim();
            }
            questionAnswerMap[answer.questionID] = answer.answer;
        });
        this.primary.disabled = true;
        this._userorgService
            .saveSecurityAnswers(
                this.userID,
                this.securityQuestionsForm.get("currentPassword").value,
                questionAnswerMap
            )
            .subscribe(
                (response) => {
                    if (response && response.locked) {
                        window.location.href =
                            "/sf/ui/login?err=You%20are%20locked%20out";
                    } else {
                        this.abort();
                        this._growlService.success("Security questions saved.");
                    }
                },
                () => {
                    this.primary.disabled = false;
                }
            );
    }

    abort() {
        this._activeModal.close(false);
    }

    catchFormChanges() {
        // Watch for changes in the questions and validate
        this.securityQuestionsForm
            .get("question0")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                let q1 = this.securityQuestionsForm.get("question1").value;
                let q2 = this.securityQuestionsForm.get("question2").value;
                let q3 = this.securityQuestionsForm.get("question3").value;
                let q4 = this.securityQuestionsForm.get("question4").value;
                if (val === q1 || val === q2 || val === q3 || val === q4) {
                    this.questionsDontMatch = false;
                    this._growlService.warning(
                        "You cannot use the same question twice."
                    );
                } else {
                    this.questionsDontMatch = true;
                    this.answersList[0].questionID = val;
                }
            });
        this.securityQuestionsForm
            .get("question1")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                let q0 = this.securityQuestionsForm.get("question0").value;
                let q2 = this.securityQuestionsForm.get("question2").value;
                let q3 = this.securityQuestionsForm.get("question3").value;
                let q4 = this.securityQuestionsForm.get("question4").value;
                if (val === q0 || val === q2 || val === q3 || val === q4) {
                    this.questionsDontMatch = false;
                    this._growlService.warning(
                        "You cannot use the same question twice."
                    );
                } else {
                    this.questionsDontMatch = true;
                    this.answersList[1].questionID = val;
                }
            });
        this.securityQuestionsForm
            .get("question2")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                let q0 = this.securityQuestionsForm.get("question0").value;
                let q1 = this.securityQuestionsForm.get("question1").value;
                let q3 = this.securityQuestionsForm.get("question3").value;
                let q4 = this.securityQuestionsForm.get("question4").value;
                if (val === q0 || val === q1 || val === q3 || val === q4) {
                    this.questionsDontMatch = false;
                    this._growlService.warning(
                        "You cannot use the same question twice."
                    );
                } else {
                    this.questionsDontMatch = true;
                    this.answersList[2].questionID = val;
                }
            });
        this.securityQuestionsForm
            .get("question3")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                let q0 = this.securityQuestionsForm.get("question0").value;
                let q1 = this.securityQuestionsForm.get("question1").value;
                let q2 = this.securityQuestionsForm.get("question2").value;
                let q4 = this.securityQuestionsForm.get("question4").value;
                if (val === q0 || val === q1 || val === q2 || val === q4) {
                    this.questionsDontMatch = false;
                    this._growlService.warning(
                        "You cannot use the same question twice."
                    );
                } else {
                    this.questionsDontMatch = true;
                    this.answersList[3].questionID = val;
                }
            });
        this.securityQuestionsForm
            .get("question4")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                let q0 = this.securityQuestionsForm.get("question0").value;
                let q1 = this.securityQuestionsForm.get("question1").value;
                let q2 = this.securityQuestionsForm.get("question2").value;
                let q3 = this.securityQuestionsForm.get("question3").value;
                if (val === q0 || val === q1 || val === q2 || val === q3) {
                    this.questionsDontMatch = false;
                    this._growlService.warning(
                        "You cannot use the same question twice."
                    );
                } else {
                    this.questionsDontMatch = true;
                    this.answersList[4].questionID = val;
                }
            });

        // watch for changes in the answers and validate
        this.securityQuestionsForm
            .get("answer0")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                if (
                    this.securityQuestionsForm.get("answer0").errors &&
                    this.securityQuestionsForm.get("answer0").errors[
                        "minlength"
                    ]
                ) {
                    this._growlService.warning(
                        "Answers must be at least 4 characters long."
                    );
                } else {
                    this.answersList[0].answer = val;
                }
            });
        this.securityQuestionsForm
            .get("answer1")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                if (
                    this.securityQuestionsForm.get("answer1").errors &&
                    this.securityQuestionsForm.get("answer1").errors[
                        "minlength"
                    ]
                ) {
                    this._growlService.warning(
                        "Answers must be at least 4 characters long."
                    );
                } else {
                    this.answersList[1].answer = val;
                }
            });
        this.securityQuestionsForm
            .get("answer2")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                if (
                    this.securityQuestionsForm.get("answer2").errors &&
                    this.securityQuestionsForm.get("answer2").errors[
                        "minlength"
                    ]
                ) {
                    this._growlService.warning(
                        "Answers must be at least 4 characters long."
                    );
                } else {
                    this.answersList[2].answer = val;
                }
            });
        this.securityQuestionsForm
            .get("answer3")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                if (
                    this.securityQuestionsForm.get("answer3").errors &&
                    this.securityQuestionsForm.get("answer3").errors[
                        "minlength"
                    ]
                ) {
                    this._growlService.warning(
                        "Answers must be at least 4 characters long."
                    );
                } else {
                    this.answersList[3].answer = val;
                }
            });
        this.securityQuestionsForm
            .get("answer4")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                if (
                    this.securityQuestionsForm.get("answer4").errors &&
                    this.securityQuestionsForm.get("answer4").errors[
                        "minlength"
                    ]
                ) {
                    this._growlService.warning(
                        "Answers must be at least 4 characters long."
                    );
                } else {
                    this.answersList[4].answer = val;
                }
            });

        // watch for changes to the show answers checkbox
        this.securityQuestionsForm
            .get("showAnswersCheckbox")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                this.showChecked = !this.showChecked;
                this.securityAnswerInputs.forEach((input: ElementRef) => {
                    input.nativeElement.type = this.showChecked
                        ? "text"
                        : "password";
                });
            });

        // watch for changes to the currentPassword field
        this.securityQuestionsForm
            .get("currentPassword")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.primary.disabled = !(
                    this.questionsDontMatch && this.securityQuestionsForm.valid
                );
            });

        // watch for changes to the securityQuestionForm to activate/deactivate ok button
        this.securityQuestionsForm.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                const currentPasswordValid =
                    this.securityQuestionsForm.get("currentPassword").valid;
                const question0valid =
                    this.securityQuestionsForm.get("question0").valid;
                const question1Valid =
                    this.securityQuestionsForm.get("question1").valid;
                const question2Valid =
                    this.securityQuestionsForm.get("question2").valid;
                const question3Valid =
                    this.securityQuestionsForm.get("question3").valid;
                const question4Valid =
                    this.securityQuestionsForm.get("question4").valid;
                const answer0Valid =
                    this.securityQuestionsForm.get("answer0").valid;
                const answer1Valid =
                    this.securityQuestionsForm.get("answer1").valid;
                const answer2Valid =
                    this.securityQuestionsForm.get("answer2").valid;
                const answer3Valid =
                    this.securityQuestionsForm.get("answer3").valid;
                const answer4Valid =
                    this.securityQuestionsForm.get("answer4").valid;

                this.primary.disabled = !(
                    this.questionsDontMatch &&
                    currentPasswordValid &&
                    question0valid &&
                    question1Valid &&
                    question2Valid &&
                    question3Valid &&
                    question4Valid &&
                    answer0Valid &&
                    answer1Valid &&
                    answer2Valid &&
                    answer3Valid &&
                    answer4Valid
                );
            });
    }

    onFocus(event: any) {
        if (event.target.type === "password") {
            event.target.type = "text";
        }
    }

    onBlur(event: any) {
        if (this.showChecked && event.target.type === "password") {
            event.target.type = "text";
        } else if (!this.showChecked && event.target.type === "text") {
            event.target.type = "password";
        }
    }
}
