import {
    Directive,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    SimpleChanges
} from "@angular/core";
import { guid } from "../helpers/guid";

@Directive({
    selector: "[sfAriaDescription]"
})
export class AriaDescriptionDirective implements OnInit {
    /* Private Variables */
    private _id = guid();
    private _descriptionEl: HTMLElement;

    @Input("sfAriaDescription") description: string;

    constructor(private _el: ElementRef, private _renderer: Renderer2) {}

    ngOnInit() {
        this._descriptionEl = this._renderer.createElement("span");
        this._renderer.addClass(this._descriptionEl, "visually-hidden");
        this._renderer.setProperty(
            this._descriptionEl,
            "innerHTML",
            this.description
        );
        this._renderer.setAttribute(this._descriptionEl, "id", this._id);
        this._renderer.appendChild(
            (this._el.nativeElement as HTMLElement).parentElement,
            this._descriptionEl
        );
        this._renderer.setAttribute(
            this._el.nativeElement,
            "aria-describedby",
            this._id
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this._descriptionEl) {
            this._renderer.setProperty(
                this._descriptionEl,
                "innerHTML",
                changes.description.currentValue
            );
        }
    }
}
