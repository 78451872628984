import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
    dayjs,
    FileUploadService,
    generateRandomString,
    GrowlService
} from "@sf/common";
import { DynamicFormStore } from "@sf/dynamic-form-viewer";
import {
    CustomFormFieldTransformerNewService,
    CustomFormService
} from "@sf/custom-form";
import {
    ErecordDocumentDetails,
    ErecordHighlightService
} from "@sf/document/erecord-viewer/common";
import { InputAutoCompleteComponent } from "../../components/input-auto-complete/input-auto-complete.component";
import { map } from "rxjs/operators";
import { AbstractControl, UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";

@Component({
    selector: "sf-custom-upload-field",
    templateUrl: "./custom-upload-field.component.html",
    styleUrls: ["./custom-upload-field.component.scss"]
})
export class CustomUploadFieldComponent
    extends InputAutoCompleteComponent
    implements OnInit, OnDestroy
{
    @Input()
    docLeftData?: ErecordDocumentDetails;

    randomName: string = generateRandomString(5);
    uploadComplete: boolean = false;
    isRequired$: Observable<boolean>;
    fileName: string;
    uploadDate: string;

    private _groupPath: string;

    constructor(
        protected _formFacade: DynamicFormStore,
        protected _customFormService: CustomFormService,
        protected _highlightService: ErecordHighlightService,
        private _growlService: GrowlService,
        private _fileUploadService: FileUploadService,
        private _transformerService: CustomFormFieldTransformerNewService
    ) {
        super(_formFacade, _customFormService, _highlightService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.isRequired$ = this.formControl$.pipe(
            map((control) => {
                const validator = control.validator?.({} as AbstractControl);
                return validator?.required;
            })
        );
        this._groupPath = this.field.fullPath.split(this.field.path)[0];
        this._formFacade
            .getFormControl(this._groupPath + "file_name")
            .subscribe((filenameField: UntypedFormControl) => {
                if (filenameField.value) {
                    this.fileName = filenameField.value;
                    this.uploadComplete = true;
                }
            });
        this._formFacade
            .getFormControl(this._groupPath + "upload_date")
            .subscribe((uploadDateField: UntypedFormControl) => {
                if (uploadDateField.value) {
                    this.uploadDate = uploadDateField.value;
                }
            });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    showHighlight() {
        super.showHighlight();
    }

    hideHighlight() {
        super.hideHighlight();
    }

    onFileSelected(event: any) {
        if (
            event &&
            event.target &&
            event.target.files &&
            event.target.files[0]
        ) {
            let file = event.target.files[0];
            this.fileName = file.name;
            this.uploadDate = dayjs().toString();
            if (file.type !== "application/pdf") {
                this._growlService.error(
                    "PDF is the only acceptable file format."
                );
                return;
            }
            this._fileUploadService
                .uploadWithPostUploadAction(
                    file,
                    this._transformerService.documentID$.value +
                        "/" +
                        this.field.toolTip,
                    "addPagesToDocument",
                    "/sf/uploadFile"
                )

                .subscribe(
                    (data: any) => {
                        this._growlService.success("Uploaded successfully.");
                        this.uploadComplete = true;
                        this.fileName = file.name ?? "";
                        this.uploadDate = dayjs().format(
                            "MM/DD/YYYY HH:mm:ss A z"
                        );
                        this._formFacade
                            .getFormControl(this._groupPath + "file_name")
                            .subscribe((filenameField: UntypedFormControl) => {
                                // may need to fix this
                                filenameField.setValue(this.fileName);
                            });
                        this._formFacade
                            .getFormControl(this._groupPath + "upload_date")
                            .subscribe(
                                (uploadDateField: UntypedFormControl) => {
                                    uploadDateField.setValue(this.uploadDate);
                                }
                            );
                        event.target.value = "";
                    },
                    () => {
                        this.uploadComplete = false;
                    }
                );
        }
    }
}
