import { Component, Input, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { BadgeType, BadgeValue } from "../../interfaces/nav-badge.interface";
import { distinctUntilChanged, shareReplay } from "rxjs/operators";

@Component({
    selector: "sf-nav-badge",
    templateUrl: "./nav-badge.component.html",
    styleUrls: ["./nav-badge.component.scss"]
})
export class NavBadgeComponent implements OnInit {
    @Input()
    value: Observable<BadgeValue> | BadgeValue;
    @Input()
    type: Observable<BadgeType> | BadgeType;
    @Input()
    extras: any;
    @Input()
    label?: Observable<BadgeValue> | BadgeValue;

    badgeType$: Observable<BadgeType>;
    value$: Observable<BadgeValue>;
    label$: Observable<BadgeValue>;

    showZeroCount = true;
    useDarkText = false;

    constructor() {}

    ngOnInit() {
        this.showZeroCount = this.extras ? this.extras.showZeroCount : true;

        if (!this.type) {
            this.type = BadgeType.Default;
        }

        if (typeof this.value === "number" || typeof this.value === "string") {
            this.value$ = of(this.value);
        } else if (this.value) {
            this.value$ = this.value.pipe(
                shareReplay({ bufferSize: 1, refCount: true })
            );
        }

        if (typeof this.type === "string") {
            this.badgeType$ = of(this.type) as Observable<BadgeType>;
            this.useDarkText = this.shouldUseDarkText(this.type);
        } else {
            this.badgeType$ = this.type;
            this.type
                .pipe(distinctUntilChanged())
                .subscribe(
                    (type: BadgeType) =>
                        (this.useDarkText = this.shouldUseDarkText(type))
                );
        }

        if (this.label) {
            if (
                typeof this.label === "number" ||
                typeof this.label === "string"
            ) {
                this.label$ = of(this.label);
            } else {
                this.label$ = this.label.pipe(
                    shareReplay({ bufferSize: 1, refCount: true })
                );
            }
        } else if (this.value$) {
            this.label$ = this.value$;
        }
    }

    shouldUseDarkText(type: BadgeType) {
        return [BadgeType.Light, BadgeType.Warning].includes(type);
    }
}
