import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Injector,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {
    ControlValueAccessor,
    UntypedFormControl,
    NG_VALUE_ACCESSOR,
    NgControl,
    NgModel
} from "@angular/forms";
import { isNumber } from "../../../helpers/number";
import { isString } from "../../../helpers/string";
import { isEmpty } from "../../../helpers/object";

const CUSTOM_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CurrencyInputComponent),
    multi: true
};

@Component({
    selector: "sf-currency-input",
    templateUrl: "./currency-input.component.html",
    styleUrls: ["./currency-input.component.scss"],
    providers: [CUSTOM_VALUE_ACCESSOR]
})
export class CurrencyInputComponent
    implements OnInit, AfterViewInit, ControlValueAccessor
{
    /** I/O **/
    @Input()
    value: number;
    @Input()
    inputName: string;
    @Input()
    inputId: string;
    @Input()
    currencyMaxDigits?: number;
    @Input()
    currencyMaxDecimal?: number;
    @Input()
    forceNegative?: boolean;
    @Input()
    forcePositive?: boolean;
    @Input()
    defaultNegative?: boolean;
    @Input()
    allowZero = true;
    @Input()
    disabled: boolean;
    @Input()
    required: boolean;
    @Input()
    readonly: boolean;
    @Input()
    tabindex: number;
    @Input()
    placeholder?: string;
    @Input()
    rightAlign: boolean;
    // @Input()
    // reset?: any;
    @Output()
    blur: EventEmitter<any> = new EventEmitter();
    @Output()
    focus: EventEmitter<any> = new EventEmitter();

    @ViewChild("inputModel")
    inputModel: NgModel;

    /** Private Variables **/
    private _myFormControl: UntypedFormControl;
    private _onChange: (_: any) => {};
    private _onTouched: () => {};
    private _previousValue: any = null;
    private _previousNegative: any = null;
    private _thousandSeparator = ",";

    /** Public Variables **/
    model: any = null;
    isNegative = false;
    isValid = true;
    ariaDescription = "";

    /** View Children **/

    constructor(private _el: ElementRef, private _injector: Injector) {}

    /** Lifecycle Hooks **/

    ngOnInit() {
        this.forceNegative = this.forceNegative || false;
        this.forcePositive = this.forcePositive || false;
        if (this.forcePositive && this.forceNegative) {
            throw new Error(
                "You can not use both forceNegative and forcePositive on the same current input"
            );
        }
        this.currencyMaxDecimal = this.currencyMaxDecimal ?? 2;
        this.currencyMaxDigits =
            this.currencyMaxDigits < 16 ? this.currencyMaxDigits : 15;
        this.defaultNegative = this.defaultNegative || false;
    }

    ngAfterViewInit(): void {
        const ngControl: NgControl = this._injector.get(NgControl, null);
        if (ngControl) {
            this._myFormControl = ngControl.control as UntypedFormControl;
            this.inputModel.control.valueChanges.subscribe(() => {
                if (this._myFormControl?.validator) {
                    this.inputModel.control.setErrors(
                        this._myFormControl.validator(this.inputModel.control)
                    );
                }
            });
        } // else Component is missing form control binding, don't do anything
    }

    /** Public Methods **/

    getAsFloat(value: any): number {
        if (isNumber(value)) {
            return value;
        }

        return parseFloat(value);
    }

    onBlur($event: any) {
        if (this.model !== null) {
            this.model = this._addThousandSeparators(
                this._fixDecimal(this._removeCommas(this.model))
            );
        }

        this.blur.emit($event);
        this._onTouched();
    }

    onFocus($event: any) {
        if (this.model !== null) {
            let curLen = this.model.length;
            let inputEl = this._el.nativeElement.querySelector("input");
            let selected = inputEl.selectionStart !== inputEl.selectionEnd;
            this.model = this._removeCommas(this.model);
            if (selected && this.model.length !== curLen) {
                // Was selected on entry AND commas were added - need to reselect all text
                setTimeout(() => {
                    inputEl.select();
                });
            }
        }
        this.focus.emit($event);
    }

    onKeyPress($event: any, value: any): void {
        let keyCode = $event.which || $event.keyCode; // keyCode is deprecated, so use 'which' if defined.
        let decimalSplit = value.split(".");
        let inputEl = this._el.nativeElement.querySelector("input");
        let hasDecimal = decimalSplit.length > 1;

        let maxDigitsTooLong =
            decimalSplit[0].length + 1 > this.currencyMaxDigits;
        let maxDecimalTooLong = hasDecimal
            ? decimalSplit[1].length + 1 > this.currencyMaxDecimal
            : false;
        let selectorBeforeDecimal = hasDecimal
            ? inputEl.selectionStart <= decimalSplit[0].length
            : true;
        let selectorAfterDecimal = hasDecimal
            ? inputEl.selectionStart >= decimalSplit[0].length + 1
            : false;

        if ($event.key === "Backspace" || $event.key === "Delete") {
            // Firefox uses keyCode 8 for Backspace and 46 for Delete.
            // Chrome handles Delete and Backspace during the keydown event.
        } else if (
            keyCode === 45 || // minus sign '-'
            (keyCode === 40 && $event.shiftKey === true) || // left paren '('
            (keyCode === 41 && $event.shiftKey === true) // right paren ')'
        ) {
            $event.preventDefault();
            if (!this.forcePositive && !this.forceNegative) {
                this.isNegative = !this.isNegative;
                this.setCurrencyValue(value);
            }
        } else if (keyCode === 46 && $event.shiftKey === false) {
            // period '.'
            let wouldBeString = this.model + ".";
            let split = wouldBeString.split(".");
            if (split.length > 2) {
                $event.preventDefault();
            }
        } else if (
            (!$event.metaKey &&
                !$event.ctrlKey &&
                !$event.altKey &&
                $event.which &&
                ($event.which < 46 || $event.which > 57)) ||
            (selectorBeforeDecimal && maxDigitsTooLong) ||
            (selectorAfterDecimal && maxDecimalTooLong)
        ) {
            $event.preventDefault();
        }
    }

    setCurrencyValue(value: any): void {
        this.model = value;

        if (value === "" || value === ".") {
            // this.model = 0;
            value = 0.0;
        }

        let valueAsNumber: number = this.getAsFloat(this._removeCommas(value));

        if (this.isNegative) {
            valueAsNumber = valueAsNumber * -1;
            this._onChange(valueAsNumber);
        } else {
            this._onChange(valueAsNumber);
        }

        this.ariaDescription = `${this.isNegative ? "-" : ""}$${value}`;

        // this._onTouched();
    }

    // ControlValue Accessor support functions

    writeValue(obj: any): void {
        this.value = obj;
        this._setInternalModelFromValue();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**  Private Methods  **/

    private _addThousandSeparators(value: any) {
        if (!isString(value)) {
            return value;
        }

        let pieces = value.split(".");

        pieces[0] = pieces[0].replace(
            /\B(?=(\d{3})+(?!\d))/g,
            this._thousandSeparator
        );

        return pieces.join(".");
    }

    private _fixDecimal(value: string) {
        let split = value.replace(/^[0]+/g, "").split(".");

        if (isEmpty(split[0]) && isEmpty(split[1])) {
            return this.allowZero ? "0" : "";
        } else if (this.currencyMaxDecimal <= 1) {
            return (+value).toFixed(this.currencyMaxDecimal);
        }

        if (isEmpty(split[0]) && !isEmpty(split[1])) {
            split[0] = "0";
        }

        if (!isEmpty(split[1]) && split[1].length === 1) {
            split[1] += "0";
        } else if (isEmpty(split[1])) {
            split[1] = "00";
        }

        return split.join(".");
    }

    private _getModelAsNumber() {
        return this.getAsFloat(this.model);
    }

    private _removeCommas(value: any) {
        if (typeof value.replace === "function") {
            return value.replace(/[^0-9.]+/g, "").replace(/\.{2,}/, ".");
        }

        return value;
    }

    private _setInternalModelFromValue() {
        // Allow null values for showing placeholder text instead of a default value
        if (isString(this.value) || this.value === undefined) {
            this.value = this.model = 0;
        } else if (typeof this.value === "number" && this.value < 0) {
            // This check is necessary so that editing an already negative number is handled correctly
            this.isNegative = true;
        }

        if (this.value !== null) {
            this.model = this._addThousandSeparators(
                this._fixDecimal(this._removeCommas(this.value.toString()))
            );

            this.isNegative = this.forcePositive
                ? false
                : this._getModelAsNumber() < 0 ||
                  this.forceNegative ||
                  this.isNegative;
        }
        this._previousValue = this.model;
    }

    private _setInternalValue() {
        this._setInternalModelFromValue();
        this._previousNegative = this.isNegative;
    }
}
