import { Injectable } from "@angular/core";
import {
    ConnectionPositionPair,
    FlexibleConnectedPositionStrategyOrigin,
    Overlay,
    OverlayConfig,
    OverlayPositionBuilder,
    PositionStrategy
} from "@angular/cdk/overlay";
import { CdkPortal } from "@angular/cdk/portal";
import { FilterPanelOverlayRef } from "./filter-panel-overlay-ref";
import { Observable, Subject } from "rxjs";
import { FilterPanelPositionStrategy } from "./filter-panel-properties";

export interface FilterPanelDialogConfig {
    panelClass?: string;
    backdropClass?: string;
    origin?: FlexibleConnectedPositionStrategyOrigin;
    positionStrategy: FilterPanelPositionStrategy;
}

const DEFAULT_CONFIG: FilterPanelDialogConfig = {
    backdropClass: "filter-panel-backdrop",
    panelClass: "filter-panel",
    origin: null,
    positionStrategy: FilterPanelPositionStrategy.GLOBAL
};

@Injectable()
export class FilterPanelOverlayService {
    private _backdropClick: Subject<void> = new Subject<void>();
    backdropClick$: Observable<void> = this._backdropClick.asObservable();

    constructor(private overlay: Overlay) {}

    open(portal: CdkPortal, config: FilterPanelDialogConfig) {
        const dialogConfig = { ...DEFAULT_CONFIG, ...config };
        const overlayRef = this._createOverlay(dialogConfig);
        const dialogRef = new FilterPanelOverlayRef(overlayRef);

        overlayRef.attach(portal);
        overlayRef.backdropClick().subscribe((_) => this._backdropClick.next());
        return dialogRef;
    }

    private _createOverlay(config: FilterPanelDialogConfig) {
        return this.overlay.create({
            hasBackdrop: true,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            positionStrategy: this._getPositionStrategy(config),
            scrollStrategy: this.overlay.scrollStrategies.reposition()
        } as OverlayConfig);
    }

    private _getPositionStrategy(
        config: FilterPanelDialogConfig
    ): PositionStrategy {
        let positionStrategy: OverlayPositionBuilder = this.overlay.position();

        if (
            config.positionStrategy &&
            config.positionStrategy === FilterPanelPositionStrategy.CONNECTED
        ) {
            return positionStrategy
                .flexibleConnectedTo(config.origin)
                .withFlexibleDimensions(true)
                .withPositions([
                    new ConnectionPositionPair(
                        { originX: "start", originY: "bottom" },
                        { overlayX: "start", overlayY: "top" },
                        -20,
                        -22,
                        "filter-panel"
                    )
                ]);
        } else {
            return positionStrategy
                .global()
                .centerHorizontally()
                .centerVertically();
        }
    }
}
