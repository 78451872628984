<div class="logo-part">
    <img src="/sf/ui/images/sf-logo-white.png" alt=""/>
</div>
<div *ngIf="!loaded">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
    <span> Loading...</span>
</div>
<div *ngIf="loaded && loadFailed">
    <div class="alert alert-warning">
        {{loadFailed}}
    </div>
</div>
<div *ngIf="loaded && !loadFailed" class="like-table row">
    <!-- LEFT SIDE -->
    <div class="left-side">
        <div class="head-text">
            {{wizardSteps[currentStep].title}}
        </div>
        <!-- ERROR MESSAGES -->
        <div class="error-row" [hidden]="!formErrorMessages || !formErrorMessages.length">
            <div>Please correct the following errors:</div>
            <ul>
                <li *ngFor="let message of formErrorMessages">
                    <span>{{message}}</span>
                </li>
            </ul>
        </div>
        <!-- STEP 0 - intro -->
        <div *ngIf="currentStep==0">
            <div class="intro">
                <b>{{fullName}} has been requested to accept a license agreement for</b>
            </div>
            <div class="role-list">
                <div><b>{{organizationName}}</b></div>
            </div>
            <div class="button-row">
                <button class="btn btn-primary" (click)="submitCurrentForm()">
                    Continue
                </button>
                <button class="btn btn-link" (click)="doCancel()">
                    Cancel Invitation
                </button>
            </div>
        </div>
        <!-- STEP 1 - confirm info -->
        <form [formGroup]="personalForm" *ngIf="currentStep==1">
            <div class="row">
                <div class="mb-3 form-group col-md-6">
                    <label for="firstName">First Name</label>
                    <input type="text" id="firstName" name="firstName" placeholder="Required"
                        class="form-control"
                        formControlName="firstName" required/>
                </div>
                <div class="mb-3 form-group col-md-6">
                    <label for="lastName">Last Name</label>
                    <input type="text" id="lastName" name="lastName" placeholder="Required"
                        class="form-control"
                        formControlName="lastName" required/>
                </div>
            </div>
            <div class="row">
                <div class="mb-3 form-group col-md-6">
                    <label for="email">Email Address</label>
                    <input type="text" id="email" name="email" placeholder="Required"
                        class="form-control"
                        formControlName="email" required/>
                </div>
                <div class="mb-3 form-group col-md-6">
                    <label for="title">Job Title</label>
                    <input type="text" id="title" name="title"
                        class="form-control"
                        formControlName="title"/>
                </div>
            </div>
            <!--
            <div class="row">
                <div class="mb-3 form-group col-md-6">
                    <label for="phone">Phone Number</label>
                    <input type="tel" id="phone" name="phone" placeholder="Required"
                        class="form-control" sfPhoneInput
                        formControlName="phone" required/>
                </div>
                <div class="mb-3 form-group col-md-3">
                    <label for="extension">Extension</label>
                    <input type="text" id="extension" name="extension" placeholder=""
                        class="form-control"
                        formControlName="extension"/>
                </div>
            </div>
            -->
            <div class="button-row">
                <button class="btn btn-primary" (click)="submitCurrentForm()">Continue</button>
                <button class="btn btn-secondary" (click)="goToStep(0)">Back</button>
            </div>
        </form>
        <!-- STEP 2 - sign license -->
        <div *ngIf="currentStep==2">
            <div class="sub-head-text">
                <span>{{organizationName}}</span>
            </div>
            <sf-sign-license [contractId]="contractID" [signerData]="signerData"
                    (actionTaken)="licenseAction($event)"></sf-sign-license>
        </div>
        <!-- STEP 3 - make payment -->
        <sf-pay-license-fee *ngIf="currentStep==3"
            [contractId]="addendumID"
            [tokenID]="tokenID"
            [organizationID]="organizationID"
            [serviceName]="serviceName"
            [licenseFeeAmount]="licenseFeeAmount"
            (actionTaken)="paymentAction($event)">
        </sf-pay-license-fee>
        <!-- STEP 4 - done -->
        <div *ngIf="currentStep==4">
            <div>
                Thank you
            </div>
        </div>
    </div>
    <!-- RIGHT SIDE -->
    <div class="right-side">
        <div>
            <div class="wizard-step" [ngClass]="{'current-step':(currentStep==0), 'done-step':(currentStep>0)}">
                <span (click)="stepClick(0)">
                    <span class="badge">1</span>
                    <span>Acceptance Requested</span>
                </span>
            </div>
            <div class="step-connector"></div>
            <div class="wizard-step" [ngClass]="{'current-step':(currentStep==1), 'done-step':(currentStep>1)}">
                    <span (click)="stepClick(1)">
                        <span class="badge">2</span>
                        <span>Confirm Contact Info</span>
                    </span>
            </div>
            <div class="step-connector"></div>
            <div class="wizard-step" [ngClass]="{'current-step':(currentStep==2), 'done-step':(currentStep>2)}">
                    <span (click)="stepClick(2)">
                        <span class="badge">3</span>
                        <span>Accept Agreement</span>
                    </span>
            </div>
            <div *ngIf="licenseFeeAmount">
                <div class="step-connector"></div>
                <div class="wizard-step" [ngClass]="{'current-step':(currentStep==3), 'done-step':(currentStep>3)}">
                    <span (click)="stepClick(3)">
                        <span class="badge">4</span>
                        <span>License Fee</span>
                    </span>
                </div>
            </div>
        </div>
        <div class="chat">
            <div class="titler">Questions?</div>
            <div>Call us at <a href="tel:800.460.5657">800.460.5657</a> and press option 3.</div>
            <div>We are available from 6 a.m. to 6 p.m. MT.</div>
        </div>
    </div>
</div>
