import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { UntypedFormBuilder } from "@angular/forms";
import {
    DynamicFormStore,
    FieldOption,
    InputComponent
} from "@sf/dynamic-form-viewer";

@Component({
    selector: "sf-custom-party-type-field",
    templateUrl: "./custom-party-type-field.component.html",
    styleUrls: ["./custom-party-type-field.component.scss"]
})
export class CustomPartyTypeFieldComponent
    extends InputComponent
    implements OnInit
{
    @Output()
    onSelection: EventEmitter<string | number> = new EventEmitter();

    options$: Observable<FieldOption[]>;

    constructor(
        private _fb: UntypedFormBuilder,
        protected _facade: DynamicFormStore
    ) {
        super(_facade);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.options$ = this._facade
            .getViewStateForField(this.field.fullPath)
            .pipe(map((viewState) => viewState?.options || []));
    }

    // IMPORTANT: this even must occur before the actual change occurs
    // which is why we are doing onClick, instead of onChange
    selectionClicked(selection: string | number) {
        this.onSelection.emit(selection);
    }
}
