<div *ngIf="vm$ | async as vm">
    <div class="rejection-reasons-form" cdkDropList (cdkDropListDropped)="drop($event)" [formGroup]="rejectionReasonsForm">
        <ng-container formArrayName="reasons">
            <div class="rejection-reason-row row gx-3" cdkDrag [cdkDragDisabled]="editing" *ngFor="let control of rejectionReasonsFormArray.controls; let $index = index" [formGroupName]="$index">
                <div class="col-1 reason-number">
                    {{$index + 1}}.
                </div>
                <div class="col-10" sfClickOff (onClickOff)="setEditing(false)">
                    <textarea formControlName="reason" [attr.aria-label]="$index + 1" (mousedown)="setEditing(true)"></textarea>
                </div>
                <div class="col-1 rejection-reason-actions">
                    <fa-icon [icon]="['far', 'bars']"
                             ngbTooltip="Drag and drop to change order"
                             placement="left"></fa-icon>
                    <fa-icon class="delete-reason ms-2" [icon]="['far', 'trash-alt']" (click)="deleteRejectionReason($index)"></fa-icon>
                </div>
            </div>
        </ng-container>
    </div>
    <br/>
    <div class="row">
        <div class="col-3">
            <button type="button" class="btn btn-secondary" (click)="addRejectionReason()"><fa-icon [icon]="['far', 'plus']"></fa-icon> Add Rejection Reason  </button>
        </div>
    </div>
</div>
