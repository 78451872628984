<div class="sf-unmask">
    <sf-modal-header title="Email Address"></sf-modal-header>
    <div class="modal-body">
        <div class="input-error row" [ngClass]="{'on': warning}">
            {{warning}}
        </div>
        <div *ngIf="!!user">
            <div class="esection">
                <div>Your current email address is:</div>
                <div class="bordered">{{user.email}}</div>
                <div *ngIf="!!user.emailVerifiedDate" class="small-text">Verified on {{prettyVerifiedDate}}</div>
            </div>
            <div *ngIf="!!user.unverifiedEmail && !processing" class="esection">
                <div>We have a request to change your email address to:</div>
                <div class="bordered">{{user.unverifiedEmail}}</div>
                <div class="small-text">It has not been verified yet</div>
                <div>
                    <button class="btn btn-link sf-btn-link small-text" (click)="requestVerify()">
                        Request an email to verify this email address
                    </button>
                </div>
            </div>
            <div class="mb-3">
                <label for="email">New email address:</label>
                <input type="email" id="email" name="email" class="form-control phone-input sf-mask" [(ngModel)]="newEmail"
                        maxlength="255"/>
            </div>
        </div>
    </div>
    <sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
</div>
