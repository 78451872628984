<sf-modal-header [title]="title"></sf-modal-header>
<div class="modal-body">
    <div *ngIf="this.primary.disabled" class="downloading-spinner">
        <fa-icon icon="spinner" [spin]="true"></fa-icon>
        Downloading
    </div>
    <form
        [formGroup]="downloadForm"
        [ngClass]="{ overlay: this.primary.disabled }"
    >
        <!-- print format -->
        <div class="mb-3">
            <label>Format</label>
            <div class="form-check-inline">
                <input
                    class="form-check-input"
                    type="radio"
                    name="printFormat"
                    id="printFormatPdf"
                    value="pdf"
                    formControlName="printFormat"
                />
                <label class="form-check-label" for="printFormatPdf">PDF</label>
            </div>
            <div class="form-check-inline">
                <input
                    class="form-check-input"
                    type="radio"
                    name="printFormat"
                    id="printFormatCsv"
                    value="csv"
                    formControlName="printFormat"
                />
                <label class="form-check-label" for="printFormatCsv">CSV</label>
            </div>
        </div>

        <!-- recipient filter -->
        <div *ngIf="orgID" class="mb-3">
            <label>Filter</label>
            <div class="form-check-inline">
                <input
                    class="form-check-input"
                    type="radio"
                    name="recipientFilter"
                    id="recipientFilterAll"
                    value="all"
                    formControlName="recipientFilter"
                />
                <label class="form-check-label" for="recipientFilterAll"
                    >All</label
                >
            </div>
            <div class="form-check-inline">
                <input
                    class="form-check-input"
                    type="radio"
                    name="recipientFilter"
                    id="recipientFilterRegistered"
                    value="registered"
                    formControlName="recipientFilter"
                />
                <label class="form-check-label" for="recipientFilterRegistered"
                    >Registered</label
                >
            </div>
            <div class="form-check-inline">
                <input
                    class="form-check-input"
                    type="radio"
                    name="recipientFilter"
                    id="recipientFilterEnabled"
                    value="enabled"
                    formControlName="recipientFilter"
                />
                <label class="form-check-label" for="recipientFilterEnabled"
                    >Enabled</label
                >
            </div>
        </div>

        <!-- secondary recipient toggle-->
        <div class="mb-3 btn-group btn-group-toggle">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="secondaryRecipients"
                    formControlName="secondaryRecipients"
                />
                <label class="form-check-label" for="secondaryRecipients"
                    >Include secondary recipients</label
                >
            </div>
        </div>
    </form>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
