export function roundToNearest(value: number, nearest: number) {
    return Math.round(value / nearest) * nearest;
}

export function isNumber(value: any) {
    return typeof value === "number" && isFinite(value);
}

// constrains a passed-in number to the min and max range.
// if it's already within that range, it returns the value unchanged
// if it's too small, it returns min
// if it's too large, it returns max
export function clamp(value: number, min: number, max: number): number {
    if (!isNumber(value) || !isNumber(min) || !isNumber(max)) {
        throw new Error("All arguments must be numbers");
    }
    if (max < min) {
        throw new Error("Maximum value must be greater than minimum value");
    }

    return Math.max(min, Math.min(max, value));
}
