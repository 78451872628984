<form class="settings-container sf-unmask">
    <!-- PROFILE -->
    <div class="mt-1">
        <h3 class="border-bottom pb-1 row">Personal Profile</h3>
        <div *ngIf="user">
            <div class="row wrapper border-bottom">
                <label for="firstName" class="col-md-4 col-sm-4 col-form-label">First Name</label>
                <div class="col-md-8 col-sm-8">
                    <input type="text" class="form-control editable sf-mask" id="firstName" name="firstName"
                            [(ngModel)]="user.name.firstName" (blur)="save()"
                            (keydown.enter)="$event.preventDefault()"
                            maxlength="82" placeholder="First Name">
                </div>
            </div>
            <div class="row wrapper border-bottom">
                <label for="middleName" class="col-md-4 col-sm-4 col-form-label">Middle Name</label>
                <div class="col-md-8 col-sm-8">
                    <input type="text" class="form-control editable sf-mask" id="middleName" name="middleName"
                            [(ngModel)]="user.name.middleName" (blur)="save()"
                            (keydown.enter)="$event.preventDefault()"
                            maxlength="82" placeholder="Middle Name">
                </div>
            </div>
            <div class="row wrapper border-bottom">
                <label for="lastName" class="col-md-4 col-sm-4 col-form-label">Last Name</label>
                <div class="col-md-8 col-sm-8">
                    <input type="text" class="form-control editable sf-mask" id="lastName" name="lastName"
                            [(ngModel)]="user.name.lastName" (blur)="save()"
                            (keydown.enter)="$event.preventDefault()"
                            maxlength="82" placeholder="Last Name">
                </div>
            </div>
            <div class="row wrapper border-bottom">
                <label for="prefix" class="col-md-4 col-sm-4 col-form-label">Prefix</label>
                <div class="col-md-8 col-sm-8 select-holder">
                    <sf-select id="prefix" placeholder="" [isSingleSelect]="true"
                            [hasClearButton]="false"
                            [selectedOption]="selectedPrefix" (select)="setPrefix($event)"
                            [options]="prefixOptions" trackBy="option" labelBy="label"></sf-select>
                </div>
            </div>
            <div class="row wrapper border-bottom">
                <label for="title" class="col-md-4 col-sm-4 col-form-label">Job Title</label>
                <div class="col-md-8 col-sm-8">
                    <input type="text" class="form-control editable" id="title" name="title"
                            [(ngModel)]="user.title" (blur)="save()"
                            (keydown.enter)="$event.preventDefault()"
                            maxlength="82" placeholder="Job Title">
                </div>
            </div>
            <div class="row wrapper border-bottom" *ngIf="allowSecondaryJobTitle">
                <label for="secondaryTitle" class="col-md-4 col-sm-4 col-form-label">Secondary Job Title</label>
                <div class="col-md-8 col-sm-8">
                    <input type="text" class="form-control editable" id="secondaryTitle" name="secondaryTitle"
                            [(ngModel)]="user.secondaryTitle" (blur)="save()"
                            (keydown.enter)="$event.preventDefault()"
                            maxlength="82" placeholder="Secondary Job Title">
                </div>
            </div>
            <div class="mb-3 row wrapper border-bottom" *ngIf="allowNameVariations">
                <label for="otherNames" class="col-md-4 col-sm-4 col-form-label">Name Variations<br/>(one per line)</label>
                <div class="col-md-8 col-sm-8">
                    <textarea type="text" class="form-control editable sf-mask" id="otherNames" name="otherNames"
                            [(ngModel)]="otherNames" (blur)="saveOtherNames()" placeholder="Name Variations">
                    </textarea>
                </div>
            </div>
            <div class="row wrapper border-bottom">
                <label for="image" class="col-md-4 col-sm-4 col-form-label">Profile Picture</label>
                <div *ngIf="!user.imageURL" class="col-md-7 col-sm-7 mt-1 ms-2">
                    <button type="button" class="btn btn-link sf-btn-link upload-btn" (click)="clickUploadProfilePic('upload')">
                        Upload Profile Picture
                    </button>
                </div>
                <ng-container *ngIf="user.imageURL">
                    <div class="col-md-7 col-sm-7 pb-2">
                        <div class="img-container ms-3">
                            <img [src]="user.imageURL" id="image" class="profile-image sf-exclude"
                                    alt="User photo"/>
                            <div class="img-overlay">
                                <button class="btn btn-link sf-btn-link btn-text" (click)="clickUploadProfilePic('edit')">
                                    Click to Edit
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-sm-1 button-box">
                        <button class="btn btn-link sf-btn-link" (click)="clickRemoveProfilePic()"
                                aria-label="Remove picture" ngbTooltip="Click to remove profile picture."
                                placement="right" tooltipClass="tooltip-interaction">
                            <fa-icon icon="times"></fa-icon>
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- CONTACT -->
    <div class="mt-4">
        <h3 class="border-bottom pb-1 row">Contact Information</h3>
        <div *ngIf="user">
            <div class="row wrapper border-bottom">
                <label for="phone" class="col-md-4 col-sm-4 col-form-label">Phone Number</label>
                <div class="col-md-8 col-sm-8">
                    <input type="text" class="form-control editable sf-mask" id="phone" name="phone"
                            [(ngModel)]="user.phoneString" (blur)="save()"
                            (keydown.enter)="$event.preventDefault()"
                            maxlength="22" placeholder="Phone Number">
                </div>
            </div>
            <div class="row wrapper border-bottom">
                <label for="extension" class="col-md-4 col-sm-4 col-form-label">Extension</label>
                <div class="col-md-8 col-sm-8">
                    <input type="text" class="form-control editable sf-mask" id="extension" name="extension"
                            [(ngModel)]="user.phoneExtension" (blur)="save()"
                            (keydown.enter)="$event.preventDefault()"
                            maxlength="9" placeholder="Extension">
                </div>
            </div>
            <div>
                <div class="row wrapper border-bottom">
                    <label class="col-md-4 col-sm-4 col-form-label">Mobile Phone Number</label>
                    <div class="col-md-7 col-sm-7">
                        <button class="btn btn-link sf-btn-link fake-link sf-mask" (click)="editMobilePhone()">
                            {{user.mobilePhoneString ? user.mobilePhoneString : 'Click to add'}}
                        </button>
                    </div>
                    <div class="col-md-1 col-sm-1 button-box">
                        <button *ngIf="!user.mobilePhoneString" class="btn btn-link sf-btn-link"
                                (click)="editMobilePhone()" aria-label="Edit mobile phone"
                                ngbTooltip="Mobile numbers can be used for two-factor authentication."
                                placement="right" tooltipClass="tooltip-interaction">
                            <fa-icon class="blue-button" icon="info-circle"></fa-icon>
                        </button>
                        <button *ngIf="!!user.mobilePhoneVerifiedDate" class="btn btn-link sf-btn-link"
                                (click)="editMobilePhone()" aria-label="Edit mobile phone"
                                ngbTooltip="Mobile phone verified on {{user.mobilePhoneVerifiedDate | date:'shortDate'}}."
                                placement="right" tooltipClass="tooltip-interaction">
                            <fa-icon icon="check-circle" class="green-button"></fa-icon>
                        </button>
                        <button *ngIf="!!user.mobilePhoneString && !user.mobilePhoneVerifiedDate" class="btn btn-link sf-btn-link"
                                (click)="editMobilePhone()" aria-label="Verify mobile phone"
                                ngbTooltip="Phone number is unverified. Click to verify."
                                placement="right" tooltipClass="tooltip-interaction">
                            <fa-icon icon="exclamation-circle" class="orange-button"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <div class="row wrapper border-bottom">
                    <label class="col-md-4 col-sm-4 col-form-label">Authenticator App</label>
                    <div class="col-md-7 col-sm-7">
                        <button class="btn btn-link sf-btn-link fake-link" (click)="registerAuthenticator()">
                            <span *ngIf="!totpRegistered">Click to register</span>
                            <span *ngIf="!!totpRegistered">Already registered</span>
                        </button>
                    </div>
                    <div class="col-md-1 col-sm-1 button-box">
                        <button *ngIf="!totpRegistered" class="btn btn-link sf-btn-link"
                                (click)="registerAuthenticator()" aria-label="Register authenticator app"
                                ngbTooltip="Authenticator apps (like Google Authenticator) can be used for two-factor authentication."
                                placement="right" tooltipClass="tooltip-interaction">
                            <fa-icon class="blue-button" icon="info-circle"></fa-icon>
                        </button>
                        <button *ngIf="!!totpRegistered" class="btn btn-link sf-btn-link"
                                (click)="registerAuthenticator()" aria-label="Register authenticator app"
                                ngbTooltip="You are already registered. Click to re-register or to unregister."
                                placement="right" tooltipClass="tooltip-interaction">
                            <fa-icon icon="check-circle" class="green-button"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row wrapper">
                <label for="verifiedEmail" class="col-md-4 col-sm-4 col-form-label">Primary Email</label>
                <div class="col-md-7 col-sm-7" id="verifiedEmail">
                    <button class="btn btn-link sf-btn-link fake-link" (click)="verifyEmail()">
                        <span>{{user.email}}</span>
                    </button>
                </div>
                <div class="col-md-1 col-sm-1 button-box">
                    <button *ngIf="isSharedEmail && !user.unverifiedEmail" class="btn btn-link sf-btn-link" (click)="verifyEmail()"
                            aria-label="Duplicate email address"
                            ngbTooltip="Email is shared with more than one user. Please update your email address or contact Support at 800.460.5657 to resolve."
                            placement="right" tooltipClass="tooltip-interaction">
                        <fa-icon class="red-button" icon="exclamation-circle"></fa-icon>
                    </button>
                    <button *ngIf="!isSharedEmail && user.emailVerifiedDate" class="btn btn-link sf-btn-link"
                            aria-label="Email verified" (click)="verifyEmail()"
                            ngbTooltip="Email verified on {{user.emailVerifiedDate | date:'shortDate'}}."
                            placement="right" tooltipClass="tooltip-interaction">
                        <fa-icon icon="check-circle" class="green-button"></fa-icon>
                    </button>
                </div>
            </div>
            <div *ngIf="!user.unverifiedEmail" class="row wrapper border-bottom">
            </div>
            <div *ngIf="!!user.unverifiedEmail" class="row wrapper border-bottom">
                <label for="unverifiedEmail" class="col-md-4 col-sm-4 email-label-2">
                    Requested Change
                </label>
                <div class="col-md-7 col-sm-7 email-box-2" id="unverifiedEmail">
                    <button class="btn btn-link sf-btn-link fake-link" (click)="verifyEmail()">
                        <span>{{user.unverifiedEmail}}</span>
                    </button>
                </div>
                <div class="col-md-1 col-sm-1 button-box-2">
                    <button class="btn btn-link sf-btn-link"
                            aria-label="Email not verified" (click)="verifyEmail()"
                            ngbTooltip="Email is not verified. Click to request verification email."
                            placement="right" tooltipClass="tooltip-interaction">
                        <fa-icon icon="exclamation-circle" class="orange-button"></fa-icon>
                    </button>
                </div>
            </div>
            <div *ngIf="user.address" class="row wrapper border-bottom">
                <label for="address" class="col-md-4 col-sm-4 col-form-label pb-3">Address</label>
                <div class="col-md-7 col-sm-7 pb-3" id="address">
                    <div class="address-box sf-mask">
                        <div *ngIf="user.address.streets">
                            {{user.address.streets[0]}}
                        </div>
                        <div *ngIf="user.address.streets && user.address.streets[1]">
                            {{user.address.streets[1]}}
                        </div>
                        <div *ngIf="user.address.city || user.address.state || user.address.zipCode">
                            <span *ngIf="user.address.city">{{user.address.city}}</span>
                            <span *ngIf="user.address.city && user.address.state">, </span>
                            <span *ngIf="user.address.state">{{user.address.state}}</span>
                            <span *ngIf="(user.address.city || user.address.state) && user.address.zipCode">&nbsp;</span>
                            <span *ngIf="user.address.zipCode">{{user.address.zipCode}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-1 col-sm-1 button-box">
                    <button class="btn btn-link sf-btn-link"
                            (click)="clickEditPersonalContactAddress()" aria-label="Edit address">
                        <fa-icon icon="edit"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- APP -->
    <div class="mt-4">
        <h3 class="border-bottom pb-1 row">Personal Application Settings</h3>
        <div *ngIf="user">
            <div class="row wrapper border-bottom pt-2"
                    *ngIf="showDefaultOrg && accessibleSubmitterOrgIDs.length > 1">
                <label for="defaultOrg" class="col-md-4 col-sm-4 col-form-label">Default Organization</label>
                <div class="col-md-8 col-sm-8 select-holder">
                    <sf-select id="defaultOrg" placeholder="-- None selected --"
                            [isSingleSelect]="true" (select)="chooseDefaultOrg($event)"
                            [selectedOption]="defaultOrgID"
                            [hasClearButton]="!!defaultOrgID" [hasFilter]="true"
                            [options]="selectableSubmitterOrgs"
                            trackBy="option" labelBy="label"
                    ></sf-select>
                </div>
            </div>
            <div class="row wrapper border-bottom pt-2"
                    *ngIf="showDefaultOrg && defaultOrgID && accessibleSubmitterOrgIDs.length > 1">
                <label for="useDefaultOrgForBankingExportSettings" class="col-md-11 col-sm-11 col-form-label">
                    Use default organization for Banking Export Report and Recording Report settings
                </label>
                <div class="col-md-1 col-sm-1 checkbox-holder">
                    <input type="checkbox" id="useDefaultOrgForBankingExportSettings" name="useDefaultOrgForBankingExportSettings"
                            [(ngModel)]="useDefaultOrgForBankingExportSettings"
                            (keydown.enter)="$event.preventDefault()"
                            (change)="setUseDefaultOrgForBankingExportSettings()"/>
                </div>
            </div>
            <div class="row wrapper border-bottom pt-2"
                    *ngIf="showDefaultOrg && accessibleSubmitterOrgIDs.length == 1">
                <label for="singleDefaultOrg" class="col-md-4 col-sm-4 col-form-label">
                    Default Submission Organization
                </label>
                <div class="col-md-8 col-sm-8">
                    <input type="text" readonly class="form-control form-control-plaintext"
                            id="singleDefaultOrg" name="singleDefaultOrg"
                            [(ngModel)]="selectableSubmitterOrgs[1].label"
                            (keydown.enter)="$event.preventDefault()"
                            ngbTooltip="You belong to only one submitter organization." placement="left"
                            tooltipClass="tooltip-interaction">
                </div>
            </div>
            <div class="row wrapper border-bottom" *ngIf="showDefaultRecipient && recipientSelectorData">
                <label for="recipientID" class="col-md-4 col-sm-4 col-form-label">Default Recipient</label>
                <div class="col-md-8 col-sm-8">
                    <sf-select id="recipientID" placeholder="-- None selected --"
                            [isSingleSelect]="true" (select)="chooseDefaultRecipient($event)"
                            [selectedOption]="defaultRecipientID"
                            [hasClearButton]="!!defaultRecipientID" [hasFilter]="true"
                            [options]="recipientSelectorData"
                            trackBy="option" labelBy="label"
                    ></sf-select>
                </div>
            </div>
            <div class="row wrapper border-bottom" *ngIf="showDefaultEEOrg">
                <label for="recipientID" class="col-md-4 col-sm-4 col-form-label">Default eSign Events Organization</label>
                <div class="col-md-8 col-sm-8">
                    <sf-select id="defaultEEOrgID" placeholder="-- None selected --"
                               [isSingleSelect]="true" (select)="saveDefaultEEOrg($event.$selection)"
                               [selectedOption]="defaultEEOrgID"
                               [hasClearButton]="!!defaultEEOrgID" [hasFilter]="true"
                               [options]="selectableEEOrgs"
                               trackBy="option" labelBy="label"
                    ></sf-select>
                </div>
            </div>
            <div class="row wrapper border-bottom" *ngIf="showHomePage">
                <label for="landingPage" class="col-md-4 col-sm-4 col-form-label">Home Page</label>
                <div class="col-md-8 col-sm-8 select-holder">
                    <sf-select id="landingPage" placeholder="-- None selected --"
                            [isSingleSelect]="true" (select)="chooseHomePage($event)"
                            [selectedOption]="currentLandingPage"
                            [options]="landingPages"
                            trackBy="option" labelBy="label"
                            (registerApi)="registerLandingPageApi($event.$api)"
                    ></sf-select>
                </div>
            </div>
            <div class="row wrapper border-bottom">
                <label for="userID" class="col-md-4 col-sm-4 col-form-label">Username</label>
                <div class="col-md-8 col-sm-8 pt-2 sf-mask" id="userID">
                    {{user.userID}}
                </div>
            </div>
            <div class="row wrapper border-bottom">
                <label for="password" class="col-md-4 col-sm-4 col-form-label">Password</label>
                <div class="col-md-8 col-sm-8 button-holder">
                    <button type="button" class="float-start btn btn-secondary me-3" id="password"
                            (click)="clickChangePassword()">Change Password
                    </button>
                </div>
            </div>
            <div class="row wrapper border-bottom" *ngIf="securityQuestionsRequired">
                <label for="securityQuestion" class="col-md-4 col-sm-4 col-form-label">Security Question</label>
                <div class="col-md-8 col-sm-8 button-holder">
                    <button type="button" class="float-start btn btn-secondary me-3" id="securityQuestion"
                            (click)="clickEditSecurityQuestions()">Edit Security Questions
                    </button>
                </div>
            </div>
            <div class="row wrapper border-bottom" *ngIf="supportQuestionRequired">
                <label for="supportQuestion" class="col-md-4 col-sm-4 col-form-label">Support Question</label>
                <div class="col-md-8 col-sm-8 button-holder">
                    <button type="button" class="float-start btn btn-secondary me-3" id="supportQuestion"
                            (click)="clickEditSupportQuestion()">Edit Support Question
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
