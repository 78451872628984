import { Component, OnDestroy, OnInit } from "@angular/core";
import {
    DynamicFormStore,
    FieldOption,
    InputComponent
} from "@sf/dynamic-form-viewer";
import { map, take, takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import {
    UntypedFormBuilder,
    UntypedFormControl,
    ValidationErrors
} from "@angular/forms";

@Component({
    selector: "sf-custom-checkbox-field",
    templateUrl: "./custom-checkbox-field.component.html",
    styleUrls: ["./custom-checkbox-field.component.scss"]
})
export class CustomCheckboxFieldComponent
    extends InputComponent
    implements OnInit, OnDestroy
{
    options$: Observable<FieldOption[]>;
    errors: ValidationErrors = null;
    private _destroy$ = new Subject<void>();
    optionValuesMap: any = {};
    isDisabled: boolean = false;
    placeFieldLabelAsOptionLabel = false;

    constructor(
        private _fb: UntypedFormBuilder,
        protected _facade: DynamicFormStore
    ) {
        super(_facade);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.options$ = this._facade
            .getViewStateForField(this.field.fullPath)
            .pipe(
                take(1),
                map((viewState) => {
                    if (
                        viewState &&
                        viewState.options &&
                        viewState.options.length > 0
                    ) {
                        if (
                            viewState.options.length === 1 &&
                            viewState.options[0].label.trim().length === 0
                        ) {
                            this.placeFieldLabelAsOptionLabel = true;
                            if (this.field.label.trim().length === 0) {
                                this.field.label = this.field.defaults.label;
                            }
                        }
                        for (
                            let valIndex = 0;
                            this.field.value &&
                            valIndex < this.field.value.length;
                            valIndex++
                        ) {
                            let selectedOption = this.field.value[valIndex];
                            for (
                                let opt = 0;
                                opt < viewState.options.length;
                                opt++
                            ) {
                                if (
                                    selectedOption ===
                                    viewState.options[opt].option
                                ) {
                                    this.optionValuesMap[selectedOption] = true;
                                }
                            }
                        }

                        return viewState.options;
                    }

                    return [];
                })
            );
        this._facade
            .getFormStateForField(this.field.fullPath)
            .subscribe((state) => {
                this.isDisabled = state.disabled;
            });

        this.formErrors$.pipe(takeUntil(this._destroy$)).subscribe((errors) => {
            this.errors = errors;
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
    }

    onCheckboxChange(e: any) {
        this.formControl$
            .pipe(take(1))
            .subscribe((control: UntypedFormControl) => {
                let value: any[] = [];
                if (Array.isArray(control.value)) {
                    value = JSON.parse(JSON.stringify(control.value));
                }
                if (e.target.checked) {
                    this._addValueToControl(value, e.target.value, control);
                } else {
                    this._removeValueFromControl(
                        value,
                        e.target.value,
                        control
                    );
                }
            });
    }

    private _addValueToControl(
        valueArray: string[],
        value: string,
        control: UntypedFormControl
    ) {
        valueArray.push(value);
        control.setValue(valueArray);
        this.field.value = valueArray;
    }

    private _removeValueFromControl(
        valueArray: string[],
        value: string,
        control: UntypedFormControl
    ) {
        let i: number = 0;
        valueArray.forEach((item: any) => {
            if (item == value) {
                valueArray.splice(i, 1);
                return;
            }
            i++;
        });

        control.setValue(valueArray);
        this.field.value = valueArray;
    }
}
