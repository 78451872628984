import { Component, Input, OnInit } from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import {
    BankAccount,
    CreditCardAccount,
    CreditCardType,
    InvoiceAccount,
    Organization,
    OrganizationService,
    PaymentAccount,
    PaymentAccountsService,
    PaymentAccountType,
    StripeService,
    User,
    UserOrgService
} from "@sf/userorg/common";
import {
    ConfirmationModalComponent,
    CopyTextService,
    dayjs,
    GrowlService,
    ModalButton,
    SessionService,
    SfValidators,
    SpinnerService
} from "@sf/common";
import {
    NgbActiveModal,
    NgbModal,
    NgbModalRef
} from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs/operators";
import { loadStripe } from "@stripe/stripe-js/pure";
import { IconName } from "@fortawesome/fontawesome-svg-core";

@Component({
    selector: "sf-add-payment-account-dialog",
    templateUrl: "./add-edit-payment-account-dialog.component.html",
    styleUrls: ["./add-edit-payment-account-dialog.component.scss"]
})
export class AddEditPaymentAccountDialogComponent implements OnInit {
    @Input()
    orgId: string;
    //for editing purposes
    @Input()
    paymentAccount: PaymentAccount;
    //(optional) initial account type
    @Input()
    initialAccountType: PaymentAccountType;
    //(optional) open from pending payments screen
    @Input()
    addCCFromPendingPayments?: boolean;
    //(optional) opened from pending payments screen and isRecording payment type
    @Input()
    isRecording?: boolean;
    @Input()
    hasSubmitter: boolean;
    @Input()
    hasDocBuilder: boolean;
    @Input()
    hasRecordingPlus: boolean;
    @Input()
    hasEsignEvents: boolean;
    @Input()
    hasNotary: boolean;

    //for reference in HTML
    get paymentAccountType() {
        return PaymentAccountType;
    }
    get bankAccount() {
        return this.paymentAccount as BankAccount;
    }

    /** PRIVATE **/
    private _addedAccountType: PaymentAccountType;
    private _accountTypeOptions: any[]; //sf-select uses onPush change detection - this is used to set options once
    private _result: any = {
        //result contains all the data returned back to dialog consumer
        validationData: {
            verifyAccountNumber: null,
            verifyRoutingNumber: null,
            agreeToTerms: false
        },
        accountData: {
            organizationID: null,
            accountType: null,
            accountLabel: null,
            accountNumber: null,
            routingNumber: null,
            bankName: null,
            nameOnBankAccount: null,
            bankAccountID: null,
            expiration: {
                month: "",
                year: ""
            },
            signatory: {
                name: null,
                title: null,
                companyName: null,
                phoneNumber: null
            }
        }
    };

    /** PUBLIC **/
    warning: string = null;
    modalTitle: string = null;
    numSteps = 0;
    stepIndex = 1;
    primaryButton: ModalButton;
    secondaryButton: ModalButton;
    nextButton: ModalButton;
    backButton: ModalButton;
    step1Group: UntypedFormGroup;
    step2Group: UntypedFormGroup;
    step2GroupCC: UntypedFormGroup;
    step3Group: UntypedFormGroup;
    step4Group: UntypedFormGroup;
    step2GroupInvoice: UntypedFormGroup;
    paymentTypeLabel: string;
    accountTypeOptions: any[];
    isSuperUser: boolean = false;
    hasAdminPermission: boolean = false;
    editMode: boolean = false;
    showSteps: boolean = true;
    hasOrgAccountingPermission: boolean = false;
    accountUsed: boolean = false;
    showLegalEase: boolean = false;
    showGeneralLedger: boolean = false;
    selectedAccountType: PaymentAccountType = null;
    radioAccountType: PaymentAccountType = PaymentAccountType.BANK;
    ccBrand: string = null;
    editPaymentMethod: boolean = false;
    currEnvironment: string;

    // credit card stuff
    private stripe: any;
    private stripeSecret: string;
    private creditCardClientSecret: string = null;
    // creditCardName: string = null;
    // creditCardZip: string = null;
    creditCardBrand: string = null;
    creditCardType: CreditCardType = CreditCardType.CREDIT; //TODO: get real card type??
    creditCardImage: IconName = null;
    creditCardNumberField: any;
    creditCardNumberError: string = null;
    creditCardDateError: string = null;
    creditCardCvcError: string = null;
    showCard: boolean = false;
    showBasicCardText: boolean = true;

    constructor(
        private _paymentAccountsService: PaymentAccountsService,
        private _sessionService: SessionService,
        private _formBuilder: UntypedFormBuilder,
        private _modalService: NgbModal,
        private _activeModal: NgbActiveModal,
        private _orgService: OrganizationService,
        private _growlService: GrowlService,
        private _userOrgService: UserOrgService,
        private spinnerService: SpinnerService,
        private stripeService: StripeService,
        private _copyTextService: CopyTextService
    ) {
        //establish default account type options
        this._accountTypeOptions = [
            {
                option: PaymentAccountType.BANK,
                label: this.getAccountTypeLabel(PaymentAccountType.BANK)
            },
            {
                option: PaymentAccountType.E_CHECK,
                label: this.getAccountTypeLabel(PaymentAccountType.E_CHECK)
            }
        ];

        this._setPaymentTypeLabel(PaymentAccountType.BANK);
    }

    ngOnInit() {
        this.editMode = !!this.paymentAccount;

        this.showCard =
            this.hasSubmitter || this.hasDocBuilder || this.hasEsignEvents;

        this.currEnvironment = this._sessionService.getEnv().toUpperCase();

        //make sure we have an org id if editing
        if (!this.orgId && this.editMode) {
            this.orgId = this.paymentAccount.organizationID;
        }

        //determine cc text to show
        this.stripeService
            .isOrganizationEnabledForCreditCard(this.orgId)
            .pipe(first())
            .subscribe((result: boolean) => {
                this.showBasicCardText = !result;
            });

        //do this early
        if (
            this.editMode &&
            this.paymentAccount.paymentAccountType ==
                PaymentAccountType.CREDIT_CARD
        ) {
            let cca: CreditCardAccount = <CreditCardAccount>this.paymentAccount;
            this._paymentAccountsService
                .getCreditCardDetails(cca.paymentAccountID, cca.organizationID)
                .pipe(first())
                .subscribe((details: any) => {
                    if (details) {
                        this.step2GroupCC.patchValue({
                            creditCardLast4: "Ending in " + details.last4
                        });
                        this.step2GroupCC.patchValue({
                            creditCardZip: details.zipCode
                        });
                        let month: string = details.expMonth;
                        if (details.expMonth < 10) {
                            month = "0" + details.expMonth;
                        }
                        this.step2GroupCC.patchValue({
                            creditCardExp: month + "/" + details.expYear
                        });
                        this.step2GroupCC.updateValueAndValidity();
                    }
                });
            this._setPaymentTypeLabel(PaymentAccountType.CREDIT_CARD);
        }

        // get the key necessary for credit cards
        this.stripeService.getStripeKey().subscribe((key) => {
            this.stripeSecret = key;
            if (this.initialAccountType == PaymentAccountType.CREDIT_CARD) {
                this.initStripe();
            }
        });

        //determine permission
        this.isSuperUser = this._sessionService.isSuperUser();
        this.hasAdminPermission = this._sessionService.hasPermission(
            "admin_accounting_payment_account_management",
            "SIMPFL"
        );
        this.hasOrgAccountingPermission = this._sessionService.hasPermission(
            "organization_accounting",
            this.orgId
        );

        //make sure this happens after we set edit mode and get permission information because controls are disabled accordingly
        this._setupFormControls();

        //if edit mode
        if (this.editMode) {
            //set initial step when editing
            this.stepIndex = 2;
            this.showSteps = false;

            switch (this.paymentAccount.paymentAccountType) {
                case PaymentAccountType.BANK:
                    this.selectedAccountType = PaymentAccountType.BANK;
                    let bankAccount: BankAccount = this
                        .paymentAccount as BankAccount;
                    //patch values into forms
                    this.step1Group.patchValue({
                        paymentAccountType: PaymentAccountType.BANK
                    });
                    this.step2Group.patchValue({
                        nickName: bankAccount.accountLabel,
                        bankAccountType: bankAccount.accountType,
                        bankName: bankAccount.bankName,
                        routingNumber: bankAccount.routingNumber,
                        verifyRoutingNumber: bankAccount.routingNumber,
                        accountNumber: bankAccount.accountNumber,
                        verifyAccountNumber: bankAccount.accountNumber,
                        expirationMonth: bankAccount.expiration.month,
                        expirationYear: bankAccount.expiration.year,
                        nameOnAccount: bankAccount.nameOnBankAccount
                    });
                    this.step3Group.patchValue({
                        signatory: bankAccount.signatory.name,
                        jobTitle: bankAccount.signatory.title,
                        phone: bankAccount.signatory.phoneNumber,
                        companyName: bankAccount.signatory.companyName
                    });
                    this._setPaymentTypeLabel(bankAccount.accountType);
                    if (!this._isACHDebitType(bankAccount.accountType)) {
                        this.selectedAccountType = bankAccount.accountType;
                    }

                    //if account type is already General Ledger, we want to show the option
                    if (
                        bankAccount.accountType ==
                        PaymentAccountType.GENERAL_LEDGER
                    )
                        this.showGeneralLedger = true;

                    break;
                case PaymentAccountType.CREDIT_CARD:
                    let ccAccount: CreditCardAccount = this
                        .paymentAccount as CreditCardAccount;
                    this.step1Group.patchValue({
                        paymentAccountType: PaymentAccountType.CREDIT_CARD
                    });
                    this.step2GroupCC.patchValue({
                        nickName: ccAccount.label,
                        creditCardName: ccAccount.nameOnCard,
                        creditCardExp:
                            ccAccount.expMonth + "/" + ccAccount.expYear
                    });
                    this._setPaymentTypeLabel(PaymentAccountType.CREDIT_CARD);
                    break;
                case PaymentAccountType.INVOICE:
                    this.step1Group.patchValue({
                        paymentAccountType: PaymentAccountType.INVOICE
                    });
                    this.step2GroupInvoice.patchValue({
                        nickName: this.paymentAccount.label
                    });
                    break;
            }
        } else if (this.addCCFromPendingPayments) {
            // this.stepIndex = this.isRecording ? 1 : 2;
            this.showSteps = this.isRecording; // Hide steps if not a recording payment
            this.initialAccountType = PaymentAccountType.CREDIT_CARD;
        } else {
            //sets defaults for adding

            this.step1Group.patchValue({
                paymentAccountType: PaymentAccountType.BANK
            });
            this.step2Group.patchValue({
                bankAccountType: PaymentAccountType.CHECKING
            });
            this.showSteps = true;
            this._setNumberOfSteps(
                this.step1Group.controls.paymentAccountType.value
            );
            this._setRoutingNumberValidation();
            this._setPaymentTypeLabel(PaymentAccountType.BANK);
        }

        //set initial step when passing in account type
        if (this.initialAccountType) {
            //set radio button from initial account type, in case they go back
            this.radioAccountType = this.initialAccountType;
            this._setNumberOfSteps(this.initialAccountType);
            this.stepIndex =
                this.addCCFromPendingPayments && this.isRecording ? 1 : 2; // if opening dialog from pending payment and it is from a recording payment, set stepIndex to 1
            if (this.initialAccountType == PaymentAccountType.CREDIT_CARD) {
                this.step1Group.patchValue({
                    paymentAccountType: this.initialAccountType
                });
                this._setPaymentTypeLabel(this.initialAccountType);
            } else {
                this.step1Group.patchValue({
                    paymentAccountType: PaymentAccountType.BANK
                });
                if (
                    this.initialAccountType == PaymentAccountType.E_CHECK ||
                    this.initialAccountType == PaymentAccountType.FROST_BANK
                ) {
                    this.step2Group.patchValue({
                        bankAccountType: this.initialAccountType
                    });
                } else {
                    this.step2Group.patchValue({
                        bankAccountType: PaymentAccountType.CHECKING
                    });
                }
                this._setPaymentTypeLabel(
                    this.step2Group.controls.bankAccountType.value
                );
            }
            this._setRoutingNumberValidation();
        }

        //get organization info and set values accordingly
        this._orgService
            .getOrganization(this.orgId)
            .subscribe((org: Organization) => {
                //set company name on results from org
                this._result.accountData.signatory.companyName = org.name;

                // //if Texas based org, add LegalEase option
                // if (org.address.state === "TX") {
                //     this.showLegalEase = true;
                //     this._accountTypeOptions.push({
                //         option: PaymentAccountType.FROST_BANK,
                //         label: this.getAccountTypeLabel(
                //             PaymentAccountType.FROST_BANK
                //         )
                //     });
                // }

                //if org has Bank business type, add General Ledger
                this._userOrgService
                    .getOrganizationConfiguration(org.id)
                    .pipe(first())
                    .subscribe((config: any) => {
                        let businessTypes: string[] = config[
                            "Organization Settings"
                        ].business_type
                            ? config[
                                  "Organization Settings"
                              ].business_type.value.split(",")
                            : [];
                        if (
                            businessTypes.includes("bank") ||
                            businessTypes.includes("credit_union") ||
                            businessTypes.includes("lender")
                        ) {
                            this.showGeneralLedger = true;
                        }

                        //set account type options
                        this.accountTypeOptions = this._accountTypeOptions;

                        //if editing, set type selection
                        if (this.editMode) {
                            if (
                                this.paymentAccount.paymentAccountType ==
                                PaymentAccountType.BANK
                            ) {
                                let bankAccount: BankAccount = this
                                    .paymentAccount as BankAccount;
                                this._setPaymentTypeLabel(
                                    bankAccount.accountType
                                );
                            }
                            this._setRoutingNumberValidation();
                        } else {
                            //when adding an account, set some default signatory info
                            if (!this.isSuperUser) {
                                this._userOrgService
                                    .getUser(this._sessionService.getUserID())
                                    .pipe(first())
                                    .subscribe((user: User) => {
                                        this.step2Group.patchValue({
                                            nameOnAccount:
                                                user.name.first +
                                                " " +
                                                user.name.last
                                        });
                                        this.step3Group.patchValue({
                                            signatory:
                                                user.name.first +
                                                " " +
                                                user.name.last
                                        });
                                        this.step3Group.patchValue({
                                            jobTitle: user.name.title
                                        });
                                        this.step3Group.patchValue({
                                            phone: SfValidators.formatPhone(
                                                "" +
                                                    user.phone.areaCode +
                                                    user.phone.prefix +
                                                    user.phone.suffix
                                            )
                                        });
                                    });
                            }
                            //if super-user only populate org name
                            this.step3Group.patchValue({
                                companyName: org.legalName
                            });
                        }
                    });
            });

        //setup buttons
        this.primaryButton = {
            text:
                (this.isRecording
                    ? "Next"
                    : this.editMode
                    ? "Save Account"
                    : "Add Account") +
                (this.addCCFromPendingPayments && !this.isRecording
                    ? " and Retry Payment"
                    : ""),
            hidden: false,
            disabled: true,
            callback: this._processAccount.bind(this)
        };

        this.secondaryButton = {
            text: "Cancel",
            hidden: false,
            callback: this._closeDialog.bind(this, [true])
        };

        this.nextButton = {
            text: "Next",
            callback: this._nextStep.bind(this)
        };

        this.backButton = {
            text: "Back",
            callback: this._prevStep.bind(this)
        };

        this._setButtonsState();

        //set title
        this.modalTitle = this.addCCFromPendingPayments
            ? `Add New Card and ${
                  this.isRecording ? "Change" : "Retry"
              } Payment`
            : this.editMode
            ? "Edit Payment Account"
            : "Add Payment Account";

        if (this.editMode && this.hasOrgAccountingPermission) {
            this._paymentAccountsService
                .isPaymentAccountUsed(
                    this.orgId,
                    this.paymentAccount.paymentAccountID
                )
                .pipe(first())
                .subscribe((isUsed: boolean) => {
                    this.accountUsed = isUsed;
                    //set fields disabled based on account being used
                    this.setControlStatus(
                        this.step2Group.controls.nickName,
                        isUsed
                    );
                    this.setControlStatus(
                        this.step2Group.controls.bankName,
                        isUsed
                    );
                    this.setControlStatus(
                        this.step2Group.controls.routingNumber,
                        isUsed
                    );
                    this.setControlStatus(
                        this.step2Group.controls.verifyRoutingNumber,
                        isUsed
                    );
                    this.setControlStatus(
                        this.step2Group.controls.accountNumber,
                        isUsed
                    );
                    this.setControlStatus(
                        this.step2Group.controls.verifyAccountNumber,
                        isUsed
                    );
                    this.setControlStatus(
                        this.step2Group.controls.expirationMonth,
                        isUsed
                    );
                    this.setControlStatus(
                        this.step2Group.controls.expirationYear,
                        isUsed
                    );
                    this.setControlStatus(
                        this.step2Group.controls.nameOnAccount,
                        isUsed
                    );
                    if (isUsed) {
                        this.step2Group.controls.bankAccountType.disable();
                    }
                });
        }

        this.focusElement("checking");
    }

    focusElement(elementID: string) {
        window.setTimeout(() => {
            let theField = document.getElementById(elementID);
            if (!!theField) theField.focus();
        }, 200);
    }

    getAccountTypeLabel(paymentType: PaymentAccountType): string {
        let label = "Unknown";

        switch (paymentType) {
            case PaymentAccountType.BANK:
            case PaymentAccountType.CHECKING:
            case PaymentAccountType.GENERAL_LEDGER:
            case PaymentAccountType.SAVINGS:
                label = "ACH Debit";
                break;
            case PaymentAccountType.FROST_BANK:
                label = "LegalEase";
                break;
            case PaymentAccountType.E_CHECK:
                label = "eCheck";
                break;
            case PaymentAccountType.INVOICE:
                label = "Invoiced";
                break;
            case PaymentAccountType.CREDIT_CARD:
                label = "Credit Card";
                break;
            default:
                break;
        }

        return label;
    }

    setControlStatus(control: AbstractControl, accountUsed: boolean) {
        // should only care about enabling fields
        if (control.disabled && !accountUsed) {
            control.enable();
        }

        //this is to update validation
        control.markAsDirty();
        control.updateValueAndValidity();
    }

    /**
     * Handles account type selection
     * @param event sf-select event object
     */
    handleAccountTypeSelected(event: any) {
        let update: boolean = false;
        //this comes from select list while editing
        if (event.$selection && event.$isSelectionChanged) {
            update = true;
            if (event.$selection.option == PaymentAccountType.BANK) {
                if (
                    !this._isACHDebitType(
                        this.step2Group.controls.bankAccountType.value
                    )
                ) {
                    this.step2Group.patchValue({
                        bankAccountType: PaymentAccountType.CHECKING
                    });
                }
            } else {
                //for E-check & LegalEase
                this.step2Group.patchValue({
                    bankAccountType: event.$selection.option
                });
            }
            this._setPaymentTypeLabel(
                this.step2Group.controls.bankAccountType.value
            );
        }
        //this comes from radio buttons while adding
        if (event.target && event.target.checked) {
            update = true;
            if (this.radioAccountType == PaymentAccountType.CREDIT_CARD) {
                this.step1Group.patchValue({
                    paymentAccountType: PaymentAccountType.CREDIT_CARD
                });
                this._setPaymentTypeLabel(PaymentAccountType.CREDIT_CARD);
            } else {
                this.step1Group.patchValue({
                    paymentAccountType: PaymentAccountType.BANK
                });
                if (
                    this.radioAccountType == PaymentAccountType.E_CHECK ||
                    this.radioAccountType == PaymentAccountType.FROST_BANK
                ) {
                    this.step2Group.patchValue({
                        bankAccountType: this.radioAccountType
                    });
                } else {
                    this.step2Group.patchValue({
                        bankAccountType: PaymentAccountType.CHECKING
                    });
                }
                this._setPaymentTypeLabel(
                    this.step2Group.controls.bankAccountType.value
                );
            }
        }
        if (update) {
            if (!this.editMode) {
                this.showSteps = true;
                this._setNumberOfSteps(
                    this.step1Group.controls.paymentAccountType.value
                );
            }
            this._setRoutingNumberValidation();
        }
        this.warning = null;
        this._validateCurrentPage();
    }

    /**
     * Handles final agreement checkbox click - sole purpose is to enable the primary button (add/save)
     * @param event MouseEvent sent when user interacts with the checkbox
     */
    handleAgreeCheckbox(event: MouseEvent) {
        let checkbox: HTMLInputElement = event.target as HTMLInputElement;
        this.primaryButton.disabled = !checkbox.checked;
    }

    populateNickname() {
        let bN: AbstractControl = this.step2Group.controls.bankName,
            aN: AbstractControl = this.step2Group.controls.accountNumber,
            vAN: AbstractControl = this.step2Group.controls.verifyAccountNumber;

        if (
            !this.editMode &&
            bN.value &&
            aN.value &&
            vAN.value &&
            aN.valid &&
            vAN.valid &&
            aN.value === vAN.value
        ) {
            this.step2Group.patchValue({
                nickName:
                    bN.value + " (" + aN.value.slice(aN.value.length - 4) + ")"
            });
        }
    }

    populateCCNickname() {
        let cN: AbstractControl = this.step2GroupCC.controls.creditCardName;

        if (!this.editMode && cN.value && this.ccBrand) {
            this.step2GroupCC.patchValue({
                nickName: cN.value + " (" + this.ccBrand + ")"
            });
        }
    }

    toggleEditPaymentMethod() {
        this.editPaymentMethod = !this.editPaymentMethod;
    }

    private _setNumberOfSteps(accountType: PaymentAccountType) {
        if (accountType == PaymentAccountType.CREDIT_CARD) {
            this.numSteps = 2;
        } else {
            this.numSteps = 4;
        }
    }

    /**
     * Sets validation on routing number/verify routing number fields, based on account type
     * @private
     */
    private _setRoutingNumberValidation() {
        if (
            this.step2Group.controls.bankAccountType.value ===
            PaymentAccountType.FROST_BANK
        ) {
            this.step2Group.controls.routingNumber.setValidators(null);
            this.step2Group.controls.verifyRoutingNumber.setValidators(null);
            this.step2Group.controls.routingNumber.setErrors(null);
            this.step2Group.controls.verifyRoutingNumber.setErrors(null);
        } else {
            this.step2Group.controls.routingNumber.setValidators([
                Validators.required,
                Validators.minLength(9),
                SfValidators.routingNumberValidator
            ]);
            this.step2Group.controls.verifyRoutingNumber.setValidators([
                Validators.required,
                Validators.minLength(9),
                SfValidators.routingNumberValidator
            ]);
        }
    }

    //builds the FormControl objects for the dialog
    private _setupFormControls() {
        this.step1Group = this._formBuilder.group({
            paymentAccountType: new UntypedFormControl(null, {
                validators:
                    !this.editMode || this.hasAdminPermission
                        ? [Validators.required]
                        : null
            })
        });

        this.step2Group = this._formBuilder.group({
            //nickname always editable
            nickName: new UntypedFormControl(null, {
                validators: [Validators.required, SfValidators.ltSpace]
            }),
            bankAccountType: new UntypedFormControl(
                {
                    value: null,
                    disabled:
                        (this.editMode &&
                            !(
                                this.hasAdminPermission ||
                                this.hasOrgAccountingPermission
                            )) ||
                        this.accountUsed
                },
                {
                    validators:
                        !this.editMode ||
                        this.hasAdminPermission ||
                        (this.hasOrgAccountingPermission && this.editMode)
                            ? [Validators.required]
                            : null
                }
            ),
            bankName: new UntypedFormControl(
                {
                    value: null,
                    disabled: this.editMode && !this.hasAdminPermission
                },
                {
                    validators:
                        !this.editMode ||
                        this.hasAdminPermission ||
                        (this.hasOrgAccountingPermission && this.editMode)
                            ? [Validators.required, SfValidators.ltSpace]
                            : null
                }
            ),
            routingNumber: new UntypedFormControl(
                {
                    value: null,
                    disabled: this.editMode && !this.hasAdminPermission
                },
                {
                    validators:
                        !this.editMode ||
                        this.hasAdminPermission ||
                        (this.hasOrgAccountingPermission && this.editMode)
                            ? [
                                  Validators.required,
                                  Validators.minLength(9),
                                  SfValidators.routingNumberValidator
                              ]
                            : null
                }
            ),
            verifyRoutingNumber: new UntypedFormControl(
                {
                    value: null,
                    disabled: this.editMode && !this.hasAdminPermission
                },
                {
                    validators:
                        !this.editMode ||
                        this.hasAdminPermission ||
                        (this.hasOrgAccountingPermission && this.editMode)
                            ? [
                                  Validators.required,
                                  Validators.minLength(9),
                                  SfValidators.routingNumberValidator
                              ]
                            : null
                }
            ),
            accountNumber: new UntypedFormControl(
                {
                    value: null,
                    disabled: this.editMode && !this.hasAdminPermission
                },
                {
                    validators:
                        !this.editMode ||
                        this.hasAdminPermission ||
                        (this.hasOrgAccountingPermission && this.editMode)
                            ? [
                                  Validators.required,
                                  Validators.minLength(5),
                                  SfValidators.numericValidator
                              ]
                            : null
                }
            ),
            verifyAccountNumber: new UntypedFormControl(
                {
                    value: null,
                    disabled: this.editMode && !this.hasAdminPermission
                },
                {
                    validators:
                        !this.editMode ||
                        this.hasAdminPermission ||
                        (this.hasOrgAccountingPermission && this.editMode)
                            ? [
                                  Validators.required,
                                  Validators.minLength(5),
                                  SfValidators.numericValidator
                              ]
                            : null
                }
            ),
            expirationMonth: new UntypedFormControl(
                {
                    value: null,
                    disabled: this.editMode && !this.hasAdminPermission
                },
                {
                    validators:
                        !this.editMode ||
                        this.hasAdminPermission ||
                        (this.hasOrgAccountingPermission && this.editMode)
                            ? [
                                  Validators.minLength(1),
                                  SfValidators.numericValidator
                              ]
                            : null
                }
            ),
            expirationYear: new UntypedFormControl(
                {
                    value: null,
                    disabled: this.editMode && !this.hasAdminPermission
                },
                {
                    validators:
                        !this.editMode ||
                        this.hasAdminPermission ||
                        (this.hasOrgAccountingPermission && this.editMode)
                            ? [
                                  Validators.minLength(2),
                                  SfValidators.numericValidator
                              ]
                            : null
                }
            ),
            nameOnAccount: new UntypedFormControl(
                {
                    value: null,
                    disabled: this.editMode && !this.hasAdminPermission
                },
                {
                    validators:
                        !this.editMode ||
                        this.hasAdminPermission ||
                        (this.hasOrgAccountingPermission && this.editMode)
                            ? [Validators.required, SfValidators.ltSpace]
                            : null
                }
            )
        });

        this.step2GroupInvoice = this._formBuilder.group({
            //nickname always editable
            nickName: new UntypedFormControl(null, {
                validators: [Validators.required, SfValidators.ltSpace]
            })
        });

        this.step2GroupCC = this._formBuilder.group({
            //nickname always editable
            nickName: new UntypedFormControl(null, {
                validators: [Validators.required, SfValidators.ltSpace]
            }),
            creditCardName: new UntypedFormControl(
                {
                    value: null,
                    disabled: this.editMode
                },
                {
                    validators: [Validators.required, SfValidators.ltSpace]
                }
            ),
            creditCardZip: new UntypedFormControl(
                {
                    value: null,
                    disabled:
                        this.editMode &&
                        !(
                            this.hasAdminPermission ||
                            this.hasOrgAccountingPermission
                        )
                },
                {
                    validators: [
                        Validators.required,
                        SfValidators.zipCodeValidator
                    ]
                }
            ),
            creditCardExp: new UntypedFormControl(
                {
                    value: null,
                    disabled:
                        this.editMode &&
                        !(
                            this.hasAdminPermission ||
                            this.hasOrgAccountingPermission
                        )
                },
                {
                    validators: this.editMode
                        ? [Validators.required, SfValidators.creditCardExp]
                        : null
                }
            ),
            creditCardLast4: new UntypedFormControl({
                value: null,
                disabled: true
            })
        });

        this.step3Group = this._formBuilder.group({
            signatory: new UntypedFormControl(
                {
                    value: null,
                    disabled: this.editMode && !this.hasAdminPermission
                },
                {
                    validators:
                        !this.editMode || this.hasAdminPermission
                            ? [Validators.required, SfValidators.ltSpace]
                            : null
                }
            ),
            jobTitle: new UntypedFormControl(
                {
                    value: null,
                    disabled: this.editMode && !this.hasAdminPermission
                },
                {
                    validators:
                        !this.editMode || this.hasAdminPermission
                            ? [Validators.required, SfValidators.ltSpace]
                            : null
                }
            ),
            phone: new UntypedFormControl(
                {
                    value: null,
                    disabled: this.editMode && !this.hasAdminPermission
                },
                {
                    validators:
                        !this.editMode || this.hasAdminPermission
                            ? [Validators.required, SfValidators.phoneValidator]
                            : null
                }
            ),
            companyName: new UntypedFormControl(
                {
                    value: null,
                    disabled: this.editMode && !this.hasAdminPermission
                },
                {
                    validators:
                        !this.editMode || this.hasAdminPermission
                            ? [Validators.required, SfValidators.ltSpace]
                            : null
                }
            )
        });

        this.step4Group = this._formBuilder.group({
            agreeCheckbox: new UntypedFormControl(null, {
                validators: null
            })
        });
    }

    private _isACHDebitType(accountType: PaymentAccountType): boolean {
        switch (accountType) {
            case PaymentAccountType.CHECKING:
            case PaymentAccountType.SAVINGS:
            case PaymentAccountType.GENERAL_LEDGER:
                return true;
            default:
                return false;
        }
    }

    private _setPaymentTypeLabel(paymentType: PaymentAccountType) {
        this.paymentTypeLabel = this.getAccountTypeLabel(paymentType);
    }

    /**
     * Handles dismissing the active dialog - can be executed as needed, or when secondary button is clicked
     * @param dismiss - boolean; if true, the dialog is dismissed rather than closed
     * @param response - any; if true, the dialog is dismissed rather than closed
     * @private
     */
    private _closeDialog(dismiss: boolean, response?: any) {
        if (dismiss) {
            this._activeModal.dismiss();
        } else if (response && this.addCCFromPendingPayments) {
            this._activeModal.close(response);
        } else {
            this._activeModal.close(this._addedAccountType);
        }
    }

    /**
     * Handles logic for moving to the next step in the dialog
     * @private
     */
    private _nextStep() {
        this._validateCurrentPage();
        if (this.warning) {
            return;
        }
        this.stepIndex++;
        this._setButtonsState();
        if (
            this.stepIndex === 2 &&
            this.step1Group.controls.paymentAccountType.value ==
                PaymentAccountType.CREDIT_CARD
        ) {
            this.initStripe();
        }
    }

    /**
     * Handles logic for moving to the previous step in the dialog
     * @private
     */
    private _prevStep() {
        this.warning = null;
        this.stepIndex--;
        this._setButtonsState();
    }

    /**
     * Handles setting the state of the various buttons in the dialog - hiding/showing, enabling/disabling, etc.
     * @private
     */
    private _setButtonsState() {
        //hide back button if first step
        this.backButton.hidden = this.stepIndex == 1;
        //hide next button if last step
        this.nextButton.hidden = this.stepIndex == this.numSteps;
        //primary button should be hidden until on last step
        this.primaryButton.hidden = this.stepIndex != this.numSteps;

        //edit mode as super user w/o admin permission (just save account from step 2)
        //if (this.editMode && !this.hasAdminPermission) {
        if (this.editMode) {
            this.backButton.hidden = true;
            this.nextButton.hidden = true;
            this.primaryButton.hidden = false;
            this.primaryButton.disabled = false;
            this.secondaryButton.hidden = false;
        }

        if (this.addCCFromPendingPayments) {
            this.backButton.hidden = true;
            this.nextButton.hidden = true;
            this.primaryButton.hidden = false;
        }

        if (this.stepIndex == this.numSteps) {
            if (
                this.step1Group.controls.paymentAccountType.value ==
                PaymentAccountType.CREDIT_CARD
            )
                this.focusElement("cardName");
            else this.focusElement("agreeCheckbox");
        }
    }

    private initStripe() {
        if (!this.stripe) {
            loadStripe(this.stripeSecret).then((result: any) => {
                this.stripe = result;
                this.loadStripeFields();
            });
            this.stripeService
                .getCardSetupIntent(this.orgId)
                .subscribe((result: string) => {
                    this.creditCardClientSecret = result;
                });
        } else {
            this.loadStripeFields();
        }
    }

    private loadStripeFields() {
        let elements: any = this.stripe.elements();
        let cardStyle = {
            base: {
                color: "#555",
                fontFamily:
                    "Simplifile Font, Helvetica Neue, Helvetica, Arial, sans serif",
                fontSize: "15px",
                "::placeholder": {
                    color: "#BC917C"
                }
            }
            /*
            invalid: {
                backgroundColor: "#FFE5E2"
            }
            */
        };
        this.creditCardNumberError = "Credit card number is required";
        this.creditCardDateError = "Expiration date is required";
        this.creditCardCvcError = "CVC is required";

        setTimeout(() => {
            this.creditCardNumberField = elements.create("cardNumber", {
                style: cardStyle
            });
            this.creditCardNumberField.mount("#cardNumberElement");
            let cardExpiry = elements.create("cardExpiry", {
                style: cardStyle
            });
            cardExpiry.mount("#cardExpiryElement");
            let cardCvc = elements.create("cardCvc", { style: cardStyle });
            cardCvc.mount("#cardCvcElement");

            this.creditCardNumberField.on("change", (error: any) => {
                this.creditCardNumberError = null;
                this.creditCardBrand = error.brand;
                switch (this.creditCardBrand) {
                    case "visa":
                        this.creditCardImage = "cc-visa";
                        this.ccBrand = "Visa";
                        break;
                    case "mastercard":
                        this.creditCardImage = "cc-mastercard";
                        this.ccBrand = "Mastercard";
                        break;
                    case "amex":
                        this.creditCardImage = "cc-amex";
                        this.ccBrand = "American Express";
                        break;
                    case "discover":
                        this.creditCardImage = "cc-discover";
                        this.ccBrand = "Discover";
                        break;
                    case "diners":
                        this.creditCardImage = "cc-diners-club";
                        this.ccBrand = "Diners Club";
                        break;
                    case "jcb":
                        this.creditCardImage = "cc-jcb";
                        this.ccBrand = "JCB";
                        break;
                    case "unionpay":
                        this.ccBrand = "UnionPay";
                        break;
                    default:
                        this.creditCardImage = null;
                        break;
                }
                if (error) {
                    if (error.message) {
                        this.creditCardNumberError = error.message;
                    } else if (error.empty) {
                        this.creditCardNumberError =
                            "Credit card number is required";
                    } else if (!error.complete) {
                        this.creditCardNumberError =
                            "Credit card number is not complete";
                    } else {
                        // we're good
                        //this.warning = null;
                    }
                }
                this.populateCCNickname();
            });
            cardExpiry.on("change", (error: any) => {
                this.creditCardDateError = null;
                if (error) {
                    if (error.message) {
                        this.creditCardDateError = error.message;
                    } else if (error.empty) {
                        this.creditCardDateError =
                            "Credit card expiration is required";
                    } else if (!error.complete) {
                        this.creditCardDateError =
                            "Credit card expiration is invalid";
                    } else {
                        // good
                        //this.warning = null;
                    }
                }
            });
            cardCvc.on("change", (error: any) => {
                this.creditCardCvcError = null;
                if (error) {
                    if (error.message) {
                        this.creditCardCvcError = error.message;
                    } else if (error.empty) {
                        this.creditCardCvcError = "Credit card CVC is required";
                    } else if (!error.complete) {
                        this.creditCardCvcError = "Credit card CVC is invalid";
                    } else {
                        // good
                        //this.warning = null;
                    }
                }
            });
        }, 0);
    }

    /**
     * Handles final add of payment account - executed when primary button is clicked
     * @private
     */
    private _processAccount() {
        if (this._sessionService.isLoggedInAs()) {
            this._growlService.error(
                "A Simplifile employee cannot add or edit payment accounts while impersonating a user."
            );
            return;
        }
        if (
            this.step1Group.controls.paymentAccountType.value ===
            PaymentAccountType.BANK
        ) {
            this._processBankAccount();
        } else if (
            this.step1Group.controls.paymentAccountType.value ===
            PaymentAccountType.CREDIT_CARD
        ) {
            this._processCreditCardAccount();
        } else if (
            this.step1Group.controls.paymentAccountType.value ===
            PaymentAccountType.INVOICE
        ) {
            this._processInvoiceAccount();
        }
    }

    private _processBankAccount() {
        //validation data
        this._result.validationData.verifyAccountNumber =
            this.step2Group.controls.verifyAccountNumber.value;
        this._result.validationData.verifyRoutingNumber =
            this.step2Group.controls.verifyRoutingNumber.value;
        this._result.validationData.agreeToTerms = true;

        //account data
        this._result.accountData.organizationID = this.orgId;
        this._result.accountData.accountType =
            this.step1Group.controls.paymentAccountType.value !=
            PaymentAccountType.BANK
                ? this.step1Group.controls.paymentAccountType.value
                : this.step2Group.controls.bankAccountType.value;
        this._result.accountData.signatory.name =
            this.step3Group.controls.signatory.value;
        this._result.accountData.signatory.title =
            this.step3Group.controls.jobTitle.value;
        this._result.accountData.signatory.phoneNumber =
            SfValidators.getNumericDigits(this.step3Group.controls.phone.value);
        this._result.accountData.accountLabel =
            this.step2Group.controls.nickName.value;
        if (
            this._result.accountData.accountType !==
            PaymentAccountType.FROST_BANK
        ) {
            this._result.accountData.routingNumber =
                this.step2Group.controls.routingNumber.value;
        } else {
            this._result.accountData.routingNumber = "";
            this._result.accountData.expiration.month =
                this.step2Group.controls.expirationMonth.value;
            this._result.accountData.expiration.year =
                this.step2Group.controls.expirationYear.value;
        }
        this._result.accountData.accountNumber =
            this.step2Group.controls.accountNumber.value;
        this._result.accountData.bankName =
            this.step2Group.controls.bankName.value;
        this._result.accountData.nameOnBankAccount =
            this.step2Group.controls.nameOnAccount.value;

        //save account information, based on edit or not
        if (this.editMode) {
            this._validateCurrentPage();
            if (this.warning) {
                return;
            }

            this._result.accountData.bankAccountID = (
                this.paymentAccount as BankAccount
            ).bankAccountID;
            if (
                this.hasAdminPermission ||
                (this.hasOrgAccountingPermission && !this.accountUsed)
            ) {
                this._paymentAccountsService
                    .updateBankAccount(
                        this._result.accountData,
                        this._result.validationData
                    )
                    .pipe(first())
                    .subscribe(
                        () => {
                            //if successful, close dialog
                            this._closeDialog(false);
                        },
                        (response) => {
                            this._growlService.error(
                                response.error.errorMessage,
                                null,
                                { disableTimeOut: true }
                            );
                        }
                    );
            } else {
                this._paymentAccountsService
                    .updateAccountLabel(
                        this._result.accountData.organizationID,
                        this._result.accountData.bankAccountID,
                        this._result.accountData.accountLabel
                    )
                    .pipe(first())
                    .subscribe(
                        () => {
                            this._closeDialog(false);
                        },
                        (response) => {
                            this._growlService.error(
                                response.error.errorMessage,
                                null,
                                { disableTimeOut: true }
                            );
                        }
                    );
            }
        } else {
            this.spinnerService.startSpinner();
            this._paymentAccountsService
                .addBankAccount(
                    this._result.accountData,
                    this._result.validationData
                )
                .pipe(first())
                .subscribe(
                    () => {
                        this.spinnerService.stopSpinner();
                        //if successful, close dialog
                        this._addedAccountType =
                            this._result.accountData.accountType;
                        this._closeDialog(false);
                    },
                    (response) => {
                        this.spinnerService.stopSpinner();
                        this._growlService.error(
                            response.error.errorMessage,
                            null,
                            { disableTimeOut: true }
                        );
                    }
                );
        }
    }

    private _processInvoiceAccount() {
        this._validateCurrentPage();
        if (this.warning) {
            return;
        }
        if (this.editMode) {
            let iAccount: InvoiceAccount = JSON.parse(
                JSON.stringify(this.paymentAccount)
            ) as InvoiceAccount;
            iAccount.viewLabel = null;
            iAccount.viewType = null;
            iAccount.label = this.step2GroupInvoice.controls.nickName.value;
            this._paymentAccountsService
                .updateInvoiceAccount(iAccount)
                .pipe(first())
                .subscribe(
                    () => {
                        this._closeDialog(false);
                    },
                    (response) => {
                        this._growlService.error(
                            response.error.errorMessage,
                            null,
                            { disableTimeOut: true }
                        );
                    }
                );
        }
    }

    private _processCreditCardAccount() {
        this._validateCurrentPage();
        if (this.warning) {
            return;
        }
        if (this.editMode) {
            //copy existing account, remove fields not needed to save and update fields
            let ccAccount: CreditCardAccount = JSON.parse(
                JSON.stringify(this.paymentAccount)
            ) as CreditCardAccount;
            ccAccount.viewLabel = null;
            ccAccount.viewType = null;
            delete ccAccount.invalid;
            delete ccAccount.group;
            ccAccount.label = this.step2GroupCC.controls.nickName.value;
            ccAccount.nameOnCard =
                this.step2GroupCC.controls.creditCardName.value;
            ccAccount.zipCode = this.step2GroupCC.controls.creditCardZip.value;
            let expDate: string =
                this.step2GroupCC.controls.creditCardExp.value;
            let parts = expDate.split("/");
            ccAccount.expMonth = parseInt(parts[0], 10);
            ccAccount.expYear = parseInt(parts[1], 10);

            if (this.hasAdminPermission || this.hasOrgAccountingPermission) {
                this._paymentAccountsService
                    .updateCreditCardAccount(ccAccount)
                    .pipe(first())
                    .subscribe(
                        () => {
                            //if successful, close dialog
                            this._closeDialog(false);
                        },
                        (response) => {
                            this._growlService.error(
                                response.error.errorMessage,
                                null,
                                { disableTimeOut: true }
                            );
                        }
                    );
            } else {
                this._paymentAccountsService
                    .updateAccountLabel(
                        ccAccount.organizationID,
                        ccAccount.paymentAccountID,
                        ccAccount.label
                    )
                    .pipe(first())
                    .subscribe(
                        () => {
                            this._closeDialog(false);
                        },
                        (response) => {
                            this._growlService.error(
                                response.error.errorMessage,
                                null,
                                { disableTimeOut: true }
                            );
                        }
                    );
            }
        } else {
            //new credit card
            let nameOnCard: string =
                this.step2GroupCC.controls.creditCardName.value;
            let zipOnCard: string =
                this.step2GroupCC.controls.creditCardZip.value;
            if (!this.creditCardClientSecret) {
                this.warning =
                    "Credit card system busy. Please try again in a minute.";
                return;
            }
            this.spinnerService.startSpinner();
            this.stripe
                .confirmCardSetup(this.creditCardClientSecret, {
                    payment_method: {
                        card: this.creditCardNumberField,
                        billing_details: {
                            name: nameOnCard,
                            address: {
                                postal_code: zipOnCard
                            }
                        }
                    }
                })
                .then(
                    (result: any) => {
                        this.spinnerService.stopSpinner();
                        if (result.error) {
                            this.warning = result.error.message;
                        } else {
                            // success
                            // Use result.setupIntent.payment_method as the card token
                            this._saveCreditCard(result);
                        }
                    },
                    (error: any) => {
                        this.spinnerService.stopSpinner();
                        this.warning = "Unable to verify credit card";
                    }
                );
        }
    }

    private _saveCreditCard(stripeResult: any) {
        let ccAccount: CreditCardAccount = {
            active: true,
            brand: this.creditCardBrand,
            cardType: this.creditCardType,
            expMonth: 0,
            expYear: 0,
            label: this.step2GroupCC.controls.nickName.value,
            nameOnCard: this.step2GroupCC.controls.creditCardName.value,
            zipCode: null,
            organizationID: this.orgId,
            paymentAccountID: null,
            paymentAccountType: PaymentAccountType.CREDIT_CARD, // will be overwritten on back end
            token: stripeResult.setupIntent.payment_method
        };
        this.spinnerService.startSpinner();
        this._paymentAccountsService
            .addCreditCardAccount(ccAccount)
            .pipe(first())
            .subscribe(
                (response) => {
                    this.spinnerService.stopSpinner();
                    //if successful, close dialog
                    this._closeDialog(false, response);
                },
                (response) => {
                    this.spinnerService.stopSpinner();
                    this._growlService.error(
                        response.error.errorMessage,
                        null,
                        { disableTimeOut: true }
                    );
                }
            );
    }

    /**
     * Entry point for page/step validation - calls individual validation based on current step
     * @private
     */
    private _validateCurrentPage() {
        this.warning = null;
        switch (this.stepIndex) {
            case 1:
                this.warning = this._validateAddStep1();
                break;
            case 2:
                this.warning = this._validateAddStep2();
                break;
            case 3:
                this.warning = this._validateAddStep3();
                break;
        }
    }

    /**
     * Validates form controls on step 1
     * @private
     */
    private _validateAddStep1(): string {
        if (!this.step1Group.valid) {
            return "Account Type is required";
        }
        return null;
    }

    private _getTextFieldError(ctrl: AbstractControl, label: string) {
        if (ctrl.errors.required) {
            return label + " is required";
        }
        if (ctrl.errors.ltSpace) {
            return label + " has leading/trailing spaces";
        }

        return null;
    }

    /**
     * Validates form controls on step 2
     * @private
     */
    private _validateAddStep2(): string {
        if (
            this.step1Group.controls.paymentAccountType.value ===
            PaymentAccountType.BANK
        ) {
            return this.validateBankAccount();
        } else if (
            this.step1Group.controls.paymentAccountType.value ===
            PaymentAccountType.CREDIT_CARD
        ) {
            return this.validateCreditCard();
        } else if (
            this.step1Group.controls.paymentAccountType.value ===
            PaymentAccountType.INVOICE
        )
            return this.validateInvoice();
    }

    /**
     * Validates form controls on step 2
     * @private
     */
    private validateBankAccount(): string {
        if (this.step2Group.controls.bankName.errors) {
            return this._getTextFieldError(
                this.step2Group.controls.bankName,
                "Bank Name"
            );
        }
        if (
            this.step2Group.controls.routingNumber.value &&
            this.step2Group.controls.verifyRoutingNumber.value &&
            ((this.step2Group.controls.routingNumber.errors &&
                this.step2Group.controls.routingNumber.errors.noMatch) ||
                (this.step2Group.controls.verifyRoutingNumber.errors &&
                    this.step2Group.controls.verifyRoutingNumber.errors
                        .noMatch))
        ) {
            if (
                this.step2Group.controls.routingNumber.value ==
                this.step2Group.controls.verifyRoutingNumber.value
            ) {
                this.step2Group.controls.routingNumber.setErrors(null);
                this.step2Group.controls.verifyRoutingNumber.setErrors(null);
            }
        }
        if (this.step2Group.controls.routingNumber.errors) {
            let errors: any = this.step2Group.controls.routingNumber.errors;
            if (errors.required) {
                return "ACH Routing Number is required";
            }
            if (errors.minlength) {
                return "ACH Routing Number must be 9 digits";
            }
            if (errors.invalidNumber) {
                if (this.editMode && !this.accountUsed && !this.isSuperUser) {
                    return "You must re-enter the numeric ACH Routing Number";
                } else {
                    return "ACH Routing Number must be numeric";
                }
            }
            if (errors.invalidChecksum) {
                return "ACH Routing Number is invalid (checksum)";
            }
            if (errors.noMatch) {
                return "ACH Routing Number and Verify ACH Routing Number must match";
            }
        }
        if (this.step2Group.controls.verifyRoutingNumber.errors) {
            let errors: any =
                this.step2Group.controls.verifyRoutingNumber.errors;
            if (errors.required) {
                return "Verify ACH Routing Number is required";
            }
            if (errors.minlength) {
                return "Verify ACH Routing Number must be 9 digits";
            }
            if (errors.invalidNumber) {
                if (this.editMode && !this.accountUsed && !this.isSuperUser) {
                    return "You must re-enter the numeric Verify ACH Routing Number";
                } else {
                    return "Verify ACH Routing Number must be numeric";
                }
            }
            if (errors.invalidChecksum) {
                return "Verify ACH Routing Number is invalid (checksum)";
            }
            if (errors.noMatch) {
                return "ACH Routing Number and Verify ACH Routing Number must match";
            }
        }
        if (
            this.step2Group.controls.routingNumber.value !=
            this.step2Group.controls.verifyRoutingNumber.value
        ) {
            this.step2Group.controls.routingNumber.setErrors({
                noMatch: true
            });
            this.step2Group.controls.verifyRoutingNumber.setErrors({
                noMatch: true
            });
            return "ACH Routing Number and Verify ACH Routing Number must match";
        } else {
            this.step2Group.controls.routingNumber.setErrors(null);
            this.step2Group.controls.verifyRoutingNumber.setErrors(null);
        }
        if (
            this.step2Group.controls.accountNumber.value &&
            this.step2Group.controls.verifyAccountNumber.value &&
            ((this.step2Group.controls.accountNumber.errors &&
                this.step2Group.controls.accountNumber.errors.noMatch) ||
                (this.step2Group.controls.verifyAccountNumber.errors &&
                    this.step2Group.controls.verifyAccountNumber.errors
                        .noMatch))
        ) {
            if (
                this.step2Group.controls.accountNumber.value ==
                this.step2Group.controls.verifyAccountNumber.value
            ) {
                this.step2Group.controls.accountNumber.setErrors(null);
                this.step2Group.controls.verifyAccountNumber.setErrors(null);
            }
        }
        if (this.step2Group.controls.accountNumber.errors) {
            let errors: any = this.step2Group.controls.accountNumber.errors;
            if (errors.required) {
                return "Account Number is required";
            }
            if (errors.minlength) {
                return "Account Number must have a minimum of 5 digits";
            }
            if (errors.invalidNumber) {
                if (this.editMode && !this.accountUsed && !this.isSuperUser) {
                    return "You must re-enter the numeric Account Number";
                } else {
                    return "Account Number must be numeric";
                }
            }
            if (errors.noMatch) {
                return "Account Number and Verify Account Number must match";
            }
        }
        if (this.step2Group.controls.verifyAccountNumber.errors) {
            let errors: any =
                this.step2Group.controls.verifyAccountNumber.errors;
            if (errors.required) {
                return "Verify Account Number is required";
            }
            if (errors.minlength) {
                return "Verify Account Number must have a minimum of 5 digits";
            }
            if (errors.invalidNumber) {
                if (this.editMode && !this.accountUsed && !this.isSuperUser) {
                    return "You must re-enter the numeric Verify Account Number";
                } else {
                    return "Verify Account Number must be numeric";
                }
            }
            if (errors.noMatch) {
                return "Account Number and Verify Account Number must match";
            }
        }
        if (
            this.step2Group.controls.accountNumber.value !=
            this.step2Group.controls.verifyAccountNumber.value
        ) {
            this.step2Group.controls.accountNumber.setErrors({
                noMatch: true
            });
            this.step2Group.controls.verifyAccountNumber.setErrors({
                noMatch: true
            });
            return "Account Number and Verify Account Number must match";
        } else {
            this.step2Group.controls.accountNumber.setErrors(null);
            this.step2Group.controls.verifyAccountNumber.setErrors(null);
        }
        if (this.step2Group.controls.expirationMonth.errors) {
            let errors: any = this.step2Group.controls.expirationMonth.errors;
            if (errors.required) {
                return "Expiration Month is required";
            }
            if (errors.minlength) {
                return "Expiration Month must be 2 digits";
            }
            if (errors.invalidNumber) {
                return "Expiration Month must be numeric";
            }
            if (errors.invalidMonth) {
                return "Expiration Month must have value between 1 - 12";
            }
        }
        let month: number = parseInt(
            this.step2Group.controls.expirationMonth.value,
            10
        );
        if (month < 1 || month > 12) {
            this.step2Group.controls.expirationMonth.setErrors({
                invalidMonth: true
            });
            return "Expiration Month must have value between 1 - 12";
        } else {
            this.step2Group.controls.expirationMonth.setErrors(null);
        }
        if (this.step2Group.controls.expirationYear.errors) {
            let errors: any = this.step2Group.controls.expirationYear.errors;
            if (errors.required) {
                return "Expiration Year is required";
            }
            if (errors.minlength) {
                return "Expiration Year must be 2 digits";
            }
            if (errors.invalidNumber) {
                return "Expiration Year must be numeric";
            }
        }

        if (this.step2Group.controls.nameOnAccount.errors) {
            return this._getTextFieldError(
                this.step2Group.controls.nameOnAccount,
                "Name on Account"
            );
        }
        if (this.step2Group.controls.nickName.errors) {
            return this._getTextFieldError(
                this.step2Group.controls.nickName,
                "Account Name"
            );
        }
        return null;
    }

    private validateInvoice(): string {
        if (this.step2GroupInvoice.controls.nickName.errors) {
            return this._getTextFieldError(
                this.step2GroupInvoice.controls.nickName,
                "Account Nickname"
            );
        }

        return null;
    }

    private validateCreditCard(): string {
        //this does NOT do Stripe validation
        if (this.step2GroupCC.controls.nickName.errors) {
            return this._getTextFieldError(
                this.step2GroupCC.controls.nickName,
                "Account Nickname"
            );
        }
        if (this.step2GroupCC.controls.creditCardName.errors) {
            return this._getTextFieldError(
                this.step2GroupCC.controls.creditCardName,
                "Name on Card"
            );
        }
        if (this.step2GroupCC.controls.creditCardZip.errors) {
            let errors: any = this.step2GroupCC.controls.creditCardZip.errors;
            if (errors.required) {
                return "Card Billing Zip Code is required";
            }
            if (errors.invalidZip) {
                return "Card Billing Zip Code is invalid";
            }
        }
        if (this.creditCardNumberError) {
            return this.creditCardNumberError;
        }
        if (this.creditCardDateError) {
            return this.creditCardDateError;
        }
        if (this.creditCardCvcError) {
            return this.creditCardCvcError;
        }
        if (this.step2GroupCC.controls.creditCardExp.errors) {
            let errors: any = this.step2GroupCC.controls.creditCardExp.errors;
            if (errors.required) {
                return "Card expiration date is required";
            }
            if (errors.invalidExpFormat) {
                return "Invalid expiration date format (MM/YYYY)";
            }
            if (errors.invalidMonth) {
                return "Invalid expiration month (01-12)";
            }
            if (errors.invalidExp) {
                return "Invalid expiration date - must be in the future";
            }
        }

        return null;
    }

    /**
     * Validates form controls on step 3
     * @private
     */
    private _validateAddStep3(): string {
        if (this.step3Group.controls.signatory.errors) {
            return this._getTextFieldError(
                this.step3Group.controls.signatory,
                "Signatory"
            );
        }
        if (this.step3Group.controls.jobTitle.errors) {
            return this._getTextFieldError(
                this.step3Group.controls.jobTitle,
                "Job Title"
            );
        }
        if (this.step3Group.controls.phone.errors) {
            let errors: any = this.step3Group.controls.phone.errors;
            if (errors.required) {
                return "Phone Number is required";
            }
            if (errors.invalidNumber) {
                return "Phone Number is invalid";
            }
        }
        if (this.step3Group.controls.companyName.errors) {
            return this._getTextFieldError(
                this.step3Group.controls.companyName,
                "Company Name"
            );
        }
        return null;
    }

    private _getCreditCardExpirationDayjs(
        account: CreditCardAccount
    ): dayjs.Dayjs {
        return dayjs(account.expMonth + "/" + account.expYear, "M/YYYY");
    }

    getCreditCardStatus(account: PaymentAccount): string {
        //getCreditCardStatus(account: CreditCardAccount): CreditCardStatus {
        let card: CreditCardAccount = <CreditCardAccount>account;
        let expDate: dayjs.Dayjs = this._getCreditCardExpirationDayjs(card);
        let now: dayjs.Dayjs = dayjs();

        //check this first because "isBefore()" will give false positive because there is no time
        if (
            expDate.year() == now.year() &&
            (expDate.month() == now.month() ||
                expDate.month() - now.month() == 1)
        ) {
            return "EXPIRING";
        }

        if (expDate.isBefore(now)) {
            return "EXPIRED";
        }

        return "GOOD";
    }

    showRoutingInfo() {
        const modalRef: NgbModalRef = this._modalService.open(
            ConfirmationModalComponent,
            { backdrop: "static" }
        );
        const modalInstance: any = modalRef.componentInstance;

        modalInstance.title = "About ACH Routing Numbers";
        modalInstance.message =
            "<p><b>Confirm ACH Routing Number</b></p>" +
            "<p>Please confirm with your bank the correct routing number used for Automated Clearing House (ACH) " +
            "or electronic payments. This routing number may differ from what's found on your checks.</p>" +
            "<p><b>Remove Debit Blocker</b></p>" +
            "<p>Please confirm that Automated Clearing House (ACH) debit is allowed for Simplifile, as some bank " +
            "accounts may have ACH debits blocked. Please contact your bank and provide the information below to " +
            "allow ACH debits from Simplifile:</p>" +
            "<div class='row'>" +
            "<div class='col-md-4'>Company Name:</div>" +
            "<div class='col-md-4'><b>Simplifile LC</b></div>" +
            "</div>" +
            "<div class='row'>" +
            "<div class='col-md-4'>Company IDs:</div>" +
            "<div class='col-md-4'><b>1010658627</b></div>" +
            "<div class='col-md-4'>(KeyBank)</div> " +
            "</div>" +
            "<div class='row'>" +
            "<div class='col-md-4'>&nbsp;</div>" +
            "<div class='col-md-4'><b>3010658627</b></div>" +
            "<div class='col-md-4'>(Wells Fargo)</div> " +
            "</div>";

        modalInstance.primary = {
            text: "Got It"
        };
        modalInstance.hideSecondary = true;

        modalRef.result.then(
            () => {
                // nothing
            },
            () => {
                // cancel
            }
        );
    }

    copyText(text: string) {
        this._copyTextService.copy(text);
        this._growlService.success(text + " copied to clipboard");
    }

    getCCAccount() {
        return this.paymentAccount as CreditCardAccount;
    }

    validateExpiration(isValidated: boolean) {
        if (isValidated) {
            this.step2GroupCC.controls.creditCardExp.setValidators([
                Validators.required,
                SfValidators.creditCardExp
            ]);
        } else {
            this.step2GroupCC.controls.creditCardExp.setValidators([
                Validators.required
            ]);
        }
    }
}
