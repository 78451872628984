export interface NotaryCredentials {
    actingCounty: string;
    attorney: boolean;
    city: string;
    commissionExpirationDate: any;
    commissionNumber: string;
    officialNumber: string;
    commissionState: string;
    county: string;
    firstName: string;
    lastName: string;
    middleName: string;
    nonResident: boolean;
    notaryCertificateID: string;
    failedCertificateID: string;
    failedCertificateMessage: string;
    signatureFormPageID: string;
    statuses: Record<NotaryMethod, NotaryStatus>;
    state: string;
    street1: string;
    street2: string;
    zip: string;
    acceptedDisclaimer: boolean;
    ronTrainingCompletedDate: any;
    statePrerequisitesComplete: boolean;
    nextENotaryTrainingDate: Date;
    hasP12: boolean;
    rejectionReason: string;
}

export interface NotarySealResponse {
    stampURL: string;
    ronStampURL: string;
    nycStampURL: string;
    expediteStampURL: string;
    notaryStatuses: Record<NotaryMethod, NotaryStatus>;
}

export enum NotaryMethod {
    RON = "RON",
    IPEN = "IPEN",
    INK = "INK"
}

export enum NotaryStatus {
    INCOMPLETE = "INCOMPLETE",
    PENDING_STATE = "PENDING_STATE",
    PENDING_SIMPLIFILE = "PENDING_SIMPLIFILE",
    REJECTED = "REJECTED",
    ACTIVE = "ACTIVE",
    DISABLED = "DISABLED",
    EXPIRED = "EXPIRED"
}
