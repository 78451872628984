import { Component, OnInit, OnDestroy } from "@angular/core";
import { InputAutoCompleteComponent } from "../../components/input-auto-complete/input-auto-complete.component";
import { CustomFormService } from "../../custom-form.service";
import { generateRandomString } from "@sf/common";
import { DynamicFormStore } from "@sf/dynamic-form-viewer";
import { ErecordHighlightService } from "@sf/document/erecord-viewer/common";
import { UntypedFormControl } from "@angular/forms";
import { dayjs } from "@sf/common";

@Component({
    selector: "sf-custom-date-field",
    templateUrl: "./custom-date-field.component.html",
    styleUrls: ["./custom-date-field.component.scss"]
})
export class CustomDateFieldComponent
    extends InputAutoCompleteComponent
    implements OnInit, OnDestroy
{
    randomName: string;

    constructor(
        protected _formFacade: DynamicFormStore,
        protected _customFormService: CustomFormService,
        protected _highlightService: ErecordHighlightService
    ) {
        super(_formFacade, _customFormService, _highlightService);
    }

    ngOnInit() {
        this.randomName = generateRandomString(5);
        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    showHighlight() {
        super.showHighlight();
    }

    hideHighlight() {
        super.hideHighlight();
    }

    checkRequired(): boolean {
        if (this.field.defaults.formState.validators.length > 0) {
            return this.field.defaults.formState.validators.some(
                (validator) => validator.identifier === "required"
            );
        }
        return false;
    }

    assignValue(control: UntypedFormControl, dateValue: string) {
        control.patchValue(dayjs(dateValue));
    }
}
