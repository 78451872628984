<div *ngIf="!showingRecentItems">
    <table>
        <tr>
            <td>
                <span class="float-start truncate-name" tooltipClass="tooltip-interaction-not">
                    <button id="btn-name" class="btn btn-link sf-btn-link truncate" sfTooltipOnOverflow="{{ item.lenderLoanNumber ?? item.settlementFileNumber }}" [openDelay]="100"
                            (click)="uscService.goToCapcLoanDetails(item)">
                        {{ item.lenderLoanNumber ?? item.settlementFileNumber ?? 'Missing ID' }}
                    </button>
                </span>
            </td>
            <td style="width: 100%">
                <span class="float-end">
                    <button *ngIf="!!item.status" id="btn-status" class="btn btn-link sf-btn-link truncate" tooltipClass="tooltip-interaction-not"
                            (click)="uscService.goToCapcLoanDetails(item)" placement="left">
                        {{ item.status }}
                    </button>
                </span>
            </td>

        </tr>
        <tr>
            <td>
                <div class="lessImportant">
                    <span class="float-start truncate" tooltipClass="tooltip-interaction-not" sfTooltipOnOverflow="{{item.borrowerName}}" [openDelay]="100">
                        {{ item.borrowerName }}
                    </span>
                </div>
            </td>
            <td>
                <div class="lessImportant">
                    <span class="float-end" tooltipClass="tooltip-interaction-not" sfTooltipOnOverflow="{{item.lenderOrganizationName}}" [openDelay]="100">
                        {{ item.lenderOrganizationName }}
                    </span>
                </div>
            </td>
        </tr>
    </table>
</div>
