import {
    Component,
    ComponentRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    Type
} from "@angular/core";
import {
    CdkPortalOutletAttachedRef,
    ComponentPortal
} from "@angular/cdk/portal";
import { Observable } from "rxjs";
import {
    COMPONENT_FROM_FIELD,
    DynamicFormStore,
    FieldState,
    InputComponent
} from "@sf/dynamic-form-viewer";
import { CustomGroupFieldComponent } from "../custom-group-field/custom-group-field.component";
import { ErecordDocumentDetails } from "@sf/document/erecord-viewer/common";

@Component({
    selector: "sf-custom-array-field-item-wrapper",
    templateUrl: "./custom-array-field-item-wrapper.component.html",
    styleUrls: ["./custom-array-field-item-wrapper.component.scss"]
})
export class CustomArrayFieldItemWrapperComponent implements OnInit {
    @Input() field: FieldState;
    @Input() controlPath: string;
    @Input() disabled$: Observable<boolean>;
    @Input() docLeftData: ErecordDocumentDetails;
    @Input() autoCompleteData?: Observable<string[]>;
    @Input() onSave: Observable<any>;
    @Input() onExpandableSet?: Observable<any>;
    @Output() onPartyTypeChanged: EventEmitter<string | number> =
        new EventEmitter();
    @Output() onPartySelection?: EventEmitter<any> = new EventEmitter();

    componentPortal: ComponentPortal<InputComponent>;

    constructor(
        private _store: DynamicFormStore,
        @Inject(COMPONENT_FROM_FIELD)
        private _getComponentFromField: (
            field: FieldState
        ) => Type<InputComponent>
    ) {}

    ngOnInit() {
        this.componentPortal = new ComponentPortal(
            this._getComponentFromField(this.field)
        );
    }

    attached(componentRef: CdkPortalOutletAttachedRef) {
        const component = componentRef as ComponentRef<InputComponent>;
        const instance = component.instance;

        if (instance instanceof CustomGroupFieldComponent) {
            instance.autoCompleteData = this.autoCompleteData;
            instance.onPartySelection = this.onPartySelection;
            instance.docLeftData = this.docLeftData;
            instance.onSave = this.onSave;
            instance.onExpandableSet = this.onExpandableSet;
        }

        instance.field = this.field;
        instance.controlPath = this.controlPath;
        (instance as CustomGroupFieldComponent).onPartyTypeChanged =
            this.onPartyTypeChanged;
    }

    remove() {
        this._store.removeFieldFromArray(this.field.fullPath, this.controlPath);
    }
}
