import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseModalComponent, ModalButton } from "@sf/common";
import { SelectableUser } from "@sf/userorg/common";
import { SelectableOrganization } from "@sf/userorg/common";

export interface SelectableUserWithAssignments extends SelectableUser {
    assignments: any[];
}

// prettier-ignore
@Component({
    selector: "sf-add-role-dialog",
    templateUrl: "./add-role-dialog.component.html",
    styleUrls: ["./add-role-dialog.component.scss"]
})
export class AddRoleDialogComponent extends BaseModalComponent implements OnInit, AfterViewInit {
    @Input() selectedUserIDs: string[];
    @Input() selectedOrganizationIDs: string[];
    @Input() selectedRoleIDs: string[];
    @Input() userOptions: SelectableUserWithAssignments[];
    @Input() allUserOptions: SelectableUserWithAssignments[];
    @Input() organizationOptions: SelectableOrganization[];
    @Input() allOrganizationOptions: SelectableOrganization[];
    @Input() lockedUserName: string;
    @Input() lockedOrganizationName: string;
    @Input() lockedRoleName: string;
    @Input() selectableServices: string[];

    existingRoleIDs: string[] = []; // role IDs the user already has

    primary: ModalButton;
    secondary: ModalButton;
    warning = "";
    initialized = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private activeModal: NgbActiveModal,
        private modalService: NgbModal
    ) {
        super();
    }

    ngOnInit() {
        // Modal buttons
        this.primary = {
            text: "Assign Roles",
            disabled: false,
            callback: this.resolve.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };

        let options: SelectableOrganization[];
        let selections: string[] = null;
        if (this.lockedOrganizationName) {
            options = this.userOptions;
            selections = this.selectedUserIDs;
        } else {
            options = this.organizationOptions;
            selections = this.selectedOrganizationIDs;
        }

        // mark the selected items as 'selected'
        if (selections) {
            options.forEach((option) => {
                option.selected = selections.includes(option.id);
            });
        }

        let event: any = {};
        if (this.lockedOrganizationName) {
            event.$selection = this.selectedUserIDs;
            this.usersChanged(event);
        } else {
            event.$selection = this.selectedOrganizationIDs;
            this.orgsChanged(event);
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.initialized = true;
        }, 0);
    }

    orgsChanged($event: any) {
        let orgIDs: string[] = [];
        if ($event.hasOwnProperty("$selection")) {
            orgIDs = $event.$selection;
            this.selectedOrganizationIDs = orgIDs;
            this.updateExistingRoles();
        }
    }

    usersChanged($event: any) {
        let userIDs: string[] = [];
        if ($event.hasOwnProperty("$selection")) {
            userIDs = $event.$selection;
            this.selectedUserIDs = userIDs;
            this.updateExistingRoles();
        }
    }

    rolesChanged($event: any) {
        let roleIDs: string[] = [];
        if ($event.hasOwnProperty("$selection")) {
            roleIDs = $event.$selection;
            this.selectedRoleIDs = roleIDs;
        }
    }

    updateExistingRoles() {
        let existingRoleIDs: string[] = null;
        let keepers: string[] = null;

        function mergeUnit(unit: any) {
            if (existingRoleIDs == null) {
                existingRoleIDs = [];
                if (unit.assignments) {
                    unit.assignments.forEach((assignment: any) => {
                        if (!assignment.virtual) {
                            existingRoleIDs.push(assignment.id);
                        }
                    });
                }
            } else if (existingRoleIDs.length) {
                keepers = [];
                if (unit.assignments) {
                    unit.assignments.forEach((assignment: any) => {
                        if (!assignment.virtual) {
                            if (existingRoleIDs.includes(assignment.id)) {
                                keepers.push(assignment.id);
                            }
                        }
                    });
                }
                existingRoleIDs = keepers;
            }
        }

        if (this.lockedOrganizationName) {
            if (this.selectedUserIDs) {
                this.selectedUserIDs.forEach((userID) => {
                    let existingUser = this.findExistingUser(userID);
                    if (existingUser) {
                        mergeUnit(existingUser);
                    } else {
                        existingRoleIDs = [];
                    }
                });
            }
        } else {
            if (this.selectedOrganizationIDs) {
                this.selectedOrganizationIDs.forEach((orgID) => {
                    let existingOrg = this.findExistingOrganization(orgID);
                    if (existingOrg) {
                        mergeUnit(existingOrg);
                    } else {
                        existingRoleIDs = [];
                    }
                });
            }
        }
        this.existingRoleIDs = existingRoleIDs ? existingRoleIDs : [];
    }

    findExistingUser(userID: string): SelectableUserWithAssignments {
        let foundUser = null;
        this.userOptions.forEach((user) => {
            if (user.id == userID) {
                foundUser = user;
            }
        });
        return foundUser;
    }

    findExistingOrganization(orgID: string): SelectableOrganization {
        let foundOrg = null;
        if (this.organizationOptions && this.organizationOptions.length) {
            this.organizationOptions.forEach((org) => {
                if (org.id == orgID) {
                    foundOrg = org;
                }
            });
        }
        return foundOrg;
    }

    resolve() {
        this.primary.disabled = true;
        let message = this.getErrorMessage();
        if (message != null) {
            this.warning = message;
            this.primary.disabled = false;
            return;
        }

        let selected = {
            organizationIDs: this.selectedOrganizationIDs,
            userIDs: this.selectedUserIDs,
            roleIDs: this.selectedRoleIDs
        };

        this.primary.disabled = false;
        this.activeModal.close(selected);
    }

    getErrorMessage(): string {
        if (
            !this.selectedOrganizationIDs ||
            !this.selectedOrganizationIDs.length
        ) {
            return "You must select an organization";
        }

        if (!this.selectedUserIDs || !this.selectedUserIDs.length) {
            return "You must select a user";
        }

        if (!this.selectedRoleIDs || !this.selectedRoleIDs.length) {
            return "You must select at least one role";
        }

        return null; // no errors detected
    }

    dismiss() {
        this.activeModal.close(null);
    }
}
