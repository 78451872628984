<div class="filter-box">
    <sf-search *ngIf="!lock" class="label-item" [model]="searchText"
        placeholder="Search all users"
        (cancelEvent)="cancelSearch()"
        [enable]="true" [showResults]="true"
        (onChange)="onSearchChange()"></sf-search>
    <span *ngIf="lock" class="label-item">User</span>
</div>

<div class="selector-top">
    <div *ngIf="!lock" class="btn-group btn-group-toggle switch" ngbRadioGroup (change)="headerToggle()"
        [(ngModel)]="selectedViewOption">
        <label ngbButtonLabel class="btn-secondary switch-item" ngbTooltip="Select one or more users below">
            <input ngbButton type="radio" value="all"/>
            <span class="">{{selectionText()}}</span>
        </label>
        <label ngbButtonLabel class="btn-secondary switch-item" ngbTooltip="Show current selection">
            <input ngbButton type="radio" value="selected"/>
            <div class="switch-right">
                <div class="float-start">Selected</div>
                <div class="float-end" *ngIf="selectedUsers.length">
                    <button class="btn btn-secondary btn-tiny" (click)="clearSelection()">Clear</button>
                </div>
            </div>
        </label>
    </div>

    <div *ngIf="lock" class="lock-text clip-text">
        {{selectionText()}}
    </div>
</div>

<div *ngIf="!lock" class="full-height">
    <!-- list of all the selectable options -->
    <cdk-virtual-scroll-viewport itemSize="21" id="sf-userorg-user-selector" *ngIf="selectedViewOption=='all'" class="selector-bottom"
        [ngClass]="{missing: !selectedUsers.length}">
        <div *cdkVirtualFor="let user of filteredUsers" class="list-item">
            <label>
                <input type="checkbox" value="{{user.id}}" [(ngModel)]="user.selected" #checkbox
                    (click)="checkChange($event)" (keyup)="checkChange($event)"/>
                <span class="tooltip-info-pending"
                    ngbTooltip="{{calculateTooltip(user)}}" [openDelay]="400" [closeDelay]="0">
                        &nbsp;{{user.label}}
                    </span>
            </label>
        </div>
        <div class="no-checkbox" *ngIf="!usersList || !usersList.length">&nbsp;</div>
    </cdk-virtual-scroll-viewport>
    <!-- list of the currently selected options -->
    <cdk-virtual-scroll-viewport itemSize="21" id="sf-userorg-user-selector-selected" *ngIf="selectedViewOption=='selected'" class="selector-bottom">
        <div *cdkVirtualFor="let user of selectedUsers" class="list-item">
            <label>
                <input type="checkbox" value="{{user.id}}" [(ngModel)]="user.selected" #checkbox
                    (click)="checkChange($event)" (keyup)="checkChange($event)"/>
                <span class="tooltip-info-pending"
                    ngbTooltip="{{calculateTooltip(user)}})" [openDelay]="400" [closeDelay]="0">
                        &nbsp;{{user.label}}
                    </span>
            </label>
        </div>
        <div class="no-checkbox" *ngIf="!selectedUsers || !selectedUsers.length">&nbsp;</div>
    </cdk-virtual-scroll-viewport>
</div>
