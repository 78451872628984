/**
Commonly-used regex patterns
 **/

/*
   matches a list of multiple valid email addresses separated by commas or semicolons
   the full email specification (RFC 5322) is very complex
   this regex is a pragmatic, general-purpose solution, but may not address every possible case

   see unit tests for valid and invalid examples
 */
export const emailDelimitedListRegex: RegExp =
    /^(((\s*[^<>\(\)\[\]\\.,;:\s@"]+(\.[^<>\(\)\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))((\s*[,;]\s*)|(\s*(?!\s*\S))))+$/i;

/*
   matches a URL format and ensures it conforms to common web address patterns:
    - handles http:// and https://
    - allows authentication pattern (user:password@)
    - allows IPv4 or domain names with certain common TLDs (not comprehensive)
    - supports optional port numbers
    - does NOT validate query parameters or fragments with strict precision

   see unit tests for valid and invalid examples
 */
export const urlRegex: RegExp =
    /^https?:\/\/([a-zA-Z0-9.\-]+(:[a-zA-Z0-9.&%$\-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|properties|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_\-]+))*$/i;
