import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectableItemWithID } from "@sf/common";
import { ModalButton } from "@sf/common";
import { SpinnerService } from "@sf/common";
import { OrganizationArchiveService } from "@sf/userorg/common";
import { Organization } from "@sf/userorg/common";
import { first } from "rxjs/operators";

@Component({
    selector: "sf-archive-organization-dialog",
    templateUrl: "./archive-organization-dialog.component.html",
    styleUrls: ["./archive-organization-dialog.component.scss"]
})
export class ArchiveOrganizationDialogComponent implements OnInit {
    @Input()
    organization: Organization;

    archivedReasons: SelectableItemWithID[] = [
        { id: "CUSTOMER_REQUEST", label: "Customer Request" },
        { id: "DUPLICATE", label: "Duplicate Organization" },
        { id: "ERROR", label: "Organization Created In Error" },
        { id: "TEST", label: "Test Organization" },
        { id: "OTHER", label: "Other" }
    ];
    details = "";
    archivedReason: string = null;
    detailsRequired = false;
    displayName: string;

    primary: ModalButton;
    secondary: ModalButton;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private activeModal: NgbActiveModal,
        private spinnerService: SpinnerService,
        private archiveService: OrganizationArchiveService
    ) {}

    ngOnInit() {
        this.displayName =
            this.organization.name + " (" + this.organization.id + ")";

        // Modal buttons
        this.primary = {
            text: "Archive",
            disabled: false,
            callback: this.resolve.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };
    }

    handleSelect(event: any) {
        if (event.hasOwnProperty("$selection")) {
            let selection: SelectableItemWithID = event.$selection;
            this.archivedReason = selection.id;
            this.detailsRequired = this.archivedReason != "OTHER";
            this.focusElement("details");
        }
    }

    focusElement(elementID: string) {
        window.setTimeout(() => {
            let theField = document.getElementById(elementID);
            if (theField) {
                theField.focus();
            }
        }, 200);
    }

    dismiss() {
        this.activeModal.close(false);
    }

    resolve() {
        if (!this.archivedReason) {
            return;
        }

        if (this.detailsRequired && !this.details) {
            return;
        }

        this.spinnerService.startSpinner();
        this.archiveService
            .archiveOrganization(
                this.organization.id,
                this.archivedReason,
                this.details
            )
            .pipe(first())
            .subscribe(
                () => {
                    this.activeModal.close(true);
                    this.spinnerService.stopSpinner();
                },
                () => {
                    this.spinnerService.stopSpinner();
                }
            );
    }
}
