import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { TokenService } from "@sf/userorg/common";
import { ConfirmationModalComponent, GrowlService } from "@sf/common";
import { takeUntil } from "rxjs/operators";
import { CancelInvitationDialogComponent } from "../../dialogs/cancel-invitation-dialog/cancel-invitation-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

declare const window: any;

// prettier-ignore
@Component({
    selector: "sf-cancel-invitation",
    templateUrl: "./cancel-invitation.component.html",
    styleUrls: ["./cancel-invitation.component.scss"]
})
export class CancelInvitationComponent implements OnInit, OnDestroy {
    loaded = false;
    loadFailed: string = null;
    tokenID: string = null;
    //cancelForm: UntypedFormGroup;

    private _ngOnDestroy: Subject<void>;

    constructor(
            private route: ActivatedRoute,
            private tokenService: TokenService,
            private modalService: NgbModal,
            private growlService: GrowlService
    ) {}

    ngOnInit() {
        this._ngOnDestroy = new Subject();

        this.route.paramMap
            .pipe(takeUntil(this._ngOnDestroy))
            .subscribe((params) => {
                this.tokenID = params.get("tokenID");
                this.loadPageInitial(this.tokenID);
            });

    }

    ngOnDestroy() {
        this._ngOnDestroy.next();
    }

    loadPageInitial(invitationTokenID: string) {
        this.loaded = false;

        this.tokenService.loginWithToken(invitationTokenID, [
                "INVITE_USER", "INVITE_SERVICE", "INVITE_ORGANIZATION"
            ])
            .subscribe((response: any) => {
                /*
                this.tokenService.getInvitationByID(invitationTokenID)
                    .subscribe((response: any) => {
                        this.loaded = true;
                    }, (response: any) => {
                        this.loaded = true;
                        this.loadFailed = response.error.errorMessage;
                    });
                */
                this.loaded = true;
                this.doCancel();
            }, (response: any) => {
                this.loadFailed = response.error.errorMessage;
                this.loaded = true;
            });
    }

    doCancel() {
        const modalRef = this.modalService.open(
                CancelInvitationDialogComponent
        );
        const modalInstance = modalRef.componentInstance;
        modalInstance.tokenID = this.tokenID;
        modalRef.result.then(
                (result: any) => {
                    if (result) {
                        sf.metricLog.recordMetric(
                                "onboarding",
                                "cancelInvitation",
                                1,
                                false
                        );
                        this.invitationCanceled(result);
                    }
                },
                () => {
                    // nothing
                }
        );
    }

    invitationCanceled(action: string) {
        const modal = this.modalService.open(ConfirmationModalComponent);
        const modalInstance = modal.componentInstance;

        modalInstance.title = "Invitation Canceled";
        modalInstance.primary = {
            text: "OK",
            responseValue: 1,
            callback: this.goToLogin.bind(this)
        };
        modalInstance.hideSecondary = true;
        modalInstance.message =
                "Thank you. Your invitation has been successfully canceled";
        if (action == "allInvites") {
            modalInstance.message +=
                    ", and you will not receive future invitations from Simplifile. You may receive other types of emails from Simplifile.";
        } else {
            modalInstance.message += ".";
        }
    }

    goToLogin() {
        window.location = "/sf/ui/login";
    }

}
