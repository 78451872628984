<span ngbDropdown id="loanViewSelect" role="none">
    <button ngbDropdownToggle
            aria-labelledby="loanViewSelect"
            role="link"
            class="btn btn-link sf-btn-link dropdown-toggle sort-link"
            [ngClass]="{'with-clear': showClear}"
            [ngbTooltip]="activeView?.tooltip || 'Manage / select from custom list of saved views'"
            [openDelay]="activeView?.tooltipDelay || 1000">
        {{activeView?.name}}<i *ngIf="viewChanged && !activeView?.unsaved"> (modified)</i>
    </button>
    <button (click)="doClear()"
            [hidden]="!showClear"
            class="btn btn-link sf-btn-link clear"
            aria-label="Click to clear field values"
            ngbTooltip="Click to clear field values">
            <fa-icon [icon]="['far', 'times']"></fa-icon>
    </button>
    <div class="dropdown-menu dropdown-menu" role="menu" ngbDropdownMenu>
        <div class="view-menu-scroll">
            <button *ngFor="let view of possibleViews"
                    ngbDropdownItem role="menuitem" class="btn"
                    (click)="selectView(view)">
                <fa-icon [icon]="['far', 'check']" *ngIf="view.name === activeView?.name" [attr.aria-label]="'active view'"></fa-icon>
                {{view.name}}
                <fa-icon [icon]="['far', 'star']" class="float-end me-0" *ngIf="view.default" [attr.aria-label]="'default view'"></fa-icon>
            </button>
        </div>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem role="menuitem" class="btn"
                [disabled]="activeView?.default"
                (click)="setCurrentAsDefault()">
            Set Current View as default
        </button>
        <button ngbDropdownItem role="menuitem" class="btn" (click)="saveCurrentAs()">
            Create New from Current...
        </button>
        <button ngbDropdownItem role="menuitem" class="btn"
                [disabled]="!viewChanged || activeView?.unsaved"
                ngbTooltip="Update '{{activeView?.name}}' with current view settings"
                (click)="updateView()">
            Update Current View...
        </button>
        <button ngbDropdownItem role="menuitem" class="btn" (click)="manageViews()">
            Manage Saved Views...
        </button>
    </div>
</span>

<ng-template #nameInputTemplate>
    <input type="text"
           ngbAutofocus
           placeholder="View name"
           [(ngModel)]="inputViewName"
           (keyup.enter)="onEnter()"
           (ngModelChange)="labelChanged()"/>
    <div *ngIf="nameExists" class="mt-3 name-error">A view named '{{nameExists}}' already exists</div>
</ng-template>

<ng-template #manageViewsTemplate>
    <div class="row table-header">
        <div class="col-10">
            View Name
        </div>
        <div class="col-1">
        </div>
        <div class="col-1">
        </div>
    </div>
    <div *ngFor="let view of possibleViews" class="row item" (dblclick)="setDefault(view)">
        <div class="col-10">
            {{view.name}}
        </div>
        <div class="col-1">
            <fa-icon [icon]="['far', 'star']" *ngIf="view.default"></fa-icon>
        </div>
        <div class="col-1">
            <span ngbDropdown [container]="'body'">
                <button ngbDropdownToggle
                        class="float-end btn btn-link sf-btn-link"
                        [attr.aria-label]="view.name + (view.default ? ' default view ' : '') + ' action menu'">
                    <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
                </button>
                <div class="action-menu" ngbDropdownMenu>
                    <button ngbDropdownItem role="menuitem" class="btn" (click)="selectView(view)">
                        Activate
                    </button>
                    <button ngbDropdownItem role="menuitem" class="btn" (click)="copyView(view)">
                        Copy
                    </button>
                    <button ngbDropdownItem role="menuitem" class="btn" (click)="removeView(view)">
                        Remove
                    </button>
                    <button ngbDropdownItem role="menuitem" class="btn" (click)="renameView(view)">
                        Rename
                    </button>
                    <button ngbDropdownItem role="menuitem" class="btn" (click)="setDefault(view)">
                        Set as Default
                    </button>
                </div>
            </span>
        </div>
    </div>
</ng-template>
