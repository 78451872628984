import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {
    DynamicField,
    DynamicFormStore,
    InputComponent
} from "@sf/dynamic-form-viewer";
import { CustomFunctionType } from "../../interfaces/custom-function-type.enum";
import { ButtonDynamicField } from "../../interfaces/button-dynamic-field.interface";
import { ErecordDocumentDetails } from "@sf/document/erecord-viewer/common";
import { dayjs, SessionService, SpinnerService } from "@sf/common";
import { CustomFormTrayService } from "../../services/custom-form-tray.service";
import { switchMap, take } from "rxjs/operators";
import { Observable, of, zip } from "rxjs";
import {
    AbstractControl,
    UntypedFormControl,
    ValidatorFn,
    Validators
} from "@angular/forms";
import { CustomFormService } from "../../custom-form.service";
import { GrowlService } from "@sf/common";
import { generateRandomString } from "@sf/common";
import { CurrentOrganizationService } from "@sf/userorg/common";
import { ActivatedRoute } from "@angular/router";
import { CustomButtonRefreshService } from "../../services/custom-button-refresh.service";

@Component({
    selector: "sf-custom-button-field",
    templateUrl: "./custom-button-field.component.html",
    styleUrls: ["./custom-button-field.component.scss"]
})
export class CustomButtonFieldComponent
    extends InputComponent
    implements OnInit, OnChanges
{
    private readonly UPLOAD_PATH: string = "/sf/uploadFile";
    private _defaultDynamicLabels: any = {
        "postRecord1.label": "Post Record 1",
        "postRecord2.label": "Post Record 2",
        "postRecord1.button": "Entered",
        "postRecord2.button": "Entered",
        "queue.lowPriority.label": "Low Priority",
        "queue.onHold.label": "On Hold"
    };

    customText: any = {
        "reviewQueue.pages": "Pages",
        "reviewQueue.validate": "Validate",
        "reviewQueue.unvalidate": "unValidate",
        "timeshareQueue.pages": "Pages",
        "uploadQueue.entry": "Entry",
        "uploadQueue.pages": "Pages",
        "printQueue.entry": "Entry",
        "printQueue.pages": "Pages",
        "recordQueue.grantor": "Grantor",
        "recordQueue.grantee": "Grantee",
        "recordQueue.receiptID": "Receipt ID",
        "recordQueue.entryNumbers": "Entry Numbers",
        "recordQueue.type": "Type",
        "recordQueue.page": "Page",
        "recordQueue.book": "Book",
        "postRecord1.label": "",
        "postRecord2.label": "",
        "postRecord1.button": "",
        "postRecord2.button": "",
        "queue.lowPriority.label": "",
        "queue.onHold.label": ""
    };
    customProperties: any = {};
    legalDescriptionUploaded: boolean;
    validated: boolean = false;
    validateToggleWaiting: boolean = false;
    exciseAgain: string = "";
    formFields: DynamicField[] = [];
    documentID: string = "";
    inputID: string = generateRandomString(5);
    exciseAgainRequired: boolean = false;
    exciseLength: number = 6;

    WRITE_TO_FILE = CustomFunctionType.WRITE_TO_FILE;
    VALIDATE_EXCISE = CustomFunctionType.VALIDATE_EXCISE;
    REVIEW_VALIDATE = CustomFunctionType.REVIEW_VALIDATE;
    NYC_LOT = CustomFunctionType.NYC_LOT;
    NYC_UNIT = CustomFunctionType.NYC_UNIT;
    LINK = CustomFunctionType.LINK;
    RECIPIENT_ACTION = CustomFunctionType.RECIPIENT_ACTION;

    private _groupPath: string;

    @Input()
    docLeftData?: ErecordDocumentDetails;

    @Input() onSave?: Observable<any>;

    // docLeftData.packageData.customizations.customProperties;
    // docLeftData.recipientConfig.customText;
    customFunctionName: string;
    linkURL: string;
    constructor(
        protected _formFacade: DynamicFormStore,
        private _growlService: GrowlService,
        private _sfSpinnerService: SpinnerService,
        private _trayService: CustomFormTrayService,
        private _customFormService: CustomFormService,
        private _customButtonRefreshService: CustomButtonRefreshService,
        private _currentOrgService: CurrentOrganizationService,
        private _activatedRoute: ActivatedRoute,
        private _sessionService: SessionService
    ) {
        super(_formFacade);
    }

    ngOnInit() {
        super.ngOnInit();

        this.customFunctionName = (
            this.field as any as ButtonDynamicField
        ).customFunction;
        this.linkURL = (this.field as any as ButtonDynamicField).linkURL;
        this._groupPath = this.field.fullPath.split(this.field.path)[0];
        this._formFacade.rootField$.subscribe((rootField) => {
            this.formFields = rootField.fields;
        });

        this._update();

        if (this.customFunctionName === this.VALIDATE_EXCISE) {
            this.formControl$
                .pipe(take(1))
                .subscribe((formControl: UntypedFormControl) => {
                    // excise and exciseAgain fields are required, must match, and must be the length of the reviewQueue.exciseNumberLength custom property (default 6)
                    const exciseAgainCrossValidator: ValidatorFn = (
                        control: AbstractControl
                    ) => {
                        return formControl.value === this.exciseAgain
                            ? null
                            : { mismatchError: true };
                    };
                    this.exciseLength = this.customProperties[
                        "reviewQueue.exciseNumberLength"
                    ]
                        ? parseInt(
                              this.customProperties[
                                  "reviewQueue.exciseNumberLength"
                              ]
                          )
                        : 6;
                    formControl.setValidators([
                        Validators.required,
                        Validators.minLength(this.exciseLength),
                        Validators.maxLength(this.exciseLength),
                        exciseAgainCrossValidator
                    ]);
                    this.exciseAgainRequired = true;

                    formControl.updateValueAndValidity();
                });
        }

        this.onSave.subscribe(() => {
            switch (this.customFunctionName) {
                case this.WRITE_TO_FILE:
                    break;
                case this.VALIDATE_EXCISE:
                    this._customFormService
                        .runRecipientCustomizationsOnDocument(this.documentID)
                        .subscribe(() => {
                            this._customButtonRefreshService.triggerPagesRefresh();
                        });
                    break;
                case this.LINK:
                    break;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.docLeftData) {
            this._update();
        }
    }

    callCustomFunction() {
        switch (this.customFunctionName) {
            case this.WRITE_TO_FILE:
                this._reetaWriteToFile();
                break;
            case this.VALIDATE_EXCISE:
                this._validateExciseNumber();
                break;
            case this.LINK:
                this._buttonAsLink();
                break;
            case this.RECIPIENT_ACTION:
                this._recipientAction();
                break;
        }
    }

    findAddress() {
        this._sfSpinnerService.startSpinner();

        zip(
            this._findControl(this._groupPath + "addressCity#nYCBorough"), // addressCity
            this._findControl(this._groupPath + "blockNumber#blockNumber"), // blockNumber
            this._findControl(this._groupPath + "lotNumber#NYCLOT"), // lotNumber
            this._findControl(this._groupPath + "unitNumber#NYCUNIT"), // unitNumber
            this._findControl(
                this._groupPath + "streetNumber#LEGALADDRESSSTREET"
            ), // streetNumber
            this._findControl(this._groupPath + "streetName#NYCLEGALSTREETNAME") // streetName
        )
            .pipe(take(1))
            .subscribe(
                (fields) => {
                    let borough = (fields[0] as UntypedFormControl)?.value;
                    let block = (fields[1] as UntypedFormControl)?.value;
                    let lot = (fields[2] as UntypedFormControl)?.value;
                    if (borough && block && lot) {
                        if (isNaN(+block) || isNaN(+lot)) {
                            this._sfSpinnerService.stopSpinner();
                            this._growlService.error(
                                "Unable to find an address based on the Borough, Block, and Lot information provided.  Please check the information provided for accuracy."
                            );
                        } else {
                            this._customFormService
                                .getNYAddress(
                                    this.docLeftData?.packageData?.document?.id,
                                    borough,
                                    block,
                                    lot
                                )
                                .subscribe(
                                    (result: any) => {
                                        if (result.error) {
                                            this._sfSpinnerService.stopSpinner();
                                            this._growlService.error(
                                                "Unable to find an address based on the Borough, Block, and Lot information provided.  Please check the information provided for accuracy."
                                            );
                                        } else if (
                                            result.streetNumber === "" &&
                                            result.streetName === "" &&
                                            result.addressUnit === ""
                                        ) {
                                            this._sfSpinnerService.stopSpinner();
                                            this._growlService.error(
                                                "Unable to find an address based on the Borough, Block, and Lot information provided.  Please check the information provided for accuracy."
                                            );
                                        } else {
                                            (
                                                fields[5] as UntypedFormControl
                                            )?.setValue(result.streetName);
                                            (
                                                fields[4] as UntypedFormControl
                                            )?.setValue(result.streetNumber);
                                            (
                                                fields[3] as UntypedFormControl
                                            )?.setValue(result.addressUnit);
                                            this._sfSpinnerService.stopSpinner();
                                        }
                                    },
                                    () => {
                                        this._sfSpinnerService.stopSpinner();
                                    }
                                );
                        }
                    } else {
                        this._sfSpinnerService.stopSpinner();
                        this._growlService.error(
                            "You must enter a Borough, Block, and Lot to find an address."
                        );
                    }
                },
                () => {
                    this._sfSpinnerService.stopSpinner();
                }
            );
    }

    findBlockAndLot() {
        this._sfSpinnerService.startSpinner();
        zip(
            this._findControl(this._groupPath + "addressCity#nYCBorough"), // addressCity
            this._findControl(this._groupPath + "unitNumber#NYCUNIT"), // unitNumber
            this._findControl(
                this._groupPath + "streetNumber#LEGALADDRESSSTREET"
            ), // streetNumber
            this._findControl(
                this._groupPath + "streetName#NYCLEGALSTREETNAME"
            ), // streetName
            this._findControl(this._groupPath + "blockNumber#blockNumber"), // blockNumber
            this._findControl(this._groupPath + "lotNumber#NYCLOT") // lotNumber
        )
            .pipe(take(1))
            .subscribe(
                (fields) => {
                    let borough = (fields[0] as UntypedFormControl)?.value;
                    let unit = (fields[1] as UntypedFormControl)?.value;
                    let streetNumber = (fields[2] as UntypedFormControl)?.value;
                    let streetName = (fields[3] as UntypedFormControl)?.value;
                    unit = unit ? unit : " ";

                    if (borough && streetNumber && streetName) {
                        let blockControl: UntypedFormControl = fields[4];
                        let lotControl: UntypedFormControl = fields[5];

                        this._customFormService
                            .getBoroughBlockLot(
                                this.docLeftData?.packageData?.document?.id,
                                borough,
                                streetName,
                                streetNumber,
                                unit
                            )
                            .subscribe(
                                (result: any) => {
                                    if (result.error) {
                                        this._sfSpinnerService.stopSpinner();
                                        this._growlService.error(
                                            "Unable to find an address based on the Borough, Block, and Lot information provided.  Please check the information provided for accuracy."
                                        );
                                    } else if (
                                        result.block === "" &&
                                        result.lot === ""
                                    ) {
                                        this._sfSpinnerService.stopSpinner();
                                        this._growlService.error(
                                            "Unable to find an address based on the Borough, Block, and Lot information provided.  Please check the information provided for accuracy."
                                        );
                                    } else {
                                        blockControl.setValue(
                                            result.block.toString()
                                        );
                                        lotControl.setValue(
                                            result.lot.toString()
                                        );
                                        this._sfSpinnerService.stopSpinner();
                                    }
                                },
                                () => {
                                    this._sfSpinnerService.stopSpinner();
                                }
                            );
                    } else {
                        this._sfSpinnerService.stopSpinner();
                        this._growlService.error(
                            "You must enter a Borough, Street Number, and Street Name to find Block and Lot information."
                        );
                    }
                },
                () => {
                    this._sfSpinnerService.stopSpinner();
                }
            );
    }

    toggleValidate() {
        let fieldID = this.customProperties["reviewQueue.customFieldsIDs"]
            ? this.customProperties["reviewQueue.customFieldsIDs"].split(",")[0]
            : this.customProperties["reviewQueue.customFieldID"];
        this._formFacade
            .getFormState(fieldID)
            .pipe(
                switchMap((formState) => {
                    if (!formState) return of(null);
                    else
                        return this._formFacade.getFormControl(
                            this._groupPath + fieldID
                        );
                })
            )
            .subscribe((validationField: UntypedFormControl) => {
                if (!validationField) {
                    this._growlService.error(
                        "The custom form is missing the customField " + fieldID
                    );
                } else {
                    this._handleToggle(validationField);
                }
            });
    }

    exciseAgainChanged(event: any) {
        this.formControl$
            .pipe(take(1))
            .subscribe((formControl: UntypedFormControl) => {
                formControl.updateValueAndValidity();
            });
    }

    private _handleToggle(validationField: UntypedFormControl) {
        let validateRequest: any = {
            sf_service: "quickStart",
            sf_debug: true,
            sf_user_session: ""
        };
        let api: any = this._getReviewQueueCustomProps();
        api.quickStartService = "review_queue_validate";
        api.documentBase =
            window.location.protocol + "//" + window.location.host + "/sf/";
        api.JSESSIONID = this.docLeftData?.packageData?.JSESSIONID;
        api.packageID = this.docLeftData?.packageData?.package?.packageID;
        api.documentID = this.documentID =
            this.docLeftData?.packageData?.document?.id;
        api.action = this.validated ? "unvalidate" : "validate";
        api.qspDebug = true;

        api.loadExternalDTD = this.customProperties.loadExternalDTD;
        api.expandEntityReferences =
            this.customProperties.expandEntityReferences;
        api.disallowDoctypeDecl = this.customProperties.disallowDoctypeDecl;
        api.externalGeneralEntities =
            this.customProperties.externalGeneralEntities;
        api.externalParameterEntities =
            this.customProperties.externalParameterEntities;

        validateRequest.api = api;

        if (this._trayService.allowUse() && this._trayService.trayEnabled()) {
            this.validateToggleWaiting = true;
            this._trayService.makeQuickstartRequest(validateRequest).subscribe(
                (response: any) => {
                    this.validateToggleWaiting = false;
                    let responseData: any;
                    if (!response.data) {
                        responseData = response;
                    } else {
                        responseData = response.data;
                    }

                    if (responseData && responseData.sf_error_message) {
                        this._growlService.error(responseData.sf_error_message);
                    } else {
                        this.validated = !this.validated;
                        if (responseData.excise_number) {
                            this.validated = true;
                        }

                        validationField.setValue(
                            this.validated ? "true" : "false"
                        );
                        setTimeout(() => {
                            this._customFormService
                                .runRecipientCustomizationsOnDocument(
                                    this.documentID
                                )
                                .subscribe(() => {
                                    // no need to do anything on success
                                });
                        });

                        // save the return result of any other fields that need to be updated as well
                        if (responseData.excise_number) {
                            this._formFacade
                                .getFormControl(
                                    this._groupPath +
                                        "reviewQueue.exciseNumberFieldID"
                                )
                                .subscribe(
                                    (exciseField: UntypedFormControl) => {
                                        exciseField.setValue(
                                            responseData.excise_number
                                        );
                                    }
                                );
                        }
                        if (responseData.exciseFilename) {
                            this._formFacade
                                .getFormControl(
                                    this._groupPath +
                                        "reviewQueue.exciseFilenameFieldID"
                                )
                                .subscribe(
                                    (fileNameField: UntypedFormControl) => {
                                        fileNameField.setValue(
                                            responseData.exciseFilename
                                        );
                                    }
                                );
                            this._formFacade
                                .getFormControl(
                                    this.customProperties[
                                        "reviewQueue.exciseFilenamePath"
                                    ]
                                )
                                .subscribe((control: UntypedFormControl) => {
                                    control.setValue(
                                        responseData.exciseFilename
                                    );
                                });
                        }
                        if (responseData.exciseNumber) {
                            this._formFacade
                                .getFormControl(
                                    this._groupPath +
                                        "reviewQueue.exciseFilenameFieldID"
                                )
                                .subscribe(
                                    (exciseField: UntypedFormControl) => {
                                        exciseField.setValue(
                                            responseData.exciseNumber
                                        );
                                    }
                                );
                            this._formFacade
                                .getFormControl(
                                    this.customProperties[
                                        "reviewQueue.exciseNumberPath"
                                    ]
                                )
                                .subscribe((control: UntypedFormControl) => {
                                    control.setValue(responseData.exciseNumber);
                                });
                        }
                        if (responseData.exciseUser) {
                            this._formFacade
                                .getFormControl(
                                    this._groupPath +
                                        "reviewQueue.exciseUserFieldID"
                                )
                                .subscribe(
                                    (exciseUserField: UntypedFormControl) => {
                                        exciseUserField.setValue(
                                            responseData.exciseUser
                                        );
                                    }
                                );
                            this._formFacade
                                .getFormControl(
                                    this.customProperties[
                                        "reviewQueue.exciseUserPath"
                                    ]
                                )
                                .subscribe((control: UntypedFormControl) => {
                                    control.setValue(responseData.exciseUser);
                                });
                        }
                        if (responseData.exciseDate) {
                            this._formFacade
                                .getFormControl(
                                    this._groupPath +
                                        "reviewQueue.exciseDateFieldID"
                                )
                                .subscribe(
                                    (exciseDataField: UntypedFormControl) => {
                                        exciseDataField.setValue(
                                            responseData.exciseDate
                                        );
                                    }
                                );
                            this._formFacade
                                .getFormControl(
                                    this.customProperties[
                                        "reviewQueue.exciseDatePath"
                                    ]
                                )
                                .subscribe((control: UntypedFormControl) => {
                                    control.setValue(
                                        dayjs(responseData.exciseDate)
                                    );
                                });
                        }
                        if (responseData.stampData) {
                            let stampValues = JSON.parse(
                                responseData.stampData
                            );

                            for (let key in stampValues) {
                                this._formFacade
                                    .getFormControl(
                                        this._groupPath + "ohb8hc_" + key
                                    )
                                    .subscribe(
                                        (stampField: UntypedFormControl) => {
                                            // may need to fix this
                                            stampField.setValue(
                                                stampValues[key]
                                            );
                                        }
                                    );
                            }
                        }
                    }
                },
                () => {
                    this.validateToggleWaiting = false;
                }
            );
        } else {
            this._growlService.error("Tray App is not enabled.");
        }
    }

    private _reetaWriteToFile() {
        if (!this.customProperties) return;
        let writeToFileRequest: any = {
            sf_service: "quickStart",
            sf_debug: true,
            sf_user_session: ""
        };

        let api: any = this._getReviewQueueCustomProps();

        api["reviewQueue.reviewClass"] =
            this.customProperties["reviewQueue.reviewClass1"];
        api.quickStartService = "review_queue_validate";
        api.documentBase =
            window.location.protocol + "//" + window.location.host + "/sf/";
        api.JSESSIONID = this.docLeftData?.packageData?.JSESSIONID;
        api.packageID = this.docLeftData?.packageData?.package?.packageID;
        api.documentID = this.docLeftData?.packageData?.document?.id;
        api.qspDebug = true;
        api.loadExternalDTD = this.customProperties.loadExternalDTD;
        api.expandEntityReferences =
            this.customProperties.expandEntityReferences;
        api.disallowDoctypeDecl = this.customProperties.disallowDoctypeDecl;
        api.externalGeneralEntities =
            this.customProperties.externalGeneralEntities;
        api.externalParameterEntities =
            this.customProperties.externalParameterEntities;
        writeToFileRequest.api = api;

        if (this._trayService.allowUse() && this._trayService.trayEnabled()) {
            this._trayService
                .makeQuickstartRequest(writeToFileRequest)
                .subscribe((response: any) => {
                    let responseData: any;
                    if (!response.data) {
                        responseData = response;
                    } else {
                        responseData = response.data;
                    }

                    if (responseData && responseData.sf_error_message) {
                        this._growlService.error(responseData.sf_error_message);
                    } else {
                        this._growlService.success("File written successfully");
                    }
                });
        } else {
            this._growlService.error("Tray App is not enabled.");
        }
    }

    private _getReviewQueueCustomProps() {
        let api: any = {};
        for (let key in this.customProperties) {
            if (key.startsWith("reviewQueue.")) {
                api[key] = this.customProperties[key];
            }
        }
        return api;
    }

    private _buttonAsLink() {
        if (this.linkURL.indexOf(":submitterID") > -1) {
            let currentOrgID = "";
            if (this._activatedRoute.snapshot.params.orgID) {
                currentOrgID = this._activatedRoute.snapshot.params.orgID;
            } else {
                this._currentOrgService.currentOrganization$
                    .pipe(take(1))
                    .subscribe((currentOrg) => {
                        currentOrgID = currentOrg.id;
                    });
            }
            this.linkURL = this.linkURL.replace(":submitterID", currentOrgID);
        }
        window.open(this.linkURL, "_blank");
    }

    private _validateExciseNumber() {
        //call the back end method with the appropriate data needed, hopefully just doc id
        //get back the parcel number

        let getExciseNumberRequest: any = {
            sf_service: "quickStart",
            sf_debug: true,
            sf_user_session: ""
        };

        let api: any = this._getReviewQueueCustomProps();
        api["reviewQueue.reviewClass"] =
            this.customProperties["reviewQueue.reviewClass2"];
        api["reviewQueue.useReceiptNumbers"] =
            this.customProperties["reviewQueue.useReceiptNumbers"];
        api.quickStartService = "review_queue_validate";
        api.documentBase =
            window.location.protocol + "//" + window.location.host + "/sf/";
        api.JSESSIONID = this.docLeftData?.packageData?.JSESSIONID;
        api.packageID = this.docLeftData?.packageData?.package?.packageID;
        api.documentID = this.docLeftData?.packageData?.document?.id;
        api.qspDebug = true;
        api.action = "getExciseNumber";
        api.loadExternalDTD = this.customProperties.loadExternalDTD;
        api.expandEntityReferences =
            this.customProperties.expandEntityReferences;
        api.disallowDoctypeDecl = this.customProperties.disallowDoctypeDecl;
        api.externalGeneralEntities =
            this.customProperties.externalGeneralEntities;
        api.externalParameterEntities =
            this.customProperties.externalParameterEntities;
        getExciseNumberRequest.api = api;

        if (this._trayService.allowUse() && this._trayService.trayEnabled()) {
            this._trayService
                .makeQuickstartRequest(getExciseNumberRequest)
                .subscribe((response: any) => {
                    let responseData: any;
                    if (!response.data) {
                        responseData = response;
                    } else {
                        responseData = response.data;
                    }

                    if (responseData && responseData.sf_error_message) {
                        this._growlService.error(responseData.sf_error_message);
                    } else {
                        // set the values of the excise, and excise again fields
                        this.formControl$.subscribe((control) => {
                            control.setValue(responseData.excise_number);
                            this.exciseAgain = responseData.excise_number;
                            control.updateValueAndValidity();
                        });

                        // look for the receiptNumber PACS field, and set its value
                        this._formFacade
                            .getFormControl(
                                this._groupPath +
                                    this.customProperties[
                                        "reviewQueue.receiptNumberPath"
                                    ]
                            )
                            .subscribe((numberPacsControl) => {
                                numberPacsControl.setValue(
                                    responseData.receipt_number
                                );
                            });
                    }
                });
        } else {
            this._growlService.error("Tray App is not enabled.");
        }
    }

    private _recipientAction() {
        this._customFormService
            .doButtonAction(
                this.docLeftData.packageData.package.currentOwnerID,
                this.docLeftData.id,
                this._sessionService.getUsername()
            )
            .subscribe(console.log);
    }

    private _update() {
        let newCustomText: any = this.docLeftData?.recipientConfig?.customText;
        if (!newCustomText) newCustomText = {};

        // Add the dynamic defaults if they are not already there...
        for (let key in this._defaultDynamicLabels) {
            if (!newCustomText.hasOwnProperty(key)) {
                newCustomText[key] = this._defaultDynamicLabels[key];
            }
        }
        // Then overwrite the current values with the new ones...
        for (let key in newCustomText) {
            this.customText[key] = newCustomText[key];
        }

        this.customProperties =
            this.docLeftData?.packageData?.customizations?.customProperties;
        if (!this.customProperties) this.customProperties = {};

        if (this.customFunctionName === this.REVIEW_VALIDATE) {
            this._formFacade
                .getFormControl(
                    this._groupPath +
                        this.customProperties["reviewQueue.customFieldID"]
                )
                .subscribe((validated: UntypedFormControl) => {
                    this.validated = validated.value === "true";
                });
        }
    }

    private _findControl(fullPath: string) {
        return this._formFacade.viewState$.pipe(
            switchMap((viewState) => {
                if (!!viewState[fullPath])
                    return this._formFacade.getFormControl(fullPath);
                else return of(null);
            })
        );
    }

    // maybe needed, maybe not
    private _getFullPathFromIDAndIndex(
        fieldId: string,
        index: number
    ): Observable<string> {
        return this._formFacade.viewState$.pipe(
            switchMap((viewState) => {
                // step 1 get group name from fieldID (if any)
                let groupName = "";
                for (let key in viewState) {
                    if (
                        key.indexOf(fieldId) > -1 &&
                        viewState[key].parent != "rootField"
                    ) {
                        groupName = viewState[viewState[key].parent].parent;
                    }
                }

                if (
                    groupName &&
                    index < viewState[groupName].arrayFields.length
                ) {
                    return of(
                        viewState[groupName].arrayFields[index] + "." + fieldId
                    );
                } else {
                    return of("");
                }
            })
        );
    }
}
