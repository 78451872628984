<div class="mb-3 row" *ngIf="formControl$ | async as control" [formGroup]="controlParent$ | async">
    <div class="col-md">
        <button [class]="!(isRequired$ | async) || uploadComplete ? 'btn btn-primary' : 'btn btn-primary'" [id]="field.id" (click)="fileInput.click()">{{uploadComplete ? 'Upload Complete' : field.label}}</button>
        <input
                hidden
                type="file"
                accept=".pdf"
                [formControl]="control"
                #fileInput
                (change)="onFileSelected($event)">
        <div *ngIf="fileName">
            {{fileName}} uploaded {{uploadDate}}
        </div>
        <sf-form-error *ngIf="control.invalid && !control.pristine" [control]="control" [label]="field.label"></sf-form-error>
    </div>
</div>
