import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { b64ToBlob, scheduleMicroTask } from "@sf/common";
import { DownloadOptions } from "../../interfaces/download-options.interface";
import { SessionService } from "@sf/common";
import { SubmitterOrganizationService } from "../../services/submitter-organization.service";

@Component({
    selector: "sf-download-options-dialog",
    templateUrl: "./download-options-dialog.component.html",
    styleUrls: ["./download-options-dialog.component.scss"],
    host: { "[class.sf-unmask]": "true" }
})
export class DownloadOptionsDialogComponent implements OnInit {
    @Input()
    orgID: string;

    downloadForm: UntypedFormGroup;

    title: string;
    primary: ModalButton;
    secondary: ModalButton;
    loadButtons: boolean;

    private _isSuperUser: boolean = false;

    constructor(
        private activeModal: NgbActiveModal,
        private _sessionService: SessionService,
        private _submitterOrgService: SubmitterOrganizationService
    ) {}

    ngOnInit(): void {
        // Set a default title if the caller did not choose one.
        if (!this.title) {
            this.title = "Download Options";
        }
        this.loadButtons = false;

        this.downloadForm = new UntypedFormGroup({
            printFormat: new UntypedFormControl("pdf", [Validators.required]),
            recipientFilter: new UntypedFormControl("all", [
                Validators.required
            ]),
            secondaryRecipients: new UntypedFormControl(false)
        });

        this.primary = {
            text: "Download",
            disabled: false,
            callback: this.onDownload.bind(this)
        };
        this.secondary = { text: "Cancel" };

        // Workaround for this issue: https://github.com/ng-bootstrap/ng-bootstrap/issues/2870
        scheduleMicroTask(() => (this.loadButtons = true));

        this._isSuperUser = this._sessionService.isSuperUser();
    }

    onDownload() {
        this.primary.disabled = true;
        this.downloadForm.disable();
        let parameters: DownloadOptions = {
            format: this.downloadForm.get("printFormat").value,
            recipientFilter: this.downloadForm.get("recipientFilter").value,
            includeSecondaryRecipients: this.downloadForm.get(
                "secondaryRecipients"
            ).value,
            orgID: this.orgID
        };

        if (this._isSuperUser) {
            this._submitterOrgService
                .adminLiveRecipientExport(parameters)
                .subscribe((data: string) => {
                    this._handleRecipientExport(parameters, data);
                });
        } else {
            this._submitterOrgService
                .submitterLiveRecipientExport(parameters)
                .subscribe((data: string) => {
                    this._handleRecipientExport(parameters, data);
                });
        }
    }

    private _handleRecipientExport(parameters: DownloadOptions, data: string) {
        this.activeModal.close();
        let url =
            parameters.format === "csv"
                ? URL.createObjectURL(b64ToBlob(data, "text/csv"))
                : URL.createObjectURL(b64ToBlob(data));
        let fileName = `County_List.${
            parameters.format === "csv" ? "csv" : "pdf"
        }`;
        let link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
