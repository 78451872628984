import { Component, OnDestroy, OnInit } from "@angular/core";
import { InputComponent } from "../../components";
import { FieldType } from "../../enums/field-type.enum";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { RpcClientService } from "@sf/common";
import { FieldOption, SelectFieldViewState } from "../../interfaces";
import { Observable, Subject } from "rxjs";
import {
    filter,
    startWith,
    switchMap,
    takeUntil,
    takeWhile,
    withLatestFrom,
    map
} from "rxjs/operators";
import { DynamicFormStore } from "../../services/dynamic-form-store";

@Component({
    selector: "sf-select-field",
    templateUrl: "./select-field.component.html",
    styleUrls: ["./select-field.component.scss"]
})
export class SelectFieldComponent
    extends InputComponent
    implements OnInit, OnDestroy
{
    private _fetchOptions = new Subject<string>();
    private _destroy$ = new Subject<void>();

    isMultiSelect = false;
    handleFilterExternally = false;
    dummyForm: UntypedFormGroup;
    options$: Observable<FieldOption[]>;
    limit = 100;

    constructor(
        private _fb: UntypedFormBuilder,
        protected _facade: DynamicFormStore,
        private _rpcClient: RpcClientService
    ) {
        super(_facade);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.isMultiSelect =
            this.field.type === FieldType.MULTISELECT ||
            this.field.type === FieldType.MULTITITLE;
        const viewState$ = this._facade.getViewStateForField(
            this.field.fullPath
        ) as Observable<SelectFieldViewState>;
        this.options$ = viewState$.pipe(
            takeUntil(this._destroy$),
            takeWhile((val) => val !== undefined),
            withLatestFrom(this.formControl$),
            switchMap(([viewState, control]) => {
                if (viewState.externalFilter) {
                    this.handleFilterExternally = true;
                    return this._getOptions(
                        viewState,
                        control.value ?? viewState.externalFilter.defaultValue
                    );
                } else {
                    return this._filterOptions(viewState?.options || []);
                }
            })
        );
    }

    ngOnDestroy() {
        this._destroy$.next();
    }

    filter({ $searchValue }: { $searchValue: string }) {
        if (this.isMultiSelect) {
            // removing filter handling on multi select allows you to check multiple options from different pages
            // sf-select reducers already do the filtering needed without fully "removing" options from the list
            return;
        }
        this._fetchOptions.next($searchValue);
    }

    // TODO: FALCON-4871 — Passing an initial filter works for single selects, but will not work for multi-selects.
    private _getOptions(
        viewState: SelectFieldViewState,
        startingQuery?: string
    ) {
        return this._fetchOptions.pipe(
            startWith(startingQuery),
            filter((query) => query != undefined),
            switchMap((query) => {
                return this._rpcClient.makeRequest<FieldOption[]>(
                    viewState.externalFilter.product,
                    viewState.externalFilter.method,
                    {
                        query,
                        limit: this.limit
                    }
                );
            })
        );
    }

    // Apply limit to initial viewState options
    private _filterOptions(
        options: FieldOption[],
        startingQuery: string = ""
    ): Observable<FieldOption[]> {
        return this._fetchOptions.pipe(
            startWith(startingQuery),
            filter((query) => query != undefined),
            map((query: string) => {
                let filteredOptions = options.filter(
                    (o) =>
                        o.label.toLowerCase().indexOf(query.toLowerCase()) !==
                        -1
                );
                if (this.isMultiSelect) return filteredOptions;
                else return filteredOptions.slice(0, this.limit);
            })
        );
    }
}
