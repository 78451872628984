import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "../../services/login.service";

declare const window: any;

// prettier-ignore
@Component({
    selector: "sf-forgot-password",
    templateUrl: "./forgot-password.component.html",
    styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
    formErrorMessages: string[] = [];
    loginForm: UntypedFormGroup;
    processing = false;
    passwordSent = false;
    sendResults: any = null;
    successMessage: string = null;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private loginService: LoginService
    ) {
        this.loginForm = formBuilder.group({
            username: [""],
            email: [""]
        });
    }

    ngOnInit() {
        this.focusToUsername();
    }

    focusToUsername() {
        this.focusElement("username");
    }

    focusElement(elementID: string) {
        window.setTimeout(() => {
            let theField = document.getElementById(elementID);
            theField.focus();
        }, 200);
    }

    clickForgotUsername() {
        let newUrl = "/login/forgot-username";
        this.router.navigate([newUrl], { relativeTo: this.route });
    }

    clickContact() {
        let newUrl = "/login/contact";
        this.router.navigate([newUrl], { relativeTo: this.route });
    }

    clickBack() {
        let newUrl = "/login";
        this.router.navigate([newUrl], { relativeTo: this.route });
    }

    submitForm() {
        this.formErrorMessages = [];

        let username: string = this.loginForm.controls.username.value;
        let email: string = this.loginForm.controls.email.value;

        if (!username) {
            this.formErrorMessages.push("Please enter your username");
        }
        if (!email) {
            this.formErrorMessages.push("Please enter your email address");
        }
        if (this.formErrorMessages.length) {
            return;
        }

        this.processing = true;
        this.loginService.sendPassword(username, email)
            .subscribe((results: any) => {
                this.passwordSent = true;
                if (results) {
                    this.sendResults = results;
                    this.successMessage = "An email with instructions has been sent to " + email + ".";
                    if (results.url) {
                        this.successMessage += "<br/><br/>" + results.url;
                    }
                } else {
                    this.processing = false;
                    this.formErrorMessages.push("There was some kind of error.");
                }
            },
            (errorResult: any) => {
                this.processing = false;
                let message = "";
                if (
                    errorResult &&
                    errorResult.error &&
                    errorResult.error.data
                ) {
                    message = errorResult.error.data;
                }
                if (!message) {
                    message = "Something went wrong, please try again.";
                }
                this.formErrorMessages.push(message);
            }
        );
    }
}
