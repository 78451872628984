import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import { ModalButton, SfValidators } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";
import { UserOrgService } from "@sf/userorg/common";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { GrowlService } from "@sf/common";
import { AnswersList, QuestionList } from "@sf/userorg/common";

@Component({
    selector: "sf-edit-support-question-settings-personal-dialog",
    templateUrl:
        "./edit-support-question-settings-personal-dialog.component.html",
    styleUrls: [
        "./edit-support-question-settings-personal-dialog.component.scss"
    ],
    host: { "[class.sf-unmask]": "true" }
})
export class EditSupportQuestionSettingsPersonalDialogComponent
    implements OnInit, OnDestroy
{
    @Input() userID: string;
    @ViewChild("answer") answerInput: ElementRef;

    private _onDestroy: Subject<void> = new Subject<void>();

    phoneSupportQuestionForm: UntypedFormGroup;
    primary: ModalButton;
    secondary: ModalButton;
    loaded: boolean;
    showChecked: boolean;
    phoneAnswerValid: boolean = false;
    questionsList: QuestionList[] = [];
    answersList: AnswersList[] = [];

    questionList$: Observable<any> = this._userorgService
        .getPhoneSupportQuestionList()
        .pipe(
            map((questionsList: any) => {
                let questionsArray: QuestionList[] = [];
                Object.keys(questionsList).forEach((key: string) => {
                    questionsArray.push({
                        id: key,
                        question: questionsList[key]
                    });
                });
                return questionsArray;
            }),
            tap(() => {
                this.answersList.push({
                    questionID: "",
                    answer: ""
                });
                this.loaded = true;
            })
        );

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _activeModal: NgbActiveModal,
        private _growlService: GrowlService,
        private _userorgService: UserOrgService
    ) {}

    ngOnInit() {
        // set up form
        this.phoneSupportQuestionForm = this._formBuilder.group({
            currentPassword: ["", Validators.required],
            phoneQuestion: ["", Validators.required],
            phoneAnswer: [
                "",
                {
                    validators: [
                        Validators.required,
                        Validators.minLength(4),
                        SfValidators.notAllWhitespace
                    ],
                    updateOn: "blur"
                }
            ],
            showAnswersCheckbox: [false]
        });

        // Modal buttons
        this.primary = {
            text: "OK",
            disabled: true,
            callback: this.save.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.abort.bind(this)
        };

        this.catchFormChanges();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
    }

    catchFormChanges() {
        // watch for changes to the question field
        this.phoneSupportQuestionForm
            .get("phoneQuestion")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                this.answersList[0].questionID = val;
            });

        // watch for changes to the answer field
        this.phoneSupportQuestionForm
            .get("phoneAnswer")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                if (
                    this.phoneSupportQuestionForm.get("phoneAnswer").errors &&
                    this.phoneSupportQuestionForm.get("phoneAnswer").errors[
                        "minlength"
                    ]
                ) {
                    this.phoneAnswerValid = false;
                    this._growlService.warning(
                        "Answers must be at least 4 characters long."
                    );
                } else {
                    this.phoneAnswerValid = true;
                    this.answersList[0].answer = val;
                }
            });

        // watch for changes to the show answers checkbox
        this.phoneSupportQuestionForm
            .get("showAnswersCheckbox")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.showChecked = !this.showChecked;
                this.answerInput.nativeElement.type = this.showChecked
                    ? "text"
                    : "password";
            });

        // watch for changes to the phoneSupportQuestionForm to activate/deactivate ok button
        this.phoneSupportQuestionForm.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                const currentPasswordValid =
                    this.phoneSupportQuestionForm.get("currentPassword").valid;
                const phoneQuestionValid =
                    this.phoneSupportQuestionForm.get("phoneQuestion").valid;
                const phoneAnswerControlValid =
                    this.phoneSupportQuestionForm.get("phoneAnswer").valid;

                this.primary.disabled = !(
                    this.phoneAnswerValid &&
                    currentPasswordValid &&
                    phoneQuestionValid &&
                    phoneAnswerControlValid
                );
            });
    }

    onFocus(event: any) {
        if (event.target.type === "password") {
            event.target.type = "text";
        }
    }

    onBlur(event: any) {
        if (this.showChecked && event.target.type === "password") {
            event.target.type = "text";
        } else if (!this.showChecked && event.target.type === "text") {
            event.target.type = "password";
        }
    }

    save() {
        const questionAnswerMap: any = {};
        let answer = this.answersList[0].answer;
        if (answer) {
            answer = answer.trim();
        }
        questionAnswerMap[this.answersList[0].questionID] = answer;

        this.primary.disabled = true;
        this._userorgService
            .savePhoneSupportAnswer(
                this.userID,
                this.phoneSupportQuestionForm.get("currentPassword").value,
                questionAnswerMap
            )
            .subscribe(
                (response) => {
                    if (response && response.locked) {
                        window.location.href =
                            "/sf/ui/login?err=You%20are%20locked%20out";
                    } else {
                        this.abort();
                        this._growlService.success("Support question saved.");
                    }
                },
                () => {
                    this.primary.disabled = false;
                }
            );
    }

    abort() {
        this._activeModal.close(false);
    }
}
