<div class="logo">
</div>
<div>
    <!-- main container -->
    <div class="login-container">
        <div class="head-text">
            South Carolina eNotary Onboarding
        </div>
        <!-- ERROR MESSAGES -->
        <div class="error-row" [hidden]="!formErrorMessages || !formErrorMessages.length">
            <ul *ngIf="formErrorMessages.length > 1">
                <li *ngFor="let message of formErrorMessages">
                    <span [innerHTML]="message"></span>
                </li>
            </ul>
            <div *ngIf="formErrorMessages.length == 1">
                <span [innerHTML]="formErrorMessages[0]"></span>
            </div>
        </div>
        <div *ngIf="!showNoAccount" class="link-container spacer">
            <button class="btn btn-link" (click)="existingAccount()">I have a Simplifile Account</button>
            <button class="btn btn-link" (click)="newAccount()">I don't have an account?</button>
        </div>
        <div *ngIf="showLogin">
            <form [formGroup]="notaryForm">
                <div class="row">
                    <div class="mb-3 col-md-12">
                        <input type="text" id="username" name="username" placeholder="Username or Email"
                               aria-label="Username or Email"
                               class="form-control" autofocus maxlength="255" formControlName="username"/>
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-md-12">
                        <input type="password" id="password" name="password" placeholder="Password" class="form-control"
                               aria-label="Password"
                               maxlength="255" formControlName="password"/>
                    </div>
                </div>
                <div class="button-row">
                    <button class="btn btn-primary" (click)="authenticate()">
                        <span>Authenticate</span>
                    </button>
                </div>
            </form>
        </div>
        <div *ngIf="showNoAccount">
            If your organization is already active on Simplifile, contact your organization administrator and have them send you an invitation to create an account.<br/><br/>
            If your organization does not already have an account, click here to <a href="https://simplifile.com/get-started"> get started</a>.<br/><br/>
            Once you have an account and login set up, you will need to again select Simplifile as your eNotary provider from the South Carolina eNotary portal to complete the process.
        </div>
    </div>
</div>
