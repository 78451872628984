import { Component, Inject, OnInit } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { Auth } from "@sf/common";
import { APP_BOOTSTRAP_SERVICE, AppBootstrapService } from "@sf/common";
import { SocketService } from "@sf/common";
import { of, throwError } from "rxjs";
import { StatesService } from "@sf/common";
import { dayjs } from "@sf/common";

const getQuestionsUrl = "/sf/lender/auth/getQuestions";
const softAuthUrl = "/sf/lender/auth/login";

@Component({
    selector: "sf-loan-authentication",
    templateUrl: "./loan-authentication.component.html",
    styleUrls: ["./loan-authentication.component.scss"]
})
export class LoanAuthenticationComponent implements OnInit {
    private _token: string;

    formErrorMessages: string[] = [];
    hideDateKludge = false; // KLUDGE to avoid console error on soft-auth transition
    questions: any = {};
    softAuthForm: UntypedFormGroup;
    tokenExpired = false;
    USStates: any[] = [];
    processing = false;

    constructor(
        @Inject(APP_BOOTSTRAP_SERVICE)
        private _appBootstrap: AppBootstrapService,
        private _formBuilder: UntypedFormBuilder,
        private _http: HttpClient,
        private _router: Router,
        private _socketService: SocketService,
        private _sfStatesService: StatesService
    ) {
        this.softAuthForm = _formBuilder.group({
            emailAddress: ["", [Validators.required]],
            securityAnswers: this._formBuilder.group({})
        });
    }

    ngOnInit() {
        this._token = this._getQueryParam();
        let data = { tokenID: this._token };

        this._http.post(getQuestionsUrl, data).subscribe((results: any) => {
            switch (results.status) {
                case "ACTIVE":
                    this.questions = results.securityQuestions;
                    let answers = this.softAuthForm.controls
                        .securityAnswers as UntypedFormGroup;
                    Object.keys(this.questions).forEach((key) => {
                        answers.addControl(
                            key,
                            new UntypedFormControl("", Validators.required)
                        );
                    });
                    break;
                case "CANCELED":
                    this.formErrorMessages.push(
                        "The closing package for this loan has been redrawn. This link is no longer valid. Please check your email for a new link to the redrawn closing package or log into your Simplifile account to gain access."
                    );
                    this.tokenExpired = true;
                    break;
                case "EXPIRED":
                    this.formErrorMessages.push(
                        "This link to access the closing package has expired. Please log into Simplifile to gain access to the closing package."
                    );
                    this.tokenExpired = true;
                    break;
            }
        });
        this.USStates = this._sfStatesService.getAllStatesAndTerritories();
    }

    doFullLogin() {
        // This is the only way I can get the redirect parameter to stick, router.navigate loses it!
        // Notice the necessity to prepend "/ui" to the loanURL - see login.component:getRedirectParam
        window.location.href = `/sf/ui/login?fr=/ui/lender/closing-status%3F${this._token}`;
    }

    doSoftAuth() {
        if (this.softAuthForm.valid && !this.tokenExpired) {
            let data = { ...this.softAuthForm.value };
            // Because some time zones calculate out to a different day (LS-13055)
            if (
                data.securityAnswers.CLOSING_DATE &&
                dayjs.isDayjs(data.securityAnswers.CLOSING_DATE)
            ) {
                data.securityAnswers.CLOSING_DATE =
                    data.securityAnswers.CLOSING_DATE.format("MM/DD/YYYY");
            }
            data.tokenID = this._token;
            this.processing = true;
            this._http.post(softAuthUrl, data).subscribe(
                (results: any) => {
                    this.hideDateKludge = true;
                    let closingURL = results.nextPage.replace("/sf/ui", "");
                    let extras: any = {
                        queryParams: {
                            loan: results.loanID.key
                        }
                    };
                    extras.queryParams[results.tokenID] = "";

                    // Copied this from login.component.ts!
                    this._socketService.authMessages
                        .pipe(
                            filter((auth: Auth) => auth.data.authenticated),
                            switchMap((auth: Auth) =>
                                this._appBootstrap.bootstrapAfterLogin(auth)
                            ),
                            take(1)
                        )
                        .subscribe(() => {
                            this._router.navigate([closingURL], extras);
                        });
                    this._socketService.reconnect();
                },
                (error: any) => {
                    this.formErrorMessages.push(error.error.errorMessage);
                    this.processing = false;
                    return throwError(error);
                }
            );
        }
    }

    _getQueryParam(param?: string): string {
        const params: Map<string, string> = new Map();
        window.location.search
            .substr(1)
            .split("&")
            .forEach((pair: any) => {
                let key, value;
                [key, value] = pair.split("=");
                params.set(key, value);
            });
        let found = null;
        if (param) {
            if (params.has(param)) {
                found = params.get(param);
            }
        } else {
            let paramIter = params.entries(),
                entry;
            while ((entry = paramIter.next().value)) {
                if (!entry[1]) {
                    found = entry[0];
                    break;
                }
            }
        }
        return found;
    }
}
