import { Injectable } from "@angular/core";
import { dayjs, SfValidators } from "@sf/common";
import "dayjs/plugin/relativeTime";
import {
    ConfirmationModalComponent,
    GrowlService,
    SessionService
} from "@sf/common";
import {
    Contract,
    ContractExtended,
    ContractFeeThresholdResetPeriod,
    ContractFeeType,
    ContractService,
    Organization,
    OrganizationService,
    ProductBasic,
    TokenService,
    User,
    UserorgActivationService,
    UserOrgService
} from "@sf/userorg/common";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { OrganizationServiceInviteDialogComponent } from "../dialogs/organization-service-invite-dialog/organization-service-invite-dialog.component";
import { SalespersonContactDialogComponent } from "../dialogs/salesperson-contact-dialog/salesperson-contact-dialog.component";
import { InvitationDetailsDialogComponent } from "../dialogs/invitation-details-dialog/invitation-details-dialog.component";
import { ContractDetailsDialogComponent } from "../dialogs/contract-details-dialog/contract-details-dialog.component";
import { LicenseSendDialogComponent } from "../dialogs/license-send-dialog/license-send-dialog.component";
import { Router } from "@angular/router";

export interface AdminLicenseUtilityInterface {
    isInHierarchy(orgID: string, onComplete: Function): void;
    refreshAfterActivate(orgID: string): void;
    licenseFileUpload(
        orgID: string,
        contractID: string,
        fileItem: File,
        contractType: string,
        productToActivate: string,
        afterMethod?: Function
    ): void;
    uploadAgreement(
        orgID: string,
        product: ProductBasic,
        onSuccess: Function
    ): void;
    uploadMSA(orgID: string, onSuccess: Function): void;
}

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class LicenseUtilityService {

    constructor(
            private activationService: UserorgActivationService,
            private contractService: ContractService,
            private sessionService: SessionService,
            private organizationService: OrganizationService,
            private tokenService: TokenService,
            private modalService: NgbModal,
            private userorgService: UserOrgService,
            private growlService: GrowlService
    ) {
    }

    formatDate(date: DateString): string {
        if (!date) {
            return "";
        }
        let monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        let parts = date.split("-");
        let month = parseInt(parts[1]);
        let day = parseInt(parts[2]);
        let monthName = monthNames[month - 1];
        let year = parts[0];
        //let result = month + "/" + day + "/" + year + "   (" + monthName + " " + day + ", " + year + ")";
        let result = monthName + " " + day + ", " + year;
        return result;
    }

    formatDayjs(date: dayjs.Dayjs): string {
        if (date) {
            return dayjs(date).format("MMM D, YYYY");
        }
        return null;
    }

    isDateBeforeToday(dateString: string): boolean {
        if (!dateString || !dateString.length) {
            return false;
        }
        let then = new Date(dateString);
        let today = new Date();
        return then.getTime() < today.getTime();
    }

    getNextYearDateYMD(): string {
        let nextYear = this.getNextYearDate();
        return this.formatDateYMD(nextYear);
    }

    // this takes a 'date' value and build a 'yyyy-mm-dd' value
    formatDateYMD(date: Date): string {
        let dd: number = date.getDate();
        let mm: number = date.getMonth() + 1; //January is 0!
        let yyyy = date.getFullYear();

        let dayString: string = "" + dd;
        if (dd < 10) {
            dayString = "0" + dayString;
        }

        let monthString: string = "" + mm;
        if (mm < 10) {
            monthString = "0" + monthString;
        }

        return yyyy + "-" + monthString + "-" + dayString;
    }

    /*
    // This takes a 'date' value and builds a standard date string like what we get from back end calls
    formatDateStd(date: Date) {
        let dd: number = date.getDate();
        let mm: number = date.getMonth() + 1; //January is 0!
        let yyyy = date.getFullYear();

        let dayString: string = "" + dd;
        if (dd < 10) {
            dayString = "0" + dayString;
        }

        let monthString: string = "" + mm;
        if (mm < 10) {
            monthString = "0" + monthString;
        }

        return yyyy + "-" + monthString + "-" + dayString + "T07:00:00Z";
    }
    */

    // returns a javascript 'date' value
    getNextYearDate(): Date {
        let nextYear = new Date(
            new Date().setFullYear(new Date().getFullYear() + 1)
        );
        return nextYear;
    }

    /*
    // This returns the date one year from now, in a standard date string
    getNextYearDateStd(): string {
        let nextYear = this.getNextYearDate();
        return this.formatDateStd(nextYear);
    }
    */

    getEnrollmentCodeName(code: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (!this.sessionService.hasPermissionInAnyOrg("admin_organization_management")) {
                // we don't display enrollment code info to non-super-users
                resolve(null);
                return;
            }
            this.contractService.getContractTemplate(code).subscribe((template: any) => {
                resolve(template.templateName);
            }, () => {
                resolve(null);
            });
        });
    }

    refreshDocumentIcon(orgID: string, contract: any) {
        if (!contract) {
            return;
        }
        if (contract.executedDate) {
            // only show the 'loading' icon if we expect a document to be there
            setTimeout(() => {
                contract.agreementLoading = true;
                contract.agreementUrl = null;
                contract.agreementMissing = false;
            }, 0);
        } else {
            contract.agreementMissing = true;
        }
        this.contractService.getContractDocumentUrlForContractID(contract.id)
            .subscribe((url: string) => {
                setTimeout(() => {
                    if (!url || !url.length) {
                        if (contract.executedDate) {
                            if (!contract.reLookCounter) {
                                contract.reLookCounter = 0;
                            }
                            let now = dayjs();
                            let then = dayjs(contract.executedDate);
                            // was the contract executed today?
                            if (now.isSame(then, "day") && contract.reLookCounter < 20) {
                                contract.reLookCounter++;
                                // sometimes it takes a while before the document is generated
                                // so try again in 3 seconds
                                setTimeout(() => {
                                    this.refreshDocumentIcon(orgID, contract);
                                }, 3000);
                            } else {
                                this.documentIsMissing(contract);
                            }
                        } else {
                            this.documentIsMissing(contract);
                        }
                    } else {
                        contract.agreementLoading = false;
                        contract.agreementUrl = url;
                        contract.agreementMissing = false;
                    }
                }, 2);
            }, () => {
                // handle rare error case
                setTimeout(() => {
                    this.documentIsMissing(contract);
                }, 2);
            });
    }

    documentIsMissing(contract: any) {
        contract.agreementLoading = false;
        contract.agreementUrl = null;
        contract.agreementMissing = true;
    }

    /**
     * See if this organization has a pending invitation for a service
     * @param component - this
     * @param orgID - organization id
     * @param service - name of service, like 'submitter'
     */
    lookForInvite(component: any, orgID: string, service: string) {
        this.tokenService.getPendingServiceInvitations(orgID)
            .subscribe((invitations: any[]) => {
                let serviceInvitations = invitations;
                component.currentInvite = serviceInvitations.find((invitation) => {
                    if (invitation.service == service) {
                        return invitation;
                    }
                    return null;
                });
                if (component.currentInvite) {
                    component.currentInviteMessage = "An invitation was sent to ";
                    if (component.currentInvite.recipients) {
                        let recipient = component.currentInvite.recipients[0];
                        let inviteDayjs = dayjs(recipient.date).fromNow();
                        component.currentInviteMessage += recipient.name + " " + inviteDayjs + ", ";
                    } else {
                        component.currentInviteMessage += "this organization, ";
                    }
                    component.currentInviteMessage += "and a license will be created when they accept the invitation.";
                }
            });
    }

    // handles numbers as strings or real numbers and returns the same type as sent in
    roundOff(thing: any, digits: number): any {
        if (thing == undefined) {
            return undefined;
        }

        if (digits === undefined) {
            digits = 2;
        }

        let theNum: number = thing;
        if (typeof thing == "string" && !!thing) {
            theNum = SfValidators.getNumberFromString(thing);
        } else if (typeof thing == "number") {
            theNum = thing;
        } else return undefined;

        let multiplicator = Math.pow(10, digits);
        theNum = parseFloat((theNum * multiplicator).toFixed(11));
        let test = Math.round(theNum) / multiplicator;
        let theString: string = test.toFixed(digits);

        if (typeof thing == "string") {
            return theString;
        } else {
            return +theString;
        }
    }

    numbersDifferent(first: any, second: any): boolean {
        let firstNumber: number;
        let secondNumber: number;

        if (typeof first == "number") {
            firstNumber = first;
        } else if (typeof first == "string") {
            firstNumber = parseFloat(first);
        }

        if (typeof second == "number") {
            secondNumber = second;
        } else if (typeof first == "string") {
            secondNumber = parseFloat(second);
        }

        return firstNumber != secondNumber;
    }

    dummyTheDumbFees(contract: Contract, feesToInit: ContractFeeType[]) {
        // contract fees are too dang complicated
        contract.contractFees = {
            DISBURSEMENT_FEE: undefined,
            DOCUMENT_BUILDER_DOCUMENT_FEE: undefined,
            DOCUMENT_BUILDER_FILE_TRANSFER_FEE: undefined,
            DOCUMENT_BUILDER_LICENSE_FEE: undefined,
            DOCUMENT_BUILDER_RENEWAL_FEE: undefined,
            LENDER_VENDOR_REVSHARE: undefined,
            NOTARY_VENDOR_REVSHARE: undefined,
            ESIGN_EVENTS_VENDOR_REVSHARE: undefined,
            LICENSE_FEE: undefined,
            NFS_RETRY_FEE: undefined,
            PAPER_SUBMISSION_FEE: undefined,
            INSTATE_ERECORD_SUBMISSION_FEE: undefined,
            OUTOFSTATE_ERECORD_SUBMISSION_FEE: undefined,
            INSTATE_PAPER_SUBMISSION_FEE: undefined,
            OUTOFSTATE_PAPER_SUBMISSION_FEE: undefined,
            PAPER_MAIL_FEE: undefined,
            PAPER_RECORDING_FEE: undefined,
            EMBEDDED_EMPLOYEE_FEE: undefined,
            RECIPIENT_VENDOR_EXCLUSIVE_REVSHARE: undefined,
            RECIPIENT_VENDOR_REVSHARE: undefined,
            RECORDING_FEE: undefined,
            RENEWAL_FEE: undefined,
            REVSHARE_FEE: undefined,
            SETTLEMENT_LOAN_ACCEPT_FEE: undefined,
            SETTLEMENT_LOAN_CREATE_FEE: undefined,
            SETTLEMENT_RENEWAL_FEE: undefined,
            SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE: undefined,
            SUBMISSION_FEE: undefined,
            SUBMITTER_VENDOR_REVSHARE: undefined,
            TRUSTEE_DOCUMENT_FEE: undefined,
            VERIFIED_WITHOUT_SECURE_DISBURSEMENT_FEE: undefined,
            LENDER_LOAN_CREATE_FEE: undefined,
            LENDER_LOAN_ACCEPT_FEE: undefined,
            LENDER_ANNUAL_FEE: undefined,
            ESE_LICENSE_FEE: undefined,
            ESE_RENEWAL_FEE: undefined,
            ESE_NO_ENOTARIZATION: undefined,
            ESE_IPEN: undefined,
            ESE_RON: undefined,
            DOCUMENT_BUILDER_RON_FEE: undefined,
            LIEN_RELEASE_FEE: undefined,
            SUBSCRIPTION_MINIMUM: undefined
        };

        feesToInit.forEach((fee) => {
            this.initializeFee(contract, fee);
        });
    }

    initFeeIfMissing(contract: Contract, feesToInit: ContractFeeType[]) {
        feesToInit.forEach((fee) => {
            if (!contract.contractFees.hasOwnProperty(fee)) {
                this.initializeFee(contract, fee);
            }
        });
    }

    initializeFee(contract: Contract, fee: ContractFeeType) {
        contract.contractFees[fee] = {
            feeType: fee,
            contractFeeTiers: [{tierThreshold: 0, feeAmount: 0}],
            thresholdResetPeriod: ContractFeeThresholdResetPeriod.INDEFINITE
        };
    }
    confirmCancelSend(orgID: string, productName: string, onConfirm: Function, contractType: string): void {
        this.contractService.getPendingContract(orgID, contractType)
            .subscribe((pendingContract: ContractExtended) => {
                this.confirmCancel(productName, onConfirm, pendingContract, false);
            });
    }

    confirmCancel(productName: string, onConfirm: Function, contract: ContractExtended, isFuture: boolean) {
        if (!!contract) {
            const modalRef = this.modalService.open(ConfirmationModalComponent, {
                backdrop: "static"
            });
            const modalInstance = modalRef.componentInstance;

            modalInstance.title = "Cancel " + (isFuture ? "Future" : "Pending") + " License Confirmation";
            modalInstance.message = "Are you sure you want to cancel the " + (isFuture ? "future " : "pending ") + productName + " license?";
            modalInstance.primary = {
                text: "Confirm",
                callback: () => {
                    modalRef.close();
                    // the 'contract type' parameter is required to cancel from the Services page
                    onConfirm(contract.contractType);
                    return true;
                }
            };
            modalInstance.secondary = {
                text: "Nevermind",
                callback: () => {
                    modalRef.close();
                }
            };
        }
    }

    confirmCancelInvite(productName: string, cancelMethod: Function) {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            backdrop: "static"
        });
        const modalInstance = modalRef.componentInstance;

        modalInstance.title = "Cancel Invitation";
        modalInstance.message =
                "Are you sure you want to cancel the " + productName + " invitation?";

        modalInstance.primary = {
            text: "Confirm",
            callback: () => {
                modalRef.close();
                cancelMethod();
                return true;
            }
        };
        modalInstance.secondary = {
            text: "Nevermind",
            callback: () => {
                modalRef.close();
            }
        };
    }

    licenseFeeChangeCheck(newFee: number, oldFee: number): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (!this.numbersDifferent(newFee, oldFee)) {
                resolve(true);
                return;
            }

            const modalRef = this.modalService.open(ConfirmationModalComponent, {
                backdrop: "static"
            });
            const modalInstance = modalRef.componentInstance;

            modalInstance.title = "Change License Fee";
            modalInstance.message = "Are you sure you want to change the Initial License Fee?" +
                " If it has already been paid, then changing it now will have no effect.";

            modalInstance.primary = {
                text: "Confirm",
                callback: () => {
                    modalRef.close();
                    resolve(true);
                    return true;
                }
            };
            modalInstance.secondary = {
                text: "Nevermind",
                callback: () => {
                    modalRef.close();
                    resolve(false);
                }
            };
        });
    }

    showInvite(organization: Organization, product: ProductBasic) {
        const modalRef = this.modalService.open(OrganizationServiceInviteDialogComponent, {
            backdrop: "static",
            windowClass: "service-invite-dialog"
        });
        const modalInstance = modalRef.componentInstance;
        modalInstance.title = "Invitation to Upgrade";
        modalInstance.organization = organization;
        modalInstance.product = product;
        //modalInstance.invitation = null;

        modalRef.result.then((result: any) => {
            if (result) {
                this.lookForInvite(this, organization.id, product.productID);
                let url: string = result.url;
                if (url) {
                    this.growlService.success(url, "Invitation URL:", { disableTimeOut: true });
                }
                this.growlService.success("Invitation sent.");
            }
        }, () => {
            // nothing on cancel
        });
    }

    showSalesPerson(salesPerson: User) {
        if (!salesPerson) {
            return;
        }

        const modalRef = this.modalService.open(SalespersonContactDialogComponent, {
            backdrop: "static"
        });
        const modalInstance = modalRef.componentInstance;

        modalInstance.salesperson = salesPerson;
    }

    showSignerDetails(currentInvite: any, pendingContract: ContractExtended, orgID: string, productName: string) {
        let modalRef: NgbModalRef;

        if (currentInvite && !pendingContract) {
            modalRef = this.modalService.open(InvitationDetailsDialogComponent, {
                backdrop: "static"
            });
        } else {
            modalRef = this.modalService.open(ContractDetailsDialogComponent, {
                backdrop: "static"
            });
            this.contractService.addContractDateDayjs(pendingContract);
        }
        const modalInstance = modalRef.componentInstance;

        modalInstance.orgID = orgID;
        modalInstance.contract = pendingContract;
        modalInstance.productName = productName;
        modalInstance.invitation = currentInvite;

        modalRef.result.then(() => {
            // nothing
        }, (/*error*/) => {
            // nothing
        });
    }

    sendContract(requiredSend: boolean, addendumPresave: Function, setup: Contract, originalValues: Contract, orgID: string, whenDone: Function) {
        // check if license expires soon
        if (originalValues.expirationDate) {
            let expDate: dayjs.Dayjs = dayjs(originalValues.expirationDate).startOf("day");
            let now: dayjs.Dayjs = dayjs().startOf("day");
            let diff: number = expDate.diff(now, "days");
            if (diff >= 0 && diff < 31) {
                let modalRef: NgbModalRef = this.modalService.open(ConfirmationModalComponent);
                const modalInstance = modalRef.componentInstance;

                modalInstance.title = "License Renews Soon";
                modalInstance.message = "Warning: This contract will renew in " + diff + " days. " +
                        "<br/><br/>" +
                        "If your proposed contract change is not accepted by the customer before the contract renews, your changes will be lost." +
                        "<br/><br/>" +
                        "Do you want to continue?";
                modalInstance.primary = {
                    text: "Continue",
                    callback: () => {
                        // they chose to continue
                        modalRef.close();
                        this.sendContractForReal(requiredSend, addendumPresave, setup, orgID, whenDone);
                    }
                };
                modalInstance.secondary = {
                    text: "Cancel",
                    callback: () => {
                        modalRef.close();
                    }
                };
            } else {
                // expires more than 30 days away
                this.sendContractForReal(requiredSend, addendumPresave, setup, orgID, whenDone);
            }
        } else {
            // no expiration date
            this.sendContractForReal(requiredSend, addendumPresave, setup, orgID, whenDone);
        }
    }

    sendContractForReal(requiredSend: boolean, addendumPresave: Function, setup: Contract, orgID: string, whenDone: Function) {
        const modalRef = this.modalService.open(LicenseSendDialogComponent, {
            backdrop: "static"
        });
        const modalInstance = modalRef.componentInstance;

        modalInstance.orgID = orgID;
        modalInstance.requiredSend = requiredSend;

        modalRef.result.then((result: any) => {
            if (!result) {
                return; // dialog cancel
            }

            // save the changes now
            addendumPresave();

            // clear stuff for new pending contract
            setup.signedBy = null;
            setup.signatureName = null;
            setup.signatureTitle = null;
            setup.executedDate = null;
            setup.fileID = null;
            setup.pendingApproval = true;

            this.contractService.saveContract(orgID, setup, result.changeDetails)
                .subscribe((contractID: string) => {
                    this.growlService.success("License details saved.");

                    // now send the contract for approval
                    if (result.email) {
                        // non-user
                        let params: any = {};
                        params.organizationID = orgID;
                        params.firstName = result.firstName;
                        params.lastName = result.lastName;
                        params.title = result.title;
                        params.email = result.email;
                        params.contractID = contractID;
                        this.tokenService.emailMSA(params).subscribe((url: string) => {
                            setup.pendingApproval = true;
                            this.growlService.success("License sent to " + result.email + ".");
                            if (url) {
                                this.growlService.success(url, "Invitation URL:", { disableTimeOut: true });
                            }
                            whenDone();
                        }, () => {
                            // error will have been reported already
                        });
                    } else {
                        // mark the license as needing approval - will send email
                        this.contractService
                            .requestContractApproval(orgID, setup.contractType, result.selectedUsernames)
                            .subscribe(() => {
                                setup.pendingApproval = true;
                                this.growlService.success("License sent.");
                                whenDone();
                            }, () => {
                                // error will have been reported already
                            });
                    }
                }, () => {
                });
        }, (/*error*/) => {
            // dialog cancel
        });
    }

    showLicenseDocuments(router: Router, orgID: string) {
        router.navigateByUrl("/admin/organization/" + orgID + "/config/services/license-documents");
    }

    goToSalesforce(opportunityID: string) {
        this.userorgService.getSalesforceAccessURLByData("opportunity", opportunityID)
            .subscribe((url: string) => {
                //if valid url open a new window, otherwise show a growl
                if (url.startsWith("http")) {
                    window.open(url);
                } else {
                    this.growlService.error(url, null, {
                        timeOut: 2500,
                        extendedTimeOut: 2500
                    });
                }
            });
    }
}
