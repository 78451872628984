<sf-tabbed-modal-header title="{{title}}" [currentStep]="currentIndex+1" [stepCount]="stepCount"></sf-tabbed-modal-header>
<div class="modal-body">
    <div class="input-error row" [ngClass]="{'on': warning}">
        <span [innerHTML]="warning"></span>
    </div>

    <!-- step 0 -->
    <form *ngIf="currentIndex == 0" name="datesForm">
        <div class="explain">
            <i>If the customer will not sign the standard Document Builder license,
                you can upload an image of a customized license
                after it has been signed by the customer and approved by Simplifile management.</i>
        </div>
        <div class="explain">
            Enter the details below that were agreed upon in the signed license.
        </div>
        <div class="row">
            <div class="col-sm-5 input-label">
                <label for="exec-date">Signature Date:</label>
            </div>
            <div class="col-sm-7">
                <sf-date-picker id="exec-date" name="exec-date" inputId="exec-date-input" class="upload-date standard-spacing"
                        [(ngModel)]="dialogData.executedDate" [required]="true" [maxDate]="maxSignDate"
                        (dateChange)="blurSignatureDate()" [minDate]="minSignDate"
                        (blur)="blurSignatureDate()"></sf-date-picker>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 input-label">
                <label for="eff-date">Effective Date:</label>
            </div>
            <div class="col-sm-7">
                <sf-date-picker id="eff-date" name="eff-date" inputId="eff-date-input" class="upload-date standard-spacing"
                        [(ngModel)]="effectiveDayjs" (dateChange)="effectiveDateChanged($event)"
                        [minDate]="today" [maxDate]="maxEffectiveDate"></sf-date-picker>
            </div>
        </div>
<!--        <div class="row">-->
<!--            <div class="col-sm-5 input-label">-->
<!--                <label for="exp-date">Expiration Date:</label>-->
<!--            </div>-->
<!--            <div class="col-sm-7">-->
<!--                <sf-date-picker id="exp-date" name="exp-date" inputId="exp-date-input" class="upload-date standard-spacing"-->
<!--                        [(ngModel)]="expirationDayjs" (dateChange)="expirationDateChanged($event)" [required]="true"-->
<!--                        [minDate]="minExpireDate" [maxDate]="maxDate"></sf-date-picker>-->
<!--            </div>-->
<!--        </div>-->
        <div class="row">
            <div class="col-sm-5 input-label">
                <label for="signer">Signer Name:</label>
            </div>
            <div class="col-sm-7">
                <input type="text" id="signer" name="signer" class="form-control standard-spacing" maxlength="68"
                        [(ngModel)]="dialogData.signatureName" [required]="true"/>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 input-label">
                <label for="title">Signer Title:</label>
            </div>
            <div class="col-sm-7">
                <input type="text" id="title" name="title" class="form-control standard-spacing" maxlength="68"
                        [(ngModel)]="dialogData.signatureTitle"/>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-11 mt-2">
                <span><i>* License expiration date will be one {{dialogData.renewalFrequency == "MONTHLY" ? "month" : "year"}} from the signature date.</i></span>
            </div>
        </div>
    </form>

    <!-- step 1 -->
    <form *ngIf="currentIndex == 1" name="feesForm">
        <div class="row">
            <div class="col-sm-5">
                <label>Enrollment Code:</label>
            </div>
            <div class="col-sm-7">
                <button *ngIf="!dialogData.pricingTemplate" class="btn btn-link sf-btn-link" (click)="showEnrollmentCodes()">
                    Add Optional Enrollment Code
                </button>
                <span *ngIf="!!dialogData.pricingTemplate">
                    <b class="code-name">{{dialogData.pricingTemplate.registrationCode}}</b>
                    <button type="button" class="btn btn-secondary btn-small" (click)="changeEnrollmentCode()">
                        Change
                    </button>
                    <button class="btn btn-secondary btn-small" (click)="removeEnrollmentCode()">
                        Remove
                    </button>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 input-label">
                <label for="licFee">Initial License Fee:</label>
            </div>
            <div class="col-sm-7">
                <span>$</span>
                <input type="text" id="licFee" name="licFee" class="form-control upload-money-input standard-spacing"
                        maxlength="10" [(ngModel)]="dialogData.licenseFee"
                        [disabled]="!!dialogData.pricingTemplate || !!existingContract"
                        [required]="true"/>
                <fa-icon *ngIf="!!existingContract" icon="question-circle" class="ms-2"
                        ngbTooltip='This fee amount has already been presented to this organization in a previous version of the contract.'>
                </fa-icon>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 input-label">
                <label for="renewFee">Renewal License Fee:</label>
            </div>
            <div class="col-sm-7">
                <span>$</span>
                <input type="text" id="renewFee" name="renewFee"
                        class="form-control upload-money-input standard-spacing" maxlength="10"
                        [(ngModel)]="dialogData.renewalFee" [disabled]="!!dialogData.pricingTemplate"
                        [required]="true"/>
            </div>
        </div>

        <div *ngIf="dialogData.documentFee.contractFeeTiers.length <= 1 && !showPricingTiers" class="row">
            <div class="col-sm-5">&nbsp;</div>
            <div class="col-sm-7">
                <button class="btn btn-link sf-btn-link" [disabled]="!!dialogData.pricingTemplate" (click)="addPricingTiers()">
                    Add Pricing Tiers
                </button>
            </div>
        </div>
        <div *ngIf="dialogData.documentFee.contractFeeTiers.length <= 1 && !showPricingTiers" class="row">
            <div class="col-sm-5 input-label">
                <label for="docFee">Document Fee:</label>
            </div>
            <div class="col-sm-7">
                <span>$</span>
                <input type="text" id="docFee" name="docFee" class="form-control upload-money-input standard-spacing"
                        maxlength="10" [(ngModel)]="dialogData.documentFee.contractFeeTiers[0].feeAmount"
                        [disabled]="!!dialogData.pricingTemplate" [required]="true"/>
            </div>
        </div>
        <div *ngIf="dialogData.documentFee.contractFeeTiers.length > 1 || showPricingTiers" class="row extra-margin">
            <div class="col-sm-5 input-label">Document Fee Pricing Tiers:</div>
            <div class="col-sm-7">
                <sf-contract-pricing-tiers [contractFee]="dialogData.documentFee"
                    productId="SUBMITTER_SIGNING" (update)="updateContractFee($event)" [thisRef]="this"></sf-contract-pricing-tiers>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-5 input-label">
                <label for="freeDocs">Free Documents:</label>
            </div>
            <div class="col-sm-7">
                <input type="text" id="freeDocs" name="freeDocs"
                        class="form-control upload-money-input standard-spacing" maxlength="8"
                        [(ngModel)]="dialogData.freeSubmissions" [disabled]="!!dialogData.pricingTemplate"
                        [required]="true"/>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 input-label">Billing Cycle:</div>
            <div class="col-sm-7">
                <sf-select class="standard-spacing"
                        [options]="selectableBillingCycles" (select)="selectBillingCycle($event)"
                        trackBy="id" labelBy="label"
                        [isSingleSelect]="true" placeholder="Select a billing cycle..."
                        [selectedOption]="dialogData.billingCycle"
                        [isDisabled]="!!dialogData.pricingTemplate && !!(dialogData.pricingTemplate.billingCycleType)">
                </sf-select>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 input-label">Payment Terms:</div>
            <div class="col-sm-7">
                <sf-select class="standard-spacing"
                        [options]="selectablePaymentTerms" (select)="selectPaymentTerm($event)"
                        trackBy="id" labelBy="label"
                        [isSingleSelect]="true" placeholder="Select a payment term..."
                        [selectedOption]="dialogData.paymentTerms"
                        [isDisabled]="!!dialogData.pricingTemplate && !!(dialogData.pricingTemplate.paymentTerms)">
                </sf-select>
            </div>
        </div>
        <div class="row top-space">
            <div class="col-sm-5">
                <button class="btn btn-secondary" (click)="openFileExplorer()">Choose File</button>
            </div>
            <div class="col-sm-7">{{dialogData.file ? dialogData.file.name : ""}}</div>
        </div>
        <input type="file" accept="application/pdf, image/tiff"
            #file style="display: none;" (change)="onFileAdded()" single />
    </form>
</div>
<sf-tabbed-modal-footer [primary]="primary" [secondary]="secondary" [nextButton]="nextButton"
        [backButton]="backButton"></sf-tabbed-modal-footer>
