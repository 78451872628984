<div *ngIf="!feeCopy">
    Initializing tiers...
</div>
<div class="sf-contract-pricing-tiers" *ngIf="feeCopy">
    <div *ngIf="this.feeCopy.contractFeeTiers.length >= 1 || !readOnly" class="pricing-tiers">
        <div>
            <span *ngIf="feeCopy.contractFeeTiers.length >= 1">{{resetThresholdLabels[feeCopy.thresholdResetPeriod]}}</span>
            <span *ngIf="!readOnly" class="action-span">
                <fa-icon [icon]="['far', 'pencil-alt']" [fixedWidth]="true" (click)="editTiers()"
                        ngbTooltip="Edit Tiers"></fa-icon>
                <fa-icon *ngIf="hasTiers || firstTime" [icon]="['far', 'trash-alt']" [fixedWidth]="true" (click)="deleteTiers()"
                        ngbTooltip="Delete Tiers"></fa-icon>
            </span>
        </div>
        <div class="span-header">
            <span>Amount /  # {{docTypeLabel}}</span>
        </div>
        <div *ngFor="let tier of feeCopy.contractFeeTiers; index as idx">
            <span *ngIf="feeCopy.contractFeeTiers.length > 1">
                <span *ngIf="idx < feeCopy.contractFeeTiers.length - 1" [ngClass]="{'bold': idx == activeTierIndex}">
                    {{tier.feeAmount | currency: 'USD' : 'symbol' : '1.' + decimalPlaces}} / {{tier.tierThreshold}} - {{feeCopy.contractFeeTiers[idx+1].tierThreshold - 1}}
                </span>
                <span *ngIf="idx == feeCopy.contractFeeTiers.length - 1" [ngClass]="{'bold': idx == activeTierIndex}">
                    {{tier.feeAmount | currency: 'USD' : 'symbol' : '1.' + decimalPlaces}} / {{tier.tierThreshold}}+
                </span>
                <span *ngIf="idx == activeTierIndex">
                    <fa-icon [icon]="['far', 'check-circle']" [fixedWidth]="true" class="active-tier"
                            ngbTooltip="Active tier"></fa-icon>
                    <span *ngIf="feeCopy.thresholdResetPeriod === indefiniteThreshold">
                        <span class="tooltip-interaction badge" style="margin-left: 15px"
                                ngbTooltip="Documents recorded">{{currDocCount}}</span>
                    </span>
                    <span *ngIf="feeCopy.thresholdResetPeriod !== indefiniteThreshold">
                        <span class="tooltip-interaction badge ms-2 statusBadge" ngbTooltip="{{getCurrentTooltip()}}">{{currDocCount}}</span>
                        <span class="tooltip-interaction badge ms-1 statusBadge" ngbTooltip="{{getPreviousTooltip()}}">{{prevDocCount}}</span>
                    </span>
                </span>

            </span>
            <span *ngIf="feeCopy.contractFeeTiers.length <= 1 && tier.feeAmount != null && tier.tierThreshold != null">
                <span>
                    {{tier.feeAmount | currency: 'USD' : 'symbol' : '1.' + decimalPlaces}} / {{tier.tierThreshold}}+
                </span>
            </span>
        </div>
    </div>
</div>
