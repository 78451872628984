import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { QueryStringService } from "@sf/common";
import { UserService } from "@sf/userorg/common";
import { LoginService } from "@sf/login";

@Component({
    selector: "sf-must-change-password",
    templateUrl: "./must-change-password.component.html",
    styleUrls: ["./must-change-password.component.scss"]
})
export class MustChangePasswordComponent implements OnInit {
    resetForm: UntypedFormGroup;

    formErrorMessages: string[] = [];
    showChecked = false;
    nextPage: string = null;
    processing = false;
    currentInputIndex = -1;

    keyInstructions: string = "Please update your password";
    username: string = "";
    changeReason: string = "";
    changeReason2: string = "";

    passwordLengthMet = false;
    passwordHas3of4 = false;
    passwordHasNumber = false;
    passwordHasLowerCase = false;
    passwordHasUpperCase = false;
    passwordHasSpecialChar = false;
    passwordsMatch = false;

    pwdLength: number = 9; //default
    blockExtendedChars = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private loginService: LoginService,
        private queryStringService: QueryStringService,
        private userService: UserService
    ) {
        this.resetForm = formBuilder.group({
            oldPassword: [""],
            newPassword: [""],
            confirm: [""]
        });
    }

    ngOnInit() {
        this.nextPage = this.queryStringService.getQueryParam("r");
        this.nextPage = decodeURIComponent(this.nextPage);

        this.focusToPassword();
        this.catchFormChanges();

        this.userService.getChangePasswordSettings().subscribe(
            (results: any) => {
                this.username = results.username;
                this.keyInstructions =
                    "Please update your password for user '" +
                    this.username +
                    "'.";

                if (results.passwordLength)
                    this.pwdLength = parseInt(results.passwordLength);
                this.blockExtendedChars = results.blockExtendedChars;
                let resettingTempPassword: boolean = results.forceChange;
                let changeDays: number = results.daysTillPasswordExpiration;
                let numInvalidPrevPasswords: number =
                    results.unusableNumPreviousPasswords;
                let organizationName: string = results.daysOrganizationName;

                if (resettingTempPassword) {
                    this.changeReason = "You must change your password.";
                    this.changeReason2 = "";
                } else {
                    if (organizationName && changeDays >= 0) {
                        this.changeReason =
                            organizationName +
                            " requires a password change every " +
                            changeDays +
                            " days.";
                    } else if (changeDays) {
                        this.changeReason =
                            "Your administrator requires a password change every " +
                            changeDays +
                            " days.";
                    } else {
                        this.changeReason = "You must change your password.";
                    }
                    this.changeReason2 = "";

                    if (numInvalidPrevPasswords) {
                        if (numInvalidPrevPasswords == 1) {
                            this.changeReason2 =
                                "You may not use your previous password.";
                        } else if (numInvalidPrevPasswords > 1) {
                            this.changeReason2 =
                                "You may not use any of your previous " +
                                numInvalidPrevPasswords +
                                " passwords.";
                        }
                    }
                }
            },
            () => {
                // nothing
            }
        );
    }

    catchFormChanges() {
        this.resetForm.get("newPassword").valueChanges.subscribe((val) => {
            let pwd = this.resetForm.controls.newPassword.value;
            let confirm = this.resetForm.controls.confirm.value;

            this.passwordLengthMet = pwd && pwd.length >= this.pwdLength;
            this.passwordHasNumber = /[0-9]/.test(pwd);
            this.passwordHasLowerCase = /[a-z]/.test(pwd);
            this.passwordHasUpperCase = /[A-Z]/.test(pwd);
            this.passwordHasSpecialChar = /[^a-zA-Z0-9]/.test(pwd);

            let countOfFour = 0;
            if (this.passwordHasNumber) {
                countOfFour++;
            }
            if (this.passwordHasLowerCase) {
                countOfFour++;
            }
            if (this.passwordHasUpperCase) {
                countOfFour++;
            }
            if (this.passwordHasSpecialChar) {
                countOfFour++;
            }
            this.passwordHas3of4 = countOfFour >= 3;

            this.passwordsMatch = confirm === pwd;
        });
        this.resetForm.get("confirm").valueChanges.subscribe((val) => {
            let pwd = this.resetForm.controls.newPassword.value;
            let confirm = this.resetForm.controls.confirm.value;
            this.passwordsMatch = confirm === pwd;
        });
    }

    focusToPassword() {
        this.focusElement("oldPassword");
    }

    focusElement(elementID: string) {
        window.setTimeout(() => {
            let theField = document.getElementById(elementID);
            if (theField) {
                theField.focus();
            }
        }, 200);
    }

    handleBlur(index: number) {
        this.currentInputIndex = -1;
    }

    handleFocus(index: number) {
        this.currentInputIndex = index;
    }

    submitResetForm() {
        this.formErrorMessages = [];

        let oldPassword: string = this.resetForm.controls.oldPassword.value;
        let password: string = this.resetForm.controls.newPassword.value;
        let confirm: string = this.resetForm.controls.confirm.value;

        if (!password) {
            this.formErrorMessages.push("Please enter your new password");
        } else if (!confirm) {
            this.formErrorMessages.push("Please confirm your new password");
        } else if (password != confirm) {
            this.formErrorMessages.push("The two passwords do not match");
        }
        if (this.includesInvalidExtendedChars(password)) {
            this.formErrorMessages.push(
                "The password you have entered contains invalid characters. " +
                    "Please use only uppercase and lowercase letters or the digits 0-9."
            );
        }
        if (!this.formErrorMessages.length) {
            if (
                !this.passwordLengthMet ||
                !this.passwordHas3of4 ||
                !this.passwordsMatch
            ) {
                this.formErrorMessages.push(
                    "Password does not meet requirements"
                );
            }
        }

        if (this.formErrorMessages.length) {
            return;
        }

        this.processing = true;
        this.userService.changePassword(oldPassword, password).subscribe(
            () => {
                //this.navigateAfterConfirm();  // session is already dead
                let loginUrl = "/sf/ui/login?err=password-changed";
                window.location.replace(loginUrl);
            },
            () => {
                this.processing = false;
                this.resetForm.patchValue({
                    newPassword: "",
                    confirm: ""
                });
                let message = "Failed to change password.";
                this.formErrorMessages.push(message);
                this.focusToPassword();
            }
        );
    }

    includesInvalidExtendedChars(pwd: string): boolean {
        // nothing is illegal normally
        if (!this.blockExtendedChars) {
            return false;
        }
        return this.loginService.passwordIncludesExtendedChar(pwd);
    }

    /*
    navigateAfterConfirm() {
        const modal = this.modalService.open(ConfirmationModalComponent);
        const modalInstance = modal.componentInstance;

        modalInstance.title = "Change Password";
        modalInstance.primary = {
            text: "OK",
            responseValue: 1,
            callback: () => {
                modal.close();
                this.goToLogin();
            }
        };
        modalInstance.hideSecondary = true;
        modalInstance.message =
            "Your password has been changed, and you will need to log in again with your new password.";
    }
    */

    clickBack() {
        this.goToLogin();
    }

    goToLogin() {
        // this first version creates a redirection to this page
        //let loginUrl = "login";
        //this.router.navigate([loginUrl], {queryParams: {}, skipLocationChange: true});

        // this causes a page reload, but it works
        let loginUrl = "/sf/ui/login";
        window.location.replace(loginUrl);
    }

    /*
    goToPage(nextPage: string) {
        if (nextPage.startsWith("/sf/ui")) {
            let nextPageInternal = nextPage.replace("/sf/ui", "");
            this.router.navigateByUrl(nextPageInternal, {queryParams: {}});
        } else {
            window.location.assign(nextPage);
        }
    }
    */
}
