<!-- TODO: (?) previous search component didn't display anything for EE for recent items -->
<!--<div *ngIf="showingRecentItems">-->
<!--</div>-->


<div *ngIf="!showingRecentItems">
    <table (click)="uscService.goToESignEventDetails(item)">
        <tr>
            <td style="width: 100%">
                <span class="float-start truncate-name" tooltipClass="tooltip-interaction-not">
                    <button *ngIf="!!item.name" id="btn-name" class="btn btn-link sf-btn-link truncate" sfTooltipOnOverflow="{{ item.name }}" [openDelay]="100" container="body"
                            (click)="uscService.goToESignEventDetails(item)">
                        {{ item.name }}
                    </button>
                </span>
            </td>
            <td style="width: 100%">
                <span class="float-end">
                    <button *ngIf="!!item.status" id="btn-status" class="btn btn-link sf-btn-link truncate" tooltipClass="tooltip-interaction-not"
                            (click)="uscService.goToESignEventDetails(item)" placement="left">
                        {{ item.status }}
                    </button>
                </span>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <div class="lessImportant">
                    <span class="float-start truncate" tooltipClass="tooltip-interaction-not" sfTooltipOnOverflow="{{item.organizationName}}" [openDelay]="100" container="body">
                        {{ item.organizationName }}
                    </span>
                </div>
            </td>
            <td style="width: 30px; padding-left: 10px">
                &nbsp;
            </td>
        </tr>
    </table>
</div>
