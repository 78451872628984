<div *ngIf="supportedType" class="submitter-setting-contents">
    <ng-container *ngTemplateOutlet="contents">
    </ng-container>
</div>

<ng-template #textField>
    <h5 class="submitter-setting-header" >{{setting.name}}</h5>
    <div class="submitter-setting-body">
        <p [innerHTML]="setting.details"></p>
        <div class="submitter-setting-value">
            <label>
                <input type="text" [(ngModel)]="submitterValues[setting.name]">
                {{labelText}}
            </label>
        </div>
    </div>
</ng-template>

<ng-template #checkboxField>
    <h5 class="submitter-setting-header" >{{setting.name}}</h5>
    <div class="submitter-setting-body">
        <p [innerHTML]="setting.details"></p>
        <div class="submitter-setting-value">
            <label>
                <input type="checkbox" [checked]="submitterValues[setting.name] === 'true'"
                       (change)="checkboxChanged($event, setting.name)" >
                {{labelText}}
            </label>
        </div>
    </div>
</ng-template>

<ng-template #mouField>
    <label>
        <input type="checkbox" [checked]="submitterValues[setting.name] === 'true'"
               (change)="checkboxChanged($event, setting.name)">
        <span class="mou-name ms-1">{{setting.name}}</span> {{labelText}}
    </label>
</ng-template>

<ng-template #dropdownField>
    <h5 class="submitter-setting-header" >{{setting.name}}</h5>
    <div class="submitter-setting-body">
        <div ngbDropdown class="submitter-setting-value">
            <button class="btn btn-outline-primary me-2" ngbDropdownToggle>{{submitterValues[setting.name] ? submitterValues[setting.name] : "Select an option"}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownManual" class="settings-dropdown">
                <button *ngFor="let option of setting.options" ngbDropdownItem (click)="submitterValues[setting.name] = option">{{option}}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #textOnlyField>
    <h5 class="submitter-setting-header" >{{setting.name}}</h5>
    <div class="submitter-setting-body">
        <p [innerHTML]="setting.details"></p>
    </div>
</ng-template>
