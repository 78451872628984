import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TestComponent } from "./test/test.component";
import { routes } from "./app.routes";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UserorgModule } from "@sf/userorg/main";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SfCommonModule } from "@sf/common";
import { SelectModule } from "@sf/common";
import { SfFormsModule } from "@sf/common";
import { DateAndTimeModule } from "@sf/common";

@NgModule({
    declarations: [TestComponent],
    imports: [
        CommonModule,
        FormsModule,
        UserorgModule,
        SelectModule,
        SfCommonModule,
        SfFormsModule,
        DateAndTimeModule,
        NgbModule,
        FontAwesomeModule,
        RouterModule.forRoot(routes),
        ReactiveFormsModule
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
