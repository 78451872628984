<sf-modal-header title="Customer Support Question"></sf-modal-header>
<form [formGroup]="phoneSupportQuestionForm">
    <div *ngIf="!loaded">
        <fa-icon icon="spinner" [spin]="true"></fa-icon>
        <span> Loading...</span>
    </div>
    <div class="modal-body" *ngIf="questionList$ | async as questionList">
        <div class="explain" *ngIf="loaded">
            <span>Please select one question and answer that we will ask you whenever you call our support center. </span>
            <span>This is to help ensure that someone doesn't try to impersonate you.</span>
        </div>
        <div class="row main-area">
            <div class="mt-3 col-md-12">
                <div class="form-row">
                    <div class="mb-3 col-md-8">
                        <label for="phoneQuestion"><strong>Phone Support Question</strong></label>
                        <sf-select formControlName="phoneQuestion"
                                   id="phoneQuestion" placeholder="Required"
                                   [isSingleSelect]="true"
                                   [options]="questionList"
                                   trackBy="id" labelBy="question"
                                   class="sf-mask"
                        ></sf-select>
                    </div>
                    <div class="mb-3 col-md-4">
                        <label for="phoneAnswer"><strong>Answer</strong></label>
                        <input #answer type="password" class="form-control security-answer sf-exclude"
                               formControlName="phoneAnswer" autocomplete="new-password" id="phoneAnswer"
                               placeholder="Required" (focus)="onFocus($event)" (blur)="onBlur($event)">
                    </div>
                </div>
                <div class="form-row">
                    <div class="mb-3 col-md-8"></div>
                    <div class="mb-3 form-check col-md-4">
                        <input class="formCheckInput" type="checkbox" id="showCheckbox" formControlName="showAnswersCheckbox">
                        <label class="formCheckLabel" for="showCheckbox">
                            Show Answers
                        </label>
                    </div>
                </div>
                <div class="mb-3 row mt-1 col-md-9">
                    <label for="currentPassword" class="col-form-label" style="padding-left: 0;">For security, enter your current password:</label>
                    <input type="password" maxlength="32"
                           formControlName="currentPassword"
                           id="currentPassword" class="form-control mx-sm-3 col-md-4 float-end sf-exclude"
                           autocomplete="new-password" placeholder="Required">
                </div>
            </div>
        </div>
    </div>
</form>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
