import {
    AfterViewInit,
    Component,
    ContentChildren,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    Output,
    QueryList
} from "@angular/core";
import { merge, Observable, Subject } from "rxjs";
import Shepherd from "shepherd.js";
import { ShepherdService } from "angular-shepherd";
import { MetricLoggerService } from "../../../services/metric-logger.service";
import { takeUntil } from "rxjs/operators";
import { TourStepComponent } from "../tour-step/tour-step.component";
import { DOCUMENT } from "@angular/common";
import { LoggerType } from "../../../enums/logger-type.enum";

enum TOUR_EVENT {
    FINISH,
    CANCEL,
    START,
    STEP_COMPLETE
}

const TOUR_GROUP = "tours";
const TOUR_STEP_MARK_NAME = "tour_step_show";

@Component({
    selector: "sf-tour",
    template: `<ng-content></ng-content>`,
    styleUrls: ["tour.component.scss"]
})
export class TourComponent implements AfterViewInit, OnDestroy {
    @ContentChildren(TourStepComponent)
    stepComponents: QueryList<TourStepComponent>;

    @Output()
    afterInit?: EventEmitter<any> = new EventEmitter();
    @Output()
    afterTour?: EventEmitter<any> = new EventEmitter();
    @Input()
    tourName: string;
    @Input()
    modal?: boolean;

    private _destroy$: Subject<void> = new Subject();
    private _tour: Shepherd.Tour = this._shepherdService.tourObject;

    private _backButton: Shepherd.Step.StepOptionsButton = {
        action: function () {
            return this.back();
        },
        classes: "shepherd-button-secondary",
        text: "Back"
    };

    private _nextButton: Shepherd.Step.StepOptionsButton = {
        action: function () {
            return this.next();
        },
        classes: "shepherd-button-primary",
        text: "Next"
    };

    constructor(
        private _shepherdService: ShepherdService,
        private _loggerService: MetricLoggerService,
        @Inject(DOCUMENT) private _document: HTMLDocument
    ) {}

    ngOnInit() {
        this._shepherdService.confirmCancel = false;
        this._shepherdService.defaultStepOptions = {
            arrow: true,
            cancelIcon: { enabled: true },
            canClickTarget: false,
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 3,
            scrollTo: false,
            floatingUIOptions: {
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 15]
                        }
                    }
                ]
            }
        };
        this._shepherdService.modal = this.modal ?? false;
        this._shepherdService.tourName = this.tourName;
    }

    ngAfterViewInit() {
        // Allow full page (with navigation) to render
        setTimeout(() => {
            const steps: any[] = [];
            for (const step of this.stepComponents) {
                if (this._isAttachToVisible(step.attachTo)) {
                    steps.push(this._createTourStep(step, steps.length));
                }
            }

            this._shepherdService.addSteps(steps);
            this._tour = this._shepherdService.tourObject;
            if (this.afterInit) {
                this.afterInit.emit({
                    tour: {
                        tour: this._tour,
                        startTour: this._tour.start // Backwards-compatibility method
                    }
                });
            }

            const {
                startEvent,
                showEvent,
                cancelEvent,
                completeEvent,
                destroyEvent
            } = this._initializeTourEvents(this._tour);

            startEvent
                .pipe(takeUntil(this._destroy$))
                .subscribe(() => this._logEventMetric(TOUR_EVENT.START, 1));

            showEvent.pipe(takeUntil(this._destroy$)).subscribe(() => {
                // step started (either from going forwards or backwards)
                this._logTourStepLength();
            });

            cancelEvent.pipe(takeUntil(this._destroy$)).subscribe(() => {
                this._logTourStepLength();
                this._logEventMetric(TOUR_EVENT.CANCEL, 1);
            });

            completeEvent.pipe(takeUntil(this._destroy$)).subscribe(() => {
                this._logTourStepLength();
                this._logEventMetric(TOUR_EVENT.FINISH, 1);
            });

            destroyEvent.pipe(takeUntil(this._destroy$)).subscribe();

            merge(cancelEvent, completeEvent, destroyEvent)
                .pipe(takeUntil(this._destroy$))
                .subscribe(() => {
                    if (this.afterTour) {
                        this.afterTour.emit();
                    }
                });
        }, 1);
    }

    ngOnDestroy() {
        this._shepherdService.cancel();
        this._destroy$.next();
        this._destroy$.complete();
    }

    public startTour() {
        this._shepherdService.start();
    }

    private _showStepProgress() {
        const steps = this._tour.steps;
        const currentStepElement = this._tour.getCurrentStep();
        const header = currentStepElement
            .getElement()
            .querySelector(".shepherd-header h3");
        const progress = document.createElement("span");
        progress.style.position = "absolute";
        progress.style.right = "33px;";
        progress.innerText = `${steps.indexOf(currentStepElement) + 1}/${
            steps.length
        }`;
        header.appendChild(progress);
    }

    private _initializeTourEvents(tour: Shepherd.Tour): {
        startEvent: Observable<void>;
        showEvent: Observable<void>;
        cancelEvent: Observable<void>;
        completeEvent: Observable<void>;
        destroyEvent: Observable<void>;
    } {
        const startEvent = new Observable<void>((subscriber) => {
            tour.on("start", () => subscriber.next());
        });

        const showEvent = new Observable<void>((subscriber) => {
            tour.on("show", () => subscriber.next());
        });

        const cancelEvent = new Observable<void>((subscriber) => {
            tour.on("cancel", () => subscriber.next());
        });

        const completeEvent = new Observable<void>((subscriber) => {
            tour.on("complete", () => subscriber.next());
        });

        const destroyEvent = new Observable<void>((subscriber) => {
            tour.on("destroy", () => subscriber.next());
        });

        return {
            startEvent,
            showEvent,
            cancelEvent,
            completeEvent,
            destroyEvent
        };
    }

    private _logEventMetric(event: TOUR_EVENT, value: number) {
        const metricName = "tour_" + event + "_" + this.tourName;
        this._loggerService
            .getLogger(LoggerType.FALCON)
            .recordMetric(TOUR_GROUP, metricName, value);
    }

    private _logTourStepLength() {
        const perf = window.performance;
        if (!perf) {
            return;
        }

        // mark that the step started
        perf.mark(TOUR_STEP_MARK_NAME);
        const entries = perf.getEntriesByName(TOUR_STEP_MARK_NAME);
        if (entries.length < 2) {
            return;
        }
        const lastMark = entries[entries.length - 1];
        const secondToLastMark = entries[entries.length - 2];
        // length of the step (in milliseconds)
        const stepMeasure = lastMark.startTime - secondToLastMark.startTime;
        this._logEventMetric(TOUR_EVENT.STEP_COMPLETE, stepMeasure);
    }

    private _createTourStep(
        step: TourStepComponent,
        index: number
    ): Shepherd.Step.StepOptions {
        const newStep: Shepherd.Step.StepOptions = {
            title: step.title,
            text: step.text,
            buttons: [
                {
                    ...this._nextButton,
                    action: step.nextAction ?? this._nextButton.action,
                    text: step.nextText ?? this._nextButton.text
                }
            ],
            when: { show: this._showStepProgress.bind(this) }
        };

        if (index !== 0 || step.backAction) {
            newStep.buttons = [
                {
                    ...this._backButton,
                    action: step.backAction ?? this._backButton.action,
                    text: step.backText ?? this._backButton.text
                },
                ...newStep.buttons
            ];
        }

        if (step.attachTo) {
            const separator = step.attachTo.lastIndexOf(" ");
            const attachElement = step.attachTo.substring(0, separator);
            const attachDirection = step.attachTo.substring(separator + 1);
            // const [attachElement, attachDirection] = step.attachTo.split(" ");
            newStep.attachTo = {
                element: attachElement,
                on:
                    (attachDirection as Shepherd.Step.PopperPlacement) ??
                    "bottom"
            };
        }

        return newStep;
    }

    // If there is an attach to property for a step, determine if the element it wants
    // to attach to is actually visible on the page
    private _isAttachToVisible(attachTo: string) {
        let isVisible = true;
        if (!!attachTo) {
            const separator = attachTo.lastIndexOf(" ");
            let attachElement = attachTo.substring(0, separator);
            attachElement = attachElement.length ? attachElement : attachTo;
            const elem = this._document
                .querySelectorAll(attachElement)
                .item(0) as HTMLElement;
            if (!elem) {
                isVisible = false;
            } else {
                isVisible =
                    elem.style.display !== "none" &&
                    elem.style.visibility !== "hidden" &&
                    !elem.classList.contains("invisible");
            }
        }

        return isVisible;
    }
}
