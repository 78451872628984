<div class="mb-3 row" *ngIf="formControl$ | async as control" [formGroup]="controlParent$ | async">
    <div class="col-md" [ngClass]="{'value-wrong':control.invalid}">
        <button [class]="!(isRequired$ | async) || uploadComplete ? 'btn btn-primary' : 'btn btn-primary'" [id]="field.id+'_custom_upload'" (click)="fileInput.click()">{{field.label}}</button>
        <input
                hidden
                type="file"
                accept=".pdf"
                [formControl]="control"
                #fileInput
                (change)="onFileSelected($event)">
        <div class="mt-2" *ngIf="fileName">
            {{fileName}} <br> {{uploadDate}}
        </div>
        <sf-form-error *ngIf="control.invalid && (!control.pristine || (control.pristine && !control.errors['required']))"
                       [control]="control"
                       [label]="field.label">
        </sf-form-error>
    </div>
</div>
