import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ConfirmationModalComponent } from "../../modals/components/confirmation-modal/confirmation-modal.component";
import { ModalButton } from "../../modals/interfaces";
import { ConfigurationService } from "../../services/configuration.service";
import { SessionService } from "../../services/session.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ViewInfo } from "./view-manager.interface";

const CLEARED_NAME = "[Not Specified]";

@Component({
    selector: "sf-view-manager",
    templateUrl: "./view-manager.component.html",
    styleUrls: ["./view-manager.component.scss"]
})
export class ViewManagerComponent implements OnInit {
    @Input()
    filterChanged$: Observable<ViewInfo>;
    @Input()
    showClear: boolean = false;
    @Input()
    viewSelected$: Subject<ViewInfo>;
    @Input()
    viewsKey: string;

    @ViewChild("manageViewsTemplate")
    manageViewsTemplate: any;
    @ViewChild("nameInputTemplate")
    nameInputTemplate: any;

    activeView: ViewInfo;
    possibleViews: ViewInfo[];
    inputViewName: string;
    nameExists: string;
    viewChanged: boolean;

    private _currentFilter: any;
    private _primaryButton: ModalButton;
    private _activeModal: NgbModalRef;

    constructor(
        private _modalService: NgbModal,
        private _sfConfigurationService: ConfigurationService,
        private _sfSessionService: SessionService
    ) {}

    ngOnInit(): void {
        this.viewChanged = false;
        this._sfConfigurationService
            .getUserSettingCustom(
                this._sfSessionService.getUsername(),
                this.viewsKey
            )
            .subscribe((jsonData: string) => {
                this.possibleViews = jsonData ? JSON.parse(jsonData) : [];
                this._sortViews();
                let dflt = this.possibleViews.find((view) => view.default);
                if (!dflt) {
                    dflt = this.possibleViews.length
                        ? this.possibleViews[0]
                        : {
                              unsaved: true,
                              name: "(No Views Defined)",
                              filter: {},
                              tooltip:
                                  "Add filters and/or specify sort order then select 'Save Current As' to create a named view",
                              tooltipDelay: 400
                          };
                }
                this.selectView(dflt);
            });
        this.filterChanged$.subscribe((changedView) => {
            if (changedView?.name) {
                this.activeView = changedView;
            }
            this._currentFilter = changedView.filter;
            this.viewChanged = this.activeView?.name !== CLEARED_NAME;
        });
    }

    copyView(view: ViewInfo) {
        let callback = () => {
            let copy = { ...view };
            copy.name = this.inputViewName.trim();
            delete copy.default;
            this.possibleViews.push(copy);
            this._saveViews();
        };
        this._inputViewName(`Copy ${view.name} To`, callback);
    }

    doClear() {
        this.selectView({ name: CLEARED_NAME, filter: {}, reset: true });
    }

    labelChanged() {
        this.nameExists = this.possibleViews.find(
            (view) =>
                view.name.toLowerCase() ===
                this.inputViewName.trim().toLowerCase()
        )?.name;
        this._primaryButton.disabled =
            this.inputViewName.length === 0 || !!this.nameExists;
    }

    manageViews() {
        this._activeModal = this._modalService.open(
            ConfirmationModalComponent,
            {
                windowClass: "manage-views-dialog"
            }
        );
        const modalInstance = this._activeModal.componentInstance;

        modalInstance.template = this.manageViewsTemplate;
        modalInstance.title = "Manage Saved Views";
        modalInstance.primary = {
            text: "OK",
            callback: () => {
                return true;
            }
        };
        modalInstance.secondary = {
            hidden: true
        };
    }

    onEnter() {
        if (!this._primaryButton.disabled) {
            this._primaryButton.callback();
            this._activeModal.close();
        }
    }

    removeView(view: ViewInfo) {
        this._activeModal = this._modalService.open(ConfirmationModalComponent);
        const modalInstance = this._activeModal.componentInstance;

        modalInstance.message = `Permanently remove view "${view.name}" from your list?`;
        modalInstance.title = "Remove Saved View";
        this._primaryButton = {
            text: "OK",
            callback: () => {
                let idx = this.possibleViews.findIndex(
                    (vw) => vw.name === view.name
                );
                this.possibleViews.splice(idx, 1);
                this._saveViews();
                return true;
            }
        };
        modalInstance.primary = this._primaryButton;
        modalInstance.secondary = {
            text: "Cancel",
            responseValue: -1
        };
    }

    renameView(view: ViewInfo) {
        let callback = () => {
            view.name = this.inputViewName.trim();
            this._saveViews();
        };
        this._inputViewName(`Rename "${view.name}" To`, callback);
    }

    saveCurrentAs() {
        let callback = () => {
            let current = {
                name: this.inputViewName.trim(),
                filter: this._currentFilter
            };
            this.possibleViews.push(current);
            this._saveViews();
            this.selectView(current);
        };
        this._inputViewName("Save Current View As", callback);
    }

    selectView(view: ViewInfo) {
        if (this._modalService.hasOpenModals()) {
            this._modalService.dismissAll();
        }
        this.activeView = view;
        this._currentFilter = view.filter;

        this.viewSelected$.next(view);
        this.viewChanged = false;
    }

    setCurrentAsDefault() {
        this.setDefault(this.activeView);
    }

    setDefault(view: ViewInfo) {
        this.possibleViews.forEach((vw) => {
            delete vw.default;
        });
        view.default = true;
        this._saveViews();
    }

    updateView() {
        this.activeView.filter = this._currentFilter;
        this._saveViews();
        this.viewChanged = false;
    }

    private _inputViewName(title: string, callback: any) {
        this._activeModal = this._modalService.open(ConfirmationModalComponent);
        const modalInstance = this._activeModal.componentInstance;

        modalInstance.template = this.nameInputTemplate;
        modalInstance.title = title;
        this._primaryButton = {
            text: "Save",
            disabled: true,
            callback: () => {
                callback();
                return true;
            }
        };
        modalInstance.primary = this._primaryButton;
        modalInstance.secondary = {
            text: "Cancel",
            responseValue: -1
        };
        this.inputViewName = "";
        this.nameExists = "";
    }

    private _saveViews() {
        this._sortViews();
        let json = JSON.stringify(this.possibleViews);
        this._sfConfigurationService
            .setUserSettingCustom(
                this._sfSessionService.getUsername(),
                this.viewsKey,
                json
            )
            .subscribe();
    }

    private _sortViews() {
        this.possibleViews = this.possibleViews.sort((a, b) =>
            a.name.localeCompare(b.name)
        );
    }
}
